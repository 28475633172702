import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterOrders',
  pure: false
})
@Injectable()
export class FilterOrdersPipe implements PipeTransform {
  transform(items: Array<any>, filters: {[key: string]: any }): Array<any> {
    for(let key in filters)  {
      if(filters[key]) {
        items = items.filter(item => item[key] == filters[key]);
      }
    }
    return items;
  }
}
