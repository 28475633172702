import { Component, OnInit, OnDestroy, Input, OnChanges, HostListener, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { NavigationService } from '../../../../utils/services/navigation.service';
import { ChatsService } from '../../../../utils/services/chats.service';
import { WebSocketService } from '../../../../utils/services/web-socket.service';
import { CommonAPIService } from '../../../../utils/services/common-api.service';
import { ImageModalService } from '../../../../utils/services/image-modal.service';
import { NotificationService } from '../../../../utils/services/notification.service';
import { environment } from 'environments/environment';
import { CommonService } from 'app/utils/services/common.service';

import * as _ from 'lodash';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from "firebase";
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Subscription,Subject} from 'rxjs';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('attachmentInput', { static: false }) attachmentInput: ElementRef
  isChatPanelOpen = false;
  showAddDeleteMember = false;
  isSmallScreen = false;
  tinyMceAPIKey = environment.config.tinyMceAPIKey;
  chatId;
  chatMessages: any[];
  chatMembers: object[] = [];
  userId;
  msgContent;
  @Input() inputOrderId: number;
  @Input() showOrderChatIcon: boolean;
  @ViewChild('scrollContainer') scrollContainer:ElementRef;
  showFullPageChat = false;
  showSmallScreenSearch = false;
  showEditOrderContext = false;
  showThreads = false;
  showChat = false;
  showArtworkGarment = false;
  showGarment = true;
  showArtwork = false;
  currentArtGarTab = 'garment';
  showSearchFilters = false;
  unreadMessagesData: number[];
  unreadMessages: number;
  isMarkedUnread = false;
  routeSubscription: any;
  allOrderChats = [];
  allUnreadChats = [];
  chatsList = [];
  selectedChat = null;
  orderProducts = [];
  orderArtworkDetails = [];
  artworkApprovalImages = [];
  currentThreadTab = 'all';
  defaultUserImg = 'assets/images/user-default.jpg';
  currentUser = null;
  editorInit = {
    height: 100,
    autoresize_bottom_margin: 0,
    max_height: 427,
    menubar: false,
    content_style: '@font-face {font-family: circular std book;src: url("/assets/fonts/circular-std/Circular-Std-Book.ttf") format("truetype");} li{font-size: 14px;color:#303252} li::marker{color:#303252} p { margin: 0; font-size: 14px;color:#303252; font-family: "circular std book";} .member-mention {color: #EC6036;} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: #9D9EB2 !important;font-size: 14px; font-weight: 300; letter-spacing: 0; text-align: left; font-family: "circular std book";}',
    toolbar_location: 'bottom',
    placeholder: 'Type your message here...',
    plugins: 'lists emoticons image mention paste autoresize',
    paste_as_text: true,
    toolbar:
      'bold italic strikethrough bullist emoticons',
    external_plugins: {
      'mention': `${environment.config.proxy_url}scripts/mention.js`,
    },
    setup: null,
    mentions: {
      source: [],
      queryBy: 'queryTerm',
      render: function (item) {
        return `<li class="${item.listIndex == 1 ? 'active' : ''}" data-totalItems="${item.totalItems}" data-listIndex="${item.listIndex}"><img src="${item.profile_pic || 'assets/images/user-default.jpg'}" /> <div class="mention-name">${item.name}</div><div class="splitter"></div><div class="mention-role">${item.permission}</div></li>`;
      },
      insert: undefined,
      items: 10
    },
  };

  dataFromIndex = 0;
  isLoadingMessages = false;
  previousScrollHeightMinusTop = 0;
  isOrderCancelled = false;

  progress = 0;
  uploads: object[] = [];
  onType = null;
  editorInstance = null;
  fetchMoreOrderChats = true;
  isSearching = false;
  searchText = '';
  membersMenuHandler = null;
  threadMenuHandler = null;
  editOrderMenuHandler = null;
  fetchingUnreadThreads = false;
  fetchingAllThreads = false;
  chatLinkHover = false;
  orderLink = '/sales/'
  intervalIdForMentionDropdown = null;
  intervalIdForDropdownStyling = null;
  unreadChatThreadNotificationsSub: Subscription;
  unreadChatThreadNotifications = [];
  fetchedChats = false;
  closeMessageActionMenu = null;
  idOfmsgInAction = null;
  searchUser = '';
  searchedChatUsers = [];
  ssActiveWearStyleImage = '';
  msgBeingEdited = null;
  isEditorFocused = false;
  msgBeingDeleted = null;
  deleteMsgEventHandler = null;
  totalProductImage = 0;
  memberBeingDeleted = null;
  deleteMemberEventHandler = null;
  allHQUsers = [];
  showConfirmDelete = false;
  threadInAction = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    public chatsService: ChatsService,
    private notificationService: NotificationService,
    private webSocketService: WebSocketService,
    private commonAPIService: CommonAPIService,
    public navService: NavigationService,
    private imgService: ImageModalService,
    private router: Router,
    private storage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private renderer: Renderer2,
    private commonService: CommonService)
     {
        this.unreadChatThreadNotificationsSub = notificationService.unreadChatThreadNotifications.subscribe((chatNotifications)=>{
          this.unreadChatThreadNotifications = [...chatNotifications];
        })
     }

  ngOnInit(): void {
    this.subscribeToRoute();
    this.initializeChat();
    this.listenToKeyUpEvents();
    this.addClickListenerToHideActionMenu();    
  }

  ngAfterViewInit() {
    this.setCustomScrollForEditor();
  }

  toggleThreadActions(event, chat) {
    event.stopPropagation();
    chat.clientX = (event.clientX - 20);
    chat.clientY = this.isSmallScreen ? (event.clientY + 20) : (event.clientY + 10);
    chat.showChatMenu = !chat.showChatMenu;
    if (chat.showChatMenu) {
      if (this.threadInAction && this.threadInAction.chat_id !== chat.chat_id) {
        this.threadInAction.showChatMenu = false;
      }
      this.threadInAction = chat;
    }
    this.closeThreadActionMenuOnOutsideClick(chat);
  }

  closeThreadActionMenuOnOutsideClick(chat) {
    const containerEle: any = document.getElementById('chatPageContainer');
    const threadListContainer: any = document.getElementById('threadListContainer');
    
    this.threadMenuHandler = (event) => {
      chat.showChatMenu = false;
    };
    if (chat.showChatMenu) {
      this.addThreadMenuClickListener(containerEle, threadListContainer);
    }
  }

  addThreadMenuClickListener (containerEle, listElement) {
    document.addEventListener('click', this.threadMenuHandler);
    listElement.addEventListener('scroll', this.threadMenuHandler);
    if (containerEle) {
      containerEle.addEventListener('click', this.threadMenuHandler);
    }
  }

  removeThreadMenuClickListener (containerEle) {
    document.removeEventListener('click', this.threadMenuHandler);
    if (containerEle) {
      containerEle.removeEventListener('click', this.threadMenuHandler);
    }
  }

  async markChatReadUnread(chat, mark, event = null) {
    if (event) event.stopPropagation();
    chat.showChatMenu = false;
    const response: any = await this.commonAPIService.fnMarkChatReadUnread({userId: this.userId, chatId: chat.chat_id, mark});
    if (response && response.isMarkUpdated) {
      chat.isMarkedUnread = mark;
      if (mark) {
        if (!this.allUnreadChats.some((thread) => thread.chat_id === chat.chat_id)) {
          this.allUnreadChats.unshift(chat);
        }
      } else {
        this.editChatListToMarkChatRead(chat);
      }
    }
    this.chatsService.fetchAllUnreadMessagesCounts();
  }

  checkIfMarkedUnread(chat) {
    if (chat.marked_unread_by) {
      return !!(JSON.parse(chat.marked_unread_by).find((user) => user === this.userId));
    }
    return false;
  }

  setCustomScrollForEditor() {
    const intervalId = setInterval(() => {
      if (this.editorInstance && this.editorInstance.contentDocument) {
        const style: any = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
        .custom-scroll::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
        .custom-scroll::-webkit-scrollbar-thumb {
            background: #E0E0E1;
            outline: none;
            border-radius: 60px;
        }`;

        const editorNodes: any = Array.from(this.editorInstance.contentDocument.all);
        if (editorNodes && editorNodes.length) {
          const head = editorNodes.find((node) => node.tagName === 'HEAD');

          if (head) {
            head.appendChild(style);
            const body = editorNodes.find((node) => node.tagName === 'BODY');
            if (body) {
              body.classList.add('custom-scroll');
              clearInterval(intervalId);
            }
          }
        }
      }
    }, 2000);
  }

  updateCurrentUser() {
    this.userId =  this.commonAPIService.fnGetUser()['id'];
    if (this.userId) {
      this.getCurrentUserDetails(this.userId);
    }
  }

  updateMentionsInCurrentMessage(keyCode) {
    if ([37, 38, 39, 40].includes(keyCode)) {
      return;
    }
    const node = this.editorInstance.selection.getNode();
    // console.log('getting current node',node);
    if (node && node.className.includes('member-mention')) {
      let currentNodeClass = '';
      node.className.replace(/mention-[a-z0-9\-]*/gi, (match) => {
        currentNodeClass = match;
        return match;
      });

      if (currentNodeClass) {
        this.msgContent = this.msgContent.replace(/<span class="member-mention mention-[a-z0-9\-]*">[a-z\s]*<\/span>/gi, (match) => {
          let matchFound = false;
          match.replace(currentNodeClass, (match) => {
            matchFound = true;
          });

          if (matchFound) {
            match = match.replace(`member-mention`, 'empty-mention').replace(/>[a-z\s]*</i, '>&nbsp;<');
            setTimeout(() => {
              const currentNode: any = Array.from(this.editorInstance.contentDocument.all).find((element: any) => element.className.includes(currentNodeClass));
              this.editorInstance.selection.setCursorLocation(currentNode, 0);
            });
          }

          return match;
        });
      }
    } else {
      this.msgContent = (this.msgContent || '').replace(/<span class="member-mention mention-[a-z0-9\-]*">[a-z\s]*<\/span>/gi, (match) => {
        let currentNodeClass = '';
        match.replace(/mention-[a-z0-9\-]*/gi, (match) => {
          currentNodeClass = match;
          return match;
        });

        let taggedUser = '';
        match.replace(/>[a-z\s]+</gi, (match) => {
          taggedUser = match.replace(/[<>]+/gi, '');
        });

        if (!taggedUser || (!this.chatMembers.some((user: any) => user.name === taggedUser) &&  !this.allHQUsers.some((user: any) => user.name === taggedUser))) {
          match = match.replace(`member-mention`, 'empty-mention').replace(/>[a-z\s]*</i, '>&nbsp;<');
          setTimeout(() => {
            const currentNode: any = Array.from(this.editorInstance.contentDocument.all).find((element: any) => element.className.includes(currentNodeClass));
            this.editorInstance.selection.setCursorLocation(currentNode, 1);
          });
        }
        return match;
      });
    }
  }

  addClickListenerToHideActionMenu() {
    this.closeMessageActionMenu = (event) => {
      if (event.target.className.includes('msg-dot')) return;
      this.closeCurrentMsgActionMenu();
    };
    const containerEle: any = document.getElementById('chatPageContainer');
    if (containerEle) {
      containerEle.addEventListener('click', this.closeMessageActionMenu);
    }
    document.addEventListener('click', this.closeMessageActionMenu);
  }

  onMouseEnterLeaveFromMsg(event: any, msg: any) {
    if (msg.is_deleted || msg.is_status_change || this.isSmallScreen || msg.user_id !== this.currentUser.user_id || msg.showDeleteConfirmation || msg.isBeingEdited || window.matchMedia("(pointer: coarse)").matches) return;
    if (event.type == 'mouseenter') {
      if (!this.showFullPageChat) {
        this.showMsgDots(msg);
      } else {
        this.showMsgActionMenu(msg);
      }
    } else if (event.type == 'mouseleave') {
      if (!this.showFullPageChat) {
        this.hideMsgDots(msg);
      } else {
        if (!window.matchMedia("(pointer: coarse)").matches) {
          this.hideMsgActionMenu(msg);
        }
      }
    }
  }

  onMsgPress(event, msg) {
    msg.pressTimer = setTimeout(() => {
      this.onMsgClick(event, msg);
      clearTimeout(msg.pressTimer);
      msg.pressTimer = null;
    }, 500);
  }

  endMsgPress(event, msg) {
    if (msg.pressTimer) {
      clearTimeout(msg.pressTimer);
    }
  }

  onMsgClick(event, msg) {
    event.stopPropagation();
    if (msg.is_deleted || msg.is_status_change || (this.showFullPageChat && !this.isSmallScreen && !window.matchMedia("(pointer: coarse)").matches) || msg.user_id !== this.currentUser.user_id) return;
    this.closeCurrentMsgActionMenu();


    if (this.isSmallScreen) {
      this.showMsgActionMenu(msg);
      msg.isInActionMode = true;
    } else if (window.matchMedia("(any-pointer: coarse)").matches) {
      if (!this.showFullPageChat) {
        this.showMsgDots(msg);
      } else {
        this.showMsgActionMenu(msg);
        msg.isInActionMode = true;
      }
    }
  }

  closeCurrentMsgActionMenu() {
    if (this.idOfmsgInAction) {
      const msg = this.chatMessages.find((msg) => msg.id === this.idOfmsgInAction);
      this.hideMsgActionMenu(msg);
      this.hideMsgDots(msg);
      msg.isInActionMode = false;
    }
  }

  onDotsClick(msg: any) {
    msg.showDotOptions = true;
  }

  showMsgActionMenu(msg: any) {
    msg.showMsgActionMenu = true;
    this.idOfmsgInAction = msg.id;
  }

  showMsgDots(msg: any) {
    msg.showDots = true;
    this.idOfmsgInAction = msg.id;
  }

  hideMsgActionMenu(msg: any) {
    msg.showMsgActionMenu = false;
    this.idOfmsgInAction = null;
  }

  hideMsgDots(msg: any) {
    msg.showDots = false;
    msg.showDotOptions = false;
    this.idOfmsgInAction = null;
  }

  addDeleteMsgClickListener() {
    this.deleteMsgEventHandler = (event) => {
      const currentNode = event.target;
      const msgDeleteBtn = document.getElementById('deleteMsgPopup');
      if (msgDeleteBtn && currentNode.id !== msgDeleteBtn.id && !msgDeleteBtn.contains(currentNode)) {
        this.cancelCurrentDeleteOperation();
        this.removeDeleteMsgClickListener();
      }
    };
    document.addEventListener('click', this.deleteMsgEventHandler);
    const containerEle: any = document.getElementById('chatPageContainer');
    if (containerEle) {
      containerEle.addEventListener('click', this.deleteMsgEventHandler);
    }
  }

  removeDeleteMsgClickListener() {
    document.removeEventListener('click', this.deleteMsgEventHandler);
    const containerEle: any = document.getElementById('chatPageContainer');
    if (containerEle) {
      containerEle.removeEventListener('click', this.deleteMsgEventHandler);
    }
  }

  cancelCurrentDeleteOperation() {
    if (this.msgBeingDeleted) {
      this.msgBeingDeleted.showDeleteConfirmation = false;
      this.msgBeingDeleted.isInActionMode = false;
      this.msgBeingDeleted = null;
    }
  }

  askDeleteConfirmation(event, msg, popup = null) {
    event.stopPropagation();
    if (!this.showFullPageChat || this.isSmallScreen) {
      this.showConfirmDelete = true;
      this.chatsService.showConfirmDelete = true;
      if (popup) {
        const popupContainer = document.getElementById('popupContainer');
        if (popupContainer) {
          const closePopup = (event, msg) => {
            event.stopPropagation();
            popupContainer.innerHTML = ' ';
            this.chatsService.showConfirmDelete = false;
            msg.isInActionMode = false;
            this.msgBeingDeleted = null;
          }; 
          popupContainer.innerHTML = popup.innerHTML;
          Array.from(popupContainer.children).forEach((node: any) => {
            node.onclick = (event) => {
              event.stopPropagation();
            };
          });
          popupContainer.onclick = (event) => {
            closePopup(event, msg);
          };
          const yesButtons = document.getElementsByClassName('dlt-msg-btn-yes');
          const noButtons = document.getElementsByClassName('dlt-msg-btn-no');
          if (yesButtons) {
            Array.from(yesButtons).forEach((btn: any) => {
              btn.onclick = (event) => {
                event.stopPropagation();
                popupContainer.innerHTML = ' ';
                this.chatsService.showConfirmDelete = false;
                this.onDeleteMsg(msg);
              };
            });
          }
          if (noButtons) {
            Array.from(noButtons).forEach((btn: any) => {
              btn.onclick = (event) => {
                closePopup(event, msg);
              };
            });
          }
        }
      }
    } else {
      msg.showDeleteConfirmation = true;
    }
    this.cancelCurrentDeleteOperation();
    msg.showMsgActionMenu = false;
    msg.showDotOptions = false;
    msg.showDots = false;
    msg.isInActionMode = true;
    this.msgBeingDeleted = msg;
    this.addDeleteMsgClickListener();
  }

  onEditMessage(msg) {
    if (this.msgBeingEdited) {
      this.msgBeingEdited.isBeingEdited = false;
    }
    msg.isBeingEdited = true;
    this.msgBeingEdited = msg;
    this.msgBeingEdited.previousMentions = this.getMentionsFromMsgBeingEdited(this.msgBeingEdited.message);
    this.msgContent = msg.message;
    this.editorInstance.focus();
    setTimeout(() => {
      const editorNodes: any = Array.from(this.editorInstance.contentDocument.all);
      if (editorNodes && editorNodes.length) {
        const outerNode = editorNodes.find((node) => node.parentNode.tagName === 'BODY');
        if (outerNode) {
          outerNode.innerHTML += '<span class="edit-here"></span>';
          const currentNode: any = Array.from(this.editorInstance.contentDocument.all).find((element: any) => element.className.includes('edit-here'));
          if (currentNode) {
            this.editorInstance.selection.setCursorLocation(currentNode, 0);
          }
        }
      }
    });
  }

  cancelMsgEditing() {
    if (this.msgBeingEdited) {
      this.msgBeingEdited.isBeingEdited = false;
      this.msgBeingEdited = null;
      this.msgContent = undefined;
    }
  }

  async onDeleteMsg(msg) {
    msg.is_deleted = true;
    msg.showDeleteConfirmation = false;
    const response: any = await this.commonAPIService.fnDeleteChatMessage({msgId: msg.id, chatId: msg.chat_id, orderId: this.inputOrderId});
    if (!response.isDeleted) {
      msg.is_deleted = false;
    } else {
      if (msg.attachments && msg.attachments.length) {
        for (let attachment of msg.attachments) {
          this.deleteFileOnFirebase(attachment.attachment);
        }
      }
      this.cancelCurrentDeleteOperation();
    }
  }

  onMouseEnterLeaveFromMember(member){
    if(!member.showDeleteMenu){
      member.showDeleteMenu = true;
    }
    else{
      member.showDeleteMenu = false;
    }
  }

  adjustMentionDropdownOnScreen () {
    this.intervalIdForMentionDropdown = setInterval(() => {
      const dropdownMenu: any = this.getMentionDropdown();
      if (dropdownMenu && dropdownMenu.offsetLeft > 0) { // Dropdown is rendered into DOM if it is true
        this.clearMentionInterval();
        if (this.showFullPageChat) {
          const chatSec = document.getElementById('chatPanelDiv');
          if (chatSec) {
            const totalWidth = chatSec.offsetLeft + chatSec.offsetWidth;
            const widthForDropdown = dropdownMenu.offsetWidth;
            if ((totalWidth - dropdownMenu.offsetLeft) < dropdownMenu.offsetWidth) {
              dropdownMenu.style.left = `${totalWidth - widthForDropdown}px`;
            }
          }
        } else {
          if ((window.innerWidth - dropdownMenu.offsetLeft) < dropdownMenu.offsetWidth) {
            dropdownMenu.style.left = `${window.innerWidth - dropdownMenu.offsetWidth}px`;
          }
        }
      }
    }, 50);
  }

  setStyleForMentionDropdown() {
    this.intervalIdForDropdownStyling = setInterval(() => {
      const dropdownMenu = this.getMentionDropdown();
      if (dropdownMenu) {
        const allItems = Array.from(dropdownMenu.childNodes);
        allItems.forEach((node: any, index) => {
          node.onmouseenter = (event) => {
            const selected: any = allItems.find((item: any) => item.className === 'active');
            if (selected) {
              selected.className = '';
            }
            event.target.className = 'on-hover';
          };
          node.onmouseleave = (event) => {
            event.target.className = '';
          };
        });
      }
    }, 500);
  }

  getMentionDropdown () {
    return Array.from(document.getElementsByClassName('rte-autocomplete')).find((ele) => ele.nodeName === 'UL');
  }

  clearMentionStylingInterval() {
    if (this.intervalIdForDropdownStyling) {
      clearInterval(this.intervalIdForDropdownStyling);
      this.intervalIdForDropdownStyling = null;
    }
  }

  listenToKeyUpEvents() {
    this.onType = (event) => {
      if (event.key === '@') {
        if (this.intervalIdForMentionDropdown) {
          this.clearMentionInterval();
        }
        this.adjustMentionDropdownOnScreen();
      }
      this.clearMentionStylingInterval();
      this.setStyleForMentionDropdown();
      const dropdownMenu: any = this.getMentionDropdown();
      if (event.type === 'keydown') {
        if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey && !dropdownMenu) {
          let trimmed = (this.msgContent || '').replaceAll(/<span class="[a-z0-9\-\s]*">[\&nbsp;]*<\/span>/gi, '').replaceAll('<p>&nbsp;</p>', '').replaceAll('\n', '').replaceAll('<br />', '').replaceAll('<p></p>', '');
          if (!trimmed) {
            this.msgContent = ''; // There is nothing in the msg other than space
          }
          if (trimmed || (this.uploads && this.uploads.length)) {
            if (!this.isUploadInProgress()) {
              this.editorInstance && this.editorInstance.setContent('');
              if (this.msgBeingEdited) {
                this.saveEditedMessage(this.msgContent, this.msgBeingEdited);
              } else {
                this.sendMessage(this.msgContent);
              }
            } else {
              alert('Please wait... Attachments are being uploaded.');
            }
          }
        } else if (dropdownMenu) {
          const allItems: any = Array.from(dropdownMenu.childNodes);
          const activeItem: any = allItems.find((item: any) => item.className === 'active');

          if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            const hoverItem: any = allItems.find((item: any) => item.className === 'on-hover');
            if (hoverItem) {
              hoverItem.className = '';
            }
            if (activeItem && activeItem.dataset) {
              const dataset = activeItem.dataset;
              if (dataset.listindex == 1) {
                dropdownMenu.scrollTop = 0;
              } else if (dataset.listindex === dataset.totalitems) {
                dropdownMenu.scrollTop = dropdownMenu.scrollHeight;
              } else if (event.key === 'ArrowDown' && dataset.listindex > 5) {
                dropdownMenu.scrollTop += 46;
              } else if (event.key === 'ArrowUp' && dataset.listindex < (dataset.totalitems - 4)) {
                dropdownMenu.scrollTop -= 46;
              }
            } else if (event.key === 'ArrowUp') {
              allItems[0].className = 'active';
            }
          }
        }
      } else if (event.type === 'keyup') {
        if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey && this.msgContent && !dropdownMenu) {
          let trimmed = this.msgContent.replaceAll('<p>&nbsp;</p>', '').replaceAll('\n', '');
          if (!trimmed) {
            this.msgContent = '';
          }
        }

        this.updateMentionsInCurrentMessage(event.keyCode);
      }
    };

    this.editorInit.setup = (editor) => {
      this.editorInstance = editor;
      editor.on('keydown', this.onType);
      editor.on('keyup', this.onType);
      editor.on('focus', () => {
        this.showAddDeleteMember = false;
        this.isEditorFocused = true;
      });
      editor.on('blur', () => {
        this.isEditorFocused = false;
      });
      editor.on('NodeChange', (data) => {
        if (data.element.id == "_mce_caret") {
          this.msgContent = (this.msgContent || '').replaceAll(/<span class="dummy">[a-z\s]*<\/span>/gi, '').replaceAll(/<span id="autocomplete-searchtext"><\/span>/gi, '').replaceAll(/<span id="autocomplete-delimiter">@<\/span>/gi, '').replaceAll(/<span id="autocomplete"><\/span>/gi, '');
          setTimeout(() => {
            const node = this.editorInstance.selection.getNode();
            if (node.innerHTML !== '<br>') {
              node.innerHTML += `&nbsp;<span class="write-here"></span>`;
              const newNode: any = Array.from(this.editorInstance.contentDocument.all).find((element: any) => element.className.includes('write-here'));
              if (newNode) {
                this.editorInstance.selection.setCursorLocation(newNode, 0);
              }
            }
          });
        }
      });
    };
  }

  clearMentionInterval() {
    clearInterval(this.intervalIdForMentionDropdown);
    this.intervalIdForMentionDropdown = null;
  }

  async getCurrentUserDetails(userId) {
    this.currentUser = await this.commonAPIService.fnGetUserById(userId);
    if(this.currentUser.permission_level_id && this.currentUser.permission_level_id == 4)
    {
      this.orderLink = '/design/';
    }
    else if(this.currentUser.permission_level_id && this.currentUser.permission_level_id == 5)
    {
      this.orderLink = '/production/';
    }
    // console.log('current user',this.currentUser);
  }

  openFullViewImage(event, attachment, msg) {
    event.stopPropagation();
    if (this.isSmallScreen) {
      attachment.fullScreenMode = true;
      this.hideMsgActionMenu(msg);
      msg.isInActionMode = true;
    } else {
      this.chatsService.isFullImageViewOpen = true;
      this.imgService.showImage(attachment.attachment);
    }
  }

  closeFullScreenImageMode(event, attachment) {
    event.stopPropagation();
    attachment.fullScreenMode = false;
  }

  subscribeToRoute() {
   this.routeSubscription = this.activatedRoute.params.subscribe((params) => {
      if (params.chatOrderId) {
        this.performMoileScreenNavigation();
        const chatOrderId = Number(params.chatOrderId);
        localStorage.removeItem('messageMentions');
        this.msgContent = undefined;
        this.showFullPageChat = this.chatsService.isChatPanelOpen = true;
        if (!this.currentUser || !this.userId) {
          this.updateCurrentUser();
        }
        if (!this.inputOrderId || this.inputOrderId !== chatOrderId) {
          console.log('coming here too');
          this.inputOrderId = chatOrderId;
          this.titleService.setTitle(`UpMerch | Chat | #${chatOrderId}`);
          this.initializeConnection(this.inputOrderId);
        }

        if (localStorage.getItem('updateThreads')) {
          this.retriveThreadsFromLocalStorage();
          this.removeThreadsFromLocalStorage();
        }
        
        if (!this.allOrderChats || !this.allOrderChats.length) {
          this.fetchOrderChats();
          this.fetchUnreadOrderChats();
        } else if (this.currentThreadTab === 'all') {
          this.selectedChat = this.allOrderChats.find((chat) => chat.order_id === this.inputOrderId);
        } else if (this.currentThreadTab === 'unread') {
          this.selectedChat = this.allUnreadChats.find((chat) => chat.order_id === this.inputOrderId);
        }

        if (!this.selectedChat) {
          this.fetchSelectedThreadDetails(this.inputOrderId);
        }
      }
      else{
        // console.log(this.router.url);
        if(this.router.url == '/chat')
        {
          localStorage.removeItem('messageMentions');
          this.msgContent = undefined;
          this.showFullPageChat = this.chatsService.isChatPanelOpen = true;
          this.titleService.setTitle(`UpMerch | Chat`);
          // console.log('full page',this.showFullPageChat);
          if (!this.currentUser || !this.userId) {
            this.updateCurrentUser();
          }
          if (!this.allOrderChats || !this.allOrderChats.length) {
            this.fetchOrderChats();
            this.fetchUnreadOrderChats();
          }
        }
      }
    });
  }

  async fetchSelectedThreadDetails(orderId) {
    const response: any = await this.commonAPIService.fnFetchSingleChatThread({userId: this.userId, orderId});
    if (response && response.chats && response.chats.length) {
      const chats = this.formatChatsList(response.chats);
      this.selectedChat = chats[0];
    }
  }

  async checkIfOrderCancelled () {
    if (this.inputOrderId) {
      const response: any = await this.commonAPIService.fnCheckIfOrderCancelled({orderId: this.inputOrderId});
      if (response) {
        this.isOrderCancelled = response.isOrderCancelled;
      }
    }
  }

  async fetchUnreadOrderChats (searchQuery='') {
    this.fetchingUnreadThreads = true;
    const response: any = await this.commonAPIService.fnFetchUnreadOrderChats({userId: this.userId, searchQuery});
    response.chats = this.formatChatsList(response.chats);
    this.allUnreadChats = response.chats;
    this.fetchingUnreadThreads = false;
    this.setChatsList();
  }

  formatChatsList(chats) {
    chats.forEach((chat) => {
      chat.isMarkedUnread = this.checkIfMarkedUnread(chat);
      if (chat.orderStatus === 'Artwork' && chat.orderSubstatus && chat.orderSubstatus.includes(':')) {
        chat.orderSubstatus = chat.orderSubstatus.split(':')[1].trim();
      }
      chat.lastMessageTime = moment(chat.lastMessageTime).format('YYYY-MM-DD HH:mm:ss');
      // console.log('last message time',chat.lastMessageTime);
      // console.log('last message date',moment(chat.lastMessageTime).format('YYYY-MM-DD HH:mm:ss'));
      chat.formattedStatus = `${chat.orderStatus}${chat.orderSubstatus ? ` - ${chat.orderSubstatus}` : ''}`;
      chat.lastMessageTimestamp = new Date(chat.lastMessageTime).getTime();
      // chat.lastMessageTimestamp = momenttz(chat.lastMessageTime).tz('America/Chicago').valueOf();
      return chat;
    });
    return chats;
  }

  async fetchOrderChats(searchQuery='') {
    if (!this.fetchMoreOrderChats || this.fetchingAllThreads) {
      return;
    }
    this.fetchingAllThreads = true;
    const reqData = {
      userId: this.userId,
      limit: this.allOrderChats.length,
      searchQuery,
      permissionLevel: null
    };
    if (this.currentUser) {
      reqData.permissionLevel = this.currentUser.permission_level_id;
    }
    const response: any = await this.commonAPIService.fnFetchOrderChats(reqData);
    if(response)
      this.fetchedChats = true;
    this.fetchMoreOrderChats = response.chats.length === 50;
    response.chats = this.formatChatsList(response.chats);

    if (!this.allOrderChats.length) {
      this.allOrderChats = response.chats;
      if (this.router.url == '/chat' && this.allOrderChats.length) {
        this.openSocketConnection(this.allOrderChats[0].chat_id);        
      }
    } else {
      this.allOrderChats.push(...response.chats);
    }
    this.fetchingAllThreads = false;
    
    this.setChatsList();
    if (!this.selectedChat && this.inputOrderId) {
      this.selectedChat = this.allOrderChats.find((chat) => chat.order_id === this.inputOrderId);
    }
    if (!this.selectedChat && !this.inputOrderId) {
      this.selectedChat = -1
    }
  }

  setChatsList() {
    if (this.currentThreadTab === 'all') {
      // const unreadChats = this.allOrderChats.filter((chat) => chat.unreadMessages);
      // const newOrderChats = this.allOrderChats.filter((chat) => chat.orderStatus === 'New Order');
      // this.chatsList = _.uniqBy(unreadChats.concat(newOrderChats, this.allOrderChats), 'chat_id');
      this.chatsList = this.allOrderChats;
    } else {
      this.chatsList = this.allUnreadChats = this.allUnreadChats.filter((chat) => chat.unreadMessages || chat.isMarkedUnread || chat.chat_id === this.selectedChat.chat_id);
    }
  }

  openFullPageChat(event) {
    this.checkAndRemoveMentionsDropdown();
    event.stopPropagation();
    // console.log('coming here before');
    // this.showFullPageChat = this.chatsService.isChatPanelOpen = true;
    // this.router.navigate([`chat/${this.inputOrderId}`]);
  }

  initializeChat() {
    this.performMoileScreenNavigation();
  }

  performMoileScreenNavigation() {
    this.isSmallScreen = window.screen.width <= 991;
    this.showThreads = this.isSmallScreen;
    if (this.isSmallScreen && (JSON.parse(localStorage.getItem('showChatSection')) || this.inputOrderId)) {
      this.navToChat();
    }
    localStorage.removeItem('showChatSection');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isSmallScreen = window.screen.width <= 991;
  }

  onChatClick(selectedChat) {
    if (this.selectedChat && this.selectedChat.chat_id == selectedChat.chat_id && this.isSmallScreen) {
      this.navToChat();
      return;
    }
    this.selectedChat = selectedChat;

    if (selectedChat.isMarkedUnread) this.markChatReadUnread(selectedChat, false);
    this.editChatListToMarkChatRead(selectedChat);

    if (this.isSmallScreen) {
      localStorage.setItem('showChatSection', "true");
    }

    if (this.router.url == '/chat') {
      this.storeThreadsInLocalStorage();
    }

    this.router.navigate([`chat/${selectedChat.order_id}`]);
  }

  storeThreadsInLocalStorage() {
    localStorage.setItem('allUnreadChats', JSON.stringify(this.allUnreadChats));
    localStorage.setItem('allOrderChats', JSON.stringify(this.allOrderChats));
    localStorage.setItem('chatsList', JSON.stringify(this.chatsList));
    localStorage.setItem('updateThreads', 'true');
  }

  removeThreadsFromLocalStorage() {
    localStorage.removeItem('allUnreadChats');
    localStorage.removeItem('allOrderChats');
    localStorage.removeItem('chatsList');
    localStorage.removeItem('updateThreads');
  }

  retriveThreadsFromLocalStorage() {
    const allUnreadChats = localStorage.getItem('allUnreadChats');
    if (allUnreadChats) {
      this.allUnreadChats = JSON.parse(allUnreadChats);
    }

    const allOrderChats = localStorage.getItem('allOrderChats');
    if (allOrderChats) {
      this.allOrderChats = JSON.parse(allOrderChats);
    }

    const chatsList = localStorage.getItem('chatsList');
    if (chatsList) {
      this.chatsList = JSON.parse(chatsList);
    }
  }

  editChatListToMarkChatRead(chat) {
    if (chat.unreadMessages) {
      this.readUnreadMessages(chat.chat_id);
      this.readChatNotification(chat.chat_id);
    }

    const chatFromAll = this.allOrderChats.find((item) => item.chat_id === chat.chat_id);
    if (chatFromAll) {
      chatFromAll.isMarkedUnread = false;
      chatFromAll.unreadMessages = 0;
    }

    const chatFromUnread = this.allUnreadChats.find((item) => item.chat_id === chat.chat_id);
    if (chatFromUnread) {
      chatFromUnread.isMarkedUnread = false;
      chatFromUnread.unreadMessages = 0;
    }
  }

  onScrollUp(){
    console.log('scrolling is working!');
    // console.log('checking',this.dataFromIndex,this.chatMessages.length);
    if(!this.isLoadingMessages && this.chatMessages.length-this.dataFromIndex>=50)
      this.fetchMoreChatMessages();
  }

  async fetchMoreChatMessages(){
    this.isLoadingMessages = true;
    // console.log(this.scrollContainer);
    this.dataFromIndex=this.chatMessages.length;
    this.previousScrollHeightMinusTop = this.scrollContainer.nativeElement.scrollHeight -this.scrollContainer.nativeElement.scrollTop;
    let chatResponse = await this.commonAPIService.fnGetChatsMessages(this.userId, this.inputOrderId, '', this.dataFromIndex);
    let newChatMessages =  _.sortBy(chatResponse['chatMessages'], 'created_at');
    newChatMessages.forEach((msg) => {
      this.addDateTimeToMsg(msg);
      this.addFileExtension(msg);
    });
    this.chatMessages.unshift(...newChatMessages);
    this.isLoadingMessages = false;
    this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight-this.previousScrollHeightMinusTop;
    // console.log(this.chatMessages);
  }

  switchThread(tab) {
    this.currentThreadTab= tab;
    this.setChatsList();
  }

  showHideArtworkGarment(section) {
    this.currentArtGarTab = section;
    this.showGarment = section === 'garment';
    this.showArtwork = section === 'artwork';
  }

  navToThreads() {
    this.showThreads = true;
    this.showChat = false;
    this.showArtworkGarment = false;
  }

  navToArtworkGarments() {
    this.showArtworkGarment = true;
    this.showThreads = false;
    this.showChat = false;
  }

  navToChat() {
    this.showChat = true;
    this.showThreads = false;
    this.showArtworkGarment = false;
  }

  toggleEditOrderContext(event) {
    event.stopPropagation();
    this.showEditOrderContext = !this.showEditOrderContext;
    this.addClickListenerOnEditDropdown();
  }

  addClickListenerOnEditDropdown() {
    this.editOrderMenuHandler = (event) => {
      this.showEditOrderContext = false;
      document.removeEventListener('click', this.editOrderMenuHandler);
    };
    document.addEventListener('click', this.editOrderMenuHandler);
  }

  onSearchClick() {
    if (this.isSmallScreen || !this.showFullPageChat) {
      this.showSmallScreenSearch = !this.showSmallScreenSearch; // Just show/hide for small screens
    } else {
      // If desktop screen process the search operation
      this.searchInChat();
    }
  }

  searchInChat() {
    // Perform search opearation here
  }

  async openChatPanel() {
    this.scrollMsgPaneToBottom(0);
    this.chatsService.isChatPanelOpen = true;
    this.navToChat();
    if (this.unreadMessagesData && this.unreadMessagesData.length > 0) {
      this.readUnreadMessages(this.chatId);
      this.readChatNotification(this.chatId)
    }
    if (this.isMarkedUnread) {
      const response: any = await this.commonAPIService.fnMarkChatReadUnread({ userId: this.userId, chatId: this.chatId, mark: false });
      if (response && response.isMarkUpdated) {
        this.isMarkedUnread = false;
      }
    }
  }

  ngOnChanges(changes): void {
    if (!this.currentUser || !this.userId) {
      this.updateCurrentUser();
    }

    if (this.webSocketService.socket && this.webSocketService.socket.connected) {
      this.webSocketService.closeTheConnection();
    }

    if (this.inputOrderId) {
      // console.log('******************ngonchanges creating connection*********************',this.inputOrderId);
      this.initializeConnection(this.inputOrderId);
    }
    
    // if (changes.inputOrderId && changes.inputOrderId.currentValue) {
    //   this.webSocketService.closeTheConnection();
    //   this.initializeConnection(this.inputOrderId);
    // }

    if (!this.showOrderChatIcon) {
      if (this.webSocketService.socket && this.webSocketService.socket.connected) {
        this.webSocketService.closeTheConnection();
      }
      this.chatsService.orderId = undefined;
      this.chatMessages = [];
      this.renderer.addClass(document.body, 'hide-tox-warnings');
    }else{
      this.renderer.removeClass(document.body, 'hide-tox-warnings');
    }
  }

  showHideAddDeleteMembers(event) {
    event.stopPropagation();
    this.showAddDeleteMember = !this.showAddDeleteMember;
    this.addRemoveDropdownEventListener();
  }

  addRemoveDropdownEventListener() {
    const containerEle: any = document.getElementById('chatPageContainer');
    this.membersMenuHandler = (event) => {
      const currentNode = event.currentTarget;
      const membersDropdownEle = document.getElementById('chatMemMenu');
      const membersDropdownBtnEle = document.getElementById('memDropdwnBtn');
      if ((membersDropdownEle && !membersDropdownEle.contains(currentNode)) || (membersDropdownBtnEle && !membersDropdownBtnEle.contains(currentNode))) {
        this.showAddDeleteMember = false;
        this.removeDropdownClickListener(containerEle);
      }
    };
    if (this.showAddDeleteMember) {
      this.addDropdownClickListener(containerEle);
    } else {
      this.removeDropdownClickListener(containerEle);
    }
  }

  addDropdownClickListener (containerEle) {
    document.addEventListener('click', this.membersMenuHandler);
    if (containerEle) {
      containerEle.addEventListener('click', this.membersMenuHandler);
    }
  }

  removeDropdownClickListener (containerEle) {
    document.removeEventListener('click', this.membersMenuHandler);
    if (containerEle) {
      containerEle.removeEventListener('click', this.membersMenuHandler);
    }
  }

  checkAndCloseDropdown (event) {
    const currentNode = event.currentTarget;
    const membersDropdownEle = document.getElementById('chatMemMenu');
    if (membersDropdownEle && !membersDropdownEle.contains(currentNode)) {
      this.showAddDeleteMember = false;
    }
  }

  closeChatPanel() {
    if (!this.showFullPageChat) {
      this.chatsService.isChatPanelOpen = false;
    }
  }

  getNameInitials (name) {
    return name.split(' ').map((word) => word[0]).join('').substring(0,2);
  }

  getJobNameInitials(name){
    const nameArray = name ? name.trim().split(' ') : [];
    if(nameArray.length == 0)
      return '';
    const finalArray = nameArray.filter((name)=>{
      return name !='';
    })
    return finalArray.length >= 2 ? (finalArray[0][0] + finalArray[1][0] + '') : finalArray[0].substring(0,2);
  }

  getChatDateTimeLabel(lastMessageDate, forThread){
    // console.log("lastMessageDate >>", lastMessageDate);
    // const currentTime = momenttz().tz('America/Chicago');
    // const lastMessageTime = momenttz(lastMessageDate);
    // const hoursDifference = currentTime.diff(lastMessageTime,'hours');
    let currentTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
    // console.log("current time: ", currentTime.format('YYYY-MM-DD HH:mm:ss'));
    let lastMessageTime = momenttz.tz(moment(lastMessageDate).format('YYYY-MM-DD HH:mm:ss'), 'America/Chicago').local();
    // console.log("lastMessageTime >>", lastMessageTime);
    let hoursDifference = currentTime.diff(lastMessageTime,'hours');
    // console.log('hours',hoursDifference,'current',currentTime.format('YYYY-MM-DD HH:mm:ss'),'last',lastMessageTime.format('YYYY-MM-DD HH:mm:ss'));
    // console.log('current time',momenttz().tz('America/Chicago').format('YYYY-MM-DD HH:mm:ss'));
    // const currentTimestamp = new Date(new Date().toLocaleString('en-US',{timeZone:'America/Chicago'})).getTime();
    // const lastMessageDate= new Date(lastMessageTimestamp);
    // let difference = currentTimestamp - lastMessageTimestamp;
    // // console.log('current',currentTimestamp,lastMessageTimestamp);
    // let hoursDifference = Math.floor(difference/1000/60/60);
    // console.log('hours',hoursDifference,'current',currentTime.format('YYYY-MM-DD HH:mm:ss'),'last',lastMessageTime.format('YYYY-MM-DD HH:mm:ss'));
    // console.log('current time',momenttz().tz('America/Chicago').format('YYYY-MM-DD HH:mm:ss'));
    // const currentTimestamp = new Date(new Date().toLocaleString('en-US',{timeZone:'America/Chicago'})).getTime();
    // const lastMessageDate= new Date(lastMessageTimestamp);
    // let difference = currentTimestamp - lastMessageTimestamp;
    // // console.log('current',currentTimestamp,lastMessageTimestamp);
    // let hoursDifference = Math.floor(difference/1000/60/60);
    if(hoursDifference == 0 && forThread)
    {
      // let minutesDifference = Math.floor(difference/1000/60);
      const minutesDifference = currentTime.diff(lastMessageTime,'minutes');
      return minutesDifference > 1 ? `${minutesDifference} mins` : `${minutesDifference} min`
    }
    if(hoursDifference < 24){
      // console.log(new Date(lastMessageTimestamp).getDate());
      // if (new Date(lastMessageTimestamp).getDate() != new Date(currentTimestamp).getDate()) {
      if(lastMessageTime.date() != currentTime.date()){
        return 'Yesterday';
      } else if (!forThread) {
        return 'Today';
      } else {
        return hoursDifference == 1 ? `${hoursDifference} hr` : `${hoursDifference} hrs`;
      }
    }
    // const firstDateOfWeek = new Date(new Date().setDate(new Date(currentTimestamp).getDate() - new Date(currentTimestamp).getDay()))
    // const lastDateOfWeek = new Date(new Date(firstDateOfWeek).setDate(firstDateOfWeek.getDate()+6));
    // firstDateOfWeek.setHours(0,0,0,0);
    // lastDateOfWeek.setHours(0,0,0,0);
    // console.log('firstdaye',firstDateOfWeek,lastDateOfWeek);
    // if(lastMessageDate >= firstDateOfWeek && lastMessageDate <= lastDateOfWeek)
    if(lastMessageTime.isSame(currentTime,'week'))
    {
      const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      return days[lastMessageTime.day()]
    }
    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    // if(lastMessageDate.getFullYear() == new Date().getFullYear())
    if(lastMessageTime.isSame(currentTime,'year'))
    {
      // return month[lastMessageDate.getMonth()] +' ' + lastMessageDate.getDate(); 
      return lastMessageTime.format('MMM DD');
    }
    return lastMessageTime.format('MMM DD, YYYY')
    // return month[lastMessageDate.getMonth()] +' ' + lastMessageDate.getDate() +', ' + lastMessageDate.getFullYear();
  }
  
  searchThreads(){
    this.isSearching = true;
    if(this.searchText!=''){
      this.allOrderChats = [];
      this.allUnreadChats = [];
      this.chatsList = [];
      this.fetchMoreOrderChats = true;
      this.fetchOrderChats(this.searchText);
      this.fetchUnreadOrderChats(this.searchText);
    }
    else{
      alert('Please enter value');
      this.closeThreads();
    }
  }

  closeThreads(){
    this.fetchMoreOrderChats = true;
    this.isSearching = false;
    this.searchText = '';
    this.allOrderChats = [];
    this.allUnreadChats = [];
    this.chatsList = [];
    this.fetchOrderChats();
    this.fetchUnreadOrderChats();
  }

  checkAndRemoveMentionsDropdown () {
    const dropdownMenu = this.getMentionDropdown();
    if (dropdownMenu) {
      dropdownMenu.remove();
    }
  }

  ngOnDestroy(): void {
    this.chatsService.isChatPanelOpen = false;
    if (this.webSocketService.socket && this.webSocketService.socket.connected) {
      this.webSocketService.closeTheConnection();
    }
    this.routeSubscription.unsubscribe();
    if(this.unreadChatThreadNotificationsSub){
      this.unreadChatThreadNotificationsSub.unsubscribe();
    }
  }

  async initializeConnection(orderId){
    // By Nitin-- get the chat And chat_messages.
    this.fetchAllHQUsers();
    this.fetchOrderProductDetails();
    this.fetchOrderArtworkDetails();
    this.fetchArtworkApprovalImages();
    this.userId =  this.commonAPIService.fnGetUser()['id'];
    this.isLoadingMessages = true;
    this.dataFromIndex = 0;
    this.previousScrollHeightMinusTop = 0;
    this.checkIfOrderCancelled();
    let chatResponse: any = await this.commonAPIService.fnGetChatsMessages(this.userId, orderId, '',this.dataFromIndex);
    this.chatId = chatResponse['chatId'];
    if (chatResponse.chatDetails && chatResponse.chatDetails.marked_unread_by) {
      this.isMarkedUnread = (JSON.parse(chatResponse.chatDetails.marked_unread_by)).some((user) => user == this.currentUser.user_id);
    }
    const chatMessages = _.sortBy(chatResponse['chatMessages'], 'created_at');
    chatMessages.forEach((msg) => {
      this.addDateTimeToMsg(msg);
      this.addFileExtension(msg);
    });
    this.chatMessages = chatMessages;
    this.isLoadingMessages = false;
    // console.log('messages',this.chatMessages);
    if (this.showFullPageChat) {
      this.scrollMsgPaneToBottom(500);
    }
    this.chatMembers = _.uniqBy(chatResponse['chatUsers'], 'user_id');
    if (this.currentUser && !this.chatMembers.some((member: any) => member.user_id == this.currentUser.user_id) && this.currentUser.permissionLevel !== 'Admin' && this.currentUser.permissionLevel !== 'Collegiate Program Coordinator' && this.currentUser.permissionLevel !== 'Collegiate Sales Director' && this.currentUser.permissionLevel !== 'CSD' && this.currentUser.permissionLevel !== 'Operations Manager') {
      this.navService.showOrderChatIcon = false;
      if (this.showFullPageChat) {
        this.router.navigate(['/']);
      }
    }

    this.setMentionSource();
    this.editorInit.mentions.insert = this.onInsertingMention;
    this.unreadMessagesData = chatResponse['unreadMessages'];
    this.unreadMessages = this.unreadMessagesData.length;
    this.openSocketConnection(this.chatId);
  }

  openSocketConnection(chatId) {
    this.webSocketService.openTheConnection(chatId, this.userId);
    this.webSocketService.listen('chatEventOccurred').subscribe((event: any) => {
      this.handleSocketEventsForChat(event);
    });
    this.webSocketService.listen('newUserAddedToChat').subscribe((eventData: object) => {
      this.onAddingNewUserToChat(eventData);
    });
  }

  handleSocketEventsForChat(event: any) {
    if (!event || !event.name || !event.data) return;
    switch (event.name) {
      case 'New Message Arrived':
        this.msgAddListner(event.data);
        break;
      case 'Message Deleted':
        this.msgDeleteHandler(event.data);
        break;
      case 'Message Edited':
        this.editMsgHandler(event.data);
        break;
      case 'Make Thread Unread':
        this.makeThreadUnreadHandler(event.data);
        break;
      case 'Update Global Unread Messages Count':
        this.updateGlobalUnreadMessageCounts(event.data);
        break;
    }
  }

  updateGlobalUnreadMessageCounts(data) {
    if (data.chat && ((!this.selectedChat) || (this.selectedChat && this.selectedChat.chat_id !== data.chat.chat_id)) && data.forUser === this.currentUser.user_id) {
      this.fetchUnreadOrderChats();
      this.chatsService.fetchAllUnreadMessagesCounts();
      const chats = this.formatChatsList([data.chat]);
      data.chat = chats[0];
      let chatIndex = this.allOrderChats.findIndex((chat) => chat.chat_id === data.chat.chat_id);
      if (chatIndex >= 0) {
        this.allOrderChats.splice(chatIndex, 1);
      }
      this.allOrderChats.unshift(data.chat);
      chatIndex = this.allUnreadChats.find((chat) => chat.chat_id === data.chat.chat_id);
      if (chatIndex >= 0) {
        this.allUnreadChats.splice(chatIndex, 1);
      }
      this.allUnreadChats.unshift(data.chat);
      this.setChatsList();
    }
  }

  makeThreadUnreadHandler(data) {
    if (data.mentions.some((user) => user.userId == this.currentUser.user_id)) {
      let threadIndex = this.allOrderChats.findIndex((chat) => chat.chat_id === data.chatId);
      if (threadIndex > -1) {
        this.allOrderChats[threadIndex].unreadMessages += 1;
        this.allOrderChats[threadIndex].lastMessageTime = data.lastMessageTime;
        if (!this.allUnreadChats.some((chat) => chat.chat_id === data.chatId)) {
          this.allUnreadChats.unshift(this.allOrderChats[threadIndex]);
        }
        this.setChatsList();
      }
    }
  }

  editMsgHandler(data) {
    if (data.chatId === this.chatId && data.msgId && data.msg) {
      const msg = this.chatMessages.find((msg) => msg.id === data.msgId);
      if (msg) {
        msg.is_edited = true;
        msg.message = data.msg;
      }
    }
  }

  msgDeleteHandler(data) {
    if (data.chatId === this.chatId && data.msgId) {
      const deletedMsg = this.chatMessages.find((msg) => msg.id === data.msgId);
      if (deletedMsg) {
        deletedMsg.is_deleted = true;
      }
    }
  }

  setMentionSource() {
    const mentions = this.chatMembers.filter((member: any) => {
      if (member.user_id !== this.userId && member.name) {
        member.queryTerm = `${member.name} ${member.permission}`;
        return true;
      }
    });
    
    this.allHQUsers.forEach((hqUser)=>{
      let presentElement = this.chatMembers.find((ele:any) => ele.user_id == hqUser.user_id);
      // console.log('getting presentELemen',presentElement);
      let mentionMember;
      if(!presentElement && hqUser.name){
        mentionMember={
          email : hqUser.email,
          name : hqUser.name,
          permission : hqUser.permissionLevel,
          profile_pic : hqUser.photo,
          queryTerm : `${hqUser.name} ${hqUser.permissionLevel}`,
          user_id : hqUser.user_id
        }
        mentions.push(mentionMember);
      }
    })

    mentions.forEach((mention: any, index) => {
      mention.listIndex = index + 1;
      mention.totalItems = mentions.length;
    });
    // console.log('getting mentions users',mentions);
    this.editorInit.mentions.source = mentions;
    this.editorInit.mentions.items = mentions.length;
  }

  onAddingNewUserToChat(eventData) {
    if (this.chatId && eventData.chat_id === this.chatId) {
      if (!this.chatMembers) {
        this.chatMembers = [eventData.newUser];
      } else if (!this.chatMembers.some((member: any) => member.user_id === eventData.newUser.user_id)) {
        this.chatMembers.push(eventData.newUser);
      }
      this.setMentionSource();
      if (eventData.newUser.user_id === this.currentUser.user_id && !this.showFullPageChat) {
        this.navService.showOrderChatIcon = true;
      }
    }
  }

  scrollMsgPaneToBottom(delay) {
    setTimeout(() => {
      const msgPane = document.getElementById('msgPane');
      if (msgPane) {
        msgPane.scrollTop = msgPane.scrollHeight;
      }
    }, delay);
  }

  onInsertingMention(mention: any) {
    let messageMentions = JSON.parse(localStorage.getItem('messageMentions')) || [];
    if (!messageMentions.some((user) => user.userId === mention.user_id)) {
      messageMentions.push({userId: mention.user_id, name: mention.name});
    }
    localStorage.setItem('messageMentions', JSON.stringify(messageMentions));
    return `<span class="member-mention mention-${uuid()}">${mention.name}</span> `;
  }

  async fetchArtworkApprovalImages() {
    let response: any = await this.commonAPIService.getArtworkApprovalImages({orderId: this.inputOrderId});
    this.artworkApprovalImages = response || [];
  }

  async fetchOrderArtworkDetails() {
    let response: any = await this.commonAPIService.getOrderArtworkDetail({orderId: this.inputOrderId});
    this.orderArtworkDetails = response;
    if (this.orderArtworkDetails && this.orderArtworkDetails.length) {
      this.orderArtworkDetails.forEach( artwork => {
        if (artwork) {
  
          if (artwork.part_name) {
            const segments = artwork.part_name.split('-');
            if (segments && segments.length > 1) {
              artwork.position = segments[0];
              artwork.positionType = segments[1];
            }
          }
    
          // if (artwork.type) {
          //   if (artwork.type === 'Screen Printing') {
          //     artwork.methodDetail = `${artwork.number_of_colors} Colors`;
          //   } else if (artwork.type === 'Direct to Garment') {
          //     artwork.methodDetail = '';
          //   } else if (artwork.type === 'Embroidery') {
          //     artwork.methodDetail = `${artwork.width}" Width x ${artwork.height}" Height`;
          //   } else if (artwork.type === 'Vinyl') {
          //     // artwork.methodDetail = `${artwork.custom_names ? 'Custom Names' : ''}${artwork.custom_numbers ? ', Custom Numbers' : ''}`;
          //     artwork.methodDetail = '';
          //   } else if (artwork.type === 'Finishings') {
          //     artwork.methodDetail = '';
          //   }
          // }
          if(artwork.sales_pictures){
            artwork.sales_pictures = artwork.sales_pictures.map( sp => {
              console.log('before sp',sp);
              let ext = '';
              let imageExt = ['.jpeg', '.jpg', '.png', '.gif'];
              let docType = 'doc';
              ext = sp.name?.match(/.+(\..+)/)[1].toLowerCase();
              if (imageExt.includes(ext)) {
                docType = 'image';
              } 
              return {
                attachment : sp.url,
                attachment_name : sp.name,
                attachment_size : sp.size,
                type : docType,
                ext
              }
            })
          }
          // console.log('artwork picture',artwork.sales_pictures);

          if (artwork.utilities) {
            artwork.utilities = JSON.parse(artwork.utilities);
            if (artwork.utilities && artwork.utilities.length) {
              let audience = '';
              for (let record of artwork.utilities) {
                audience += ` ${record['label']}`;
              }
              artwork.audience = audience.trim().split(' ').join(', ');
            }
          }

        }
      });
    }
  }

  async fetchAllHQUsers(){
    let response:any = await this.commonAPIService.getallHQUsers({});
    this.allHQUsers = response;
    // console.log('got all hq users',this.allHQUsers);
  }

  async fetchOrderProductDetails() {
    let response: any = await this.commonAPIService.getOrderProductsDetail({orderId: this.inputOrderId});
    this.orderProducts = response;
    let styleImage: Array<any> = [];
    this.orderProducts.forEach((product) => {
      // const titleElements = (product.title || '').split(' - ');
      // if (titleElements && titleElements.length === 3) {
      //   product.brand = titleElements[0];
      //   product.garment = titleElements[1];
      //   product.style = titleElements[2];
      // }
      if (product.supplierID == 1) {
        styleImage = (product.style_image as String).split("/");
        styleImage[styleImage.length - 1] = (styleImage[styleImage.length - 1] as String).replace("fm", "fl");
        product.style_image = 'https://cdn.ssactivewear.com/' + styleImage.join("/");

        product.selectedImage = product.style_image || product.model_front_image || product.model_side_image || product.model_custom_image || product.model_back_image || product.color_front_image || product.color_back_image || product.color_side_image;

      }
      else if (product.supplierID == 2) {
        product.selectedImage = product.model_front_image || product.model_side_image || product.model_custom_image || product.model_back_image || product.color_front_image || product.color_back_image || product.color_side_image || product.style_image;
      }
    });

    this.orderProducts.forEach(element => {
    this.totalProductImage = 0;
      if (element.style_image) {
        this.totalProductImage += 1;
      }
      if (element.model_front_image) {
        this.totalProductImage += 1;
      }
      if (element.model_back_image) {
        this.totalProductImage += 1;
      }
      if (element.model_side_image) {
        this.totalProductImage += 1;
      }
      if (element.model_custom_image) {
        this.totalProductImage += 1;
      }
      if (element.color_front_image) {
        this.totalProductImage += 1;
      }
      if (element.color_back_image) {
        this.totalProductImage += 1;  
      }
      if (element.color_side_image) {
        this.totalProductImage += 1;
      }

      if (this.totalProductImage > 2) {
        element.isDownArrowVisible = true;
      } else {
        element.isDownArrowVisible = false;
      }
    });
  }

  updateLastMessageTimeInThreadList(chatId, lastMessageTime) {
    const lastMessageTimestamp = new Date(lastMessageTime).getTime();
    const threadIndex = this.chatsList.findIndex((chat) => chat.chat_id === chatId);
    if (threadIndex !== -1) {
      this.chatsList[threadIndex].lastMessageTime = lastMessageTime;
    }
  }

  async msgAddListner(msg) {
    const msgIndex = this.chatMessages.findIndex((item: any) => item.temp_msg_id === msg.temp_msg_id);
    // console.log('coming here',msg);
    if (this.chatId === msg.chat_id) {
      this.addFileExtension(msg);
      if (msg.is_status_change || msg.is_artwork_reject_reason || msgIndex < 0) {
        this.chatMessages.push(msg);
      } else {
        this.chatMessages[msgIndex] = msg;
      }
      // this.updateLastMessageTimeInThreadList(msg.chat_id, msg.created_at);
      this.scrollMsgPaneToBottom(100);

      if (!msg.isBeingSent) {
        this.addDateTimeToMsg(msg);
        if (!this.chatsService.isChatPanelOpen  && !msg.is_status_change) {
          this.unreadMessagesData.push(msg.id);
          this.unreadMessages = this.unreadMessagesData.length;
        } else {
          if (msg.user_id != this.userId) {
            let data = { 'chat_id': this.chatId, 'user_id': this.userId };
            let apiResult = await this.commonAPIService.fnChangeUserMsgStatusToRead(data);
            this.readChatNotification(this.chatId);
          }
        }
      }
    }
    // else{
    //   if (msg.user_id != this.userId){
    //   let chat_id = this.chatsList.findIndex((chat) => chat.chat_id === msg.chat_id);
    //   if(chat_id != -1){
    //     this.chatsList[chat_id].unreadMessages += 1; 
    //     this.unreadMessagesData.push(msg.id);
    //     this.unreadMessages = this.unreadMessagesData.length;
    //     this.updateLastMessageTimeInThreadList(msg.chat_id, msg.created_at);
    //   }
    // }
    // }
  }

  downloadAttachment($event, attachment) {
    $event.stopPropagation();
    fetch(attachment.attachment)
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a: any = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = attachment.attachment_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // a.download = (attachment.attachment_name || '').replace(' ', '-');
      // const eleId = `link-to-${a.download}`;
      // a.id = eleId;
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   setTimeout(() => {
      //     a.href = reader.result;
      //     document.body.appendChild(a);
      //     setTimeout(() => {
      //       const downloadInterval = setInterval(() => {
      //         const attachedLink = document.getElementById(eleId);
      //         if (attachedLink) {
      //           clearInterval(downloadInterval);
      //           attachedLink.click();
      //         }
      //       }, 100);
      //     });
      //   });
      // };

      // reader.readAsDataURL(blob);
    })
  }

  addFileExtension(msg) {
    if (msg.attachments && msg.attachments.length) {
      msg.attachments.forEach((attachment) => {
        const nameSegments = attachment.attachment_name.match(/.+(\..+)/);
        if (nameSegments && nameSegments.length > 1) {
          attachment.ext = nameSegments[1].toLowerCase();
        } else {
          attachment.ext = '';
        }
      });
    }
  }

  addDateTimeToMsg(msg) {
    msg.timestamp = new Date(msg.created_at).getTime();
    msg.regDate = this.getChatDateTimeLabel(msg.created_at, false);
    msg.created_at = moment(msg.created_at).format('YYYY-MM-DD HH:mm:ss');
    // console.log('created at',msg.created_at);
    msg.time = momenttz.tz(moment(msg.created_at).format('YYYY-MM-DD HH:mm:ss'), 'America/Chicago').local();
    // console.log('msg time',msg.time);
    // const today = moment().format('D MMMM, YYYY');
    // const msgTime = moment(msg.created_at).format('D MMMM, YYYY');
    // msg.regDate = today === msgTime ? '' : msgTime;
  }

  openOrderPage(){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`${this.orderLink}/${this.selectedChat?.order_id}`])
    );
    // console.log('url',url);
    window.open(url,'_blank');
  }

  async readUnreadMessages(chatId){
      let data = {'chat_id': chatId, 'user_id': this.userId}
      let apiResult  = await this.commonAPIService.fnChangeUserMsgStatusToRead(data);
      if (apiResult['status']) {
        this.chatsService.fetchAllUnreadMessagesCounts();
          this.unreadMessagesData = [];
          this.unreadMessages = this.unreadMessagesData.length;
          if (this.selectedChat) {
            this.selectedChat.unreadMessages = 0;
          }
      } 
  }

  readChatNotification(chatId){
    // console.log('comming here...',chatId);
    // console.log('unread',this.notificationService.unreadChatNotifications);
    let user = this.commonAPIService.fnGetUser();
    // this.notificationService.unreadChatNotifications.forEach(notification=>{
    //   if (!notification.isRead && chatId == notification.chatId) {
    //     notification.isRead = true;
    //     this.notificationService.readChatNotification(user.id, notification);
    //   }
    // })
    this.notificationService.unreadChatNotifications.forEach(notification=>{
      if (!notification.isRead && chatId == notification.chatId) {
        this.commonAPIService.fnDismissChatNotification(user.id, notification.notificationId).catch(error => {
          console.log(error.message, 'dissmised error')
        })
      }
    })
    // this.unreadChatThreadNotifications.forEach(notification=>{
    //   if (!notification.isRead && chatId == notification.chatId) {
    //     notification.isRead = true;
    //     this.notificationService.readChatThreadNotification(user.id, notification);
    //   }
    // })
    this.unreadChatThreadNotifications.forEach(notification=>{
      if (!notification.isRead && chatId == notification.chatId) {
        // console.log('notification',notification);
        // this.notificationService.dismissChatThreadNotification(user.id, notification.notificationId);
        this.commonAPIService.fnDismissChatThreadNotification(user.id, notification.Id).catch(error => {
          console.log(error.message, 'dissmised error')
        })
      }
    })
  }

  getMessageMentions(message) {
    let mentions = [];
    (JSON.parse(localStorage.getItem('messageMentions')) || []).forEach((member) => {
      let userFound;
      if (message.includes(`>${member.name}<`)) {
        userFound = this.chatMembers.find((user: any) => user.user_id === member.userId);
        if(!userFound){
          userFound = this.allHQUsers.find((user: any) => user.user_id == member.userId);
        }
        // console.log('userFound',userFound);
        if (userFound) {
          mentions.push({userId: userFound.user_id, email: userFound.email, permission: userFound.permission});
        }
      }
    });
    return mentions;
  }

  getMentionsFromMsgBeingEdited(msgText) {
    let mentionedNames = [];
    let mentions = [];
    msgText.replaceAll(/<span class="member-mention mention-[a-z0-9\-]*">[a-z\s]*<\/span>/gi, (match) => {
      let mentionedName = '';
      match.replace(/>[a-z\s]*</i, (name) => {
        mentionedName = name.slice(1, (name.length - 1));
      });

      if (mentionedName) {
        mentionedNames.push(mentionedName);
      }
    });

    this.chatMembers.forEach((user: any) => {
      if (mentionedNames.includes(user.name)) {
        mentions.push(user);
      }
    });

    return mentions;
  }

  isUploadInProgress() {
    return this.uploads.some((file: any) => !file.url);
  }

  formatHyperlinksInMessage(message) {
    if (message) {

      message = message.replaceAll(/href="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)[^\s<]*"/gi, (match) => {
        return `href=""`;
      });

      message = message.replaceAll(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)[^\s<]*/gi, (match) => {
        match = `<a href="${match}" target="_blank" class="link-in-msg">${match}</a>`;
        return match;
      });
    }
    return message;
  }

  async sendMessage(messageText) {
    this.clearMentionStylingInterval();
    let isAttachment = false
    if (this.uploads.length > 0) {
      if (this.isUploadInProgress()) {
        alert('Please wait... Attachments are being uploaded.')
        return false;
      }
      isAttachment = true;
    }
    if (!messageText && isAttachment == false) {
      alert('Please enter a message');
      return false;
    }
    messageText = this.formatHyperlinksInMessage(messageText);
    const temp_msg_id = uuid();
    const attachments = this.uploads.map((file: any) => {
      return {
        attachment: file.url,
        attachment_name: file.name,
        attachment_size: file.size,
        type: file.type
      };
    });
    const newMessage = {
      attachments,
      chat_id: this.chatId,
      temp_msg_id,
      is_edited: 0,
      is_status_change: 0,
      message: messageText,
      parent_message_id: null,
      permissions_role: this.currentUser.permissionLevel,
      profile_pic: this.currentUser.photo,
      user: this.currentUser.name,
      user_id: this.userId,
      isBeingSent: true
    };
    this.msgAddListner(newMessage);
    const mentions = this.getMessageMentions(messageText);
    let data = { message: messageText, user_id: this.userId, senderName: this.currentUser.name, chat_id: this.chatId, temp_msg_id, attachments: this.uploads, mentions, orderId: this.inputOrderId, permissionLevel: this.currentUser.permissionLevel };
    localStorage.removeItem('messageMentions');
    this.uploads = [];
    this.msgContent = undefined;
    let apiResult: any = await this.commonAPIService.fnAddChatsMessages(data);
    // console.log('send message result',apiResult);
    if (apiResult && !apiResult.status) {
      this.chatMessages.filter((item: any) => {
        if (item.temp_msg_id === newMessage.temp_msg_id) {
          item.isFailed = true;
        }
      });
    }
    //To update thread-list after sending a message.
    const chatIndex = this.allOrderChats.findIndex(chat=>chat.chat_id == apiResult.msg.chat_id);
    if(chatIndex != -1){
      this.allOrderChats[chatIndex].lastMessageTime = apiResult.msg.created_at;
      if(chatIndex != 0){
        const chatThread = this.allOrderChats.splice(chatIndex,1);
        // console.log('chat',chatThread[0]);
        this.allOrderChats.unshift(chatThread[0]);
      }
      this.setChatsList();
    }
    this.chatMessages[this.chatMessages.length - 1]['attachments'] = apiResult.msg.attachments;
    this.attachmentInput.nativeElement.value = '';
  }

  // startTimerForDeleteMember() {
  //   // console.log('coming in starting timer');
  //   if (this.memberBeingDeleted) {
  //     const memberId = this.memberBeingDeleted.id;
  //     this.memberBeingDeleted.timerId = setTimeout(() => {
  //       if (this.memberBeingDeleted?.id === memberId) {
  //         this.cancelCurrentDeleteMember();
  //         // this.memberBeingDeleted.timerId = null;
  //       }
  //     }, 3000);
  //   }
  // }

  // cancelTimerForDeleteMember(memberId) {
  //   // console.log('coming in canceling');
  //   if (this.memberBeingDeleted && this.memberBeingDeleted.timerId && this.memberBeingDeleted.id == memberId) {
  //     clearTimeout(this.memberBeingDeleted.timerId);
  //     this.memberBeingDeleted.timerId = null;
  //   }
  // }

  cancelCurrentDeleteMember() {
    if (this.memberBeingDeleted) {
      this.memberBeingDeleted.confirmMenu = false;
      this.memberBeingDeleted.showDeleteMenu = false;
      this.memberBeingDeleted = null;
    }
  }

  confirmRemoveChatMember(member){
    this.cancelCurrentDeleteMember();
    member.confirmMenu = true;
    member.showDeleteMenu = false;
    this.memberBeingDeleted = member;
    // console.log('memberbeing deleted',this.memberBeingDeleted);
    this.addDeleteMemberClickListener();
  }

  addDeleteMemberClickListener() {
    // console.log('coming here in listener');
    this.deleteMemberEventHandler = (event) => {
      const currentNode = event.target;
      const msgDeleteBtn = document.getElementById('deleteMemberPopup');
      // console.log('click listsner',currentNode,msgDeleteBtn,msgDeleteBtn.contains(currentNode));
      if (msgDeleteBtn && currentNode.id !== msgDeleteBtn.id && !msgDeleteBtn.contains(currentNode) && currentNode.id !='deleteMemberIcon' ) {
        this.cancelCurrentDeleteMember();
        this.removeDeleteMemberClickListener();
      }
    };
    document.addEventListener('click', this.deleteMemberEventHandler);
    const containerEle: any = document.getElementById('chatPageContainer');
    const chatMemberEle:any = document.getElementById('chatMemMenu');
    if (containerEle) {
      containerEle.addEventListener('click', this.deleteMemberEventHandler);
    }
    if (chatMemberEle) {
      chatMemberEle.addEventListener('click', this.deleteMemberEventHandler);
    }
  }

  removeDeleteMemberClickListener() {
    document.removeEventListener('click', this.deleteMemberEventHandler);
    const containerEle: any = document.getElementById('chatPageContainer');
    const chatMemberEle:any = document.getElementById('chatMemMenu');
    if (containerEle) {
      containerEle.removeEventListener('click', this.deleteMemberEventHandler);
    }
    if (chatMemberEle) {
      chatMemberEle.removeEventListener('click', this.deleteMemberEventHandler);
    }
  }

  async fetchSearchUsers(){
    // console.log('coming here in search users');
    if(this.searchUser != ''){
      const requestData = {
        searchQuery : this.searchUser,
        userIds : this.chatMembers.map((user:any)=> {
          return user.user_id;
        })
      }
      const response:any =  await this.commonAPIService.fnSearchUsers(requestData);
      this.searchedChatUsers = response;
      // console.log('getting search response',response);
    }
  }

  async removeChatMember(member){
    const response: any = await this.commonAPIService.fnDeleteChatMember({chatUserId: member.id, chatId: this.chatId});
    if (response.isDeleted) {
      const deletedMemberIndex = this.chatMembers.findIndex((chatMember:any) => chatMember.id == member.id)
      this.chatMembers.splice(deletedMemberIndex,1);
      this.setMentionSource();
    }
  }

  async addChatMember(member){
    const response:any = await this.commonAPIService.fnAddChatMember({'chatId': this.selectedChat ? this.selectedChat.chat_id : this.chatId, 'userId' : member.user_id});
    // console.log('response',response);
    const chatMember = {
      'email' : member.email,
      'name' : member.name,
      'id' : response.chatMember,
      'permission' : member.permissionLevel,
      'profile_pic' : member.photo,
      'user_id' : member.user_id
    }
    // console.log('chatmember',chatMember);
    this.chatMembers.push(chatMember)
    this.searchUser = '';
    this.setMentionSource();
    // console.log('memebers',this.chatMembers);
  }

  async saveEditedMessage(msgText, msg) {
    if (!msgText) {
      alert('Please enter a message');
      return;
    }
    msgText = this.formatHyperlinksInMessage(msgText);
    const latestMentions = (this.getMessageMentions(msgText)).filter((mention) => !msg.previousMentions.some((user: any) => user.user_id === mention.userId));
    const reqData = {
      chatId: this.msgBeingEdited.chat_id,
      msgId: this.msgBeingEdited.id,
      msg: msgText,
      mentions: latestMentions,
      orderId: this.inputOrderId,
      senderName: this.currentUser.name,
    };
    const response: any = await this.commonAPIService.fnEditChatMessage(reqData);
    this.msgBeingEdited.isBeingEdited = false;
    this.msgBeingEdited.isInActionMode = false;
    this.msgBeingEdited = null;
    this.msgContent = undefined;
  }

  // removeChatMember(chatMemberId){
  //   alert('Are you sure, you want to remove the user from chat? ' + chatMemberId)
  // }


  async uploadAttachments(event) {
    try {
        let indexAddition = this.uploads.length;
        let files = event.srcElement.files
        let imageExt = ['.jpeg', '.jpg', '.png', '.gif','.heic'];

        for (let file of files) {          
          let convertedHeicFile = '';
          if (file.name.includes('.heic')){
              let fileName = file.name;
              let newFileName = fileName.replace('.heic', '') + '.jpeg'
              let jpgBlob: any = await this.commonService.convertTheHeic(file)
              file = new File([jpgBlob], newFileName);
              convertedHeicFile = file;
          }
          let size = (file.size/1000).toFixed(2)
          let ext = file.name.match(/.+(\..+)/)[1].toLowerCase();
          let src = undefined
          let type = 'doc'
          if (imageExt.includes(ext)) {
              src = URL.createObjectURL(file)              
              src = this.sanitizer.bypassSecurityTrustUrl(src)
              type = 'image'
          }
          let fileObject = {'name': file.name, 'size': size, 'progress': 0, 'src': src, 'type': type, ext, convertedHeicFile}
          this.uploads.push(fileObject)
        }
        console.log(this.uploads)

        for (let fileIndex in files) {
            let file = files[fileIndex];
            console.log(file, '---', fileIndex, '---', file.name);
            if (!file.name || file.name == 'item') {
              continue;
            }
            if (this.uploads[fileIndex]['convertedHeicFile']){
              file = this.uploads[fileIndex]['convertedHeicFile']
            }

            fileIndex =  (parseInt(fileIndex) + indexAddition).toString()
            let fileLocation = `chatAttachments/${Date.now()}_${file.name}`;
            let storageRef = firebase.default.storage().ref();
            let uploadTask = storageRef.child(fileLocation).put(file);
            uploadTask.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                    if (this.uploads[fileIndex]) {
                        this.uploads[fileIndex]['progress'] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    }
                },
                (error) => {
                    console.log(error)
                },
                () => {
                    this.storage.ref(fileLocation).getDownloadURL().subscribe(res => {
                        console.log(res)
                        if (this.uploads[fileIndex]) {
                            console.log('Set url in uploads object == ', fileIndex, file.name)
                            this.uploads[fileIndex]['url'] = res    
                        } else {
                            console.log('Delete from firebase == ', fileIndex, file.name)
                            this.deleteFileOnFirebase(res);
                        }
                        console.log(this.uploads)
                    });
                }
            )
        }
    } catch (e) {
      console.log(e)
    }
  }

  removeAttachment(index){
      console.log('removeAttachment() function')
      console.log(index, '---', this.uploads[index])
      let file = this.uploads[index];
      this.uploads.splice(index, 1);
      console.log(file)
      if (file['url']) {
          this.deleteFileOnFirebase(file['url']);
      } else {
          // Set file in delete after upload set.
      }
  }

  deleteFileOnFirebase(fileUrl){
      console.log('In deleteFileOnFirebase() method --- ', fileUrl)
      let fileRef = firebase.default.storage().refFromURL(fileUrl)
      fileRef.delete().then(function () {
          console.log("File Deleted")
      }).catch(function (error) {
          console.log(error)
      });
  }

  downArrowClick(index) {
    let garmentDivArray = [];
    this.orderProducts.forEach((element, i) => {
      let garmentDivById = document.getElementsByClassName('gar-item-'+i);
      garmentDivArray.push(garmentDivById)
    });
    let garmentDiv = garmentDivArray[index];
    garmentDiv[0].scrollTop += 100;
  }

  upArrowClick(index) {
    let garmentDivArray = [];
    this.orderProducts.forEach((element, i) => {
      let garmentDivById = document.getElementsByClassName('gar-item-'+i);
      garmentDivArray.push(garmentDivById)
    });
    let garmentDiv = garmentDivArray[index];
    garmentDiv[0].scrollTop -= 100;
  }

  scrollHandler(event, index) {
      if (event.target.scrollTop == 0) {
        this.orderProducts[index].isUpArrowVisible = false;
      }
      if (Math.floor(event.target.scrollTop - 1) > 0) {
        this.orderProducts[index].isUpArrowVisible = true;
      }
      if (event.target.scrollTop < event.target.scrollHeight) {
        this.orderProducts[index].isDownArrowVisible = true;
      }
      if (event.target.offsetHeight + event.target.scrollTop + 5 >= event.target.scrollHeight) {
        this.orderProducts[index].isDownArrowVisible = false;
      }
  }
}
