<div class="sidebar" *ngIf="isSidebarOpen">
  <div id="mobile-sidebar">
    <ul>
      <li>
        <div class="item-title"  id="brand"  data-toggle="collapse" data-target="#collapsebrand" aria-expanded="true" aria-controls="collapsebrand">
          Brands
        </div>
        <div id="collapsebrand" class="sub-menu collapse" aria-labelledby="brand" data-parent="#mobile-sidebar">
          <div class="row sidebar-brands" *ngIf="brands">
            <ng-container *ngFor="let brand of brands">
              <div class="col-md-12 header-brand">
                <!-- <span (click)="searchProducts(brand);">{{brand}}</span> -->
                <a  routerLink="/products/{{brand | removeSpaceConvertUnderScopeLowerCase}}" (click)="closeSidebar()">{{brand}}</a>
              </div>
            </ng-container>
          </div>
          <div class="col-md-12 text-center mt-2 header-view-all">
            <a href="/products">View All</a>
          </div>
        </div>
      </li>
      <li>
        <div class="item-title" id="categories"  data-toggle="collapse" data-target="#collapsecategories" aria-expanded="true" aria-controls="collapsecategories">
          Categories
        </div>
        <div id="collapsecategories" class="sub-menu collapse" aria-labelledby="categories" data-parent="#mobile-sidebar">
          <div class="row sidebar-categories" *ngIf="categories">
            <ng-container *ngFor="let cat of categories">
              <div class="col-md-12 header-category">
                <!-- <span (click)="searchProducts(cat);">{{cat}}</span> -->
                <a routerLink="/products/{{cat | removeSpaceConvertUnderScopeLowerCase}}" (click)="closeSidebar()">{{cat}}</a>
              </div>
            </ng-container>
          </div>
          <div class="col-md-12 text-center mt-2 header-view-all">
            <a href="/products">View All</a>
          </div>
        </div>
      </li>

      <li *ngIf="authService.isUserAuthorized()">  
        <div class="item-title" id="admin"  data-toggle="collapse" data-target="#collapseadmin" aria-expanded="true" aria-controls="collapseadmin">
          Admin
        </div>
        <div class="sub-menu" id="collapseadmin" class="sub-menu collapse" aria-labelledby="admin"
          data-parent="#mobile-sidebar">
          <!-- sales  -->
          <ng-container
            *ngIf="authService.isUserAuthorized() && (authService.isUserSalesPartner() || authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
            <div class="sub-head">
              <span>Sales</span>
            </div>
            <div>
              <span routerLink="/sales" (click)="closeSidebar()">Orders</span>
            </div>
          </ng-container>

          <!-- Design -->
          <ng-container
            *ngIf="authService.isUserAuthorized() && (authService.isUserDesignPartner() || authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
            <div class="sub-head">
              <span> Design </span>
            </div>
            <div>
              <span routerLink="/design" (click)="closeSidebar()"> Orders</span>
            </div>
          </ng-container>

          <!-- Production -->
          <ng-container
            *ngIf="authService.isUserAuthorized() && (authService.isUserProductionPartner() || authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
            <div class="sub-head">
              <span> Production </span>
            </div>
            <div>
              <span routerLink="/production" (click)="closeSidebar()"> Orders</span>
            </div>
            <div>
              <span routerLink="/production/pricing" (click)="closeSidebar()"> Pricing </span>
            </div>
          </ng-container>


          <!-- Product -->
          <ng-container  *ngIf="authService.isUserAuthorized() && (authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
            <div class="sub-head"><span>Products</span></div>
            <div><span routerLink="/products" (click)="closeSidebar()"> Catalog </span></div>
            <div><span routerLink="/products/manage" (click)="closeSidebar()"> Manage </span></div>
            <div><span routerLink="/products/manage/categories" (click)="closeSidebar()"> Categories </span></div>
            <div><span routerLink="/products/manage/colors" (click)="closeSidebar()"> Colors </span></div>
          </ng-container>

          <!-- Setting -->
          <ng-container *ngIf="(authService.isUserAdmin() || authService.isUserCSD() || authService.isUserOM())">
            <div class="sub-head">
              <span> Settings </span>
            </div>
            <div>
              <span routerLink="/admin" (click)="closeSidebar()"> Users</span>
            </div>
          </ng-container>

          <!-- Resources -->
          <ng-container >
            <div class="sub-head"><span>Resources</span></div>
            <!-- <div *ngIf="authService.isUserAuthorized() && !authService.isUserDesignPartner() && !authService.isUserProductionPartner()"><a href="https://docs.google.com/spreadsheets/d/1NNwbwTxEhujHJylOUkztDyVIk2bFdtsHcmjQZ8iWnSw/edit?usp=sharing" target="_blank" (click)="closeSidebar()"> Master Doc </a></div> -->
            <!-- <div *ngIf="authService.isUserAuthorized() && !authService.isUserDesignPartner() && !authService.isUserProductionPartner()"><a href="https://resources.upmerch.com/" target="_blank" (click)="closeSidebar()"> Knowledge Base </a></div> -->
            <div *ngIf="authService.isUserAuthorized() && !authService.isUserDesignPartner() && !authService.isUserProductionPartner()"><a href="http://resources.upmerch.com/en/" target="_blank" (click)="closeSidebar()">Knowledge Library</a></div>
            <div><a href="https://form.asana.com/?k=oh_5Sucn7zkDtYsVP8LVuQ&d=1195957427643197" target="_blank" (click)="closeSidebar()"> Report Bug </a></div>
            <div><a href="https://form.asana.com?k=arcyiWpebjrU-qyBk91sLw&d=1195957427643197" target="_blank" (click)="closeSidebar()"> Request Feature </a></div>
            
            <!-- <div><span> Bill N Bag Templates </span></div>
            <div><span> Call with CSD </span></div>
            <div><span> Ordering Policies </span></div>
            <div><span> Video Lession </span></div>
            <div><span> UPS Ground Map </span></div>
            <div><span> Master Doc </span></div>
            <div><span> Social Media </span></div> -->
          </ng-container>
        </div>
      </li>
       <li *ngIf="authService.isUserAuthorized()">
          <div class="item-title" (click)="closeSidebar();authService.logout();">
              Log Out
          </div>
       </li>
       <li *ngIf="authService.isLoading == false && (authService.isUserAuthorized() == false || authService.isUserAuthorized() == null)">
        <div class="item-title">
          <a  [routerLink]="['/login']" >
            Partners
          </a>
        </div>
      </li>
      <li *ngIf="authService.isLoading == false && (authService.isUserAuthorized() == false || authService.isUserAuthorized() == null)">
        <div class="item-title start-order-div">
          <a  (click)="routeTOStartAnOrder()" class="start-order-btn">
            Start an Order
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="background-layer" (click)="closeSidebar()"></div>