import { Component, OnInit } from '@angular/core';
import { CommonAPIService } from '../../../../utils/services/common-api.service';
import { environment } from '../../../../../environments/environment';
declare const $: any;


@Component({
  selector: 'app-pp-members',
  templateUrl: './pp-members.component.html',
  styleUrls: ['./pp-members.component.scss']
})
export class PpMembersComponent implements OnInit {

  table;
  ppCompanyId : number = 1;
  searchData : any ;
  status: string = 'Active'
  statusesCount : Object = {'active': 0, 'inactive': 0, 'invited': 0}
  // headers = ['user', 'role', 'phone', 'email']
  // selectedheader = 'user';
  // sortingDirection = 'asc'
  
  constructor(
    private commonAPIService: CommonAPIService,
  ) { }

  async ngOnInit() {
  	var $this = this;
    // let serverUrl = location.origin;
    let targetUrl = environment.config.proxy_url;
    let postUrl = targetUrl + 'getPpTeamMembers';
    let accessToken = environment.config.api_key

    let table = $('#example').DataTable({
        // columns: $this.columnSet,
        // data: $this.objectDataSet,
        // "processing": true,
        "serverSide": true,        
        "ajax": {
          "url": postUrl,
          "type": "POST",
          "data": function ( d ) {
             return $.extend( {}, d, {
             'status': $this.status,
             'pp_company_id': $this.ppCompanyId,
             });
           },
          'beforeSend': function (request) {
            request.setRequestHeader("access-token", accessToken);
          }
        },
        columns: [            
            {
              data: 'user',
              render: function (data, type, row) {                        
                  let photo = row.photo
                  // let style='background-color: green;'
                  let style='color: green; font-size: 25px;'
                  // console.log('----------------', data, type, row)
                  // $(this).addClass('label-warning');
                  let html = '<div style="padding: 5px">' +
                                '<img src="'+photo+'" style="height: 40px; width: 40px; border-radius: 20px">'+
                                '<span style="margin-left: 20px">'+data+'</span>'+
                              '</div>'
                  // return '<a class="cells" href="' + link + '" style="' + style + '">' + data + '</a>';
                  return html; 
              },
            },
            {
              data: 'role',
              render: function(data, type){
                let html = '<span style="height: 30px; border-radius: 17px; background-color: #ECEEF1; padding: 8px 20px 7px 20px;">'+data+'</span>';
                return html;
              },
            },
            {
              data: 'phone',
              render: function(data, type){
                let html = '<span style="color: #9D9EB2;">'+data+'</span>';
                return html;
              },
            },
            {
              data: 'email',
              render: function(data, type){
                let html = '<span style="color: #9D9EB2;">'+data+'</span>';
                return html;
              },
            },
        ],

        keys: true,       
        // colReorder: true,        
        info: false,
        paging: false,        
    });

    this.table = table;
    $("#example_filter").hide();

    // $('#example').on( 'click', 'tbody td', function () {
    //    console.log('Td clicked')
    //    console.log($(this).html())
    // });
    // $('#example').on( 'click', 'tr', function () {
    // 	console.log('Tr clicked')
    // 	console.log(table.row( this ).id())
    // 	console.log(table.row( this ).user())
    // });

    // Api
    // Call the api
    await this.getStatusesCount()    
    
    // $('#example').on( 'draw.dt', function () {
    //   let order = $this.table.order();
    //   $this.selectedheader = $this.headers[order[0][0]]
    //   console.log(order)
    //   $this.sortingDirection = order[0][1]
    // });    
  }

  searchMembers(){
    console.log('In search method', this.searchData)
    console.log(this.table.settings()[0].jqXHR)    
    this.table.settings()[0].jqXHR.abort()
    console.log(this.table.settings()[0].jqXHR)
    this.table.search(this.searchData).draw();
  }

  async getStatusesCount(){
    // this.userId =  this.commonAPIService.fnGetUser()['id'];
    let data = {'pp_company_id': this.ppCompanyId}
    const response: any = await this.commonAPIService.fnGetStatusesCount(data);
    this.statusesCount = response;
  }

  changeStatus(status){
    console.log('in function changeStatus()')
    this.status = status
    this.table.draw()
  }

  closeSearch(){
        this.searchData = '';
        // this.isSearching = false;
        // this.currentPage = 0;
        this.table.search(this.searchData).draw();
        // this.getStatusesCount();
    }

}
