import { Injectable } from '@angular/core';
// import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from '@ngx-meta/core';

@Injectable()
export class SeoService {

  constructor( private readonly meta: MetaService) { }

  /* setMetaTags(title: string, description: string): void {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });
  } */
   getRandomMetaTemplate(brand: string, productName: string): string {
    const templates = [
      `Create ${brand} ${productName} custom-branded merchandise with your logo! Many product options, high-quality logo decoration & great service!`,
      `${brand} ${productName} for corporate gifting & marketing solutions. Add your logo for custom branding your team members will love.`,
      `Add your company's logo to the ${brand} ${productName}! Guaranteed to make your team look awesome, be high-quality & memorable.`,
      `Boost your brand's visibility with our favorite ${brand} ${productName}! Customizable with your logo for maximum impact & quality.`,
      `Personalize the ${brand} ${productName} today! UpMerch offers thousands of products, custom decoration, & excellent service.`
    ];

    const randomIndex = Math.floor(Math.random() * templates.length);
    return templates[randomIndex];
  }

  setTitle(title: string): void {
    this.meta.setTitle(title);
  }

  setMetaDescription(description: string): void {
    // this.meta.updateTag({ name: 'description', content: description });
    this.meta.setTag('description', description);
  }

  setProductMetaDescription(brand: string, productName: string): void {
    const randomTemplate = this.getRandomMetaTemplate(brand, productName);
    /* this.meta.updateTag({ name: 'description', content: randomTemplate });
    this.meta.updateTag({ property: 'og:description', content: randomTemplate }); */
    this.meta.setTag('description', randomTemplate);
    this.meta.setTag('og:description', randomTemplate);
  }

  setOgMetaTags(title: string, description: string, imageUrl: string, url: string) {
    /* this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
    this.meta.updateTag({ property: 'og:url', content: url }); */
    this.meta.setTag('og:title', title);
    this.meta.setTag('og:image', imageUrl);
    this.meta.setTag('og:url', url);
  }
}

