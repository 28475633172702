import { Injectable } from '@angular/core';
import * as _ from "lodash";

@Injectable()
export class EventsService {

  eventCallbacks: any = {};

  constructor() { }

  public subscribe(event,callback){
    if(this.eventCallbacks[event]){
      this.eventCallbacks[event].push(callback);
    }else {
      this.eventCallbacks[event] = [callback];
    }
  }

  public publish(event,data){
    _.forEach(this.eventCallbacks[event],(callback)=>{
      callback(data);
    })
  }

}
