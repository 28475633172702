<!-- <div class="backgroud-layer" [hidden]="hideNotifications" (click)="hide()">

</div>
<div class="notification-list" [class.empty-box]="fnFilterByUnread().length == 0" [hidden]="hideNotifications">
    <i class="fa fa-sort-asc brand-sort-asc" aria-hidden="true"></i>
    <div class=header-content> -->
        <!-- <div class="close-button">
            <span (click)="hide()">X</span>
        </div> -->
        <!-- header -->
        <!-- <div class="header">
            <span>Notifications</span>
            <hr>
        </div> -->
        <!-- filter option -->
        <!-- <div class="filter">
            <div class="unread"> <span (click)="fnFilter()">{{filterByUnread ? "View All": "Filter by Unread"}}</span>
            </div>
            <div class="mark-all-read"> <span (click)="fnMarkAsRead()">Mark All as Read</span></div>
        </div>
    </div> -->
    <!-- listing of messages -->
    <!-- <div class="noti-list" (scroll)="onScrollNoti($event)">
        <div class="noti-list-wrapper">
            <div class="noti-empty" *ngIf="filteredNification.length == 0  && !isLoading"> -->
                <!-- <div class="icon-section">
                </div> -->
                <!-- <span class="image-section">
                    <i class="fa fa-comment-o" aria-hidden="true"></i>
                </span>
                <br><br>
                <span>
                    No Unread Notifications
                </span>
                <br>
                <small>
                    Click <u><span class="view-all-in-empty-message link"
                            (click)="fnFilter()">{{filterByUnread ? "View All": "Filter by Unread"}}</span></u> to view
                    all of your chat notifications
                </small>
            </div>
            <div class="noti-empty" *ngIf="isLoading">
                <paper-spinner active></paper-spinner>
                <br><br> -->
                <!-- <span class="updating-messages">
                   <strong>Please wait, Updating messages as read and will take few minutes!</strong>
                </span>
                <br><br> -->
            <!-- </div>
            <ng-container *ngIf="filteredNification.length > 0 && !isLoading">
                <div class="position-relative" *ngFor="let notification of filteredNification; let i=index">
                    <div>
                    <div class="single-notification" [class.filer]="filterByUnread">
                        <a [routerLink]="editPath + notification.orderId" (click)="readNotification(notification)">
                            <div class="order-number">Order #{{notification.orderId}} |
                                {{notification.jobName ? notification.jobName : ""}}</div>
                            <div class="message">{{ notification.message }}

                            </div>
                            <div class="action-info">
                                <div></div>
                                <div class="date">
                                    {{ notification.timestamp | date: 'h:mma M/d/yyyy' | lowercase }}
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="read-noti-position">
                        <div class="read-flag ">
                            <div class="dark" [tooltip]="(notification.isRead ? 'Mark Unread' : 'Mark Read')"
                                [class.green]="!notification.isRead" (click)="changeStatus(notification)"></div>
                        </div>
                    </div>
                    </div>
            </div>
            <div class="load-more" *ngIf="!filterByUnread && !isFetchingdata && !paginationAll.isFinished"><span (click)="loadMoreNotifications()">Load More...</span></div>
            </ng-container>
        </div>
        <div></div>
    </div>
</div> -->

<!-- <paper-icon-button icon="mail" slot="dropdown-trigger" (click)="toggleNotificationDropdown()"></paper-icon-button> -->
<!-- <img (click)="toggleNotificationDropdown()" class="icon-img" src="/assets/images/iconmonstr-bell-2.svg" alt="" srcset=""> -->

<a routerLink="/chat">
    <div class="noti-icon-wrapper">
        <img class="icon-img" src="/assets/images/comments-alt-black.svg">
        <paper-badge *ngIf="chatsService.unreadMessages"
            label="{{ chatsService.unreadMessages > 20 ? '20+': chatsService.unreadMessages}}"
            class="chat-notification-badge" style="left: 22px;
    top: 8.8px;"></paper-badge>
    </div>
</a>