<div class="mainDiv">
    <div class="mainDivChild">

        <div class="tableTopHeader" style="height: 65px;">            
            <div class="labelWrapper">
                <div class="labelDiv">
                    <i class="fa-regular fa-user-group" style="font-size: 18px; margin-right: 7px; opacity: 0.5;"></i>
                    <span class="label-team-member">Team Members</span>
                </div>
            </div>

            <div class="statusWrapper">
                <div class="statusPDiv">
                    <div [ngClass]="{'statusTextBright': status === 'Active'}" (click)="changeStatus('Active')">
                        <span class="cursorPointer">Active</span>
                        <span class="statusCountBox" [ngClass]="{'statusBright': status === 'Active'}">{{statusesCount['active']}}</span>
                        <span class="dot"></span>
                    </div>
                    <div [ngClass]="{'statusTextBright': status === 'Inactive'}" (click)="changeStatus('Inactive')">
                        <span class="cursorPointer">Inactive</span>
                        <span class="statusCountBox" [ngClass]="{'statusBright': status === 'Inactive'}">{{statusesCount['inactive']}}</span>
                        <span class="dot"></span></div>
                    <div [ngClass]="{'statusTextBright': status === 'Invited'}" (click)="changeStatus('Invited')">
                        <span class="cursorPointer">Invited</span>
                        <span class="statusCountBox" [ngClass]="{'statusBright': status === 'Invited'}">{{statusesCount['invited']}}</span>
                    </div>
                </div>
            </div>
            
            <!-- <div class="searchWrapper" style="width: 20%;">
              <div class="searchDiv">
                <span class="searchAnchor" (click)="searchMembers()" href="#">
                  <img src="/assets/images/iconmonstr-magnifier-6.svg" alt="" style="height: 16px; width: 16px;">
                  <i class="fa-regular fa-magnifying-glass"></i>
                </span>                
                <input type="search" aria-label="Search" [(ngModel)]="searchData" placeholder="Search.." style="border: none;" (keyup)="searchMembers()">
              </div>              
            </div> -->

            <div class="search-wrapper searchDiv" style="width: 20%;">
                <i class="fa-regular fa-magnifying-glass"></i>
                <input type="search" [(ngModel)]="searchData" placeholder="Search members..." style="border: none; margin-left: 6px;" (keyup)="searchMembers()">
                <i class="fa-regular fa-xmark" *ngIf="searchData" (click)="closeSearch()"></i>
            </div>

            <div class="addMemberWrapper" style="width: 15%;">
                <div class="addMemberButton" ><span style="font-size: 13px; font-weight: bolder;">+</span>  Member</div>
            </div>

        </div>

        <table class="row-border" id="example" width="100%">            
            <thead>
                <tr>
                <th>Member</th>
                <th>Role</th>
                <th>Phone</th>
                <th>Email</th>
                </tr>
            </thead>
            <!-- <tbody>                
            </tbody> -->
            <!-- <tfoot>
                <tr>
                <th>User</th>
                <th>Role</th>
                <th>Phone</th>
                <th>Email</th>
                </tr>
            </tfoot> -->
        </table>

        <div class="reset-bloc" *ngIf="status != 'Active'">
            <div class="reset-wrapper">
                <span style="color: #9D9EB2; font-weight: 300;">Showing {{status}} Members Only</span>
                <span class="reset" *ngIf="status != 'Active'" (click)="changeStatus('Active')">Reset</span>
            </div>
            
        </div>

    </div>
</div>