import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatsComponent } from './components/chats/chats.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { ChatsRoutingModule } from './chats-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SafeHTMLPipe } from '../../utils/pipes/safe-html.pipe';

@NgModule({
  declarations: [ChatsComponent, SafeHTMLPipe],
  imports: [
    CommonModule,
    EditorModule,
    FormsModule,
    ChatsRoutingModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    InfiniteScrollModule
  ],
  exports: [
    ChatsComponent
  ]
})
export class ChatsModule { }
