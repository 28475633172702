import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PpMembersComponent } from './components/pp-members/pp-members.component';

const routes: Routes = [
  {path:"", component: PpMembersComponent},
  {path:":members", component: PpMembersComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PPDashboardRoutingModule { }
