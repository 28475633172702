import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

@Injectable()
export class ValidationService {

  decimalNumberValidator(decimal_allowed: number){
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      var input = control.value;
      if (input !== undefined && (!RegExp(/^[0-9]+(\.[0-9]{1,2})?$/).test(input) || input <= 0)) {
        return { 'number_valid': true }; 
      }
      return null;
    };
  }

  /* static decimalNumberValidator(decimal_allowed: number) {
    return (c: FormControl): ValidationErrors => {
      var input = c.value;
      const isValid = input !== undefined && RegExp(/^[0-9]+(\.[0-9]{1,2})?$/).test(input);
      
      const message = {
        'number_valid': {
          'message': 'Number not valid. Only ' + decimal_allowed + ' decimal allowed.'
        }
      };
      return isValid ? null : message;
    }
  } */
}
