import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, HostListener } from '@angular/core';
import { NotificationService } from 'app/utils/services/notification.service';
import { CommonAPIService } from 'app/utils/services/common-api.service';
import { AuthService } from 'app/utils/services/auth.service';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import * as _ from 'lodash'
import { FirebaseKeyHandler } from 'app/utils/models/firebase_key_handler';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChatsService } from '../../services/chats.service';
import { WebSocketService } from 'app/utils/services/web-socket.service';

declare const $:any;
@Component({
  selector: 'chat-notification-popup',
  templateUrl: './chat-notification-popup.component.html',
  styleUrls: ['./chat-notification-popup.component.scss']
})
export class ChatNotificationPopupComponent implements OnInit {

  filterByUnread:boolean = true;
  editPath: string = '/chat/';
 
  filteredNification:Array<any> = [];
  pagination:any  = {};
  paginationAll:any  = {};
  unreadNotifications = [];
  unreadThreadNotifications = [];
  hideNotifications:boolean  = true;
  isLoading:boolean = false;
  isFetchingData = false
  uid:number
  startedAt:string;

  notifications : Array<any> = [];
  @Input('auth') auth : any;
 
  constructor(
    private notificationService:NotificationService,
    private commonAPIService:CommonAPIService,
    private  route:Router,
    private authService:AuthService,
    // private db: AngularFireDatabase,
    private http: HttpClient,
    public chatsService: ChatsService,
    private webSocketService: WebSocketService,
  ) { 


  }




  ngOnInit() {

    // this.webSocketService.listen('message-notification').subscribe((event: any) => {
    //   this.chatsService.fetchAllUnreadMessagesCounts()
    //   this.fnUnreadChatNotification();
    // });

    // this.webSocketService.listen('chat-thread-notification').subscribe((event: any) => {
    //   this.chatsService.fetchAllUnreadMessagesCounts()  
    //   this.fnUnreadChatThreadNotification();
    // });

    this.authService.checkAuthAndCallback(auth => {
      this.uid = auth.uid;
      this.webSocketService.openTheConnection(null, this.uid);
  
      this.fetchUnreadMessageCountsIfNeeded(); // Fetch counts if not already fetched
      this.fnUnreadChatNotification();
      this.fnUnreadChatThreadNotification();
    });
  }
  
  fetchUnreadMessageCountsIfNeeded() {
    if (!this.chatsService.areUnreadMessageCountsFetched()) {
      this.chatsService.fetchAllUnreadMessagesCounts();
    }
  }
  
  fnUnreadChatNotification() {
    this.commonAPIService.fnGetChatUnreadNotification(this.uid).then((data: any[]) => {
      let notifications: any[];
      notifications = data;
      this.unreadNotifications = notifications;
      this.notificationService.unreadChatNotifications = [...this.unreadNotifications];
      this.notificationService.unreadChatNotifications = [...this.unreadNotifications];
    }).catch(error => console.log(error));
  }
  
  fnUnreadChatThreadNotification() {
    this.commonAPIService.fnGetAllChatThreadNotification(this.uid).then((data: any[]) => {
      let notifications: any[];
      notifications = data;
      this.unreadThreadNotifications = notifications;
      this.notificationService.unreadChatThreadNotifications.next(this.unreadThreadNotifications);
    }).catch(error => console.log(error));
  }

}
//   fetchNotifications(){
//     this.isFetchingData=true
//     this.notificationService.getChatNotifications(this.uid, this.startedAt).subscribe((notifications:any) => {
//       const tempNotifications = FirebaseKeyHandler.convertMultiple(notifications);
//       // console.log('notifications..',tempNotifications);
//       this.startedAt=tempNotifications[0]?.$key
//       notifications = tempNotifications.length >50 ? tempNotifications.slice(1,tempNotifications.length) : [...tempNotifications]
//       // console.log('notifications from firebase',notifications);
//       _.forEach(notifications, (notification) => {
//         if (notification.from) {
//           notification.user = this.db.object(
//             '/users/' + notification.from).valueChanges().subscribe((user:any) => {
//               notification.user = user;
//             });
//         }
//       });
//       notifications = _.orderBy(notifications, ['timestamp'], ['desc']);
//       this.notifications.push(...notifications);
//       this.isFetchingData=false
//       // console.log('notificatons first',this.notifications);
//       // this.resetPagination();
//       this.loadNextPageDataAll()
//     });
//   }

//   async fnMarkAsRead() {
//     this.isLoading = true;
//     await this.readAllNotifications();
//     setTimeout(async () => {
//       this.isLoading = false;
//     }, 1000);
//     if(this.filterByUnread == true) {
//       this.resetPagination();
//       this.loadNextPageData();
//     }
//   }

//   resetPaginationAll(){
//     this.filteredNification = [];
//     this.notifications=[]
//     this.paginationAll = {
//       pageSize : 50,
//       page:0,
//       isFinished : false
//     };
//   }
//   resetPagination() {

//     this.filteredNification = [];
    
//     this.pagination = {
//       pageSize : 50,
//       page:1,
//       isFinished : false
//     };
//   }

//   hide() {
//      this.toggleNotificationDropdown();
//      this.filterByUnread = true;
//      this.resetPagination();
//      this.loadNextPageData();
//   }

//   fnFilterByUnread() {
//     // console.log(this.notifications);
//     return this.unreadNotifications
//     // .filter(notifi => {
//     //     if( !notifi.isRead || this.filterByUnread == false) {
//     //       return notifi;
//     //     }
//     //   })
//     .filter(notifi => {
//       return (notifi != undefined && notifi != '' && notifi != 'undefined');
//       })
//     .map(notifi => {
//        notifi.message = notifi.message ? ( notifi.message as String).replace(new RegExp("Order #"+notifi.orderId+ ":"),"") : "";
//        return notifi;
//     });
//   }

//   fnFilter() {
//     this.filterByUnread = !this.filterByUnread ;
//     if(this.filterByUnread){
//       this.resetPagination();
//       this.loadNextPageData();
//     }
//     else{
//       this.startedAt = '';
//       this.resetPaginationAll();
//       this.fetchNotifications();
//     }
//   }

//   async readNotification(notification) {
//     let user = this.commonAPIService.fnGetUser();
//     if (!notification.isRead) {
//       notification.isRead = true;
//       this.notificationService.readChatNotification(user.id, notification);
//       await this.commonAPIService.fnChangeUserMsgStatusToRead({'user_id': user.id, 'chat_id': notification.chatId});
//     }
//     this.hide();
//     this.route.navigate([this.editPath,notification.orderId]);
//   }

//   changeStatus(notification) {
//     let user = this.commonAPIService.fnGetUser();
//     notification.isRead = !notification.isRead;
//     // console.log('notification',notification,user.id);
//     // this.notificationService.readNotification(this.auth.uid, notification).then(res => {
//     this.notificationService.readChatNotification(user.id, notification).then(async (res) => {
//       if(notification.isRead){
//         await this.commonAPIService.fnChangeUserMsgStatusToRead({'user_id': user.id, 'chat_id': notification.chatId});
//       }
//       if(this.filterByUnread){
//         this.resetPagination();
//         this.loadNextPageData();
//       }
//       else{
//         this.startedAt=''
//         this.resetPaginationAll()
//         this.fetchNotifications()
//       }
//     });
//   }

//   loadNextPageData() {
//     let totalNotification:Array<any> = this.fnFilterByUnread();
//     let start = (this.pagination.page * this.pagination.pageSize) - this.pagination.pageSize;
//     let end  = this.pagination.page * this.pagination.pageSize;
//     let slice:Array<any> = [];

//     if(this.pagination.isFinished == false) {
//      if (totalNotification.length > 0) {
//        if (totalNotification.length > this.pagination.pageSize) {
//          slice = totalNotification.slice(start, end);
//        } else {
//          slice = totalNotification;
//          this.pagination.isFinished = true;
//        }
//        if (slice.length == 0) {
//          this.pagination.isFinished = true;
//          return;
//        }
//        this.pagination.page++;
//        this.filteredNification.push(...slice);
//      }
//     }
//  }

//   loadNextPageDataAll() {
//      let totalNotification:Array<any> = this.notifications
//      .filter(notifi => {
//       return (notifi != undefined && notifi != '' && notifi != 'undefined');
//       })
//       .map(notifi => {
//         notifi.message = notifi.message ? ( notifi.message as String).replace(new RegExp("Order #"+notifi.orderId+ ":"),"") : "";
//         return notifi;
//       });
//      let start = (this.paginationAll.page * this.paginationAll.pageSize);
//      let end  = this.paginationAll.page * this.paginationAll.pageSize + this.paginationAll.pageSize;
//      let slice:Array<any> = [];
//       // console.log(totalNotification.length , this.paginationAll.page * this.paginationAll.pageSize);
//      if(this.paginationAll.isFinished == false) {
//       if (totalNotification.length > 0) {
//         if (totalNotification.length > this.paginationAll.page * this.paginationAll.pageSize) {
//           slice = totalNotification.slice(start, end);
//         } else {
//           // slice = totalNotification;
//           this.paginationAll.isFinished = true;
//         }
//         if (slice.length == 0) {
//           this.paginationAll.isFinished = true;
//           return;
//         }
//         this.paginationAll.page++;
//         this.filteredNification.push(...slice);
//         this.filteredNification = [...this.filteredNification.reduce((map, obj) => map.set(obj.$key, obj), new Map()).values()];
//       }
//      }
//   }

//   async readAllNotifications() {
//     let updateNotifications: Object = {};
//     let user = this.commonAPIService.fnGetUser();
//     let chats = [];
//     for (let notification of this.unreadNotifications) {
//       if (!notification.isRead) {
//         let key = notification.$key;
//         notification.isRead = true;
//         delete notification.$key;
//         if (notification.user) {
//           delete notification.user;
//         }
//         updateNotifications[this.auth.uid + "/" + key] = notification;
//         // this.db.object("notifications/" + this.auth.uid + "/" + key).update(notification);
//         this.db.object("message_notifications/" + user.id + "/" + key).update(notification);
//         if(!chats.includes(notification.chatId)){
//           console.log('updating query',notification.chatId);
//           await this.commonAPIService.fnChangeUserMsgStatusToRead({'user_id': user.id, 'chat_id': notification.chatId});
//           chats.push(notification.chatId);
//         }
//         console.log('Done index =', key);
//       } 
//     }
    // this.af.database.object("notifications/").update(updateNotifications);
    // var index = 0;
    // var arrayLength = this.notifications.length;
    // let chunk_size = 100;
    // for (index = 0; index < arrayLength; index += chunk_size) {
    //     let updateNotifications = {};
    //     let myChunk = this.notifications.slice(index, index+chunk_size);
    //     console.log('myChunk.length  =', myChunk.length);
    //     for (let notification of myChunk) {
    //       if (!notification.isRead) {
    //           let key = notification.$key;
    //           notification.isRead = true;
    //           updateNotifications[this.auth.uid  + "/" + key] = notification; 
    //       } 
    //     }
    //     this.af.database.object("notifications/").update(updateNotifications);
    //     console.log('Done index =', index);
    // }
  // }

  // toggleNotificationDropdown() {
  //   this.hideNotifications = !this.hideNotifications;
  // }

  // loadMoreNotifications(){
  //   // console.log('here');
  //   // this.loadNextPageData()
  //   this.fetchNotifications()
  // }

  // @HostListener('scroll', ['$event']) 
  // onScrollNoti($event) {
  //   let scrollTop = $event.target.scrollTop;
  //   let scrollHeight = $event.target.scrollHeight;
   
  //   if(this.filterByUnread && scrollTop > (scrollHeight - 1000)) {
  //      this.loadNextPageData();
  //     // this.fetchNotifications()
  //   }
  // }

// }
