import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alterString'
})
export class AlterStringPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && value.startsWith('None')) {
    	value = value.replace('None-', '');
    }
    return value;
  }

}
