import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
    name: 'negativeToPositive'
})
export class NegativeToPostivePipe implements PipeTransform {
    transform(n: any) {
        if (n == "") {
            return ""
        } else {
            return Math.abs(n)
        }
    }
}
