import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ImageModalService {
  observal:Subject<string> = new Subject();
  subscribe  = this.observal.asObservable();

  constructor() { }

  showImage(srcUrl:string) {
      this.observal.next(srcUrl);
  }

  closeModal() {
    this.observal.next("");
  }

}
