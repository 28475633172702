import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InfiniteService {
  observal:Subject<string> = new Subject();
  subscribe  = this.observal.asObservable();
  constructor() { }

  scroll() {
    console.log("scroll event is executed");
    this.observal.next("scroll");
  }

  replaceAmpWithAndSelection(sentGarment,prevStr,newStr){
    sentGarment = sentGarment.map((item)=> {
      item.data = item.name;
      if(item.name && item.name.includes(prevStr)){
        item.name = item.name.replace(prevStr,newStr);
      }
      return item;
    })
    return sentGarment;
  }

}
