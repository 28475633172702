import { Directive, ElementRef, Renderer2, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { NgModel } from "@angular/forms";
declare const $:any;

@Directive({
  selector: '[ngModel][integer-input]'
})
export class IntegerInputDirective {
@Output() ngModelChange: EventEmitter<any> = new EventEmitter(false);
  constructor(private el: ElementRef, private renderer: Renderer2, private changeDetector: ChangeDetectorRef,private model:NgModel) {
    this.renderer.listen(el.nativeElement,"input",(event)=>{
      if(parseInt(event.target.value)!==event.target.value){
        if(parseInt(event.target.value) <=  el.nativeElement.max){
          if(!event.target.value){
            this.ngModelChange.emit(0);
          }else {
            this.ngModelChange.emit(parseInt(event.target.value));
          }
        }else if(parseInt(event.target.value) > parseInt(el.nativeElement.max)){
          this.ngModelChange.emit(parseInt(el.nativeElement.max));
        }
      }
    })
    this.renderer.listen(el.nativeElement,"wheel",(event)=>{
      event.cancelBubble = true;
      event.preventDefault();
      if(event.deltaY <=0 && parseInt(event.target.value) < parseInt(event.target.max)){
        if(event.target.value==null){
          this.ngModelChange.emit(0);
        }else {
          this.ngModelChange.emit(parseInt(event.target.value) + 1);
        }
      }else if(event.deltaY > 0 && parseInt(event.target.value) >=1){
        this.ngModelChange.emit(parseInt(event.target.value)  - 1);
      }else if(!event.target.value){
        this.ngModelChange.emit(0);
      }
    })
  }


}
