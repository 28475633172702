import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {

	socket: any

    constructor() {
    }

    openTheConnection(chatId, userId) {
        if (chatId) {
            let socktData = { auth: { chatId, userId} };
            this.socket = io.connect(environment.config.proxy_url, socktData);
            this.socket.on('connect', function(){
                console.log('Connected to Server')
            });
            this.socket.on("connect_error", (err) => {
                console.log('user disconnect', err.message); // prints the message associated with the error
            });
        } else {
            let socktData = { auth: { userId } };
            this.socket = io.connect(environment.config.proxy_url, socktData);
            this.socket.on('connect', function () {
                console.log('Connected to Server')
            });
            this.socket.on("connect_error", (err) => {
                console.log('user disconnect', err.message); // prints the message associated with the error
            });
        }
    }

    listen(eventName : string) {
    	return new Observable((subscriber) => {
    		this.socket.on(eventName, (data) => {
    			subscriber.next(data)
    		})
    	})
    }

    emit(eventName : string, data: any) {
    	this.socket.emit(eventName, data)
    }

    closeTheConnection() {
    	this.socket.close();
    }
}