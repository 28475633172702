import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizeFirstPipe } from 'app/utils/shared-modules/use-pipes/pipes/capitalize-first.pipe';
import { RemoveZeroPipe } from 'app/utils/shared-modules/use-pipes/pipes/remove-zero.pipe';
import { RoundPipe } from 'app/utils/shared-modules/use-pipes/pipes/round.pipe';
import { FilterOrdersPipe } from './pipes/filter.pipe';
import { RemoveSpacesPipe } from './pipes/remove-spaces.pipe';
import { NegativeToPostivePipe } from './pipes/negative-to-positive-number.pipe';
import { RemoveSpaceConvertUnderScopeLowerCasePipe } from './pipes/remove-space-convert-under-scope-lower-case.pipe';
import { AlterStringPipe } from './pipes/alter-string.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CapitalizeFirstPipe,
    RemoveZeroPipe,
    RoundPipe,
    FilterOrdersPipe,
    RemoveSpacesPipe,
    NegativeToPostivePipe,
    RemoveSpaceConvertUnderScopeLowerCasePipe,
    AlterStringPipe
  ],
  exports:[CapitalizeFirstPipe,RemoveZeroPipe,RoundPipe,RemoveSpacesPipe,NegativeToPostivePipe,RemoveSpaceConvertUnderScopeLowerCasePipe,AlterStringPipe]
})
export class UsePipesModule { }
