import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatsComponent } from './components/chats/chats.component';

const routes: Routes = [
  {path:"", component: ChatsComponent},
  {path:":chatOrderId", component: ChatsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ChatsRoutingModule { }
