import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';


import * as firebase from 'firebase';
import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs'
import global from 'environments/global';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseKeyHandler } from '../models/firebase_key_handler';
import { CommonAPIService } from '../services/common-api.service';


@Injectable()
export class NotificationService {
  notification: {
    from?: string,
    jobName?: string,
    message: string,
    timestamp: any,
    isRead: boolean,
    isDismissed: boolean,
    orderId: string,
    to: string
  };
  unreadChatNotifications = [];
  unreadNotifications: any[] = [];
  unreadChatThreadNotifications: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    // private db: AngularFireDatabase,
    private http: HttpClient,
    private commonAPIService: CommonAPIService,
    private auth: AuthService
  ) { }

  async createNotification(orderId: string, message: string, to: string, jobName: string, from?: string,) {
    this.notification = {
      'message': message,
      'timestamp': firebase.default.database.ServerValue.TIMESTAMP,
      'isRead': false,
      'isDismissed': false,
      'orderId': orderId,
      'jobName': jobName,
      'to': to
    };

    if (from) {
      this.notification.from = from;
    }

    //  store notification into mysql 
    this.commonAPIService.fnCreateNotification(this.notification).then(res => console.log(res)).catch(err => console.log(err));

  }

  readNotification(uid, notification) {
    
    return this.commonAPIService.fnMarkAsReadById(notification.notificationId,notification.isRead,uid)

  }


  // readChatThreadNotification(uid, notification) {
  //   let key = notification.$key;
  //   delete notification.$key;
  //   if (notification.user) {
  //     delete notification.user;
  //   }
  //   console.log('firebase closed for')
  //   // return this.db.object('/chat_thread_notifications/' + uid + '/' + key).update(notification);
  // }

  async sendArtworkForApproval(order, to, from, orderId) {
    const message = 'Order #' + orderId + ': Artwork sent for approval';

    //When a designer submits your order for approval

    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(to, 'SP_ORDER_SUBMIT_APPROVE');
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      this.createNotification(orderId, message, to, order.job, from);
    }


    // this.db.object('/users/' + to).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_ORDER_SUBMIT_APPROVE)) {
    //     this.createNotification(orderId, message, to, order.job, from);
    //   }
    // });


    const response: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(1, 'AD_ORDER_SUBMIT_APPROVE');
    if (response && response.allowedUsers && response.allowedUsers.length) {
      _.forEach(response.allowedUsers, (user) => {
        this.createNotification(orderId, message, user.user_id, order.job, from);
      });
    }    

    // this.db.list("/users", ref => ref.orderByChild("permissionLevel").equalTo("Admin")).snapshotChanges().subscribe((adminUsers:any) => {
    //   adminUsers = FirebaseKeyHandler.convertMultiple(adminUsers);
    //   _.forEach(adminUsers, (adminUser) => {
    //     //When a designer submits an order for approval
    //     this.db.object('/users/' + adminUser.$key).valueChanges().subscribe((user: any) => {
    //       if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_SUBMIT_APPROVE)) {
    //         this.createNotification(orderId, message, adminUser.$key, order.job, from);
    //       }
    //     });
    //   });
    // });

    const responseData: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(7, 'OM_ORDER_SUBMIT_APPROVE');
    if (responseData && responseData.allowedUsers && responseData.allowedUsers.length) {
      _.forEach(responseData.allowedUsers, (user) => {
        this.createNotification(orderId, message, user.user_id, order.job, from);
      });
    } 

    // Send Notification to CSD User in ArtWork Approval on Order
    if (order.salesPartner && (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER)) {
      let sellerUId = order.salesPartner.uid;

      const csdUserRes: any = await this.commonAPIService.fnGetCSDUserFromSalesPartner(sellerUId);
      const csdUser = csdUserRes.csdUser;
      if (csdUser && csdUser.user_id && csdUser.permission_level !== 1) {
        const notifyUser: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(csdUser.user_id, 'AD_ORDER_SUBMIT_APPROVE');
        if (notifyUser && notifyUser.isUserAllowed) {
          this.createNotification(orderId, message, csdUser.user_id, order.job, from);
        }
      }


      // this.db.object('/users/' + sellerUId).valueChanges().subscribe((seluser: any) => {
      //   if (seluser && seluser.csd) {
      //     let csdUser = seluser.csd;
      //     this.db.list('/users', ref => ref.orderByChild('name').equalTo('csdUser')).snapshotChanges().subscribe((csdusers:any) => {
      //       csdusers = FirebaseKeyHandler.convertMultiple(csdusers);
      //       _.forEach(csdusers, (csduser) => {
      //         if (csduser.permissionLevel != 'Admin') {
      //           this.db.object('/users/' + csduser.$key).valueChanges().subscribe((user: any) => {
      //             if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_SUBMIT_APPROVE)) {
      //               this.createNotification(orderId, message, csduser.$key, order.job, from);
      //             }
      //           });
      //         }
      //       });
      //     });
      //   }
      // });


    }


  }

  //When changes are needed for an in progress order
  async sendArtworkRejected(to, from, orderId) {

    return new Promise(async (resolve, reject) => {
      const message = 'Order #' + orderId + ': Artwork was rejected';
      const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(to, 'DP_CHANGES_PROGRESS_ORDER');
      if (allowanceResponse && allowanceResponse.isUserAllowed) {
        const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
        const orderJob = orderData.orderJob || '';
        this.createNotification(orderId, message, to, orderJob, from);
      }
    });


    // this.db.object('/orders/' + orderId).valueChanges().subscribe((order: any) => {
    //   this.db.object('/users/' + order.designPartner.uid).valueChanges().subscribe((user: any) => {
    //     if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.DP_CHANGES_PROGRESS_ORDER)) {
    //       this.createNotification(orderId, 'Order #' + orderId + ': Artwork was rejected', to, order.job, from);
    //     }
    //   });
    // });

  }

  //When an in progress order is complete
  async sendArtworkComplete(to, from, orderId) {
    const message = 'Order #' + orderId + ': Artwork was accepted';
    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(to, 'DP_PROGRESS_ORDER_COMPLETE');
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
      const orderJob = orderData.orderJob || '';
      this.createNotification(orderId, message, to, orderJob, from);
    }




    // this.db.object('/orders/' + orderId).valueChanges().subscribe((order: any) => {
    //   this.db.object('/users/' + order.designPartner.uid).valueChanges().subscribe((user: any) => {
    //     if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.DP_PROGRESS_ORDER_COMPLETE)) {
    //       this.createNotification(orderId, 'Order #' + orderId + ': Artwork was accepted', to, order.job, from);
    //     }
    //   });
    // });


  }

  //SP-When your order ships
  async sendShippedOrder(order, to, orderId) {
    const message = 'Order #' + orderId + ': Order has been shipped';
    const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
    const orderJob = orderData.orderJob || '';

    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(to, 'SP_ORDER_SHIPPED');
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      this.createNotification(orderId, message, to, orderJob);
    }

    // this.db.object('/users/' + to).valueChanges().subscribe((user: any) => {
    //   if (user && user != null && user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_ORDER_SHIPPED)) {
    //     this.createNotification(orderId, 'Order #' + orderId + ': Order has been shipped', to, order.job);
    //   }
    // });

    const response: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(1, 'AD_ORDER_SHIPPED');
    if (response && response.allowedUsers && response.allowedUsers.length) {
      _.forEach(response.allowedUsers, (admin) => {
        this.createNotification(orderId, message, admin.user_id, orderJob);
      });
    }


    // this.db.list("/users", ref => ref.orderByChild("permissionLevel").equalTo("Admin")).snapshotChanges().subscribe((adminUsers:any) => {
    //   adminUsers = FirebaseKeyHandler.convertMultiple(adminUsers);
    //   _.forEach(adminUsers, (adminUser) => {
    //     //When a designer submits an order for approval
    //     this.db.object('/users/' + adminUser.$key).valueChanges().subscribe((user: any) => {
    //       if (user && user != null && user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_SHIPPED)) {
    //         this.createNotification(orderId, 'Order #' + orderId + ': Order has been shipped.', adminUser.$key, order.job);
    //       }
    //     });
    //   });
    // });

    const responseData: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(7, 'OM_ORDER_SHIPPED');
    if (responseData && responseData.allowedUsers && responseData.allowedUsers.length) {
      _.forEach(responseData.allowedUsers, (om) => {
        this.createNotification(orderId, message, om.user_id, orderJob);
      });
    }

    // Send Notification to CSD User on Order Shipped
    if (order.salesPartner && (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER)) {
      let sellerUId = order.salesPartner.uid;
      const csdUserRes: any = await this.commonAPIService.fnGetCSDUserFromSalesPartner(sellerUId);
      const csdUser = csdUserRes.csdUser;
      if (csdUser && csdUser.user_id && csdUser.permission_level !== 1) {
        const notifyUser: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(csdUser.user_id, 'AD_ORDER_SHIPPED');
        if (notifyUser && notifyUser.isUserAllowed) {
          this.createNotification(orderId, message, csdUser.user_id, orderJob);
        }
      }




      // this.db.object('/users/' + sellerUId).valueChanges().subscribe((seluser: any) => {
      //   if (seluser && seluser.csd) {
      //     let csdUser = seluser.csd;
      //     this.db.list('/users', ref => ref.orderByChild('name').equalTo(csdUser)).snapshotChanges().subscribe((csdusers:any) => {
      //       csdusers = FirebaseKeyHandler.convertMultiple(csdusers);
      //       _.forEach(csdusers, (csduser) => {
      //         if(csduser.permissionLevel != 'Admin') {
      //           this.db.object('/users/' + csduser.$key).valueChanges().subscribe((user: any) => {
      //             if (user && user != null && user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_SHIPPED)) {
      //               const message = 'Order #' + orderId + ': Order has been shipped.';
      //               this.createNotification(orderId, message, user.$key, order.job);
      //             }
      //           });
      //         }
      //       });
      //     });
      //   }
      // });

    }
  }

  //When your order status changes
  async sendStatusOrderChange(to, from, orderId, newStatus) {
    
    const response: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(to, 'SP_ORDER_STATUS_CHANGED');
    if (response && response.isUserAllowed) {
      const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
      const orderJob = orderData.orderJob || '';
      const message = 'Order #' + orderId + ': Status changed to ' + newStatus;
      this.createNotification(orderId, message, to, orderJob, from);
    }

    // this.db.object('/orders/' + orderId).valueChanges().subscribe((order: any) => {
    //   this.db.object('/users/' + to).valueChanges().subscribe((user: any) => {
    //     if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_ORDER_STATUS_CHANGED)) {
    //       this.createNotification(orderId, 'Order #' + orderId + ': Status changed to ' + newStatus, to, order.job, from);
    //     }
    //   });
    // });

  }

  async sendHiredDesignerNotification(order, orderId) {
    // Send Notification to Designer partner when user hired one
    if (order.selectedDesigner) {
      let designerUId = order.selectedDesigner.uid;

      const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(designerUId, 'DP_TAGGED');
      if (allowanceResponse && allowanceResponse.isUserAllowed) {
        const message = 'You have been hired';
        this.createNotification(orderId, message, designerUId, order.job);
      }

      // this.db.object('/users/' + designerUId).valueChanges().subscribe((designUser: any) => {
      //   if (designUser) {
      //     if (designUser.notificationsConfig && designUser.notificationsConfig.inApp && designUser.notificationsConfig.inApp.includes(this.NotificationConfig.DP_TAGGED)) {
      //       const message = 'You have been hired';
      //       this.createNotification(orderId, message, designerUId, order.job);
      //     }
      //   }
      // });
      
    }
  }

  async sendNewOrderDesigner(order, orderId) {
    const message = 'Needs designer';
    const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
    const orderJob = orderData.orderJob || '';

    const response: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(1, 'AD_NEED_DP');
    if (response && response.allowedUsers && response.allowedUsers.length) {
      _.forEach(response.allowedUsers, (admin) => {
        this.createNotification(orderId, message, admin.user_id, orderJob);
      });
    }

    //Send Admin Needs Designer notification to CSD User
    if (order.salesPartner && (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER)) {
      let sellerUId = order.salesPartner.uid;

      const csdResponse: any = await this.commonAPIService.fnGetCSDUserFromSalesPartner(sellerUId);
      const csdUser = csdResponse.csdUser;
      if (csdUser && csdUser.user_id && csdUser.permission_level !== 1) {
        const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(csdUser.user_id, 'AD_ORDER_CREATED');
        if (allowanceResponse && allowanceResponse.isUserAllowed) {
          this.createNotification(orderId, message, csdUser.user_id, orderJob);
        }
      }
    }
    /* 
    Card : https://trello.com/c/8hoGXdjW/662-remove-new-order-available-notification-for-design-partner
    Reason: Remove the New Order Available notification from the Design Partner notification panel
    Date : 28-06-2021
    */
    /* this.db.list("/users",ref => ref.orderByChild("permissionLevel").equalTo("Design Partner")).snapshotChanges().subscribe((users:any) => {
        users = FirebaseKeyHandler.convertMultiple(users);
      _.forEach(users, (user) => {
        if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.DP_ORDER_CREATE)) {
          let message = 'Order #' + orderId + ': New Order Available';
          this.createNotification(orderId, message, user.$key, order.job);
        }
      });
    });*/
  }

  //When a new order is created
  async sendNewOrderAdmin(order, orderId) {
    // console.log('auth ID',this.auth.getUID());
    const message = 'Order #' + orderId + ': Created by ' + order.salesPartner.name;
    const response: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(1, 'AD_ORDER_CREATED');
    if (response && response.allowedUsers && response.allowedUsers.length) {
      // console.log('allowed users',response.allowedUsers);
      response.allowedUsers.forEach((user) => {
        if(user.user_id != this.auth.getUID())
          this.createNotification(orderId, message, user.user_id, order.job, order.salesPartner.uid);
      });
    }

    const responseData: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(7, 'OM_ORDER_CREATED');
    if (responseData && responseData.allowedUsers && responseData.allowedUsers.length) {
      responseData.allowedUsers.forEach((user) => {
        if (user.user_id)
          this.createNotification(orderId, message, user.user_id, order.job, order.salesPartner.uid);
      });
    }


    // this.db.list("/users", ref => ref.orderByChild("permissionLevel").equalTo("Admin")).snapshotChanges().subscribe((users:any) => {
    //   users = FirebaseKeyHandler.convertMultiple(users);
    //   _.forEach(users, (user) => {
    //     if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_CREATED)) {
    //       let message = 'Order #' + orderId + ': Created by ' + order.salesPartner.name;
    //       this.createNotification(orderId, message, user.$key, order.job, order.salesPartner.uid);
    //     }
    //   });
    // });


    /*
    this.db.list("/permissions", {
      query: {
        orderByChild: "permissionLevel",
        equalTo: "Admin"
      }
    }).take(1).subscribe(permissions => {
      _.forEach(permissions, (permission) => {
        this.db.object('/users/' + permission.$key).subscribe((user:any) => {
          if(user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_CREATED)) {
            const message = 'Order #' + orderId + ': Created by ' + order.salesPartner.name;
            this.createNotification(orderId, message, permission.$key, order.salesPartner.uid);
          }
        });
      });
    });
    */


    // Send Notification to CSD User Order Create
    if (order.salesPartner && (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER)) {
      let sellerUId = order.salesPartner.uid;

      const csdResponse: any = await this.commonAPIService.fnGetCSDUserFromSalesPartner(sellerUId);
      const csdUser = csdResponse.csdUser;
      if (csdUser && csdUser.permission_level !== 1) {
        const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(csdUser.user_id, 'AD_ORDER_CREATED');
        if (allowanceResponse && allowanceResponse.isUserAllowed) {
          this.createNotification(orderId, message, csdUser.user_id, order.job, order.salesPartner.uid);
        }
      }


      // this.db.object('/users/' + sellerUId).valueChanges().subscribe((seluser: any) => {
      //   if (seluser && seluser.csd) {
      //     let csdUser = seluser.csd;
      //     this.db.list('/users', ref => ref.orderByChild('name').equalTo(csdUser)).snapshotChanges().subscribe((csdusers:any) => {
      //       csdusers = FirebaseKeyHandler.convertMultiple(csdusers);
      //       _.forEach(csdusers, (csduser) => {
      //         if(csduser.permissionLevel != 'Admin') {
      //           this.db.object('/users/' + csduser.$key).valueChanges().subscribe((user: any) => {
      //             if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_CREATED)) {
      //               const message = 'Order #' + orderId + ': Created by ' + order.salesPartner.name;
      //               this.createNotification(orderId, message, csduser.$key, order.job, order.salesPartner.uid);
      //             }
      //           });
      //         }
      //       });
      //     });
      //   }
      // });


    }
  }

  async sendSalesPartnerModifiedOrder(order, orderId, newStatus) {

    let usersToBeNotified = [];
    const message = 'Order #' + orderId + ': Status changed to ' + newStatus + ' by ' + order.salesPartner.name;

    if (newStatus == 'Artwork: Need Designer') {
      const response: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(1, 'AD_NEED_DP');
      if (response && response.allowedUsers && response.allowedUsers.length) {
        usersToBeNotified = response.allowedUsers;
      }
    } else if (newStatus != 'Artwork: Need Designer') {
      const response: any = await this.commonAPIService.fnGetUsersByPermissionLevel(1);
      if (response && response.allowedUsers && response.allowedUsers.length) {
        usersToBeNotified = response.allowedUsers;
      }
    }

    usersToBeNotified.forEach((user) => {
      this.createNotification(orderId, message, user.user_id, order.job, order.salesPartner.uid);
    });


    // this.db.list("/users", ref => ref.orderByChild("permissionLevel").equalTo("Admin")).snapshotChanges().subscribe((users:any) => {
    //     users = FirebaseKeyHandler.convertMultiple(users);
    //   _.forEach(users, (user) => {
    //     let message = 'Order #' + orderId + ': Status changed to ' + newStatus + ' by ' + order.salesPartner.name;
    //     if (newStatus == 'Artwork: Need Designer' && user && user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_NEED_DP)) {
    //       this.createNotification(orderId, message, user.$key, order.job, order.salesPartner.uid);
    //     } else if (newStatus != 'Artwork: Need Designer') {
    //       this.createNotification(orderId, message, user.$key, order.job, order.salesPartner.uid);
    //     }
    //   });
    // });


    /*
    this.db.list("/permissions", {
      query: {
        orderByChild: "permissionLevel",
        equalTo: "Admin"
      }
    }).take(1).subscribe(permissions => {
      _.forEach(permissions, (permission) => {
        const message = 'Order #' + orderId + ': Status changed to ' + newStatus + ' by ' + order.salesPartner.name;
        this.createNotification(orderId, message, permission.$key, order.salesPartner.uid);
      });
    });
    */

    // Send Notification to CSD User in Order Status Change
    if (order.salesPartner && (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER)) {
      let sellerUId = order.salesPartner.uid;
      const csdUserRes: any = await this.commonAPIService.fnGetCSDUserFromSalesPartner(sellerUId);
      const csdUser = csdUserRes.csdUser;
      if (csdUser && csdUser.user_id && csdUser.permission_level !== 1) {
        this.createNotification(orderId, message, csdUser.user_id, order.job, order.salesPartner.uid);
      }


      // let sellerUId = order.salesPartner.uid;
      // this.db.object('/users/' + sellerUId).valueChanges().subscribe((seluser: any) => {
      //   if (seluser && seluser.csd) {
      //     let csdUser = seluser.csd;
      //     this.db.list('/users', ref => ref.orderByChild('name').equalTo(csdUser)).snapshotChanges().subscribe((csdusers:any) => {
      //       csdusers = FirebaseKeyHandler.convertMultiple(csdusers);
      //       _.forEach(csdusers, (csduser) => {
      //         if(csduser.permissionLevel != 'Admin') {
      //           const message = 'Order #' + orderId + ': Status changed to ' + newStatus + ' by ' + order.salesPartner.name;
      //           this.createNotification(orderId, message, csduser.$key, order.job, order.salesPartner.uid);
      //         }
      //       });
      //     });
      //   }
      // });


    }


  }

  async sendDesignPartnerAssignedOrder(order, orderId) {
    const message = 'Order #' + orderId + ': Assigned to ' + order.designPartner.name;

    const response: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(1, 'AD_ORDER_TAKEN');
    if (response && response.allowedUsers && response.allowedUsers.length) {
      _.forEach(response.allowedUsers, (user) => {
        this.createNotification(orderId, message, user.user_id, order.job, order.designPartner.uid);
      });
    }


    // this.db.list("/users", ref => ref.orderByChild("permissionLevel").equalTo("Admin")).snapshotChanges().subscribe((adminUsers:any) => {
    //   adminUsers = FirebaseKeyHandler.convertMultiple(adminUsers);
    //   _.forEach(adminUsers, (adminUser) => {
    //     //When a designer takes an order
    //     this.db.object('/users/' + adminUser.$key).valueChanges().subscribe((user: any) => {
    //       if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_TAKEN)) {
    //         const message = 'Order #' + orderId + ': Assigned to ' + order.designPartner.name;
    //         this.createNotification(orderId, message, adminUser.$key, order.job, order.designPartner.uid);
    //       }
    //     });
    //   });
    // });

    const responseData: any = await this.commonAPIService.fnGetUsersForNotificationByPermissionLevel(7, 'OM_ORDER_TAKEN');
    if (responseData && responseData.allowedUsers && responseData.allowedUsers.length) {
      _.forEach(responseData.allowedUsers, (user) => {
        this.createNotification(orderId, message, user.user_id, order.job, order.designPartner.uid);
      });
    }

    const spNotificationConfig: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(order.salesPartner.uid, 'SP_ORDER_COMMENT');
    if (spNotificationConfig && spNotificationConfig.isUserAllowed) {
      this.createNotification(orderId, message, order.salesPartner.uid, order.job, order.designPartner.uid);
    }



    // this.db.object('/users/' + order.salesPartner.uid).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_ORDER_COMMENT)) {
    //     const message = 'Order #' + orderId + ': Assigned to ' + order.designPartner.name;
    //     this.createNotification(orderId, message, order.salesPartner.uid, order.job, order.designPartner.uid);
    //   }
    // });

    // Send Notification to CSD User in Design Partner Assiged on Order
    if (order.salesPartner && (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER)) {
      let sellerUId = order.salesPartner.uid;

      const csdUserRes: any = await this.commonAPIService.fnGetCSDUserFromSalesPartner(sellerUId);
      const csdUser = csdUserRes.csdUser;
      if (csdUser && csdUser.user_id && csdUser.permission_level !== 1) {
        const notifyUserRes: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(csdUser.user_id, 'AD_ORDER_TAKEN');
        if (notifyUserRes && notifyUserRes.isUserAllowed) {
          this.createNotification(orderId, message, csdUser.user_id, order.job, order.designPartner.uid);
        }
      }


      // this.db.object('/users/' + sellerUId).snapshotChanges().subscribe((seluser: any) => {
      //   seluser = FirebaseKeyHandler.convertMultiple(seluser);
      //   if (seluser && seluser.csd) {
      //     let csdUser = seluser.csd;
      //     this.db.list('/users', ref => ref.orderByChild('name').equalTo(csdUser)).snapshotChanges().subscribe((csdusers:any) => {
      //       csdusers = FirebaseKeyHandler.convertMultiple(csdusers);
      //       _.forEach(csdusers, (csduser) => {
      //         if (csduser.permissionLevel != 'Admin'){
      //           this.db.object('/users/' + csduser.$key).valueChanges().subscribe((user: any) => {
      //             if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_TAKEN)) {
      //               const message = 'Order #' + orderId + ': Assigned to ' + order.designPartner.name;
      //               this.createNotification(orderId, message, csduser.$key, order.job, order.designPartner.uid);
      //             }
      //           });
      //         }
      //       });
      //     });
      //   }
      // });


    }
  }

  async sendPendingPaymentNotification(order, orderId, newStatus) {
    const response: any = await this.commonAPIService.fnGetUsersByPermissionLevel(1);
    if (response && response.allowedUsers && response.allowedUsers.length) {
      const message = 'Order #' + orderId + ': Payment pending';
      response.allowedUsers.forEach((user) => {
        this.createNotification(orderId, message, user.user_id, order.salesPartner.uid);
      });
    }



    // this.db.list("/permissions", ref => ref.orderByChild("permissionLevel").equalTo("Admin")).snapshotChanges().subscribe((permissions:any) => {
    //   permissions = FirebaseKeyHandler.convertMultiple(permissions);
    //   _.forEach(permissions, (permission) => {
    //     const message = 'Order #' + orderId + ': Payment pending';
    //     this.createNotification(orderId, message, permission.$key, order.salesPartner.uid);
    //   });
    // });


  }

  async sendChatNotification(orderId, from) {
    const response: any =  await this.commonAPIService.fnGetUsersForChatNotification(orderId, from);

    if (response && response.usersToBeNotified && response.usersToBeNotified.length) {
      const notifMessage = 'Order #' + orderId + ': New comment';
      response.usersToBeNotified.forEach((user) => {
        this.createNotification(orderId, notifMessage, user, response.orderJob, from);
      });
    }


    // let mainObs = this.db.object('/orders/' + orderId).snapshotChanges().subscribe((order:any) => {
    //   order = FirebaseKeyHandler.covertSingle(order);
    //   const notifMessage = 'Order #' + orderId + ': New comment';
    //   if (from != order.salesPartner.uid) {

    //     //SP-When there is a comment on your order
    //     let obs = this.db.object('/users/' + order.salesPartner.uid).valueChanges().subscribe((user: any) => {
    //       if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_ORDER_COMMENT)) {
    //         this.createNotification(orderId, notifMessage, order.salesPartner.uid, order.job, from);
    //       }
    //       obs.unsubscribe();
    //     });
    //   }

    //   //DP-When there is a comment on your order
    //   if (order.designPartner && from != order.designPartner.uid) {
    //     let obs = this.db.object('/users/' + order.designPartner.uid).valueChanges().subscribe((user: any) => {
    //       if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.DP_ORDER_COMMENT)) {
    //         this.createNotification(orderId, notifMessage, order.designPartner.uid, order.job, from);
    //       }
    //       obs.unsubscribe();
    //     });
    //   }

    //   //PP-When there is a comment on your order
    //   if (order.productionPartner && from != order.productionPartner.uid) {
    //     let obs = this.db.object('/users/' + order.productionPartner.uid).valueChanges().subscribe((user: any) => {
    //       if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.PP_ORDER_COMMENT)) {
    //         this.createNotification(orderId, notifMessage, order.productionPartner.uid, order.job, from);
    //       }
    //       obs.unsubscribe();
    //     });
    //   }

    //   mainObs.unsubscribe();
    // })
  }

  //When Greek Licensing is approved
  async sendGreekLicensingApproved(order, orderId, from) {

    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(order.salesPartner.user_id, 'SP_GREEK_LICENSING_APPROVED');
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
      const orderJob = orderData.orderJob || '';
      const message = 'Order #' + orderId + ': Greek licensing approved';
      this.createNotification(orderId, message, order.salesPartner.user_id, orderJob, from);
    }

    // let ods = this.db.object('/users/' + order.salesPartner.uid).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_GREEK_LICENSING_APPROVED)) {
    //     const message = 'Order #' + orderId + ': Greek licensing approved';
    //     this.createNotification(orderId, message, order.salesPartner.uid, order.job, from);
    //   }
    //   ods.unsubscribe();
    // });

  }

  //When Greek Licnesing is rejected
  async sendGreekLicensingRejected(order, orderId, from) {

    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(order.salesPartner.user_id, 'SP_GREEK_LICENSING_REJECTED');
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
      const orderJob = orderData.orderJob || '';
      const message = 'Order #' + orderId + ': Greek licensing rejected';
      this.createNotification(orderId, message, order.salesPartner.user_id, orderJob, from);
    }


    // let ods = this.db.object('/users/' + order.salesPartner.uid).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_GREEK_LICENSING_REJECTED)) {
    //     const message = 'Order #' + orderId + ': Greek licensing rejected';
    //     this.createNotification(orderId, message, order.salesPartner.uid, order.job, from);
    //   }
    //   ods.unsubscribe();
    // });


  }

  /**
     * This is commented out to remove the order delivered notifications as requested
     *
//When commission is processed for your order
  //When your order delivers
  //When an order is delivered
  sendOrderDelivered(order, orderId, from, newStatus) {
    this.db.object('/users/' + order.salesPartner.uid).subscribe((user:any) => {
      if(user.notificationsConfig && user.notificationsConfig.inApp && (user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_COMMISION_PROCESSED ||
                                            user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_ORDER_DELIVERS)))) {
        const message = 'Order #' + orderId + ': Status modified to' + newStatus;
        this.createNotification(orderId, message, order.salesPartner.uid, from);
      }
    });

    this.db.list("/permissions", {
      query: {
        orderByChild: "permissionLevel",
        equalTo: "Admin"
      }
    }).subscribe(permissions => {
      _.forEach(permissions, (permission) => {
        this.db.object('/users/' + permission.$key).subscribe((user:any) => {
          if(user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_ORDER_DELIVERED)) {
            const message = 'Order #' + orderId + ': Status modified to' + newStatus;
            this.createNotification(orderId, message, order.salesPartner.uid, from);
          }
        });
      });
    });
  }
  */

  //When payment is pending
  async sendPaymentPending(order, orderId, from) {
    const response: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(order.salesPartner.uid, 'SP_PAYMENT_PENDING');
    if (response && response.isUserAllowed) {
      const message = 'Order #' + orderId + ': Payment pending';
      const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
      const orderJob = orderData.orderJob || '';
      this.createNotification(orderId, message, order.salesPartner.uid, orderJob, from);
    }


    // const message = 'Order #' + orderId + ': Payment pending';
    // this.db.object('/users/' + order.salesPartner.uid).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_PAYMENT_PENDING)) {
    //     this.createNotification(orderId, message, order.salesPartner.uid, order.job, from);
    //   }
    // });

  }

  //When payment is received
  async sendPaymentRecieved(order, orderId, from) {
    const response: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(order.salesPartner.uid, 'SP_PAYMENT_RECEIVED');
    if (response && response.isUserAllowed) {
      const message = 'Order #' + orderId + ': Payment received';
      const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
      const orderJob = orderData.orderJob || '';
      this.createNotification(orderId, message, order.salesPartner.uid, orderJob, from);
    }


    // const message = 'Order #' + orderId + ': Payment received';
    // this.db.object('/users/' + order.salesPartner.uid).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_PAYMENT_RECEIVED)) {
    //     this.createNotification(orderId, message, order.salesPartner.uid, order.job, from);
    //   }
    // });


    /**
     * This is commented out to remove payment received notification for admins
        this.db.list("/permissions", {
          query: {
            orderByChild: "permissionLevel",
            equalTo: "Admin"
          }
        }).take(1).subscribe(permissions => {
          _.forEach(permissions, (permission) => {
            this.db.object('/users/' + permission.$key).subscribe((user:any) => {
              if(user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_PAYMENT_RECEIVED)) {
                this.createNotification(orderId, message, permission.$key, from);
              }
            });
          });
        });
     */
  }

  async sendMentionNotification(order, message, to, from) {
    const notificationKeys = ['AD_TAGGED', 'DP_TAGGED', 'PP_TAGGED', 'SP_TAGGED', 'OM_TAGGED']; // check if user is allowed to notify for any of these notifications
    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(to, notificationKeys);
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      this.createNotification(order.order_id, message, to, order.job, from);
    }

    // let obs = this.db.object('/users/' + to).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && (user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_TAGGED) || 
    //   user.notificationsConfig.inApp.includes(this.NotificationConfig.AD_TAGGED) || 
    //   user.notificationsConfig.inApp.includes(this.NotificationConfig.DP_TAGGED) || 
    //   user.notificationsConfig.inApp.includes(this.NotificationConfig.PP_TAGGED))) {
    //     this.createNotification(order.$key, message, to, order.job, from);
    //   }
    //   obs.unsubscribe();
    // });

  }

  // when order is sent to production
  async sendSentToProduction(orderId, to, from) {
    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(to, 'SP_ORDER_PRODUCTION');
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      const orderData: any = await this.commonAPIService.fnGetOrderJob(orderId);
      const orderJob = orderData.orderJob || '';
      const message = 'Order #' + orderId + ": Sent to production";
      this.createNotification(orderId, message, to, orderJob, from);
    }


    // let obs1 =  this.db.object('/orders/' + orderId).valueChanges().subscribe((order:any) => {
    //   let obs2 = this.db.object('/users/' + to).valueChanges().subscribe((user: any) => {
    //     if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.SP_ORDER_PRODUCTION)) {
    //       const message = 'Order #' + orderId + ": Sent to production";
    //       this.createNotification(orderId, message, to, order.job, from);
    //     }
    //     obs2.unsubscribe();
    //   })
    //   obs1.unsubscribe();
    // })

  }

  async sendNewProductionOrderIsAvailable(order, from) {

    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(order.productionPartner.user_id, 'PP_ORDER_AVAILABLE');
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      const orderData: any = await this.commonAPIService.fnGetOrderJob(order.order_id);
      if (orderData) {
        const orderJob = orderData.orderJob || '';
        const message = 'Order #' + order.order_id + ': Order is available';
        this.createNotification(order.order_id, message, order.productionPartner.user_id, orderJob, from);
      }
    }

    // let obs = this.db.object('/users/' + order.productionPartner.uid).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.PP_ORDER_AVAILABLE)) {
    //     const message = 'Order #' + order.$key + ': Order is available';
    //     this.createNotification(order.$key, message, order.productionPartner.uid, order.job, from);
    //   }
    //   obs.unsubscribe();
    // });

  }

  async sendEmailToPPNewOrderEmails(order)  {
    await this.commonAPIService.fnSendToPPNewOrderEmails(order.order_id);

    // let data = { orderId: order.order_id };
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Target-URL' : environment.config.productionNewOrderEmailFunctionURL
    // });
    // this.http.post(environment.config.proxy_url,data,{headers:headers}).subscribe(res => {
    // },error => {
    // });
  }

  async sendEmailToSPuserForSendToProduction(order_id, email, SPid, key) {
    await this.commonAPIService.fnSendEmailToSPuserForSendToProduction(order_id, email, SPid, key);
  }

  async sendEmailToDPArtworkFileRequested(order_id, email, dpId, key) {
    await this.commonAPIService.fnSendEmailToDPArtworkFileRequested(order_id, email, dpId, key);
  }

  async sendInvoicePaidNotification(order, from) {
    const message = 'Order #' + order.order_id + ': Invoice has been paid';

    const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(order.productionPartner.uid, 'PP_ORDER_PAID');
    if (allowanceResponse && allowanceResponse.isUserAllowed) {
      this.createNotification(order.order_id, message, order.productionPartner.uid, order.job, from);
    }
    

    // this.db.object('/users/' + order.productionPartner.uid).valueChanges().subscribe((user: any) => {
    //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.PP_ORDER_PAID)) {
    //     const message = 'Order #' + order.$key + ': Invoice has been paid';
    //     this.createNotification(order.$key, message, order.productionPartner.uid, order.job, from);
    //   }
    // });

  }

  async sendPricingUpdate(order, from) {
    if (order && order.productionPartner && order.productionPartner.uid) {

      const message = 'Order #' + order.order_id + ': Pricing has been updated';
      const allowanceResponse: any = await this.commonAPIService.fnCheckIfUserIsAllowedToBeNotifiedInApp(order.productionPartner.uid, 'PP_PRICE_UPDATED');
      if (allowanceResponse && allowanceResponse.isUserAllowed) {
        this.createNotification(order.order_id, message, order.productionPartner.uid, order.job, from);
      }

      // this.db.object('/users/' + order.productionPartner.uid).valueChanges().subscribe((user: any) => {
      //   if (user.notificationsConfig && user.notificationsConfig.inApp && user.notificationsConfig.inApp.includes(this.NotificationConfig.PP_PRICE_UPDATED)) {
      //     const message = 'Order #' + order.$key + ': Pricing has been updated';
      //     this.createNotification(order.$key, message, order.productionPartner.uid, order.job, from);
      //   }
      // });

    }
  }

  getNotificationOptionsByPermissionLevel(permissionLevel) {
    return this.notificationOptions[permissionLevel];
  }

  NotificationConfig = {
    'DP_TAGGED': 33,
    'DP_CHANGES_PROGRESS_ORDER': 1,
    'DP_PROGRESS_ORDER_COMPLETE': 2,
    'DP_ORDER_COMMENT': 3,
    'SP_TAGGED': 4,
    'SP_ORDER_STATUS_CHANGED': 5,
    'SP_ORDER_TAKEN': 6,
    'SP_ORDER_SUBMIT_APPROVE': 7,
    'SP_GREEK_LICENSING_APPROVED': 8,
    'SP_GREEK_LICENSING_REJECTED': 9,
    'SP_ORDER_SHIPPED': 10,
    /**'SP_ORDER_DELIVERS' : 11, commented out to remove order delivered notification */
    /**'SP_COMMISION_PROCESSED' : 12, commented out to remove processed commission notification */
    'SP_PAYMENT_RECEIVED': 13,
    'SP_ORDER_COMMENT': 14,
    'AD_TAGGED': 15,
    'AD_ORDER_CREATED': 16,
    'AD_ORDER_TAKEN': 17,
    'AD_ORDER_SUBMIT_APPROVE': 18,
    /**'AD_PAYMENT_RECEIVED' : 19, commented out to remove payment received notification for admins */
    'AD_ORDER_SHIPPED': 20,
    /**'AD_ORDER_DELIVERED' : 21, commented out to remove order delivered notification */
    'PP_TAGGED': 22,
    'PP_ORDER_AVAILABLE': 23,
    'PP_ORDER_PAID': 24,
    /**'PP_ORDER_DELIVERED' : 25, commented out to remove order delivered notification */
    'PP_PRICE_UPDATED': 26,
    'PP_ORDER_COMMENT': 27,
    'SP_ORDER_PRODUCTION': 28,
    'DP_ORDER_CREATE': 29,
    'SP_PAYMENT_PENDING': 30,
    'AD_NEED_DP': 31,
    'ORDER_DELIVERED':32

  }

  /* 
    Card : https://trello.com/c/8hoGXdjW/662-remove-new-order-available-notification-for-design-partner
    Reason : Remove the Notification Option from the Design Partner profile
    Date : 28-06-2021
  */
  notificationOptions = {
    'Design Partner': [{ id: this.NotificationConfig.DP_TAGGED, value: 'When you´re tagged in a comment', email: true, inApp: true },
    { id: this.NotificationConfig.DP_CHANGES_PROGRESS_ORDER, value: 'When an order Under Review is Rejected', email: true, inApp: true },
    { id: this.NotificationConfig.DP_PROGRESS_ORDER_COMPLETE, value: 'When an order Under Review is Approved', email: true, inApp: true },
    { id: this.NotificationConfig.DP_ORDER_COMMENT, value: 'When there is a comment on your order', email: true, inApp: true },
   // { id: this.NotificationConfig.DP_ORDER_CREATE, value: 'When a new order is available', email: true, inApp: true }
    ],
    

    'Sales Partner': [{ id: this.NotificationConfig.SP_TAGGED, value: 'When you´re tagged in a comment', email: true, inApp: true },
    { id: this.NotificationConfig.SP_ORDER_STATUS_CHANGED, value: 'When your order status changes', email: true, inApp: true },
    { id: this.NotificationConfig.SP_ORDER_TAKEN, value: 'When a designer takes your order', email: true, inApp: true },
    { id: this.NotificationConfig.SP_ORDER_SUBMIT_APPROVE, value: 'When a designer submits your order for approval', email: true, inApp: true },
    { id: this.NotificationConfig.SP_GREEK_LICENSING_APPROVED, value: 'When Greek Licensing is approved', email: true, inApp: true },
    { id: this.NotificationConfig.SP_GREEK_LICENSING_REJECTED, value: 'When Greek Licnesing is rejected', email: true, inApp: true },
    { id: this.NotificationConfig.SP_ORDER_SHIPPED, value: 'When your order ships', email: true, inApp: true },
    /** {id : this.NotificationConfig.SP_ORDER_DELIVERS, value : 'When your order delivers', email: true, inApp: true}, commented out to remove order delivered  notification */
    /**{id : this.NotificationConfig.SP_COMMISION_PROCESSED, value : 'When commission is processed for your order', email: true, inApp: true}, commented out to remove processed commission notification */
    { id: this.NotificationConfig.SP_PAYMENT_RECEIVED, value: 'When payment is received', email: true, inApp: true },
    { id: this.NotificationConfig.SP_ORDER_COMMENT, value: 'When there is a comment on your order', email: true, inApp: true },
    { id: this.NotificationConfig.SP_ORDER_PRODUCTION, value: 'When your order is sent to production', email: true, inApp: true },
    { id: this.NotificationConfig.SP_PAYMENT_PENDING, value: 'When payment is pending', email: true, inApp: true },
    { id: this.NotificationConfig.ORDER_DELIVERED, value: 'When an order is delivered', email: true, inApp: true }
    ],
    'Admin': [{ id: this.NotificationConfig.AD_TAGGED, value: 'When you´re tagged in a comment', email: true, inApp: true },
    { id: this.NotificationConfig.AD_ORDER_CREATED, value: 'When a new order is created', email: true, inApp: true },
    { id: this.NotificationConfig.AD_ORDER_TAKEN, value: 'When a designer takes an order', email: true, inApp: true },
    { id: this.NotificationConfig.AD_ORDER_SUBMIT_APPROVE, value: 'When a designer submits an order for approval', email: true, inApp: true },
    /**{id : this.NotificationConfig.AD_PAYMENT_RECEIVED, value : 'When payment is received', email: true, inApp: true}, commented out to remove payment received notification for admins */
    { id: this.NotificationConfig.AD_ORDER_SHIPPED, value: 'When an order ships', email: true, inApp: true },
    { id: this.NotificationConfig.AD_NEED_DP, value: 'When a Sales Partner requests a Designer Partner', email: true, inApp: true },/**, commented out to remove order delivered notification,,
                              {id : this.NotificationConfig.AD_ORDER_DELIVERED, value : 'When an order is delivered', email: true, inApp: true}*/
                              { id: this.NotificationConfig.ORDER_DELIVERED, value: 'When an order is delivered', email: true, inApp: true }                          
    ],
    'Collegiate Program Coordinator': [{ id: this.NotificationConfig.AD_TAGGED, value: 'When you´re tagged in a comment', email: true, inApp: true },
    { id: this.NotificationConfig.AD_ORDER_CREATED, value: 'When a new order is created', email: true, inApp: true },
    { id: this.NotificationConfig.AD_ORDER_TAKEN, value: 'When a designer takes an order', email: true, inApp: true },
    { id: this.NotificationConfig.AD_ORDER_SUBMIT_APPROVE, value: 'When a designer submits an order for approval', email: true, inApp: true },
    /**{id : this.NotificationConfig.AD_PAYMENT_RECEIVED, value : 'When payment is received', email: true, inApp: true}, commented out to remove payment received notification for admins */
    { id: this.NotificationConfig.AD_ORDER_SHIPPED, value: 'When an order ships', email: true, inApp: true },/**, commented out to remove order delivered notification
    {id : this.NotificationConfig.AD_ORDER_DELIVERED, value : 'When an order is delivered', email: true, inApp: true}*/
    { id: this.NotificationConfig.ORDER_DELIVERED, value: 'When an order is delivered', email: true, inApp: true }
    ],
    'Production Partner': [{ id: this.NotificationConfig.PP_TAGGED, value: 'When you´re tagged in a comment', email: true, inApp: true },
    { id: this.NotificationConfig.PP_ORDER_COMMENT, value: 'When there is a comment on your order', email: true, inApp: true },
    { id: this.NotificationConfig.PP_ORDER_AVAILABLE, value: 'When a new order is available', email: true, inApp: true },
    { id: this.NotificationConfig.PP_ORDER_PAID, value: 'When an invoice has been paid', email: true, inApp: true },
    /** {id : this.NotificationConfig.PP_ORDER_DELIVERED, value : 'When an order is delivered', email: true, inApp: true}, commented out to remove order delivered notification */
    { id: this.NotificationConfig.PP_PRICE_UPDATED, value: 'When the pricing for an order is updated', email: true, inApp: true },
    ]

  };
}
