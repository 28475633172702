import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ImportProductService {

  constructor(
    private http:HttpClient
  ) { }

  getStyle() {
      return this.http.get("assets/data/styles.json");
  }

}
