import { Directive,ElementRef, Renderer2, OnInit } from '@angular/core';
import { EventsService } from "../shared-modules/vieworder/services/events.service";
import * as _ from "lodash";
declare const window: any;
@Directive({
  selector: '[fit-children]'
})
export class FitChildrenDirective implements OnInit{
  children: ElementRef[];
  constructor(private el: ElementRef, private renderer: Renderer2, private events:EventsService) {
    renderer.listen(window,"resize",(event)=>{
        
      this.resize();
    })
    events.subscribe("resize",()=>{
      this.resize();
    });
  }

  ngOnInit(){
    this.resize()
  }

  resize(){
    setTimeout(()=>{
      this.children = this.el.nativeElement.children;
      let width = this.el.nativeElement.clientWidth;
      let filteredElements = _.filter(this.children,(elem:any)=>{
        return elem.clientWidth > 0
      })
      let occupiedWidth = _.reduce(this.children,(accum,elem:any)=>{
        return accum + elem.clientWidth
      },0)
      let difference = width - occupiedWidth;
      let perElement = (difference) / filteredElements.length;
      _.forEach(filteredElements,(elem:any)=>{
        elem.style["width"]=`${elem.clientWidth + (perElement-25)}px`
      })
    },100)
  }

}
