import { Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ppTurnAroundList {
    [ppCompanyId: string]: any[];
}

declare const window: any;
declare const $: any;

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    supportedMIMETypes = ["image/png", "image/jpeg", "image/gif"];
    searchFor = '';

    private turnaroundSource = new BehaviorSubject<ppTurnAroundList>({});
    private estimatedCostEmitter = new BehaviorSubject<any>({});
    private orderSavedEmitter = new BehaviorSubject<any>(null);
    private processingStatusEmitter = new BehaviorSubject<any>(null);
    private turnaroundCalculationEventEmitter = new BehaviorSubject<any>(null);
    private loaderStatusEventEmitter = new BehaviorSubject<any>(null);
    private turnAroundListUpdatedEmitter = new BehaviorSubject<any>(null);
    private recountPriceUpdatedEmitter = new BehaviorSubject<any>(null);
    private totalCostUpdatedEmitter = new BehaviorSubject<any>(null);
    private dropdownEventEmitter = new BehaviorSubject<any>(null);
    private closeCostDialogueEventEmitter = new BehaviorSubject<any>(null);
    private estimationCallEventEmitter = new BehaviorSubject<any>(null);
    private saveArtworkStatusEventEmitter = new BehaviorSubject<any>(null);
    private selfCheckoutOrderSaveEventEmitter = new BehaviorSubject<any>(null);
    public isFinalCallCompleted: boolean = false;
    public isSavingAdminOrder: boolean = false;
    public isFinalCallCompletedInterval: any;
    private storageKey = 'navigationState';
    public data: any;

    setData(data: any) {
        sessionStorage.setItem('checkout-order', JSON.stringify(data));
    }
    
    getData() {
        const data = JSON.parse(sessionStorage.getItem('checkout-order'));
        return data;
    }

    currentMessage = this.turnaroundSource.asObservable();

    emitTurnaroundList(message: ppTurnAroundList) {
        this.turnaroundSource.next(message);
    }

    emitCostEstimation = this.estimatedCostEmitter.asObservable();

    emitEstimatedCost(estimatedCost: any, order: any) {
        this.estimatedCostEmitter.next({ estimatedCost: estimatedCost, order: order });
    }

    orderSaved = this.orderSavedEmitter.asObservable();

    emitOrderSaved(status) {
        this.orderSavedEmitter.next(status);
    }

    processingStatus = this.processingStatusEmitter.asObservable();

    emitProcessingStatus(status) {
        this.processingStatusEmitter.next(status);
    }

    turnaroundCalculationEvent = this.turnaroundCalculationEventEmitter.asObservable();

    emitTurnaroundCalculation(turnaroundEvent) {
        this.turnaroundCalculationEventEmitter.next(turnaroundEvent);
    }

    saveArtworkStatus = this.saveArtworkStatusEventEmitter.asObservable();

    emitSaveArtworkStatus(status) {
        this.saveArtworkStatusEventEmitter.next(status);
    }

    updateLoaderStatus = this.loaderStatusEventEmitter.asObservable();

    emitLoaderStatusEvent(status) {
        this.loaderStatusEventEmitter.next(status);
    }

    updatePpTurnaroundList = this.turnAroundListUpdatedEmitter.asObservable();

    emitUpdateTurnaroundListEvent(status) {
        this.turnAroundListUpdatedEmitter.next(status);
    }

    updateRecountPrice = this.recountPriceUpdatedEmitter.asObservable();

    emitUpdateRecountPriceEvent(status) {
        this.recountPriceUpdatedEmitter.next(status);
    }

    updateTotalCostEvent = this.totalCostUpdatedEmitter.asObservable();

    emitUpdateTotalEvent(status) {
        this.totalCostUpdatedEmitter.next(status);
    }

    dropdownEvent = this.dropdownEventEmitter.asObservable();

    emitDropdownEvent(status) {
        this.dropdownEventEmitter.next(status);
    }

    closeCostDialogueEvent = this.closeCostDialogueEventEmitter.asObservable();

    emitCloseCostDialogueEvent(status) {
        this.closeCostDialogueEventEmitter.next(status);
    }

    estimationCallEvent = this.estimationCallEventEmitter.asObservable();

    emitEstimationCallEvent(status) {
        this.estimationCallEventEmitter.next(status);
    }

    // Store navigation state in localStorage
    async setNavigationState(state: any) {
        try {
        const stateString = JSON.stringify(state);
        localStorage.setItem(this.storageKey, stateString);
        } catch (e) {
        console.error('Error saving to localStorage', e);
        }
    }

    // Retrieve navigation state from localStorage
    getNavigationState() {
        try {
        const stateString = localStorage.getItem(this.storageKey);
        return stateString ? JSON.parse(stateString) : null;
        } catch (e) {
        console.error('Error retrieving from localStorage', e);
        return null;
        }
    }

    // Clear navigation state from localStorage
    clearNavigationState() {
        localStorage.removeItem(this.storageKey);
    }

    // emit Self-checkout order save event
    selfCheckoutOrderSaveEvent = this.selfCheckoutOrderSaveEventEmitter.asObservable();
    emitSelfCheckoutOrderSaveEvent(status) {
        console.log('Self checkout order service event');
        
        this.selfCheckoutOrderSaveEventEmitter.next(status);
    }
    constructor() { }

    showHideDropdown(dropDown) {
        setTimeout(() => {
            dropDown.show = true;
            const closeOpenedDropdown = (event) => {
                if (dropDown) {
                    dropDown.show = false;
                }
                document.removeEventListener('click', closeOpenedDropdown);
            };
            document.addEventListener('click', closeOpenedDropdown);
        }, 180);
    }

    decideDeviceType() {
        if (window.innerWidth <= 767) {
            return 'mobile';
        } else if (window.innerWidth > 767 && window.innerWidth <= 1930) {
            return 'tabletOrLaptop';
        } else {
            return 'desktop';
        }
    }

    getImageBlob(imageUrl) {
        try {
            return new Promise(async (resolve, reject) => {
                await fetch(imageUrl)
                    .then(function (response) {
                        // console.log('in fetch', response)
                        return response.blob()
                    })
                    .then(function (blob) {
                        // here the image is a blob
                        // console.log('in fetch 2', blob)
                        resolve(blob)
                    })
                    .catch(error => {
                        // throw(error);
                        console.log(error.message)
                        reject(error.message)
                    });
            })

        } catch (e) {
            console.log(e.message)

        }
    }

    async convertTheHeic(blob) {
        return new Promise(async (resolve, reject) => {
            this.heic2any({ 'blob': blob, 'toType': "image/jpeg" })
                .then(function (resultBlob) {
                    resolve(resultBlob)
                    // $this.saveFile(resultBlob);
                })
                .catch(function (x) {
                    console.log(x.message)
                })
        })
    }


    // async uploadAttachments(event){
    //     let files = event.srcElement.files
    //     let imageExt = ['.jpeg', '.jpg', '.png', '.gif'];
    //     let $this = this
    //     // let blob = files[0]
    //     let imageUrl = 'https://firebasestorage.googleapis.com/v0/b/upmerch-34a26.appspot.com/o/images%2Fnull-668-undefined.heic?alt=media&token=b75d018d-9ebe-49c2-862f-1b09e0b36b5f';
    //     let blob = await this.getImageBlob(imageUrl)
    //     console.log(blob)
    //     console.log('Inside uploadAttachments() ', blob)
    //     this.heic2any({            'blob': blob,'toType': "image/jpg"}        )
    //     .then(function (resultBlob) {
    //         console.log(resultBlob)
    //         // $this.saveFile(resultBlob, blob.name + ".jpg");
    //     })
    //     .catch(function(x){
    //         console.log(x.message)
    //     })
    // }
    // saveFile(blob, imageName){
    //     let imgUrl = window.URL.createObjectURL(blob)
    //     $('#img').attr('src', imgUrl);
    // }

    // 


    async heic2any({ blob, toType = "image/jpeg", quality = 0.92, gifInterval = 0.4, multiple = undefined, }:
        { blob: Blob; multiple?: true; toType?: string; quality?: number; gifInterval?: number; }
    ): Promise<Blob | Blob[]> {
        return new Promise(
            (resolve, reject) => {

                if (!(blob instanceof Blob)) {
                    // utils.error(`ERR_USER library only accepts BLOBs as input`);
                    console.log('It is not instance of blob')
                }
                if (typeof multiple !== "boolean") {
                    // utils.error(
                    //     `ERR_USER "multiple" parameter should be of type "boolean"`
                    // );
                }
                if (typeof quality !== "number") {
                    // utils.error(
                    //     `ERR_USER "quality" parameter should be of type "number"`
                    // );
                }
                if (typeof gifInterval !== "number") {
                    // utils.error(
                    //     `ERR_USER "gifInterval" parameter should be of type "number"`
                    // );
                }

                const reader = new FileReader();
                reader.onload = (e) => {
                    let gifWidth = 0;
                    let gifHeight = 0;
                    const buffer = (e as any).target.result;
                    const otherImageType = this.otherImageType(buffer);
                    // console.log('twooooo', otherImageType)
                    if (otherImageType) {
                        return reject(
                            // utils.error(
                            //     `ERR_USER Image is already browser readable: ${otherImageType}`
                            // )
                            ['error']
                        );
                    }
                    this.decodeBuffer(buffer)
                        .then((imageDataArr) => {
                            // console.log('Thirddddd')
                            gifWidth = imageDataArr[0].width;
                            gifHeight = imageDataArr[0].height;
                            return Promise.all(
                                imageDataArr.map((imageData) =>
                                    this.imageDataToBlob({
                                        imageData,
                                        toType,
                                        quality,
                                    })
                                )
                            );
                        })
                        .then((blobs) => {
                            if (toType === "image/gif") {
                                return Promise.all(
                                    blobs.map((blob) => this.blobToDataURL(blob))
                                );
                            } else if (multiple) {
                                resolve(blobs);
                                return [""];
                            } else {
                                resolve(blobs[0]);
                                return [""];
                            }
                        })
                        .then((dataURIs) => {
                            if (toType === "image/gif" && dataURIs) {
                                // return utils.imagesToGif({
                                //     images: dataURIs,
                                //     interval: gifInterval,
                                //     gifWidth,
                                //     gifHeight,
                                // });
                                return '';
                            }
                            return "";
                        })
                        .then((resultingGif) => {
                            if (toType === "image/gif" && resultingGif) {
                                const blob = this.dataURItoBlob(resultingGif);
                                if (typeof blob === "string") {
                                    // reject(this.utils.error(blob));
                                    reject('error')
                                } else {
                                    resolve(blob);
                                }
                            }
                        })
                        .catch((e) => {
                            // reject(this.utils.error(e));
                            reject('error');
                        });
                };
                reader.readAsArrayBuffer(blob);
            }
        );
    }

    otherImageType(buffer: ArrayBuffer) {
        // console.log('otherImageType() starts ')
        const arr = new Uint8Array(buffer).subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
            header = header + arr[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return false;
        }
        // console.log('otherImageType() ends ')
    }

    imageDataToBlob({
        imageData,
        toType = "image/jpeg",
        quality = 0.92,
    }: {
        imageData: ImageData;
        toType?: string;
        quality?: number;
    }): Promise<Blob> {
        // normalize quality
        if (quality > 1 || quality < 0) {
            quality = 0.92;
        }
        // normalize MIME type
        if (this.supportedMIMETypes.indexOf(toType) === -1) {
            toType = "image/png";
        }
        toType = 'jpeg';
        console.log(toType)
        return new Promise((resolve, reject) => {
            let canvas: HTMLCanvasElement | null = null;

            try {
                canvas = document.createElement("canvas");
            } catch (e) { }

            if (!canvas) {
                return reject(
                    "ERR_CANVAS Error on converting imagedata to blob: Could not create canvas element"
                );
            }

            canvas.width = imageData.width;
            canvas.height = imageData.height;
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                return reject(
                    "ERR_CANVAS Error on converting imagedata to blob: Could not get canvas context"
                );
            }
            ctx.putImageData(imageData, 0, 0);
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        return reject(
                            "ERR_CANVAS Error on converting imagedata to blob: Could not get blob from canvas"
                        );
                    }
                    return resolve(blob);
                },
                toType,
                quality
            );
        });
    }

    decodeBuffer(buffer: ArrayBuffer): Promise<ImageData[]> {
        // console.log('decodeBuffer() starts ', buffer)
        return new Promise((resolve, reject) => {
            try {
                const id = (Math.random() * new Date().getTime()).toString();
                const message = { id, buffer };

                (window.__heic2any__worker as Worker).postMessage(message);

                (window.__heic2any__worker as Worker).addEventListener(
                    "message",
                    (message) => {
                        if (message.data.id === id) {
                            if (message.data.error) {
                                console.log('Error ', message.data.error)
                                return reject(message.data.error);
                            }
                            return resolve(message.data.imageDataArr);
                        }
                    }
                );
            }
            catch (e) { console.log('error in decodeBuffer', e.message) }
        });
    }

    blobToDataURL(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onerror = function () {
                reject("ERR_DOM Error on converting blob to data URL");
            };
            reader.onload = (e) => {
                resolve((reader as any).result);
            };
            reader.readAsDataURL(blob);
        });
    }

    dataURItoBlob(dataURI: string): Blob | string {
        try {
            var byteString = atob(dataURI.split(",")[1]);
            var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var blob = new Blob([ab], { type: mimeString });
            return blob;
        } catch (e) {
            return "ERR_DOM Error on converting data URI to blob " + e &&
                e.toString
                ? e.toString()
                : e;
        }
    }

    replaceUnderscoreAndCapitalize(input: string): string {
        // Replace underscores and dashes with spaces
        let stringWithSpaces = input.replace(/[_-]/g, ' ');

        // Capitalize each word
        stringWithSpaces = stringWithSpaces.replace(/\b\w/g, (char) => char.toUpperCase());

        return stringWithSpaces;
    }

    replaceUnderscoreAndLower(input: string): string {
        // Replace underscores, dashes, and spaces with dashes
        let stringWithDashes = input.replace(/[_\s-]/g, '-');

        // Remove special characters
        stringWithDashes = stringWithDashes.replace(/[^\w-]/g, '');

        // Capitalize each word
        stringWithDashes = stringWithDashes.toLowerCase();

        return stringWithDashes;
    }

    scrollToTop(): void {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: smooth scrolling animation
          });
    }

}
