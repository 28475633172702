import { Component, OnInit, HostListener } from '@angular/core';
import { ImageModalService } from 'app/utils/services/image-modal.service';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {
  isOpen:boolean = false;
  imageUrl = "";

  constructor(
    private imageservice:ImageModalService
  ) { }

  ngOnInit() {
     this.imageservice.subscribe.subscribe((imageUrl)=> {
        if(imageUrl != "") {
          this.imageUrl = imageUrl;
          this.isOpen = true;
        } else {
          this.isOpen = false;
        }
     })
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    
     this.closemodal();
  }
  
  closemodal() {
    this.isOpen = false;
  }

}
