import { Injectable } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
// import {parseString } from 'xml2js';
import * as _ from "lodash";
import global from 'environments/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const ssHost = "https://api.ssactivewear.com"; // s&s api url
let ssAuth = "Basic NDg1NTQ6OGRmYzUwMWQtZGIyNC00NzEzLWJhMDktZGFlN2EwNWI1NGZk";
import { environment } from 'environments/environment';


declare const window: any;

@Injectable()
export class SnsService {

  constructor( private http: HttpClient, private authService:AuthService) {
    if(!window.subscriptions){
      window.subscriptions = []
    }

  }

  public getStyle(style, callback){
    /*
      callback for when a user selects a style. Fetches up to date
      details from the S&S api.
    */
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append("Authorization",ssAuth);
      
    headers.append("Target-URL",`${ssHost}/v2/products/?style=${encodeURIComponent(style.brandName)}+${style.uniqueStyleName}`)
    this.http.get(environment.config.proxy_url,{
      headers: headers
    })
      .subscribe(
        (data:Array<any>) => {
           console.log(data);
          let colorOpts= _.uniqBy(data,'colorName');
          let selectedShirt = data;
          _.forEach(selectedShirt,(size)=>{
            console.log(size);
            size.adminPrice = size.customerPrice;
            size.customerPrice = size.customerPrice;
          })
          callback(selectedShirt,colorOpts)
        },
        err => {
          console.error(err);
          callback();
        }
      );
  }

  public getAllStyleList(callback) {
  
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization"," Basic NDg1NTQgOjhkZmM1MDFkLWRiMjQtNDcxMy1iYTA5LWRhZTdhMDViNTRmZA==");
      
    headers.append("Target-URL",`${ssHost}/v2/styles/`)
    this.http.get(environment.config.proxy_url,{
      headers: headers
    })
      .subscribe(
        (data:Array<any>) => {
          let colorOpts= _.uniqBy(data,'colorName');
          let selectedShirt = data;
          _.forEach(selectedShirt,(size)=>{
            size.adminPrice = size.customerPrice;
            size.customerPrice = size.customerPrice;
          })
          callback(selectedShirt,colorOpts)
        },
        err => {
          console.error(err);

          callback();
        }
      );

  }

  public getAllCategorisList(callback) {
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization"," Basic NDg1NTQgOjhkZmM1MDFkLWRiMjQtNDcxMy1iYTA5LWRhZTdhMDViNTRmZA==");
      
    headers.append("Target-URL",`${ssHost}/v2/categories/`)
    this.http.get(environment.config.proxy_url,{
      headers: headers
    })
      .subscribe(
        (data:Response) => {

            callback(data.json())
        },
        err => {
          console.error(err);

          callback();
        }
      );
  }

  public getProductFromPromoStandard (styleId){
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'text/xml');
    headers.append('Accept', 'text/xml');
       // Website you wish to allow to connect
       headers.append('Access-Control-Allow-Origin', 'http://localhost:4200/');

       // Request methods you wish to allow
       headers.append('SOAPAction', 'getProduct');
   
       // Request headers you wish to allow
      //  headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
   
       // Set to true if you need the website to include cookies in the requests sent
       // to the API (e.g. in case you use sessions)
      //  headers.append('Access-Control-Allow-Credentials', "true");
    headers.append("Target-URL",'https://promostandards.ssactivewear.com/productdata/v2/productdataservicev2.svc')
    let body =  `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"> 
    <s:Header />
    <s:Body xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
      <GetProductRequest xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/">
        <wsVersion xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/SharedObjects/">2.0.0</wsVersion>
        <id xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/SharedObjects/">48554</id>
        <password xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/SharedObjects/">8dfc501d-db24-4713-ba09-dae7a05b54fd</password>
        <productId xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/SharedObjects/">5000</productId>
      </GetProductRequest>
    </s:Body>
  </s:Envelope>`;
 
    // return this.http.post(environment.config.proxy_url,body , { headers: headers, responseType: ResponseContentType.Text }).subscribe(
    //   res => {
    //     console.log(res);
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );

    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', 'https://promostandards.ssactivewear.com/productdata/v2/productdataservicev2.svc', true);
    // const input_element = <HTMLInputElement> document.getElementById('choosenNumber');

    // console.log('chVal : ' + input_element.value);
    // const choosenNumberValue = input_element.value;

    // The following variable contains the xml SOAP request.
    const sr = body;
       

    xmlhttp.onreadystatechange =  () => {
        if (xmlhttp.readyState == 4) {
            if (xmlhttp.status == 200) {
                const xml = xmlhttp.responseXML;
                // Here I'm getting the value contained by the <return> node.
                const response_number = parseInt(xml.getElementsByTagName('return')[0].childNodes[0].nodeValue);
                // Print result square number.
                console.log(response_number);
            }
        }
    }
    // Send the POST request.
    xmlhttp.setRequestHeader('Content-Type', 'text/xml');
    xmlhttp.responseType = 'document';
    xmlhttp.send(sr);
  } 

  async getProduct(styleId) {
    let body:String =  `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Header />
    <s:Body xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
      <GetProductRequest xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/">
        <wsVersion xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/SharedObjects/">2.0.0</wsVersion>
        <id xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/SharedObjects/">48554</id>
        <password xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/SharedObjects/">8dfc501d-db24-4713-ba09-dae7a05b54fd</password>
        <productId xmlns="http://www.promostandards.org/WSDL/ProductDataService/2.0.0/SharedObjects/">${styleId}</productId>
      </GetProductRequest>
    </s:Body>
  </s:Envelope>`;
    let procutCMR = await this.XMLRequest("https://promostandards.ssactivewear.com/productdata/v2/productdataservicev2.svc",body,'getProduct');
    let productJson = this.parseXml(procutCMR);
    console.log(productJson,"product json");
    let product = productJson['s:Envelope']['s:Body'][0]["GetProductResponse"][0]['Product'][0];
    let productPart = product.ProductPartArray[0]["ProductPart"];
    console.log(productPart[0],productPart[0]["ColorArray"][0]["Color"][0]["colorName"][0]);
    let productpartArray = _(productPart)
    .groupBy(part =>( part["ColorArray"][0]["Color"][0]["colorName"][0] || "")).value();
     product.ProductPartGroups = productpartArray;
     return product;
  }

  async getProductMedial(styleName) {
    let body:String = `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Header />
    <s:Body xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
      <GetMediaContentRequest xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.promostandards.org/WSDL/MediaService/1.0.0/">
        <wsVersion xmlns="http://www.promostandards.org/WSDL/MediaService/1.0.0/SharedObjects/">1.0.0</wsVersion>
        <id xmlns="http://www.promostandards.org/WSDL/MediaService/1.0.0/SharedObjects/">48554</id>
        <password xmlns="http://www.promostandards.org/WSDL/MediaService/1.0.0/SharedObjects/">8dfc501d-db24-4713-ba09-dae7a05b54fd</password>
        <productId xmlns="http://www.promostandards.org/WSDL/MediaService/1.0.0/SharedObjects/">${styleName}</productId>
        <mediaType xmlns="http://www.promostandards.org/WSDL/MediaService/1.0.0/SharedObjects/">Image</mediaType>
      </GetMediaContentRequest>
    </s:Body>
  </s:Envelope>`;
    let procutMedia = await this.XMLRequest("https://promostandards.ssactivewear.com/mediacontent/v1/mediacontentservice.svc",body,'getMediaContent');
    let mediaJson = this.parseXml(procutMedia);
    let media = mediaJson['s:Envelope']['s:Body'][0]["GetMediaContentResponse"][0]['MediaContentArray'][0]["MediaContent"];
    // console.log(productPart[0],productPart[0]["ColorArray"][0]["Color"][0]["colorName"][0]);
    let productmedia = _(media).groupBy(media =>( media["partId"][0]["_"] || "primary")).value();
    // console.log(productpartArray);
    return productmedia;
  }

  async getProductPrice(styleName) {
    let  body:String = `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Header />
    <s:Body xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
      <GetConfigurationAndPricingRequest xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/">
        <wsVersion xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">1.0.0</wsVersion>
        <id xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">48554</id>
        <password xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">8dfc501d-db24-4713-ba09-dae7a05b54fd</password>
        <productId xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">${styleName}</productId>
        <currency xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">USD</currency>
        <fobId xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">IL</fobId>
        <priceType xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">Customer</priceType>
        <localizationCountry xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">US</localizationCountry>
        <localizationLanguage xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">en</localizationLanguage>
        <configurationType xmlns="http://www.promostandards.org/WSDL/PricingAndConfiguration/1.0.0/SharedObjects/">Blank</configurationType>
      </GetConfigurationAndPricingRequest>
    </s:Body>
  </s:Envelope>`;
    let procutPrice = await this.XMLRequest("https://promostandards.ssactivewear.com/PricingAndConfiguration/v1/PricingAndConfigurationService.svc",body,'getConfigurationAndPricing');
    let priceJson = this.parseXml(procutPrice);
    console.log(priceJson);
    let price = priceJson['s:Envelope']['s:Body'][0]["GetConfigurationAndPricingResponse"][0]['Configuration'][0]["PartArray"][0]["Part"];
    let productPrice = _(price).groupBy(media =>( media["partId"][0]["_"] || "primary")).value();
    return productPrice;
  }

  async getProductInventory(styleName) { 
    let body:String = `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ns="http://www.promostandards.org/WSDL/InventoryService/1.0.0/">
    <soapenv:Header/>
    <soapenv:Body>
        <ns:Request>
            <ns:wsVersion>1.2.1</ns:wsVersion>
            <ns:id>48554</ns:id>
            <ns:password>8dfc501d-db24-4713-ba09-dae7a05b54fd</ns:password>
            <ns:productID>${styleName}</ns:productID>
            <ns:productIDtype>Supplier</ns:productIDtype>
        </ns:Request>
    </soapenv:Body>
    </soapenv:Envelope>`; 
    let procutInventory:any = await this.XMLRequest("https://promostandards.ssactivewear.com/Inventory/v1/InventoryService.svc",body,'getInventoryLevels');
    let inventoryJson = this.parseXml(procutInventory.text());
    console.log(inventoryJson);
    let inventory = inventoryJson['s:Envelope']['s:Body'][0]["Reply"][0]['ProductVariationInventoryArray'][0]["ProductVariationInventory"];
    let productInventory = _(inventory).groupBy(media =>( media["partID"][0] || "primary")).value();
    return productInventory;
  }
  
 parseXml(xmlStr) {
    var result;
    // parseString(xmlStr, (e, r) => {result = r});
    return result;
 }

 async XMLRequest(URL,body,action) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'text/xml');
    headers.append('Target-URL',URL);
    headers.append('SOAPAction',action);
    headers.append("Authorization","Basic NDg1NTQ6OGRmYzUwMWQtZGIyNC00NzEzLWJhMDktZGFlN2EwNWI1NGZk");

    return await this.http.post('https://proxy.upmerch.com/',body,{headers:headers,responseType:'text'}).toPromise();
 }



}
