import { Injectable } from '@angular/core';

import { AuthService } from "../../../services/auth.service";
import * as _ from "lodash";
import { FormArray, FormGroup } from '@angular/forms';
import { FirebaseKeyHandler } from 'app/utils/models/firebase_key_handler';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { CommonAPIService } from '../../../../utils/services/common-api.service';
import { CommonService } from 'app/utils/services/common.service';
import { OrderService } from 'app/modules/sales/order.service';
import { environment } from 'environments/environment';
import { imagesChanged } from '@milancrest274/price-calculations';
@Injectable({
    providedIn: 'root'
})

export class PricingService {
    DEFAULT_MARKUP: number = environment.config.default_markup;
    markup: number = this.DEFAULT_MARKUP;
    commission: number;
    salescommission: number;
    isAdmin: boolean = false;
    isCSD: boolean = false;
    forceTransform: boolean = false;
    priceScreenList;
    priceDirectList;
    priceEmbList;
    screenItems;
    dtgItems;
    vinylItems;
    vinylMethodId = 4;
    embItems = [];
    finishingItems;
    productionPartner;
    user;
    garmentCost: number = 0.0;
    printCost: number = 0.0;
    greekLicensing: number = 0.0;
    turnAroundCost: number = 0.0;
    shipCost: number = 0.0;
    isImageChanged = true;
    garmentOrderDetail: any;
    totalInfluencerValue: BehaviorSubject<any> = new BehaviorSubject(null);
    totalDonationValue: BehaviorSubject<any> = new BehaviorSubject(null);
    orderDetail: BehaviorSubject<any> = new BehaviorSubject(null);
    convertScreenPrintToDTG: BehaviorSubject<any> = new BehaviorSubject(null);
    holdCurrentLineItem: BehaviorSubject<any> = new BehaviorSubject(null);
    calculateRecountCharge: BehaviorSubject<any> = new BehaviorSubject(null);
    displayPricingFunctionFlag: BehaviorSubject<any> = new BehaviorSubject(null);
    assignRecentlyUpdatedLineItems: BehaviorSubject<any> = new BehaviorSubject(null);
    calculateLatestLineItems: BehaviorSubject<any> = new BehaviorSubject(null);
    calculateOnPaymentTypeChange: BehaviorSubject<any> = new BehaviorSubject(null);
    screenItemLoad = false;
    DtgItemLoad = false;
    EmbroideryItemLoad = false;
    finishingItemLoad = false;
    initialProductionPartnerId: number = 0;
    fleeceAndHoodieCategories = [
        'Crewneck',
        'Full Zip Hoodie',
        'Hoodie',
        'Performance Full Zip Hoodie',
        'Performance Hoodie',
        'Performance Pullover',
        'Performance Quarter Zip',
        'Quarter Zip'
    ]

    polyesterCategories = [
        'Jersey',
        'Performance Full Zip Hoodie',
        'Performance Hoodie',
        'Performance Long Sleeve',
        'Performance Polo',
        'Performance Pullover',
        'Performance Quarter Zip',
        'Performance Tank Top',
        'Performance V-Neck'

    ]

    screenItemOptions = [
        { key: 'Screens', label: 'Screens' },
        { key: 'Fleece / Hoodie', label: 'Fleece / Hoodie' },
        { key: 'Custom Names', label: 'Custom Names' },
        { key: 'Custom Numbers', label: 'Custom Numbers' },
        { key: 'Custom Names & Numbers', label: 'Custom Names & Numbers' },
        { key: 'Pocket Print', label: 'Pocket Print' },
        { key: 'Polyester', label: 'Polyester' }

    ];

    embItemOptions = [
        { key: 'Digitizing', label: 'Digitizing' },
        { key: 'Thread Color Change', label: 'Thread Color Change' },
        { key: 'Puff', label: 'Puff' },
        { key: 'Stitches per Square Inch', label: 'Stitches per Square Inch' },
    ]

    dtgItemOptions = [
        { key: 'Set-up (Under 12 Pieces)', label: 'Set-up (Under 12 Pieces)' },
        { key: 'Fleece / Hoodie', label: 'Fleece / Hoodie' }
    ]

    lintedItemOptions = [
        'Ink Change',
        'Oversize Printing',
        'Jumbo Printing',
        'Jumbo XL Printing',
        'Fashion Soft Plastisol',
        'Discharge / Waterbase',
        'Metallic / Shimmer',
        'Foil',
        'High Density Gel, Puff, Suede, Glow, Crystalina',
        'CYMK Process',
        'Simulated Process',
        'Mesh',
        'Nylon',
        'Tag Print',
        'Over Seam / Collar / Zipper / Off Bottom',
        'Transfer Application',
        'Pre-Production Sample',
        'Special Location Print',
        'Tear Away Tag Removal',
        'Sewn Tag Removal',
        'Unpack Individual',
        'Screens',
        'Fleece / Hoodie',
        'Customer Names',
        'Custom Numbers',
        'Custom Names & Numbers',
        'Pocket Print',
        'Polyester',
        'Set-up (Under 12 Pieces)',
        'Fleece / Hoodie',
        'Individual Fulfillment',
        'Poly Bagging & Folding – Short Sleeve',
        'Poly Bagging & Folding – Long Sleeve',
        'Folding – Short Sleeve',
        'Folding – Long Sleeve',
        'Hang Tag',
        'Poly Bagging, Folding & Customer Name/Size Sticker – Short Sleeve',
        'Poly Bagging, Folding & Customer Name/Size Sticker – Long Sleeve',
        'Size Sticker',
        'Greek Licensing Sticker',
        "Puff",
        "Thread Color Change"
    ]

    shortSleeve = [
        'Short Sleeve',
        'Short Sleeves',
        'Tank Tops'
    ]

    notShortSleeve = [
        'Long Sleeves',
        'Fleece',
        'Sweatshirts',
        'Hooded / Hoods',
        'Headwear',
        'Sweatpants',
        'Bags',
        'Jackets'
    ]


    longSleeve = [
        '3/4 Sleeve',
        'Crewneck',
        'Dress',
        'Full Zip Hoodie',
        'Hoodie',
        'Legging',
        'Long Sleeve',
        'Long Sleeve Pocket',
        'Pants',
        'Performance Full Zip Hoodie',
        'Performance Hoodie',
        'Performance Long Sleeve',
        'Performance Pull Over',
        'Performance Quarter Zip',
        'Quarter Zip',
        'Shorts'
    ]


    markups = {
        "Seller": 1 + (this.DEFAULT_MARKUP / 100),
        "Admin": 1,
        "Design Partner": 2,
        "Not Authorized": 2,
        "Production Partner": 2,
        "Collegiate Program Coordinator": 1,
        "Operations Manager": 1,
        "guest": 2
    };

    costLocations = [
        'Front - Left Chest',
        'Front - Right Chest',
        'Front - Pocket',
        'Front - Full Chest',
        'Front - Belly',
        'Back - Upper Back',
        'Back - Lower Back',
        'Back - Full Back',
        'Sleeve - Right Sleeve',
        'Sleeve - Right Sleeve',
        'Sleeve - Left Sleeve',
        'Back-Back',
        'Front-Front'
    ];

    embroideryHeaders:any = [];
    ppCompany: any;
    ppCompanyId: number = 1;

    constructor(
        private auth: AuthService,
        private commonAPIService: CommonAPIService,
        private orderService: OrderService,
        public commonService: CommonService,
    ) {
        /*this.db.object("/settings").valueChanges().subscribe((settings:any) => {
    
          this.markup = settings.markup;
          this.commission = settings.commission;
          this.salescommission = settings.salescommission;
          console.log(this.auth.user,"markup");
          this.user = this.auth.user;
    
          
          this.db.list("/users").snapshotChanges().subscribe((users:any) => {
            users = FirebaseKeyHandler.convertMultiple(users);
            let productionUsers = _.filter(users, user => {
              return user.permissionLevel == 'Production Partner'
            });
    
            
            this.getEmbTableandItemData();
            this.productionPartner = productionUsers[0];
            
            this.db.object(`/prices/screen/` + this.productionPartner.$key).valueChanges().subscribe((screenList:any) => {
              this.db.object(`/prices/direct/` + this.productionPartner.$key).valueChanges().subscribe((dtgList:any) => {
                this.db.object(`/prices/items/screen/` + this.productionPartner.$key).valueChanges().subscribe((screenItems:any) => {
                  this.db.object(`/prices/items/dtg/` + this.productionPartner.$key).valueChanges().subscribe((dtgItems:any) => {
    
                    this.db.object(`/prices/items/finishing/` + this.productionPartner.$key).valueChanges().subscribe((finishingItems:any) => {
                      if (screenList && screenList.length > 0 && screenList[0]) { 
                        for (let i = 0; i < screenList.length; i++) {
                          if (screenList.length != i + 1) {
                            let low = parseInt(screenList[i].quantity);
                            let high = parseInt(screenList[i + 1].quantity) - 1;
                            screenList[i].expression = (count) => { return count >= low && count <= high };
                          } else {
                            screenList[i].expression = (count) => { return count >= parseInt(screenList[i].quantity) };
                          }
                        }
                        this.priceScreenList = screenList;
                      }
                      if (dtgList && dtgList.length > 0 && dtgList[0]) {
                        for (let i = 0; i < dtgList.length; i++) {
                          if (dtgList.length != i + 1) {
                            let low = parseInt(dtgList[i].quantity);
                            let high = parseInt(dtgList[i + 1].quantity) - 1;
                            dtgList[i].expression = (count) => { return count >= low && count <= high };
                          } else {
                            dtgList[i].expression = (count) => { return count >= parseInt(dtgList[i].quantity) };
                          }
                        }
                        this.priceDirectList = dtgList;
                      }
                      if (screenItems && screenItems.length > 0 && screenItems[0]) {
                        this.screenItems = screenItems;
                      }
                      if (dtgItems && dtgItems.length > 0 && dtgItems[0]) {
                        this.dtgItems = dtgItems;
                      }
              
                      if (finishingItems && finishingItems.length > 0 && finishingItems[0]) {
                        this.finishingItems = finishingItems;
                      }
                    });
                    });
                    });
                    });
                  });
         
          });
        })*/

        this.orderDetail.subscribe((value) => {            
            if (value) {
              this.garmentOrderDetail = value;
            }
        });

        this.setSettingAndPrintingValues();
    }

    async setInitialProductionPartnerId(ppUserId) {
        this.initialProductionPartnerId = ppUserId;
    }

    async setSettingAndPrintingValues() {
        let settings: any = await this.commonAPIService.getSystemSettings();
        if (settings) {
            this.markup = Number(settings.markup);
            this.commission = Number(settings.commission);
            this.salescommission = Number(settings.salescommission);
            this.user = this.auth.user;
            let ppUsers: any = await this.commonAPIService.getAllProductionUsers();
            let PPCompanies: any = await this.commonAPIService.getAllPpCompanies();            

            if (ppUsers && this.garmentOrderDetail) {
                let productionPartnerUID = ppUsers[0].user_id;
                if (this.garmentOrderDetail.productionPartner) {
                    productionPartnerUID = this.garmentOrderDetail.productionPartner.user_id;
                }
                let ppMemberData = await this.commonAPIService.getPpMemberByUserUID(productionPartnerUID);

                if (ppMemberData) {
                    let ppCompanyId = ppMemberData['ppCompanyId'];
                    this.ppCompany = PPCompanies.find(company => company.id === ppCompanyId);
                    this.productionPartner = ppUsers.find(ppUsers => ppUsers.user_id === productionPartnerUID);
    
                    if (this.productionPartner && this.productionPartner.user_id && this.ppCompany && this.ppCompany.id) {
                        let ppMemberData = await this.commonAPIService.getPpMemberByUserUID(this.productionPartner.user_id);
                        while (!ppMemberData) {
                            await new Promise(resolve => setTimeout(resolve, 100));
                        }
                
                        this.ppCompanyId = ppMemberData['ppCompanyId'];
                        let ppCompany = PPCompanies.find((company: any) => company.id === this.ppCompanyId);
                        if (ppCompany) {
                            this.productionPartner['ppCompanyName'] = ppCompany.name;
                        }    
                        await this.fetchVinylId();
                        this.priceScreenList = await this.getPricingScreenPrinting(this.ppCompany.id);
                        this.priceEmbList = await this.getPricingEmbroideryPrinting(this.ppCompany.id);
                        this.priceDirectList = await this.getPricingDirectToGarmentPrinting(this.ppCompany.id);
                        await this.getVinylPricing(this.ppCompany.id);
                        await this.generateFinishingRules(this.ppCompany.id);
                    }
                }
            }
        }
    }

    async setPpSettingAndPrintingValues(ppUser, ppCompany) {
        let settings: any = await this.commonAPIService.getSystemSettings();
        if (settings) {
            this.markup = Number(settings.markup);
            this.commission = Number(settings.commission);
            this.salescommission = Number(settings.salescommission);

            if (ppUser && ppUser.user_id && ppCompany && ppCompany.id) {    
                await this.fetchVinylId();
                this.priceScreenList = await this.getPricingScreenPrinting(ppCompany.id);
                this.priceEmbList = await this.getPricingEmbroideryPrinting(ppCompany.id);
                this.priceDirectList = await this.getPricingDirectToGarmentPrinting(ppCompany.id);
                await this.getVinylPricing(ppCompany.id);
                await this.generateFinishingRules(ppCompany.id);
            }

        }
    }

    async updatePricingDataService(order) {
        this.garmentOrderDetail = order;
        await this.setSettingAndPrintingValues();
    }

    async getPricingScreenPrinting(ppCompanyId) {
        let priceScreenList:any;
        let screenPrintMethod = await this.commonAPIService.getPrintMethodByName(this.commonAPIService.SCREEN_PRINTING);
        if (!screenPrintMethod) {
            screenPrintMethod = await this.commonAPIService.fnGetPrintMethodByName(this.commonAPIService.SCREEN_PRINTING)
        }
        if (screenPrintMethod) {
            let screenPrintMethodId = screenPrintMethod['print_method_id'];
            if (screenPrintMethodId) {
                this.generateScreenIfThenFunction(screenPrintMethodId, ppCompanyId);
            }
            // console.log('screenPrintMethodId =', screenPrintMethodId);
            let pricingScreenPrinting: any = await this.commonAPIService.fnGetProductionPricing(screenPrintMethodId, ppCompanyId);
            // console.log('pricingScreenPrinting =', pricingScreenPrinting);
            if (pricingScreenPrinting) {
                if (pricingScreenPrinting.rows && pricingScreenPrinting.rows.length > 0) {
                    let screenList = pricingScreenPrinting.rows;
                    for (let i = 0; i < screenList.length; i++) {
                        if (screenList.length != i + 1) {
                            let low = parseInt(screenList[i].quantity);
                            let high = parseInt(screenList[i + 1].quantity) - 1;
                            screenList[i].expression = (count) => { return count >= low && count <= high };
                        } else {
                            screenList[i].expression = (count) => { return count >= parseInt(screenList[i].quantity) };
                        }
                    }
                    priceScreenList = screenList;
                }
            }
        }
        // console.log('priceScreenList =', priceScreenList);
        return priceScreenList;
    }

    async getPricingEmbroideryPrinting(ppCompanyId) {
        let priceEmbList:any;
        let embroideryMethod = await this.commonAPIService.getPrintMethodByName(this.commonAPIService.EMBROIDERY_PRINTING);
        if (embroideryMethod) {
            let embroideryMethodId = embroideryMethod['print_method_id'];
            if (embroideryMethodId) {
                this.generateEmbroideryIfThenFunction(embroideryMethodId, ppCompanyId);
            }
            // console.log('embroideryMethodId =', embroideryMethodId);
            let pricingEmbroideryPrinting: any = await this.commonAPIService.fnGetProductionPricing(embroideryMethodId, ppCompanyId);
            // console.log('pricingEmbroideryPrinting =', pricingEmbroideryPrinting);
            if (pricingEmbroideryPrinting) {
                if (pricingEmbroideryPrinting.column && pricingEmbroideryPrinting.column.length > 0) {
                    this.embroideryHeaders = _.map(pricingEmbroideryPrinting.column, 'number_of_colors');
                }
                if (pricingEmbroideryPrinting.rows && pricingEmbroideryPrinting.rows.length > 0) {
                    let embList = pricingEmbroideryPrinting.rows;
                    if (embList && embList.length > 0 && embList[0]) {
                        for (let i = 0; i < embList.length; i++) {
                            if (embList.length != i + 1) {
                                let low = parseInt(embList[i].quantity);
                                let high = parseInt(embList[i + 1].quantity) - 1;
                                embList[i].expression = (count) => { return count >= low && count <= high };
                            } else {
                                embList[i].expression = (count) => { return count >= parseInt(embList[i].quantity) };
                            }
                        }
                        priceEmbList = embList;
                    }
                }
            }
        }
        // console.log('priceEmbList =', priceEmbList);
        return priceEmbList;
    }

    async getPricingDirectToGarmentPrinting(ppCompanyId) {
        let priceDirectList:any;
        let directToGarmentMethod = await this.commonAPIService.getPrintMethodByName(this.commonAPIService.DIRECT_TO_GARMENT_PRINTING);
        
        if (!directToGarmentMethod) {
            directToGarmentMethod = await this.commonAPIService.fnGetPrintMethodByName(this.commonAPIService.DIRECT_TO_GARMENT_PRINTING)
        }
        if (directToGarmentMethod) {
            let directToGarmentMethodId = directToGarmentMethod['print_method_id'];
            if (directToGarmentMethodId) {
                this.generateDTGIfThenFunction(directToGarmentMethodId, ppCompanyId);
            }
            // console.log('directToGarmentMethodId =', directToGarmentMethodId);
            let pricingDirectToGarmentPrinting: any = await this.commonAPIService.fnGetProductionPricing(directToGarmentMethodId, ppCompanyId);
            // console.log('pricingDirectToGarmentPrinting =', pricingDirectToGarmentPrinting);
            if (pricingDirectToGarmentPrinting) {
                if (pricingDirectToGarmentPrinting.rows && pricingDirectToGarmentPrinting.rows.length > 0) {
                    let dtgList = pricingDirectToGarmentPrinting.rows;
                    if (dtgList && dtgList.length > 0 && dtgList[0]) {
                        for (let i = 0; i < dtgList.length; i++) {
                            if (dtgList.length != i + 1) {
                                let low = parseInt(dtgList[i].quantity);
                                let high = parseInt(dtgList[i + 1].quantity) - 1;
                                dtgList[i].expression = (count) => { return count >= low && count <= high };
                            } else {
                                dtgList[i].expression = (count) => { return count >= parseInt(dtgList[i].quantity) };
                            }
                        }
                        priceDirectList = dtgList;
                    }
                }
            }
        }
        // console.log('priceDirectList =', priceDirectList);
        return priceDirectList;
    }

    async generateScreenIfThenFunction(printMethodId, ppCompanyId) {
		try {
            this.screenItemLoad = false;
			let screenItems: any = await this.commonAPIService.getPricingByPpCompanyAndPrintMethodId(printMethodId, ppCompanyId);
            if (!screenItems) {
                screenItems = await this.commonAPIService.fnGeneratePricingFunction(printMethodId, ppCompanyId, "reload");
            }
            this.screenItemLoad = true;
			if (screenItems && screenItems.length) {
				this.screenItems = screenItems;
			}
		} catch (e) {
			console.log(e);
		}
	}

    async generateDTGIfThenFunction(printMethodId, ppCompanyId) {
		try {
            this.DtgItemLoad = false;
			let DtgItems: any = await this.commonAPIService.getPricingByPpCompanyAndPrintMethodId(printMethodId, ppCompanyId);

            if (!DtgItems) {
                DtgItems = await this.commonAPIService.fnGeneratePricingFunction(printMethodId, ppCompanyId, 'reload')
            }
            this.DtgItemLoad = true;
             
			if (DtgItems && DtgItems.length) {
				this.dtgItems = DtgItems;
			} else {
                this.dtgItems = [];
            }
		} catch (e) {
			console.log(e);
		}
	}

    async generateEmbroideryIfThenFunction(printMethodId, ppCompanyId) {
		try {
            this.EmbroideryItemLoad = false;
			let EmbItems: any = await this.commonAPIService.getPricingByPpCompanyAndPrintMethodId(printMethodId, ppCompanyId);
            this.EmbroideryItemLoad = true;
			if (EmbItems && EmbItems.length) {
				this.embItems = EmbItems;
			}
		} catch (e) {
			console.log(e);
		}
	}

    async fetchVinylId() {
		let vinylMethod = await this.commonAPIService.getPrintMethodByName(this.commonAPIService.VINYL_PRINTING);
        if (vinylMethod) {
            this.vinylMethodId = vinylMethod['print_method_id'];
		}
	}

    async getVinylPricing(ppCompanyId) {
        let vinylData: any = await this.commonAPIService.getVinylPricingByPpCompany(ppCompanyId);
        this.vinylItems = vinylData;
    }

    async generateFinishingRules (ppCompanyId) {
        let FinishingMethod = await this.commonAPIService.getPrintMethodByName(this.commonAPIService.FINISHINGS);
        if (!FinishingMethod) {
            FinishingMethod = await this.commonAPIService.fnGetPrintMethodByName(this.commonAPIService.FINISHINGS)
        }

        if (FinishingMethod) {
            let finishingMethodId = FinishingMethod['print_method_id'];
            if (finishingMethodId) {
                try {
                    this.finishingItemLoad = false;
                    let finishingItems: any = await this.commonAPIService.getPricingByPpCompanyAndPrintMethodId(finishingMethodId, ppCompanyId);
                    if (!finishingItems) {
                        finishingItems = await this.commonAPIService.fnGeneratePricingFunction(finishingMethodId, ppCompanyId, "reload");
                    }
                    this.finishingItemLoad = true;
                    if (finishingItems && finishingItems.length) {
                        this.finishingItems = finishingItems;
                    } else {
                        this.finishingItems = [];
                    }
                } catch (e) {
                    console.log(e);
                }

            }
        }
    }

    markupPriceBasedOnTheRole(amount, user, orderMarkup = undefined) {

        if (orderMarkup == undefined) {
            orderMarkup = {};
            orderMarkup.markUp = 1 + (this.DEFAULT_MARKUP / 100);
        }

        let permissionLevel = user != undefined ? user.permissionLevel : 'guest';

        let val = this.markups[permissionLevel];

        if (permissionLevel == this.commonAPIService.USER_SP || permissionLevel == this.commonAPIService.USER_SELLER) {
            // val = user.markup ? 1 + (user.markup / 100) : 1.25; // dynamic merkup from SP profile
            // val = (user.markup && user.markup >= 1 && user.markup <= 2) ? user.markup : ((user.markup) ? 1 + (user.markup / 100) : 1.25);
            val = (orderMarkup.markUp && orderMarkup.markUp >= 1 && orderMarkup.markUp <= 2) ? orderMarkup.markUp : ((orderMarkup.markUp) ? 1 + (orderMarkup.markUp / 100) : 1 + (this.DEFAULT_MARKUP / 100));
        } else if (!val) {
            val = 2; //for the guest user
        }

        return amount * val;

    }

    /*getEmbTableandItemData() {
  
      return new Promise((resolve) => {
  
        if (this.embItems && this.embItems.length <= 0) {
  
          this.db.list("/users").snapshotChanges().subscribe((users:any) => {
  
            users = FirebaseKeyHandler.convertMultiple(users);
  
            let productionUsers = _.filter(users, user => {
              return user.permissionLevel == 'Production Partner'
            });
            this.productionPartner = productionUsers[0];
  
            this.db.object(`/prices/emb/` + this.productionPartner.$key).valueChanges().subscribe((embList:any) => {
              if (embList && embList.length > 0 && embList[0]) {
                for (let i = 0; i < embList.length; i++) {
                  if (embList.length != i + 1) {
                    let low = parseInt(embList[i].quantity);
                    let high = parseInt(embList[i + 1].quantity) - 1;
                    embList[i].expression = (count) => { return count >= low && count <= high };
                  } else {
                    embList[i].expression = (count) => { return count >= parseInt(embList[i].quantity) };
                  }
                }
                this.priceEmbList = embList;
              }
              this.db.object(`/prices/items/emb/` + this.productionPartner.$key).valueChanges().subscribe((embItems: any) => {
                if (embItems && embItems.length > 0 && embItems[0]) {
                  this.embItems = embItems;
                  this.embItems = (this.embItems as Array<any>).filter((e: any) => {
  
                    if (e.key != "Stitches per Square Inch") {
                      return e;
                    }
                  });
                }
                resolve(null);
              });
            });
          });
  
        } else {
          resolve(null);
        }
      });
  
    }*/

    transform(price: any) {
        if (!price) {
            return 0;
        }
        if (typeof price != "number") {
            price = parseFloat(price);
        }
        if (this.forceTransform && !this.isAdmin) {
            return parseFloat((price * this.markup).toFixed(2));
        } else if (this.forceTransform && this.isAdmin) {
            return parseFloat((price).toFixed(2))
        } else if (this.isAdmin) {
            return parseFloat((price).toFixed(2))
        } else {
            return parseFloat((price * this.markup).toFixed(2));
        }
    }

    addMarkup(price: any, isAdmin, markup = 1) {
        if (!price) {
            return 0;
        }
        if (typeof price != "number") {
            price = parseFloat(price);
        }

        if (isAdmin) {
            return price
        }

        if (markup == null) {
            markup = this.markup;
        }

        return price * markup;
    }

    calculateSplitAddressShippingCost(addresses, isAdmin, markup = 1, totalQuantity = 0) {
        let price = 0;
        // if (product && product.splitAddresses && product.splitAddresses.length) {
        //     product.splitAddresses.forEach(address => {
        //         if (address.method == this.commonAPIService.BULK_SHIPPING || address.method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
        //             totalNumberOfProductsShippedViaBulkShipping += address.totalQuantity;
        //         }
        //     });
        // }

        if (addresses && addresses.length && totalQuantity > 0) {
            addresses.forEach(element => {
                if (element.method == this.commonAPIService.BULK_SHIPPING) {
                    if (element && element.shippingOption && element.shippingOption.shipping_amount && element.shippingOption.shipping_amount.amount) {
                        price += element.shippingOption.shipping_amount.amount / totalQuantity;
                    }
                }
            });
        }

        if (!price) {
            return 0;
        }
        if (typeof price != "number") {
            price = parseFloat(price);
        }
        if (isAdmin) {
            return price;
        }
        if (markup == null) {
            markup = this.markup;
        }
        return price * markup;
    }

    async getArtWorkEstimatedCost(order, isArtworkEstimation = false) {
        let estimatedCost;
        if (isArtworkEstimation) {
            estimatedCost = await this.getArtworkModalEstimatedPrintingCost(order);
        } else {
            estimatedCost = await this.getEstimatedPrintingCost(order);
        }
        let isAdmin = (order.salesPartner?.permissionLevel == this.commonAPIService.USER_ADMIN) || (order.salesPartner?.permissionLevel == this.commonAPIService.USER_CSD) || (order.salesPartner?.permissionLevel == this.commonAPIService.USER_OM);

        let response = await this.getMarkupBySallesPartner(order);
        let spMarkup = response?.markup;

        let finalEstimatedCost = this.addMarkup(estimatedCost, isAdmin, spMarkup);
        this.orderService.setLineItemsWithMarkup.next(true);
        return finalEstimatedCost;
    }

    getCommission(price: any, isAdmin) {
        if (!price) {
            return 0;
        }
        if (typeof price != "number") {
            price = parseFloat(price);
        }

        if (isAdmin) {
            return price
        }

        return price * this.commission
    }

    getTotalCost(order): number {
        var markup = this.markup
        if (!order.salesPartner) {
            return 0;
        }

        if (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER) {
            var total = 0
            if (order.shirts) {
                for (let i = 0; i < order.shirts.length; i++) {
                    total += Math.round(order.shirts[i].totalCostPerShirt * markup * 100) / 100 * order.shirts[i].totalQty;
                };
            }
            return total;
        } else {
            return order.totalCost ? order.totalCost : 0;
        }
    }

    getMarkupBySallesPartner(order) {
        if (order.orderProfitShareMarkupDetails) {
            let psmObj = order.orderProfitShareMarkupDetails;
            let sp = _.clone(order.salesPartner);
            if (sp && (sp.permissionLevel == this.commonAPIService.USER_SP || sp.permissionLevel == this.commonAPIService.USER_SELLER)) {
                sp.markup = (psmObj.markUp && psmObj.markUp >= 1 && psmObj.markUp <= 2) ? psmObj.markUp : ((psmObj.markUp) ? 1 + (psmObj.markUp / 100) : 1 + (this.DEFAULT_MARKUP / 100));
                if (this.garmentOrderDetail?.greekLicensingStatus == "greek_exec_member") {
                    sp.profitShare = 0; 
                } else {
                    sp.profitShare = sp.profitShare ? (sp.profitShare / 100) : 0.5;
                }
            } else {
                try {
                    sp.markup = this.markup != null ? this.markup : 1;
                    sp.profitShare = 0.5;
                } catch (e) {}
            }
            return sp;
        } else {
            let sp = _.clone(order.salesPartner);
            if (sp && (sp.permissionLevel == this.commonAPIService.USER_SP || sp.permissionLevel == this.commonAPIService.USER_SELLER)) {
                // sp.markup = sp.markup ? 1 + (sp.markup / 100) : 1.25;
                sp.markup = (sp.markup && sp.markup >= 1 && sp.markup <= 2) ? sp.markup : ((sp.markup) ? 1 + (sp.markup / 100) : 1 + (this.DEFAULT_MARKUP / 100));
                if (this.garmentOrderDetail?.greekLicensingStatus == "greek_exec_member") {
                    sp.profitShare = 0; 
                } else {
                    sp.profitShare = sp.profitShare ? (sp.profitShare / 100) : 0.5;
                }
            } else if (sp) {
                try {
                    sp.markup = this.markup != null ? this.markup : 1;
                    sp.profitShare = 0.5;
                } catch (e) {}
            }
            return sp;
        }
    }

    getTotalInvoice(order) {
        return _.reduce(order.lintedItems, (total, lintedItem: any) => {
            if (lintedItem.isdelete == true) {
                return total + 0;
            } else {
                return total + lintedItem.value * lintedItem.quantity;
            }
        }, 0)
    }

    getMarkup(order) {
        return this.getTotalCost(order) - order.totalCost;
    }

    getTotalProfit(order): number {
        if (!order.salesPartner) {
            return 0;
        }
        return _.reduce(order.shirts, (total, shirt: any) => {
            return total + this.getTotalProfitPerGarment(order, shirt);
        }, 0)
    }


    getProfitForListing(order, isAdmin) {
        let estimationProfit = 0.0;

        let totalRevanue = this.getTotalRevenue(order);
        let markupData = this.getMarkupBySallesPartner(order)
        let totalCost = this.getTotalCostListing(order, isAdmin, markupData.markup);
        estimationProfit = totalRevanue - totalCost;
        return isAdmin === true ? estimationProfit : estimationProfit * markupData.profitShare;
    }

    getTotalCostListing(order, isAdmin, margin) {

        let shippingCost = 0.0, totalCost = 0.0;

        if (order.shippingOption && order.shippingOption && order.shippingOption.shipping_amount.amount) {

            shippingCost = order.shippingOption.shipping_amount.amount / this.getOrderQuantity(order);
        }

        // if (!isAdmin) {

        //   shippingCost = shippingCost * this.markup;
        // }

        let artworkFee = this.getArtworkFee(order);
        this.countPrintCost(isAdmin, margin, order);
        this.turnAroundCost = this.orderService.getTurnAroundValue(order);
        this.countShipCost(shippingCost, isAdmin, margin)
        return _.reduce(order.shirts, (total, shirt: any) => {
            this.countGarmentCost(shirt.perShirtCost, order.salesPartner);
            this.countGreekLicenning(shirt.greekLicensing, isAdmin, margin)
            totalCost = totalCost + parseFloat((this.garmentCost + this.printCost + artworkFee + this.shipCost + this.greekLicensing as number).toFixed(2)) * shirt.totalQty;
            return totalCost;
        }, 0);
    }
    countGarmentCost(perShirtCost, order) {
        this.garmentCost = this.markupPriceBasedOnTheRole(perShirtCost, order, order.orderProfitShareMarkupDetails);
    }

    countPrintCost(isAdminOrCSD, margin, order) {
        this.printCost = this.addMarkup((this.getTotalPerPiece(order)), isAdminOrCSD, margin);
    }
    countGreekLicenning(greekLicensing, isAdmin, margin) {
        this.greekLicensing = this.addMarkup(greekLicensing, isAdmin, margin);
    }
    countShipCost(shippingCost, isAdminOrCSD, margin) {
        this.shipCost = this.addMarkup(shippingCost, isAdminOrCSD, margin);
    }
    getTotal(order) {
        return _.reduce(order.lintedItems, (total, lintedItem: any) => {
            if (lintedItem.isdelete == true) {
                return total + 0;
            } else {
                return total + lintedItem.value * lintedItem.quantity;
            }
        }, 0)
    }

    getTotalPerPiece(order) {
        let totalPieces = _.reduce(order.shirts, (total, shirt: any) => {
            return total + shirt.totalQty;
        }, 0);
        if (totalPieces) {
            return this.getTotal(order) / totalPieces;
        } else {
            return this.getTotal(order);
        }
    }


    getOrderQuantity(order) {
        return _.reduce(order.shirts, (total, shirt: any) => {

            return total + shirt.totalQty;
        }, 0)
    }

    getTotalProfitShare(order): number {
        if (!order.salesPartner) {
            return 0;
        }
        return _.reduce(order.shirts, (total, shirt: any) => {
            return total + this.getProfitSharePerGarment(order, shirt);
        }, 0)
    }

    getTotalProfitPerGarment(order, shirt) {
        if (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER) {
            return shirt.totalQty * (shirt.customerPrice - this.round(shirt.totalCostPerShirt * this.markup) || 0)
        } else {
            return shirt.totalQty * (shirt.customerPrice - shirt.totalCostPerShirt || 0)
        }
    }

    getProfitSharePerGarment(order, shirt) {
        if (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER) {
            if (order.commission || typeof (order.$key) == 'undefined') {
                return this.getTotalProfitPerGarment(order, shirt) * this.salescommission;
            } else {
                return this.getTotalProfitPerGarment(order, shirt) * this.commission;
            }
        } else {
            return 0
        }
    }

    getSalesPartnerProfitPerGarment(order, shirt) {
        if (order.salesPartner.permissionLevel == this.commonAPIService.USER_SP || order.salesPartner.permissionLevel == this.commonAPIService.USER_SELLER) {
            if (order.commission || typeof (order.$key) == 'undefined') {
                return this.getTotalProfitPerGarment(order, shirt) * (1 - this.salescommission);
            } else {
                return this.getTotalProfitPerGarment(order, shirt) * (1 - this.commission);
            }
        } else {
            return this.getTotalProfitPerGarment(order, shirt);
        }
    }

    getSalesPartnerTotalProfit(order) {
        return _.reduce(order.shirts, (total, shirt: any) => {
            return total + this.getSalesPartnerProfitPerGarment(order, shirt);
        }, 0)
    }

    round(value) {
        if (typeof value != "number") {
            value = parseFloat(value);
        }
        return Math.round(value * 100) / 100;
    }

    getAdminTotalCost(order) {
        return _.reduce(order.shirts, (total, shirt: any) => {
            console.log(shirt.totalCostPerShirt, "price per shirt");
            return total + (shirt.totalQty * shirt.totalCostPerShirt || 0)
        }, 0)

    }

    getTotalRevenue(order): number {
        return _.reduce(order.shirts, (total, shirt: any) => {
            return total + (shirt.totalQty * (shirt.customerPrice || 0));
        }, 0)
    }

    getTotalCommission(order): number {
        return _.reduce(order.shirts, (commission, shirt: any) => {
            let qty = parseFloat(shirt.totalQty)
            return commission + ((parseFloat(shirt.customerPrice) * qty) - (this.transform(shirt.totalCostPerShirt) * qty))
        }, 0)
    }

    getLintedItem(old, shirt, key, label, price) {
        let qty = 0;
        if (old && old.quantity) {
            qty = old.quantity + shirt.totalQty;
        } else {
            qty = shirt.totalQty;
        }
        return {
            key: key,
            lebal: label,
            quantity: qty,
            value: price
        };
    }

    removeLintedItem(lintedItems, key) {
        for (let i = 0; i < lintedItems.length; i++) {
            if (key == lintedItems[i].key) {
                lintedItems.splice(i, 1);
            }
        }
    }

    // Calculate line items using npm package
    async imagesChanged(order, event?: any, spNotify = null, estimationCall = false) {
        let embroideryMethod = await this.commonAPIService.getPrintMethodByName(this.commonAPIService.EMBROIDERY_PRINTING);
        if (embroideryMethod) {
            let embroideryMethodId = embroideryMethod['print_method_id'];
            if (this.embItems && this.embItems.length <= 0) {
                this.embItems = await this.commonAPIService.getPricingByPpCompanyAndPrintMethodId(embroideryMethodId, this.ppCompanyId);
            }
        }
        let screenFee;
        if(estimationCall){
            screenFee = this.commonAPIService.fnGetScreenFees(order?.productionPartner?.user_id);
        } else {
            screenFee = this.commonAPIService.fnGetScreenFees(this.initialProductionPartnerId);
        }
        let functionPayload = {
            priceScreenList: this.priceScreenList,
            commonAPIService: this.commonAPIService,
            orderService: this.orderService,
            SCREEN_PRINTING: this.commonAPIService.SCREEN_PRINTING,
            EMBROIDERY_PRINTING: this.commonAPIService.EMBROIDERY_PRINTING,
            DIRECT_TO_GARMENT_PRINTING: this.commonAPIService.DIRECT_TO_GARMENT_PRINTING,
            initialProductionPartnerId: this.initialProductionPartnerId,
            priceDirectList: this.priceDirectList,
            priceEmbList: this.priceEmbList,
            embroideryHeaders: this.embroideryHeaders,
            vinylItems: this.vinylItems,
            costLocations: this.costLocations,
            embroideryMethod: embroideryMethod,
            embItems: this.embItems,
            screenItems: this.screenItems,
            INDIVIDUAL_SHIPPING: this.commonAPIService.INDIVIDUAL_SHIPPING,
            dtgItems: this.dtgItems,
            finishingItems: this.finishingItems,
            productionPartnerScreenFee: screenFee,
            holdCurrentLineItem: this.holdCurrentLineItem,
            USER_ADMIN: this.commonAPIService.USER_ADMIN,
            USER_CSD: this.commonAPIService.USER_CSD,
            USER_OM: this.commonAPIService.USER_OM,
            USER_SP: this.commonAPIService.USER_SP,
            USER_SELLER: this.commonAPIService.USER_SELLER,
            DEFAULT_MARKUP: this.DEFAULT_MARKUP,
            garmentOrderDetail: this.garmentOrderDetail,
            markup: this.markup,
            setLineItemsWithMarkupSub: this.orderService.setLineItemsWithMarkup,
            salescommission: this.salescommission,
            assignLineItemFlag: this.commonAPIService.assignLineItemFlag,
            assignRecentlyUpdatedLineItems: this.assignRecentlyUpdatedLineItems,
        };
        imagesChanged(order, functionPayload, event, spNotify, estimationCall);       
    }

    // Calculate line items without using npm package 
    // async imagesChanged(order, event?: any, spNotify = null, estimationCall = false) {
    //     // order.lintedItems = [];
    //     // let formatedLintedItems = {};

    //     if (!order) {
    //         return;
    //     }
        
    //     let eventIsTrue: any = false;
    //     // if (!event) {
    //     let costData = await this.updatePrintingCost(order);
        
    //     if (!estimationCall) {
    //         for (let key of Object.keys(order.cost)) {
                
    //             for (let item of Object.keys(costData)) {
    //                 if (key == item) {
    //                     if (order.cost[key] && order.cost[key].type && costData[item] && costData[item].type && order.cost[key].type == costData[item].type) {


    //                         if (!order.cost.hasOwnProperty('pricePerPrint') || (order.cost[key].pricePerPrint != costData[item].pricePerPrint)) {
                                
    //                             if (costData[item].pricePerPrint || costData[item].pricePerPrint == 0) {
    //                                 order.cost[key].pricePerPrint = costData[item].pricePerPrint;

    //                             }
    //                         }
    //                         if (!order.cost.hasOwnProperty('screenPrinting') && (costData[item].screenPrinting || costData[item].screenPrinting == false)) {
    //                             order.cost[key].screenPrinting = costData[item].screenPrinting;
    //                         }
    //                         if (!order.cost.hasOwnProperty('totalStich') && costData[item].totalStich) {
    //                             order.cost[key].totalStich = costData[item].totalStich;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         order.cost = costData;
    //     }
        
    //     event = order.cost;
    //     // }
    //     this.getEmbTableandItemData().then(() => {

    //     let cloneorder = _.cloneDeep(order);
    //     let additionalItems = []
    //     if (cloneorder && cloneorder.lintedItems) {
    //         additionalItems = cloneorder.lintedItems.filter((item) => item.isAdditional == true)
    //     }
    //     order.lintedItems = [];
    //     let printMethods = [], allPrintRules = [];
    //     let newLintedItems = [];
    //     let lintedItems = [];
    //     let turnAroundLineItem = [];
    //     let formatedLintedItems = {};
    //     let finishingItemsDisplay = [];
    //     let locations = _.compact(_.map(order.artwork.positions, (image, key) => {
    //         if (key.toString() != "totalCost" && key.toString() != 'shirtCount' && key.toString() != 'pricePerPrint' && key.toString() != 'colorCount') {
    //             printMethods.push(image.type);
    //             return { key: key.toString(), type: image.type }
    //         }
    //     })).reverse();


    //     _.forEach(order.shirts, (shirt, key) => {
    //         shirt.pricePerPrint = (event.pricePerPrint != undefined) ? event.pricePerPrint : 0.0;
    //         // this.screenItems = _.uniqBy(this.screenItems, 'dynamicPrintArray');
    //         // this.dtgItems = _.uniqBy(this.dtgItems, 'dynamicPrintArray');
    //         // this.embItems = _.uniqBy(this.embItems, 'dynamicPrintArray');


    //         shirt.greekLicensing = 0;
    //         order.shirts[key].greekLicensing = 0;
    //         let shippingCost: number = order.shippingCost ? (order.shippingCost / order.totalQuantity) : 0;
    //         shirt.shippingCost = shippingCost
    //         order.shirts[key].shippingCost = shippingCost;

    //         let screenPrint = _.find(printMethods, (printMethod) => {
    //             return printMethod == this.commonAPIService.SCREEN_PRINTING;
    //         });
    //         let DtgPrint = _.find(printMethods, (printMethod) => {
    //             return printMethod == this.commonAPIService.DIRECT_TO_GARMENT_PRINTING;
    //         });
    //         let EmbPrint = _.find(printMethods, (printMethod) => {
    //             return printMethod == this.commonAPIService.EMBROIDERY_PRINTING;
    //         });

    //         if (screenPrint) {
    //             eventIsTrue = true
    //         }

    //         //  Screen Printing
    //         if (!_.isUndefined(screenPrint)) {
    //             _.forEach(locations, (location, locationIndex) => {
    //                 if (location.type == 'Screen Printing') {
    //                     _.forEach(this.screenItems, (screenItem, screenItemIndex) => {
    //                         let conditions = [];
    //                         let screenPrintQuantity;
    //                         let individualShipmentGarmentQuantityForScreenPrint = 0;
    //                         let perLocationQuantity = 0;
    //                             _.forEach(screenItem.rules, (rule, index) => {

    //                                 // Print Method
    //                                 if (rule.comparator == "equal_to" && rule.printCategory == "Print Method" && rule.dynamicPrintArray == "Screen Printing" && screenItem.actions && screenItem.actions[0] && (screenItem.actions[0].addOrSubtract == "add" || screenItem.actions[0].addOrSubtract == "subtract")) {
    //                                     if (location.type == "Screen Printing") {
    //                                         if (screenItem.actions[0].location == "piece") {
    //                                             let colorCount = 0, underbaseCount = 0;
    //                                             _.forEach(this.getLocations(order), (location: any) => {
    //                                                 if (order.artwork.positions[location].colors) {
    //                                                     colorCount += parseInt(order.artwork.positions[location].colors);
    //                                                     underbaseCount += parseInt(order.artwork.positions[location].underbase);
    //                                                     // underbaseCount++;
    //                                                 }
    //                                             });
    //                                             screenPrintQuantity = order.totalQty;
    //                                             conditions[index] = true;
    //                                         }
    //                                         if (screenItem.actions[0].location == "location") {
    //                                             for (let loc of printMethods) {
    //                                                 if (loc == "Screen Printing") {
    //                                                     perLocationQuantity += 1
    //                                                 }
    //                                             }
    //                                             conditions[index] = true;
    //                                         }
            
    //                                     }
    //                                 }
            
    //                                 // Print Location
    //                                 if (rule.comparator == "equal_to" && rule.printCategory == "Print Location" && locations.length > 0 && screenItem.actions && screenItem.actions[0] && (screenItem.actions[0].addOrSubtract == "add" || screenItem.actions[0].addOrSubtract == "subtract") && screenItem.actions[0].value) {
    //                                     // let locationFound: any = _.filter(locations, { type: "Screen Printing" });
    //                                     let locationFound: any = location.type == "Screen Printing" ? [location] : [];
    //                                     // if (!_.isUndefined(locationFound) && locationFound.length > 0) {
    //                                     //   if(_.filter(locationFound, { key: rule.dynamicPrintArray }).length > 0){
    //                                     //     conditions[index] = true;
    //                                     //   }
    //                                     // }
    //                                     if (screenItem.actions[0].location == "location") {
    //                                         if (!_.isUndefined(locationFound) && locationFound.length > 0) {
    //                                             let tempLocationFound = [];
    //                                             for (let i = 0; i < locationFound.length; i++) {
    //                                                 tempLocationFound.push({...locationFound[i], key: locationFound[i]['key'].replaceAll(' ', '')})
    //                                             }
    //                                             if (_.filter(tempLocationFound, { key: rule.dynamicPrintArray.toString().replaceAll(' ', '') }).length > 0) {
    //                                                 conditions[index] = true;
    //                                             }
    //                                         }
    //                                     } else if (screenItem.actions[0].location == "piece") {
    //                                         if (!_.isUndefined(locationFound) && locationFound.length > 0) {
    //                                             let tempLocationFound = [];
    //                                             for (let i = 0; i < locationFound.length; i++) {
    //                                                 tempLocationFound.push({...locationFound[i], key: locationFound[i]['key'].replaceAll(' ', '')})
    //                                             }
    //                                             if (_.filter(tempLocationFound, { key: rule.dynamicPrintArray.toString().replaceAll(' ', '') }).length > 0) {
    //                                                 conditions[index] = true;
    //                                             }
    //                                         }
    //                                     }
    //                                 }
            
    //                                 // Category
    //                                 if (rule.comparator == "equal_to" && rule.printCategory == "Category" && screenItem.actions && screenItem.actions[0] && (screenItem.actions[0].addOrSubtract == "add" || screenItem.actions[0].addOrSubtract == "subtract") && screenItem.actions[0].location == "piece" && screenItem.actions[0].value) {
    //                                     if (!_.isUndefined(shirt?.style?.baseCategory) && shirt?.style?.baseCategory.toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase() == rule.dynamicPrintArray.toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase()) {
    //                                         conditions[index] = true;
    //                                     } else if (!_.isUndefined(shirt?.style?.categories)) {
    //                                         for (let i = 0; i < shirt?.style?.categories.length; i++) {
    //                                             if (shirt.style.categories[i].includes("&amp;amp;")) {
    //                                                 shirt.style.categories[i] = shirt.style.categories[i].replace("&amp;amp;", "&")
    //                                             }
    //                                             if (shirt.style.categories[i].toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase() === rule.dynamicPrintArray.toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase()) {
    //                                                 conditions[index] = true;
    //                                                 break;
    //                                             }
    //                                         }
    //                                     }
    //                                 }
            
    //                                 // ADDITIONAL 
    //                                 if (rule.comparator == "equal_to" && rule.printCategory == "Additional" && screenItem.actions && screenItem.actions[0] && (screenItem.actions[0].addOrSubtract == "add" || screenItem.actions[0].addOrSubtract == "subtract") && screenItem.actions[0].value) {
    //                                     if ((cloneorder.hangTag && rule.dynamicPrintArray == "Hang Tag") || (cloneorder.sizeSticker && rule.dynamicPrintArray == "Size Sticker") || (cloneorder.folding && rule.dynamicPrintArray == "Folding") || (cloneorder.customNames && rule.dynamicPrintArray == "Custom Names / Size Sticker") || (cloneorder.polybagging && rule.dynamicPrintArray == "Polybagging")) {
    //                                         conditions[index] = true;
    //                                     }
    //                                 }
            
    //                                 // SHIPPING METHODS
    //                                 if (rule.comparator == "equal_to" && rule.printCategory == "Shipping Method" && rule.dynamicPrintArray == "Indiviual Shipment" && screenItem.actions && screenItem.actions[0] && (screenItem.actions[0].addOrSubtract == "add" || screenItem.actions[0].addOrSubtract == "subtract") && screenItem.actions[0].value) {
    //                                     if (cloneorder?.splitAddresses && cloneorder?.splitAddresses.length > 1) {
    //                                         cloneorder?.splitAddresses?.forEach((address, i) => {
    //                                             if (address.method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
    //                                                 individualShipmentGarmentQuantityForScreenPrint += this.orderService.getTotalQuantityOnEachSplitAddresses(address?.shirts, cloneorder?.shirts);
    //                                                 conditions[index] = true;
    //                                             }
    //                                         });
    //                                     } else if (cloneorder?.splitAddresses && cloneorder?.splitAddresses?.length == 1) {
    //                                         if (cloneorder?.splitAddresses[0].method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
    //                                             individualShipmentGarmentQuantityForScreenPrint = this.orderService.getTotalQuantity(cloneorder);
    //                                             conditions[index] = true;
    //                                         }
    //                                     }
    //                                 }
            
    //                                 // LICENCING
    //                                 if (rule.comparator == "equal_to" && rule.printCategory == "Licensing" && screenItem.actions && screenItem.actions[0] && (screenItem.actions[0].addOrSubtract == "add" || screenItem.actions[0].addOrSubtract == "subtract") && screenItem.actions[0].location == "piece" && screenItem.actions[0].value) {
    //                                     let greekLineItem = false;
    //                                     if (cloneorder.greekLicensing) {
    //                                         if (rule.dynamicPrintArray == "Greek: Exec Member" && order.greekLicensingStatus == "greek_exec_member") {
    //                                             greekLineItem = true;
    //                                         } else if (rule.dynamicPrintArray == "Greek: Non-Exec Member" && order.greekLicensingStatus == "greek_non_exec_member") {
    //                                             greekLineItem = true;
    //                                         } else if (rule.dynamicPrintArray == "CHAARG" && order.greekLicensingStatus == "CHAARG") {
    //                                             greekLineItem = true;
    //                                         }
    //                                         if (greekLineItem) {
    //                                             conditions[index] = true;
    //                                         }
    //                                     }
    //                                 }
            
    //                             })
    //                         let allConditionFlag = true
    //                         for (let con = 0; con < screenItem.rules.length; con++) {
    //                             if (conditions[con] != true) {
    //                                 allConditionFlag = false
    //                                 break;
    //                             }
    //                         }
    //                         let labelKey = screenItem.key;
    //                         let label = screenItem.label;
    //                         if (allConditionFlag) {
    //                             // finishingItemsDisplay.push(labelKey);

    //                             // order.finishingItemsDisplay = finishingItemsDisplay;
    //                             let price = screenItem.actions[0].value || 0;

    //                             // this.removeItem(lintedItems, labelKey);
    //                             if (screenPrintQuantity) {
    //                                 allPrintRules.push({ key: screenItem.key, label: screenItem.key, quantity: screenPrintQuantity, value: price, type: "Screen" })
    //                             } else if (perLocationQuantity > 0) {
    //                                 allPrintRules.push({ key: screenItem.key, label: screenItem.key, quantity: perLocationQuantity, value: price, type: "Screen" })
    //                             } else if (individualShipmentGarmentQuantityForScreenPrint) {
    //                                 allPrintRules.push({ key: screenItem.key, label: screenItem.key, quantity: individualShipmentGarmentQuantityForScreenPrint, value: price, type: "Screen" });
    //                             } else {
    //                                 // allPrintRules.push({ key: screenItem.key, label: screenItem.key, quantity: order.shirtCount, value: price, type: "Screen" })
    //                                 formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //                             }

    //                             // formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //                         } else {

    //                             // _.remove(finishingItemsDisplay, (item) => {
    //                             //       return item == labelKey;
    //                             //     })
    //                             // order.finishingItemsDisplay = finishingItemsDisplay;
    //                             // this.removeItem(lintedItems, labelKey);

    //                         }
    //                     });
    //                 }
    //             });
    //         }

    //         //  DTG ITEMS
    //         if (!_.isUndefined(DtgPrint)) {
    //             _.forEach(locations, (location, locationIndex) => {
    //                 if (location.type == 'Direct to Garment') {
    //                     _.forEach(this.dtgItems, (dtgItem) => {
    //                         let conditions = [];
    //                         let perLocationQuantity = 0;
    //                         let individualShipmentGarmentQuantityForDTG = 0;

    //                         _.forEach(dtgItem.rules, (rule, index) => {

    //                             // Print Method
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Print Method" && rule.dynamicPrintArray == "Direct to Garment" && dtgItem.actions && dtgItem.actions[0] && (dtgItem.actions[0].addOrSubtract == "add" || dtgItem.actions[0].addOrSubtract == "subtract")) {
    //                                 //   if (printMethods.includes("Direct to Garment")) {
    //                                 //       conditions[index] = true;
    //                                 // }
    //                                 if (location.type == "Direct to Garment") {
    //                                     if (dtgItem.actions[0].location == "piece") {
    //                                         conditions[index] = true;
    //                                     }
    //                                     if (dtgItem.actions[0].location == "location") {
    //                                         for (let loc of printMethods) {
    //                                             if (loc == "Direct to Garment") {
    //                                                 perLocationQuantity += 1
    //                                             }
    //                                         }
    //                                         conditions[index] = true;
    //                                     }
    //                                 }
    //                             }

    //                             // Print Location
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Print Location" && locations.length > 0 && dtgItem.actions && dtgItem.actions[0] && (dtgItem.actions[0].addOrSubtract == "add" || dtgItem.actions[0].addOrSubtract == "subtract") && dtgItem.actions[0].value) {
    //                                 // let locationFound: any = _.filter(locations, { type: "Direct to Garment" });
    //                                 let locationFound: any = location.type == "Direct to Garment" ? [location] : [];
    //                                 if (dtgItem.actions[0].location == "location") {
    //                                     if (!_.isUndefined(locationFound) && locationFound.length > 0) {
    //                                         let tempLocationFound = [];
    //                                         for (let i = 0; i < locationFound.length; i++) {
    //                                             tempLocationFound.push({...locationFound[i], key: locationFound[i]['key'].replaceAll(' ', '')})
    //                                         }
    //                                         if (_.filter(tempLocationFound, { key: rule.dynamicPrintArray.toString().replaceAll(' ', '') }).length > 0) {
    //                                             conditions[index] = true;
    //                                         }
    //                                     }
    //                                 } else if (dtgItem.actions[0].location == "piece") {
    //                                     if (!_.isUndefined(locationFound) && locationFound.length > 0) {
    //                                         let tempLocationFound = [];
    //                                         for (let i = 0; i < locationFound.length; i++) {
    //                                             tempLocationFound.push({...locationFound[i], key: locationFound[i]['key'].replaceAll(' ', '')})
    //                                         }
    //                                         if (_.filter(tempLocationFound, { key: rule.dynamicPrintArray.toString().replaceAll(' ', '') }).length > 0) {
    //                                             conditions[index] = true;
    //                                         }
    //                                     }
    //                                 }
    //                             }

    //                             // Category
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Category" && dtgItem.actions && dtgItem.actions[0] && (dtgItem.actions[0].addOrSubtract == "add" || dtgItem.actions[0].addOrSubtract == "subtract") && dtgItem.actions[0].location == "piece" && dtgItem.actions[0].value) {
    //                                 if (!_.isUndefined(shirt?.style?.baseCategory) && shirt?.style?.baseCategory.toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase() == rule.dynamicPrintArray.toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase()) {
    //                                     conditions[index] = true;
    //                                 } else if (!_.isUndefined(shirt?.style?.categories)) {
    //                                     for (let i = 0; i < shirt?.style?.categories.length; i++) {
    //                                         if (shirt.style.categories[i].includes("&amp;amp;")) {
    //                                             shirt.style.categories[i] = shirt.style.categories[i].replace("&amp;amp;", "&")
    //                                         }
    //                                         if (shirt.style.categories[i].toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase() === rule.dynamicPrintArray.toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase()) {
    //                                             conditions[index] = true;
    //                                             break;
    //                                         }
    //                                     }
    //                                 }
    //                             }

    //                             // ADDITIONAL
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Additional" && dtgItem.actions && dtgItem.actions[0] && (dtgItem.actions[0].addOrSubtract == "add" || dtgItem.actions[0].addOrSubtract == "subtract") && dtgItem.actions[0].value) {
    //                                 if ((cloneorder.hangTag && rule.dynamicPrintArray == "Hang Tag") || (cloneorder.sizeSticker && rule.dynamicPrintArray == "Size Sticker") || (cloneorder.folding && rule.dynamicPrintArray == "Folding") || (cloneorder.customNames && rule.dynamicPrintArray == "Custom Names / Size Sticker") || (cloneorder.polybagging && rule.dynamicPrintArray == "Polybagging")) {
    //                                     conditions[index] = true;
    //                                 }
    //                             }

    //                             // SHIPPING METHODS
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Shipping Method" && rule.dynamicPrintArray == "Indiviual Shipment" && dtgItem.actions && dtgItem.actions[0] && (dtgItem.actions[0].addOrSubtract == "add" || dtgItem.actions[0].addOrSubtract == "subtract") && dtgItem.actions[0].value) {
    //                                 if (cloneorder?.splitAddresses && cloneorder?.splitAddresses.length > 1) {
    //                                     cloneorder?.splitAddresses?.forEach((address, i) => {
    //                                         if (address.method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
    //                                             individualShipmentGarmentQuantityForDTG += this.orderService.getTotalQuantityOnEachSplitAddresses(address?.shirts, cloneorder?.shirts);
    //                                             conditions[index] = true;
    //                                         }
    //                                     });
    //                                 } else if (cloneorder?.splitAddresses && cloneorder?.splitAddresses.length == 1) {
    //                                     if (cloneorder?.splitAddresses[0].method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
    //                                         individualShipmentGarmentQuantityForDTG = this.orderService.getTotalQuantity(cloneorder);
    //                                         conditions[index] = true;
    //                                     }
    //                                 }
    //                             }

    //                             // LICENCING
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Licensing" && dtgItem.actions && dtgItem.actions[0] && (dtgItem.actions[0].addOrSubtract == "add" || dtgItem.actions[0].addOrSubtract == "subtract") && dtgItem.actions[0].location == "piece" && dtgItem.actions[0].value) {
    //                                 let greekLineItem = false;
    //                                 if (cloneorder.greekLicensing) {
    //                                     if (rule.dynamicPrintArray == "Greek: Exec Member" && order.greekLicensingStatus == "greek_exec_member") {
    //                                         greekLineItem = true;
    //                                     } else if (rule.dynamicPrintArray == "Greek: Non-Exec Member" && order.greekLicensingStatus == "greek_non_exec_member") {
    //                                         greekLineItem = true;
    //                                     } else if (rule.dynamicPrintArray == "CHAARG" && order.greekLicensingStatus == "CHAARG") {
    //                                         greekLineItem = true;
    //                                     }
    //                                     if (greekLineItem) {
    //                                         conditions[index] = true;
    //                                     }
    //                                 }
    //                             }

    //                         })
    //                         let allConditionFlag = true
    //                         for (let con = 0; con < dtgItem.rules.length; con++) {
    //                             if (conditions[con] != true) {
    //                                 allConditionFlag = false
    //                                 break;
    //                             }
    //                         }
    //                         let labelKey = dtgItem.key;
    //                         let label = dtgItem.label;
    //                         if (allConditionFlag) {
    //                             // finishingItemsDisplay.push(labelKey);
    //                             // order.finishingItemsDisplay = finishingItemsDisplay;
    //                             let price = dtgItem.actions[0].value || 0;

    //                             // this.removeItem(lintedItems, labelKey);
    //                             if (perLocationQuantity > 0) {
    //                                 allPrintRules.push({ key: dtgItem.key, label: dtgItem.key, quantity: perLocationQuantity, value: price, type: "DTG" })
    //                                 // let tempLintedItem = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //                                 // tempLintedItem.quantity = perLocationQuantity;
    //                                 // formatedLintedItems[labelKey] = tempLintedItem;
    //                             } else if (individualShipmentGarmentQuantityForDTG) {
    //                                 allPrintRules.push({ key: dtgItem.key, label: dtgItem.key, quantity: individualShipmentGarmentQuantityForDTG, value: price, type: "DTG" });
    //                             } else {
    //                                 // allPrintRules.push({ key: dtgItem.key, label: dtgItem.key, quantity: order.shirtCount, value: price, type: "Screen" })
    //                                 formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //                             }
    //                             // formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //                         }
    //                     });
    //                 }
    //             });
    //         }

    //         //  EMB ITEMS
    //         if (!_.isUndefined(EmbPrint)) {
    //             _.forEach(locations, (location, locationIndex) => {
    //                 if (location.type == 'Embroidery') {
    //                     _.forEach(this.embItems, (embItem) => {
    //                         let conditions = [];
    //                         let perLocationQuantity = 0;
    //                         let individualShipmentGarmentQuantityForEMB = 0;

    //                         _.forEach(embItem.rules, (rule, index) => {

    //                             // Print Method
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Print Method" && rule.dynamicPrintArray == "Embroidery" && embItem.actions && embItem.actions[0] && (embItem.actions[0].addOrSubtract == "add" || embItem.actions[0].addOrSubtract == "subtract")) {
    //                                 if (printMethods.includes("Embroidery")) {
    //                                     if (embItem.actions[0].location == "piece") {
    //                                         conditions[index] = true;
    //                                     }
    //                                     if (embItem.actions[0].location == "location") {
    //                                         for (let loc of printMethods) {
    //                                             if (loc == "Embroidery") {
    //                                                 perLocationQuantity += 1
    //                                             }
    //                                         }
    //                                         conditions[index] = true;
    //                                     }
    //                                 }
    //                             }

    //                             // Print Location
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Print Location" && locations.length > 0 && embItem.actions && embItem.actions[0] && (embItem.actions[0].addOrSubtract == "add" || embItem.actions[0].addOrSubtract == "subtract") && embItem.actions[0].value) {
    //                                 // let locationFound: any = _.filter(locations, { type: "Embroidery" });
    //                                 let locationFound: any = location.type == "Embroidery" ? [location] : [];

    //                                 if (embItem.actions[0].location == "location") {
    //                                     if (!_.isUndefined(locationFound) && locationFound.length > 0) {
    //                                         let tempLocationFound = [];
    //                                         for (let i = 0; i < locationFound.length; i++) {
    //                                             tempLocationFound.push({...locationFound[i], key: locationFound[i]['key'].replaceAll(' ', '')})
    //                                         }
    //                                         if (_.filter(tempLocationFound, { key: rule.dynamicPrintArray.toString().replaceAll(' ', '') }).length > 0) {
    //                                             conditions[index] = true;
    //                                         }
    //                                     }
    //                                 } else if (embItem.actions[0].location == "piece") {
    //                                     if (!_.isUndefined(locationFound) && locationFound.length > 0) {
    //                                         let tempLocationFound = [];
    //                                         for (let i = 0; i < locationFound.length; i++) {
    //                                             tempLocationFound.push({...locationFound[i], key: locationFound[i]['key'].replaceAll(' ', '')})
    //                                         }
    //                                         if (_.filter(tempLocationFound, { key: rule.dynamicPrintArray.toString().replaceAll(' ', '') }).length > 0) {
    //                                             conditions[index] = true;
    //                                         }
    //                                     }
    //                                 }
    //                             }

    //                             // Category
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Category" && embItem.actions && embItem.actions[0] && (embItem.actions[0].addOrSubtract == "add" || embItem.actions[0].addOrSubtract == "subtract") && embItem.actions[0].location == "piece" && embItem.actions[0].value) {
    //                                 if (!_.isUndefined(shirt?.style?.baseCategory) && shirt?.style?.baseCategory.toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase() == rule.dynamicPrintArray.toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase()) {
    //                                     conditions[index] = true;
    //                                 } else if (!_.isUndefined(shirt?.style?.categories)) {
    //                                     for (let i = 0; i < shirt?.style?.categories.length; i++) {
    //                                         if (shirt.style.categories[i].includes("&amp;amp;")) {
    //                                             shirt.style.categories[i] = shirt.style.categories[i].replace("&amp;amp;", "&")
    //                                         }
    //                                         if (shirt.style.categories[i].toString().replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase() === rule.dynamicPrintArray.toString().replaceAll("&amp;amp;", "&").replaceAll(' ', '').toLowerCase()) {
    //                                             conditions[index] = true;
    //                                             break;
    //                                         }
    //                                     }
    //                                 }
    //                             }

    //                             // ADDITIONAL 
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Additional" && embItem.actions && embItem.actions[0] && (embItem.actions[0].addOrSubtract == "add" || embItem.actions[0].addOrSubtract == "subtract") && embItem.actions[0].value) {
    //                                 if ((cloneorder.hangTag && rule.dynamicPrintArray == "Hang Tag") || (cloneorder.sizeSticker && rule.dynamicPrintArray == "Size Sticker") || (cloneorder.folding && rule.dynamicPrintArray == "Folding") || (cloneorder.customNames && rule.dynamicPrintArray == "Custom Names / Size Sticker") || (cloneorder.polybagging && rule.dynamicPrintArray == "Polybagging")) {
    //                                     conditions[index] = true;
    //                                 }
    //                             }

    //                             // SHIPPING METHODS
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Shipping Method" && rule.dynamicPrintArray == "Indiviual Shipment" && embItem.actions && embItem.actions[0] && (embItem.actions[0].addOrSubtract == "add" || embItem.actions[0].addOrSubtract == "subtract") && embItem.actions[0].value) {
    //                                 if (cloneorder?.splitAddresses && cloneorder?.splitAddresses.length > 1) {
    //                                     cloneorder?.splitAddresses?.forEach((address, i) => {
    //                                         if (address.method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
    //                                             individualShipmentGarmentQuantityForEMB += this.orderService.getTotalQuantityOnEachSplitAddresses(address?.shirts, cloneorder?.shirts);
    //                                             conditions[index] = true;
    //                                         }
    //                                     });
    //                                 } else if (cloneorder?.splitAddresses && cloneorder?.splitAddresses.length == 1) {
    //                                     if (cloneorder?.splitAddresses[0].method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
    //                                         individualShipmentGarmentQuantityForEMB = this.orderService.getTotalQuantity(cloneorder);
    //                                         conditions[index] = true;
    //                                     }
    //                                 }
    //                             }

    //                             // LICENCING
    //                             if (rule.comparator == "equal_to" && rule.printCategory == "Licensing" && embItem.actions && embItem.actions[0] && (embItem.actions[0].addOrSubtract == "add" || embItem.actions[0].addOrSubtract == "subtract") && embItem.actions[0].location == "piece" && embItem.actions[0].value) {
    //                                 let greekLineItem = false;
    //                                 if (cloneorder.greekLicensing) {
    //                                     if (rule.dynamicPrintArray == "Greek: Exec Member" && order.greekLicensingStatus == "greek_exec_member") {
    //                                         greekLineItem = true;
    //                                     } else if (rule.dynamicPrintArray == "Greek: Non-Exec Member" && order.greekLicensingStatus == "greek_non_exec_member") {
    //                                         greekLineItem = true;
    //                                     } else if (rule.dynamicPrintArray == "CHAARG" && order.greekLicensingStatus == "CHAARG") {
    //                                         greekLineItem = true;
    //                                     }
    //                                     if (greekLineItem) {
    //                                         conditions[index] = true;
    //                                     }
    //                                 }
    //                             }

    //                         })
    //                         let allConditionFlag = true
    //                         for (let con = 0; con < embItem.rules.length; con++) {
    //                             if (conditions[con] != true) {
    //                                 allConditionFlag = false
    //                                 break;
    //                             }
    //                         }
    //                         let labelKey = embItem.key;
    //                         let label = embItem.label;
    //                         if (allConditionFlag) {
    //                             // finishingItemsDisplay.push(labelKey);
    //                             // order.finishingItemsDisplay = finishingItemsDisplay;
    //                             let price = embItem.actions[0].value || 0

    //                             // this.removeItem(lintedItems, labelKey);
    //                             if (perLocationQuantity > 0) {
    //                                 allPrintRules.push({ key: embItem.key, label: embItem.key, quantity: perLocationQuantity, value: price, type: "Emb" })
    //                                 // let tempLintedItem = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //                                 // tempLintedItem.quantity = perLocationQuantity;
    //                                 // formatedLintedItems[labelKey] = tempLintedItem;
    //                             } else if (individualShipmentGarmentQuantityForEMB) {
    //                                 allPrintRules.push({ key: embItem.key, label: embItem.key, quantity: individualShipmentGarmentQuantityForEMB, value: price, type: "EMbroidery" });
    //                             } else {
    //                                 // allPrintRules.push({ key: embItem.key, label: embItem.key, quantity: order.shirtCount, value: price, type: "Screen" })
    //                                 formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //                             }
    //                             // formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //                         }
    //                     });
    //                 }
    //             });
    //         }

    //         // FINISHING ITEMS

    //         _.forEach(this.finishingItems, (finishingItem) => {
    //             let isActionNotSame = false
    //             let tempActions = finishingItem.actions[0]
    //             let conditions = [];
    //             let individualShipmentGarmentQuantityForFinishing = 0;


    //             _.forEach(finishingItem.rules, (rule, index) => {

    //                 // Print Method
    //                 if (rule.comparator == "equal_to" && rule.printCategory == "Print Method" && finishingItem.actions && finishingItem.actions[0] && (finishingItem.actions[0].addOrSubtract == "add" || finishingItem.actions[0].addOrSubtract == "subtract") && finishingItem.actions[0].location == "piece") {
    //                     if (((printMethods.includes("Embroidery") && rule.dynamicPrintArray == "Embroidery") || (printMethods.includes("Direct to Garment") && rule.dynamicPrintArray == "Direct to Garment") || (printMethods.includes("Screen Printing") && rule.dynamicPrintArray == "Screen Printing")) && finishingItem.actions[0].value) {
    //                         conditions[index] = true;
    //                     }
    //                 }

    //                 // Print Location
    //                 if (rule.comparator == "equal_to" && rule.printCategory == "Print Location" && locations.length > 0 && finishingItem.actions && finishingItem.actions[0] && (finishingItem.actions[0].addOrSubtract == "add" || finishingItem.actions[0].addOrSubtract == "subtract") && finishingItem.actions[0].value) {
    //                     if (finishingItem.actions[0].location == "location") {
    //                         // if (!_.isUndefined(locations) && locations.length > 0 && _.filter(locations, { key: rule.dynamicPrintArray }).length > 0 ) {
    //                         //   conditions[index] = true;
    //                         // }
    //                     } else if (finishingItem.actions[0].location == "piece") {
    //                         if (!_.isUndefined(locations) && locations.length > 0 && _.filter(locations, { key: rule.dynamicPrintArray }).length > 0) {
    //                             conditions[index] = true;
    //                         }
    //                     }

    //                 }

    //                 // Category
    //                 if (rule.comparator == "equal_to" && rule.printCategory == "Category" && finishingItem.actions && finishingItem.actions[0] && (finishingItem.actions[0].addOrSubtract == "add" || finishingItem.actions[0].addOrSubtract == "subtract") && finishingItem.actions[0].location == "piece" && finishingItem.actions[0].value) {
    //                     if (!_.isUndefined(shirt?.style?.baseCategory) && shirt?.style?.baseCategory.replaceAll("&amp;amp;", "&").toLowerCase() == rule.dynamicPrintArray.replaceAll("&amp;amp;", "&").toLowerCase()) {
    //                         conditions[index] = true;
    //                     } else if (!_.isUndefined(shirt?.style?.categories)) {
    //                         for (let i = 0; i < shirt?.style?.categories.length; i++) {
    //                             if (shirt.style.categories[i].includes("&amp;amp;")) {
    //                                 shirt.style.categories[i] = shirt.style.categories[i].replace("&amp;amp;", "&")
    //                             }

    //                             if (shirt.style.categories[i].replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase() === rule.dynamicPrintArray.replaceAll(' ', '').replaceAll("&amp;amp;", "&").toLowerCase()) {
    //                                 conditions[index] = true;
    //                                 break;
    //                             }
    //                         }
    //                     }
    //                 }

    //                 // ADDITIONAL 
    //                 if (rule.comparator == "equal_to" && rule.printCategory == "Additional" && finishingItem.actions && finishingItem.actions[0] && (finishingItem.actions[0].addOrSubtract == "add" || finishingItem.actions[0].addOrSubtract == "subtract") && finishingItem.actions[0].value) {
    //                     if ((cloneorder.hangTag && rule.dynamicPrintArray == "Hang Tag") || (cloneorder.sizeSticker && rule.dynamicPrintArray == "Size Sticker") || (cloneorder.folding && rule.dynamicPrintArray == "Folding") || (cloneorder.customNames && rule.dynamicPrintArray == "Custom Names / Size Sticker") || (cloneorder.polybagging && rule.dynamicPrintArray == "Polybagging")) {
    //                         conditions[index] = true;
    //                         finishingItemsDisplay.push(rule.dynamicPrintArray)
    //                     }
    //                 }


    //                 // SHIPPING METHODS
    //                 if (rule.comparator == "equal_to" && rule.printCategory == "Shipping Method" && rule.dynamicPrintArray == "Indiviual Shipment" && finishingItem.actions && finishingItem.actions[0] && (finishingItem.actions[0].addOrSubtract == "add" || finishingItem.actions[0].addOrSubtract == "subtract") && finishingItem.actions[0].value) {

    //                     if (cloneorder?.splitAddresses && cloneorder?.splitAddresses.length > 1) {
    //                         cloneorder?.splitAddresses?.forEach((address, i) => {
    //                             if (address.method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
    //                                 individualShipmentGarmentQuantityForFinishing += this.orderService.getTotalQuantityOnEachSplitAddresses(address?.shirts, cloneorder?.shirts);
    //                                 // conditions[index] = true;
    //                                 finishingItemsDisplay.push(finishingItem.key);
    //                                 let price = finishingItem.actions[0].value || 0;
    //                                 let obj = allPrintRules.find((x) => x.key == finishingItem.key);
    //                                 if (obj) {
    //                                     obj['quantity'] = individualShipmentGarmentQuantityForFinishing;
    //                                 } else {
    //                                     allPrintRules.push({ key: finishingItem.key, label: finishingItem.key, quantity: individualShipmentGarmentQuantityForFinishing, value: price, type: "Finishings" });
    //                                 }
    //                             }
    //                         });
    //                     } else if (cloneorder?.splitAddresses && cloneorder?.splitAddresses.length == 1) {
    //                         if (cloneorder?.splitAddresses[0].method == this.commonAPIService.INDIVIDUAL_SHIPPING) {
    //                             individualShipmentGarmentQuantityForFinishing = this.orderService.getTotalQuantity(cloneorder);
    //                             // conditions[index] = true;
    //                             finishingItemsDisplay.push(finishingItem.key);
    //                             let price = finishingItem.actions[0].value || 0;
    //                             let obj = allPrintRules.find((x) => x.key == finishingItem.key);
    //                             if (obj) {
    //                                 obj['quantity'] = individualShipmentGarmentQuantityForFinishing;
    //                             } else {
    //                                 allPrintRules.push({ key: finishingItem.key, label: finishingItem.key, quantity: individualShipmentGarmentQuantityForFinishing, value: price, type: "Finishings" });
    //                             }
    //                         }
    //                     }
    //                 }

    //                 // LICENCING
    //                 if (rule.comparator == "equal_to" && rule.printCategory == "Licensing" && finishingItem.actions && finishingItem.actions[0] && (finishingItem.actions[0].addOrSubtract == "add" || finishingItem.actions[0].addOrSubtract == "subtract") && finishingItem.actions[0].location == "piece" && finishingItem.actions[0].value) {
    //                     let greekLineItem = false;
    //                     if (cloneorder.greekLicensing) {
    //                         if (rule.dynamicPrintArray == "Greek: Exec Member" && order.greekLicensingStatus == "greek_exec_member") {
    //                             greekLineItem = true;
    //                         } else if (rule.dynamicPrintArray == "Greek: Non-Exec Member" && order.greekLicensingStatus == "greek_non_exec_member") {
    //                             greekLineItem = true;
    //                         } else if (rule.dynamicPrintArray == "CHAARG" && order.greekLicensingStatus == "CHAARG") {
    //                             greekLineItem = true;
    //                         }
    //                         if (greekLineItem) {
    //                             conditions[index] = true;
    //                             // finishingItemsDisplay.push(finishingItem.key);
    //                         }
    //                     }
    //                 }
    //             })
    //             let allConditionFlag = true
    //             for (let con = 0; con < finishingItem.rules.length; con++) {
    //                 if (conditions[con] != true) {
    //                     allConditionFlag = false
    //                     break;
    //                 }
    //             }
    //             let labelKey = finishingItem.key;
    //             let label = finishingItem.label;
    //             if (allConditionFlag) {


    //                 let price = finishingItem.actions[0].value || 0

    //                 this.removeItem(lintedItems, labelKey);
    //                 formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, label, price);
    //             } else {

    //                 // _.remove(finishingItemsDisplay, (item) => {
    //                 //       return item == labelKey;
    //                 //     })
    //                 // order.finishingItemsDisplay = finishingItemsDisplay;
    //                 // this.removeItem(lintedItems, labelKey);

    //             }
    //         })

    //         // TURNAROUND ITEMS
    //         if (cloneorder && cloneorder.turnAround && cloneorder.turnAround.hasOwnProperty('id')) {
    //             let totalPrice = cloneorder.totalQuantity * cloneorder.turnAround.value || 0;
    //             let turnAroundValue = cloneorder.turnAround.value || 0;
    //             let turnAroundPercentage = cloneorder.turnAround.amount || 0;
    //             turnAroundLineItem.push({category: 'Turnaround', description: 'Turnaround', key: 'Turnaround', label: 'Turnaround', location: null, quantity: cloneorder.totalQuantity, total: totalPrice, type: "Dynamic Rules", value: turnAroundValue, percent: turnAroundPercentage});
    //         }

    //         // only finishing items will show in finishingItemsDisplay

    //         let UniqueFinishingItemsDisplay = [...new Set(finishingItemsDisplay)];
    //         order.finishingItemsDisplay = UniqueFinishingItemsDisplay;

    //         let artworkFee = this.getArtworkFee(order);
    //         shirt.totalCostPerShirt = parseFloat(shirt.perShirtCost + shirt.pricePerPrint + artworkFee + (shirt.additionalCost ? shirt.additionalCost : 0)).toFixed(2);
    //         shirt.totalCost = (parseFloat(shirt.totalCostPerShirt) * shirt.totalQty).toFixed(2);
    //     })

    //     if (eventIsTrue == true) {
    //         let colorCount = 0;
    //         let underbaseCount = 0;

    //         _.forEach(this.getLocations(order), (location: any) => {
    //             if (order.artwork.positions[location].colors) {
    //                 colorCount += parseInt(order.artwork.positions[location].colors);
    //                 underbaseCount += parseInt(order.artwork.positions[location].underbase);
    //                 // underbaseCount++;
    //             }
    //         });
    //         console.log("this.initialProductionPartnerId", this.initialProductionPartnerId);
    //         // let ppMemberData = await this.commonAPIService.getPpMemberByUserUID(this.initialProductionPartnerId);
    //         // let ppCompanyId = ppMemberData['ppCompanyId'];
            
    //         // let screenPrice = this.getScreenItemPrice('Screens')
    //         let screenPrice = this.getNewScreenItemPrice('Screens')
    //         let screenLintedPrice;
    //         if (estimationCall) {
    //             screenLintedPrice = this.getLintedItemPrice(order.lintedItems, "Screens", order?.productionPartner?.user_id)
    //         } else {
    //             screenLintedPrice = this.getLintedItemPrice(order.lintedItems, "Screens", this.initialProductionPartnerId)
    //         }
    //         // order.lintedItems.push({ key: 'Screens', label: 'Screens', quantity: colorCount + underbaseCount, value: screenLintedPrice ? screenLintedPrice : screenPrice })
    //         allPrintRules.push({ key: "Screens", label: "Screens", quantity: colorCount + underbaseCount, value: screenLintedPrice ? screenLintedPrice : screenPrice, type: "Screen" })
    //     }

    //     _.forEach(order.shirts, (shirt, key) => {
    //         for (let location of this.getLocations(order)) {
    //             if (order.cost[location] && shirt.color) {
    //                 let color;
    //                 if (order.artwork.positions[location].type != "Embroidery" && order.cost[location].type != "Vinyl") {
    //                     color = 'Colors: ' + (order.artwork.positions[location].type == "Direct to Garment" ? 'DTG' : (parseInt(order.artwork.positions[location].colors) + parseInt(order.artwork.positions[location].underbase)).toString());
    //                     let lebal = location + ' - ' + color;
    //                     let price = order.cost[location].pricePerPrint || 0;
    //                     let printPrice = this.getLintedItemPrice(order.lintedItems, lebal);
    //                     price = printPrice > 0 ? printPrice : price;
    //                     formatedLintedItems[location] = this.getLintedItem(formatedLintedItems[location], shirt, lebal, lebal, price);
    //                 } else if (order.cost[location].type == "Vinyl") {
    //                     let lebal = location + ' - ' + order.cost[location].lable;
    //                     let price = order.cost[location].pricePerPrint || 0;
    //                     formatedLintedItems[location] = this.getLintedItem(formatedLintedItems[location], shirt, lebal, lebal, price);
    //                 } else {
    //                     color = 'Stitches: ' + order.artwork.positions[location].totalStich;
    //                     let lebal = location + ' - ' + color;
    //                     let price = order.cost[location].pricePerPrint || 0;
    //                     formatedLintedItems[location] = this.getLintedItem(formatedLintedItems[location], shirt, lebal, lebal, price);
    //                 }
    //                 // let itemName = location + ' - ' + color + ' - Item #' + shirt.style.styleName + ' - ' + shirt.color.colorName;

    //                 // this.removeItem(order.lintedItems, formatedLintedItems[location].lebal);

    //                 // this.removeItem(order.lintedItems, itemName);
    //             }
    //             // if(order.cost[location].type == "Vinyl") {
    //             //   let lebal = location + ' - ' + order.cost[location].lable;
    //             //   let price = order.cost[location].pricePerPrint;
    //             //   allPrintRules.push({ key: lebal, label: lebal, quantity: order.shirtCount, value: price, type: "Vinyl" })
    //             // }
    //         }
    //         let pocketPrintTotalQty = 0;
    //         let pocketPrintLintedPrice = this.getLintedItemPrice(lintedItems, "Pocket Print");
    //         for (let i = 0; i < order.shirts?.length; i++) {
    //             if (order.shirts[i]?.style?.categories.includes('Pockets')) {
    //                 pocketPrintTotalQty += order.shirts[i]?.totalQty;
    //                 let price = pocketPrintLintedPrice ? pocketPrintLintedPrice : this.getPocketPrintPrice(order, 'Pocket Print')
    //                 let labelKey = 'Pocket Print';
    //                 formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, labelKey, price);
    //                 if (!formatedLintedItems[labelKey].value) {
    //                     formatedLintedItems[labelKey] = {};
    //                 }
    //             }
    //             else {
    //                 this.getLintedItemPrice(lintedItems, "Pocket Print");
    //             }
    //         }
    //         if (order?.lintedItems && order?.lintedItems?.length) {
    //             var pocketObj = order?.lintedItems.slice().reverse().find(pocketObj => pocketObj.label === 'Pocket Print');
    //             if (pocketObj && pocketObj?.quantity) {
    //               let half = pocketObj?.quantity / order?.shirts?.length;
    //               pocketObj.quantity = half * order?.shirts?.length;
    //               order.lintedItems = order?.lintedItems.filter((item) => item.label != 'Pocket Print');
    //               order.lintedItems.push(pocketObj);
    //             }
    //         }
    //     });

    //     newLintedItems = lintedItems;
    //     for (let key in formatedLintedItems) {
    //         let litem = formatedLintedItems[key];

    //         newLintedItems.push({ key: litem.key, label: litem.lebal, quantity: litem.quantity, value: litem.value });
    //     }

    //     if (order.lintedItems) {
    //         order.lintedItems.splice(0, order.lintedItems.length)
    //     } else {
    //         order.lintedItems = [];
    //     }

    //     order.lintedItems.push(...newLintedItems);
    //     order.lintedItems.push(...additionalItems);
    //     order.lintedItems.push(...turnAroundLineItem);
    //     order.shirtCount = order.totalQuantity = cloneorder.shirtCount;
    //     // order.lintedItems = _.uniqBy(allPrintRules, printRule => [printRule.key, printRule.type].join());
    //     let uniqueAllPrintRules = _.uniqBy(allPrintRules, printRule => [printRule.key, printRule.type].join());
    //     for (let index = 0; index < uniqueAllPrintRules.length; index++) {
    //         let litem = uniqueAllPrintRules[index];
    //         order.lintedItems.push({ key: litem.key, label: litem.label, quantity: litem.quantity, value: litem.value });
    //     }

    //     // // all inted items display in finishingItemsDisplay
    //     // let allLintedItemKeys = order.lintedItems.map((item)=> {
    //     //   return item.key 
    //     // })
    //     // order.finishingItemsDisplay = allLintedItemKeys

    //     this.holdCurrentLineItem.next(order.lintedItems);
    //     console.log("order........", _.cloneDeep(order));
    //     // for (let key in formatedLintedItems) {
    //     //   let litem = formatedLintedItems[key];
    //     //   order.lintedItems.push({ key: litem.key, label: litem.lebal, quantity: litem.quantity, value: litem.value });
    //     // }

    //     if (estimationCall === true) {
    //         this.updateTotal(order, true)
    //     } else {
    //         this.updateTotal(order)
    //     }

    //     if (spNotify != null)
    //         spNotify.next(null);
    // });

    // }

    async updateTotal(order, estimationCall = false) {
        let pocketPrintTotalQty = 0;
        let cloneorder = _.cloneDeep(order);
        let newLintedItems = [];
        let lintedItems = []
        let formatedLintedItems = {};
        if (cloneorder.lintedItems) {
            lintedItems = _.cloneDeep(cloneorder.lintedItems);
        }

        // if((this.finishingItems && this.finishingItems[0]?.isDeletedany) || (this.screenItems && this.screenItems[0]?.isDeletedany) || (this.dtgItems && this.dtgItems[0]?.isDeletedany) || (this.embItems && this.embItems[0]?.isDeletedany) ){
        //   this.finishingItems[0].isDeletedany = false
        //   this.embItems[0].isDeletedany = false
        //   this.screenItems[0].isDeletedany = false
        //   this.dtgItems[0].isDeletedany = false
        //   this.imagesChanged(order)
        // }

        cloneorder.shirtCount = cloneorder.totalQuantity = _.reduce(cloneorder.shirts, (accum, shirt: any) => {
            return accum + shirt.totalQty
        }, 0);

        let shirtData: any;
        _.forEach(cloneorder.shirts, (shirt, key) => {
            let finishingItemsDisplay = [];
            shirt.greekLicensing = 0;
            order.shirts[key].greekLicensing = 0;

            // let shippingCost: number = cloneorder.shippingCost ? (cloneorder.shippingCost / cloneorder.totalQuantity) : 0;

            let shippingCost = 0;
            if (cloneorder.shippingOption && cloneorder.shippingOption.shipping_amount && cloneorder.shippingOption.shipping_amount.amount) {
                shippingCost = (cloneorder.shippingOption.shipping_amount.amount / cloneorder.totalQuantity);
            } else {
                shippingCost = 0;
            }

            shirt.shippingCost = shippingCost;
            order.shirts[key].shippingCost = shippingCost;

            if (cloneorder.greekLicensing) {
                shirt.setNewGrossMargin = false;
                order.shirts[key].setNewGrossMargin = false;
                if (order.greekLicensingStatus == 'CHAARG') {
                    if (shirt.chaargPercentage === null) {
                        shirt.greekLicensing = shirt.customerPrice * 0.15;
                        order.shirts[key].greekLicensing = shirt.customerPrice * 0.15;
                        order.shirts[key].chaargPercentage = 15;
                    } else if (!shirt.hasOwnProperty('chaargPercentage')) {
                        if (order.shirts.some((shirt) => (shirt.chaargPercentage == 15 || shirt.chaargPercentage === null))) {
                            shirt.greekLicensing = shirt.customerPrice * 0.15;
                            order.shirts[key].greekLicensing = shirt.customerPrice * 0.15;
                            order.shirts[key].chaargPercentage = 15;
                        } else {
                            shirt.greekLicensing = shirt.customerPrice * 0.20;
                            order.shirts[key].greekLicensing = shirt.customerPrice * 0.20;
                            order.shirts[key].chaargPercentage = 20;
                        }
                    } else {
                        shirt.greekLicensing = shirt.customerPrice * (shirt.chaargPercentage / 100);
                        order.shirts[key].greekLicensing = shirt.customerPrice * (shirt.chaargPercentage / 100);
                    }
                } else {
                    shirt.greekLicensing = shirt.customerPrice * 0.095
                    order.shirts[key].greekLicensing = shirt.customerPrice * 0.095;
                }
            }




            // let greekLicensingStickerLintedPrice = this.getLintedItemPrice(lintedItems, "Greek Licensing Sticker")
            // let individualShippmentLintedPrice = this.getLintedItemPrice(lintedItems, "Individual Fulfillment")
            //let hangTagLintedPrice = this.getLintedItemPrice(lintedItems, "Hang Tag")
            // let sizeStickerLintedPrice = this.getLintedItemPrice(lintedItems, "Size Sticker")
            // let opcShortSleeveLintedPrice = this.getLintedItemPrice(lintedItems, "Poly Bagging, Folding & Customer Name/Size Sticker – Short Sleeve")
            // let opgLongSleeveLintedPrice = this.getLintedItemPrice(lintedItems, "Poly Bagging, Folding & Customer Name/Size Sticker – Long Sleeve")
            // let opShortSleeveLintedPrice = this.getLintedItemPrice(lintedItems, "Poly Bagging & Folding – Short Sleeve")
            // let opLongSleeveLintedPrice = this.getLintedItemPrice(lintedItems, "Poly Bagging & Folding – Long Sleeve")
            // let oLongSleeveLintedPrice = this.getLintedItemPrice(lintedItems, "Folding – Short Sleeve")
            // let oShortSleeveLintedPrice = this.getLintedItemPrice(lintedItems, "Folding – Long Sleeve")
            let pocketPrintLintedPrice = this.getLintedItemPrice(lintedItems, "Pocket Print");
            shirtData = shirt;

            if (pocketPrintTotalQty > 0) {
                pocketPrintTotalQty = 0;
            }

        for (let i = 0; i < order.shirts?.length; i++) {
            if (order.shirts[i]?.style?.categories.includes('Pockets')) {
              pocketPrintTotalQty += order.shirts[i]?.totalQty;
              let price = pocketPrintLintedPrice ? pocketPrintLintedPrice : this.getPocketPrintPrice(order, 'Pocket Print')
               // if (!pocketPrintLintedPrice) {
                    let labelKey = 'Pocket Print';
                   // this.removeItem(lintedItems, labelKey);
                    formatedLintedItems[labelKey] = this.getLintedItem(formatedLintedItems[labelKey], shirt, labelKey, labelKey, price);
               // }
            }
            else {
                this.getLintedItemPrice(lintedItems, "Pocket Print")
            }
          }
        })

        newLintedItems = lintedItems;
        for (let key in formatedLintedItems) {
            let litem = formatedLintedItems[key];

            if (litem?.value) {
                newLintedItems.push({ key: litem.key, label: litem.lebal, quantity: pocketPrintTotalQty, value: litem?.value });
            }
        }

        if (order.lintedItems) {
            order.lintedItems.splice(0, order.lintedItems.length)
        } else {
            order.lintedItems = [];
        }

        newLintedItems.forEach(element => {
            if (element.key) {
                order.lintedItems.push(element)
            }
        });
        this.removeDiplicateFromArrayOfObjects(order);
        // order.lintedItems.push(...newLintedItems);
        order.shirtCount = order.totalQuantity = cloneorder.shirtCount;

        _.forEach(order.shirts, async (shirt) => {

            // shirt.subtotal = _.reduce(lintedItems, (accum, item: any) => {
            //     return accum + (item.quantity * item.value)
            // }, 0) / cloneorder.totalQuantity;
            let subTotal = await this.getArtWorkEstimatedCost(order);
            
            shirt.subtotal =  subTotal;

            if (order?.lintedItems && order?.lintedItems?.length) {
                var pocketObj = order?.lintedItems.slice().reverse().find(pocketObj => pocketObj.label === 'Pocket Print');
                if (pocketObj && pocketObj?.quantity) {
                  let half = pocketObj?.quantity / order?.shirts?.length;
                  pocketObj.quantity = half * order?.shirts?.length;
                  order.lintedItems = order?.lintedItems.filter((item) => item.label != 'Pocket Print');
                  order.lintedItems.push(pocketObj);
                }
              }

            shirt.subtotal = shirt.subtotal || 0;

            let artworkFee = this.getArtworkFee(order);
            shirt.totalCostPerShirt = (shirt.perShirtCost ? shirt.perShirtCost : 0) +
                (shirt.subtotal ? shirt.subtotal : 0) +
                (shirt.shippingCost ? shirt.shippingCost : 0) +
                (shirt.greekLicensing ? shirt.greekLicensing : 0) +
                artworkFee;
            shirt.totalCostPerShirt = Math.round(shirt.totalCostPerShirt * 100) / 100

            shirt.totalCost = shirt.totalCostPerShirt * shirt.totalQty
        })
        order.totalCost = Number(_.reduce(order.shirts, (accum, shirt) => {
            return accum + shirt.totalCost;
        }, 0)).toFixed(2);
        
        order.totalCost = Number(order.totalCost);
        order.cost.totalCost = order.totalCost;
        if ((order?.salesPartner?.permissionLevel == this.commonAPIService.USER_SP || order?.salesPartner?.permissionLevel == this.commonAPIService.USER_SELLER) && (typeof (order?.$key) == 'undefined' || order?.commission == 'commission')) {
            order.commission = this.salescommission;
        }
        this.orderService.setLineItemsWithMarkup.next(true);
        if (this.commonAPIService.assignLineItemFlag) {
            this.assignLineItem();
        }
        if (estimationCall === true) {
            this.commonService.emitUpdateTotalEvent(true);
        }
    }

    getScreenFee(initialProductionPartnerId){
        let screenFee = this.commonAPIService.fnGetScreenFees(initialProductionPartnerId); 
        return screenFee;
    }

    assignLineItem() {
        this.assignRecentlyUpdatedLineItems.next(true);
        this.commonAPIService.assignLineItemFlag = false;
    }

    removeDiplicateFromArrayOfObjects(order) {
        if (order && order.lintedItems && order.lintedItems.length) {
            let lintedItems = order.lintedItems;
            let uniqueLintedItems = lintedItems.filter((thing, index, self) =>
                index == self.findIndex((t) => (
                    t.key == thing.key
                ))
            )
            order.lintedItems = uniqueLintedItems;
        }
    }

    removeFoldingPolybagging(lintedItems, order) {
        let labelKey1 = 'Poly Bagging, Folding & Customer Name/Size Sticker – Short Sleeve';
        let labelKey2 = 'Poly Bagging, Folding & Customer Name/Size Sticker – Long Sleeve';
        let labelKey3 = 'Poly Bagging & Folding – Short Sleeve';
        let labelKey4 = 'Poly Bagging & Folding – Long Sleeve';
        let labelKey5 = 'Folding – Short Sleeve';
        let labelKey6 = 'Folding – Long Sleeve';

        this.removeItem(lintedItems, labelKey1);
        this.removeItem(lintedItems, labelKey2);
        this.removeItem(lintedItems, labelKey3);
        this.removeItem(lintedItems, labelKey4);
        this.removeItem(lintedItems, labelKey5);
        this.removeItem(lintedItems, labelKey6);
    }

    getLintedItemPrice(lintedItems, item, initialProductionPartnerId = null) {
        if (item === 'Screens' && initialProductionPartnerId) {
            return this.getScreenFee(initialProductionPartnerId);
        }
        for (let i = 0; i < lintedItems.length; i++) {
            if (item == lintedItems[i].key) {
                let price = lintedItems[i].value || 0;
                //lintedItems.splice(i,1);
                return price;
            }
        }
        return 0;
    }

    getLintedItemQty(lintedItems, item) {
        for (let i = 0; i < lintedItems.length; i++) {
            if (item == lintedItems[i].key) {
                let price = lintedItems[i].quantity;
                return price;
            }
        }
        return 0;
    }

    removeItem(lintedItems, item) {
        for (let i = 0; i < lintedItems.length; i++) {
            if (item == lintedItems[i].key) {
                lintedItems.splice(i, 1);
            }
        }
    }


    isFleeceAndHoodie(category) {
        return this.fleeceAndHoodieCategories.indexOf(category) > -1 || /Performance.*/.test(category);
    }

    isPolyesterPrint(category) {
        return this.polyesterCategories.indexOf(category) > -1 || /Performance.*/.test(category);
    }

    getLocations(order): string[] {
        return _.compact(_.map(order.artwork.positions, (image, key) => {
            if (key.toString() != "cost" && key.toString() != 'mainLocation' && key.toString() != 'Artwork Approval') {
                return key.toString()
            }
        }));
    }

    isShortSleeve(category) {
        if (_.isArray(category)) {
            return (this.shortSleeve.some(cat => category.indexOf(cat) > -1) && this.notShortSleeve.some(cat => category.indexOf(cat) == -1));
        } else {
            return (this.shortSleeve.indexOf(category) > -1 && this.notShortSleeve.indexOf(category) == -1);
        }
    }

    isLongSleeve(category) {
        if (_.isArray(category)) {
            return this.longSleeve.some(cat => category.indexOf(cat) !== -1);
        } else {
            return this.longSleeve.indexOf(category) > -1;
        }
    }

    isShortSleevePocket(category) {
        return 'Short Sleeve Pocket' == category;
    }

    isLongSleevePocket(category) {
        return 'Long Sleeve Pocket' == category;
    }

    getFinishingPrice(finishing) {
        if (this.finishingItems) {
            for (let item of this.finishingItems) {
                if (item.key == finishing) {
                    return item.value;
                }
            }
        }
        return 0;
    }

    getNewFinishingPrice(finishing, actionIndex) {
        if (this.finishingItems) {
            for (let item of this.finishingItems) {
                if (item.key == finishing) {
                    return item.actions[actionIndex].value;
                }
            }
        }
        return 0;
    }

    getPocketPrintPrice(order, key) {
        if (order.artwork.artwork_location_id && order?.artwork?.positions['Front-Pocket']?.mainLocation == "Front-Pocket") {
            if (this.screenItems) {
                for (let item of this.screenItems) {
                    if (item.key == key) {
                        return item.actions[0]?.value;
                    }
                }
            }
            return 0;
        } else {
            return 0;
        }
    }

    getScreenItemPrice(key) {
        if (this.screenItems) {
            for (let item of this.screenItems) {
                if (item.key == key) {

                    return item.value;
                }
            }
        }
        return 0;
    }
    getNewScreenItemPrice(key) {
        if (this.screenItems) {
            for (let item of this.screenItems) {
                if (item.key == key) {

                    return item.actions[0].value;
                }
            }
        }
        return 0;
    }

    getDTGItemPrice(key) {
        for (let item of (this.dtgItems || [])) {
            if (item.key == key) {
                return item.value;
            }
        }
        return 0;
    }
    getEMBItemPrice(key) {
        for (let item of this.embItems) {
            if (item.key == key) {
                return item.value;
            }
        }
        return 0;
    }

    getEmbItemPrice() {

    }

    async getEstimatedPrintingCost(order) {
        let turnaroundPercentage = 0;
        const turnAroundItem = order.lintedItems.find((item) => item.key === 'Turnaround')
        if (turnAroundItem) {
            turnaroundPercentage = (turnAroundItem.percent) ? turnAroundItem.percent : 0;
        }        
        const filteredItems = order.lintedItems.filter(item => item.key !== 'Turnaround');
        const resLineItem = filteredItems.reduce((accum, item) => accum + (item.quantity * Number(item.value)), 0) / order.totalQuantity;
        return (Number(((turnaroundPercentage / 100) * resLineItem))) + resLineItem;
      }
      

    async getArtworkModalEstimatedPrintingCost(order) {
        let filteredItems = order.lintedItems.filter(item => item.key !== 'Turnaround');
        const resLineItem = filteredItems.reduce((accum, item) => accum + (item.quantity * Number(item.value)), 0) / order.totalQuantity;
        return Number(resLineItem);
    }

    validateArtworkFormArray(formArray: FormArray) {

        let isValid = true;
        for (let fg of formArray.controls) {
            isValid = this.validateForm((fg as FormGroup));

            if (isValid === false)
                break;
        }
        return isValid;
    }

    validateForm(fg: FormGroup): boolean {
        return fg.valid;
    }

    async updatePrintingCost(order) {
        let cost: any = {};
        let shirtCount = _.reduce(order?.shirts, (total, shirt: any) => {
            return total + shirt.totalQty;
        }, 0)        

        _.forEach(this.priceScreenList, (screenPriceEntry) => {
            // Verify if the chosen quantity falls within the pricing matrix's minimum and maximum limits for screen printing.
            if (screenPriceEntry.expression(shirtCount)) {

                // Iterate through each artwork position in the order
                _.forEach(order.artwork.positions, (image, location) => {

                    // Skip specific conditions for 'Artwork Approval', 'Vinyl', 'Embroidery', 'Direct-to-Garment', and empty types
                    if (location === 'Artwork Approval' || image.type == "Vinyl" || image.type == this.commonAPIService.EMBROIDERY_PRINTING || image.type == this.commonAPIService.DIRECT_TO_GARMENT_PRINTING || image.type == "") {
                        return;
                    }
                    
                    // Initialize variables for screen printing price and color count
                    var spPrice = 0;
                    let colorCount = 1;

                    // Calculate color count considering underbase if available
                    if (image.colors && Number(image.underbase) >= 0) {
                        colorCount = Number(image.colors) + Number(image.underbase);
                    }

                    // Check if screen printing price entry and color-specific price are available
                    if (screenPriceEntry && screenPriceEntry.prices && screenPriceEntry.prices[colorCount - 1] && screenPriceEntry.prices[colorCount - 1].value) {
                        // Set screen printing price if available
                        spPrice = parseFloat(screenPriceEntry.prices[colorCount - 1].value);
                        cost[location] = {};
                    } else {
                        // Set screen printing price to 0 if not available
                        spPrice = 0;
                        cost[location] = {};
                    }

                     // Handle special case for zero or NaN screen printing price with SCREEN_PRINTING type
                    if ((isNaN(spPrice) || spPrice == 0) && image.type == this.commonAPIService.SCREEN_PRINTING) {
                        
                        if (this.initialProductionPartnerId === order.production_partner_id) {
                            // Emit event to convert screen printing to DTG
                            const emitObject = {
                                status: true,
                                priceScreenList: this.priceScreenList,
                            }
                            this.convertScreenPrintToDTG.next(emitObject);
                        } else {
                             // Set type and print method for Direct-to-Garment printing
                            image.type = 'Direct to Garment';
                            image.print_method_id = 2;
                        }
                        return cost;
                    }

                    // Set screen printing details in the cost object if the type is SCREEN_PRINTING
                    if (image.type == this.commonAPIService.SCREEN_PRINTING) {
                        cost[location].pricePerPrint = spPrice;
                        order.artwork.positions[location].pricePerPrint = spPrice;
                        cost[location].screenPrinting = true;
                    }
                    
                    // Set the type of the image for the current location
                    cost[location].type = image.type;
                });
            }
        });

        _.forEach(this.priceDirectList, (directPriceEntry) => {
            // Will check if the selected quaniitay is between to max and min limit of the pricing matrix
            if (directPriceEntry.expression(shirtCount)) {
                // Iterate through each artwork position in the order
                _.forEach(order.artwork.positions, (image, location) => {

                    // Skip specific conditions for 'Artwork Approval', 'Vinyl', 'Embroidery', 'Screen Printing', and empty types
                    if (location === 'Artwork Approval' || image.type == "Vinyl" || image.type == this.commonAPIService.EMBROIDERY_PRINTING || image.type == this.commonAPIService.SCREEN_PRINTING || image.type == "") {
                        return;
                    }
                    let dtgPrice = 0;
                    if (directPriceEntry.prices[1]) {
                        dtgPrice = parseFloat(directPriceEntry.prices[1].value);
                    } else {
                        dtgPrice = parseFloat(directPriceEntry.prices[0].value);
                    }
                    
                    // Initialize cost object for the current location
                    cost[location] = {};

                     // Check if the image type is Direct-to-Garment (DTG) printing
                    if (image.type == this.commonAPIService.DIRECT_TO_GARMENT_PRINTING) {
                        cost[location].pricePerPrint = dtgPrice;
                        order.artwork.positions[location].pricePerPrint = dtgPrice;
                        cost[location].screenPrinting = false;
                    }

                    // Set the type of the image for the current location
                    cost[location].type = image.type;
                });
            }
        });

        _.forEach(this.priceEmbList, (embPriceEntry) => {
            // Verify if the chosen quantity falls within the pricing matrix's minimum and maximum limits for embroidery.
            if (embPriceEntry.expression(shirtCount)) {

                _.forEach(order.artwork.positions, (image, location) => {
                    if (location === 'Artwork Approval' || image.type == "Vinyl" || image.type == "Direct to Garment" || image.type == "Screen Printing" || image.type == "") {
                        return;
                    }
                    let embCost = 0;
                    if (!isNaN(image.totalStich) && image.totalStich !== null && image.totalStich !== 0) {
                        embCost = this.priceForSwitches(embPriceEntry.prices, image.totalStich);
                    }

                    cost[location] = {};
                    cost[location].pricePerPrint = embCost;
                    order.artwork.positions[location].pricePerPrint = embCost;
                    cost[location].screenPrinting = false;
                    cost[location].totalStich = image.totalStich;
                    cost[location].type = image.type;
                });
            }
        });

        _.forEach(this.vinylItems, (vinylPriceEntry) => {
            _.forEach(order?.artwork?.positions, (image, location) => {
                if (image.type != 'Vinyl') {
                    return;
                }

                let lebal = "";
                if (image.customName == true && image.customNumber == true) {
                    lebal = 'Custom Names & Numbers';
                } else if (image.customName) {
                    lebal = 'Custom Names';
                } else if (image.customNumber) {
                    lebal = 'Custom Numbers';
                }

                let vnlygPrice = 0;
                let customNameNumber = this.vinylItems.find(({ key }) => key === 'customNameNumber');
                if (image.customNameNumber && customNameNumber && customNameNumber.value) {
                    vnlygPrice = customNameNumber.value;
                } else {
                    _.forEach(this.vinylItems, (vinly) => {
                        if (image[vinly.key] && vinly.value) {
                            vnlygPrice += parseFloat(vinly.value);
                        }
                    });
                }

                cost[location] = {};
                cost[location].pricePerPrint = vnlygPrice;
                order.artwork.positions[location].pricePerPrint = vnlygPrice;
                cost[location].lable = lebal;
                cost[location].customName = image.customName;
                cost[location].customNumber = image.customNumber;
                cost[location].type = image.type;
                image.label = lebal;
            });
        });

        cost.pricePerPrint = _.reduce(this.costLocations, (accum, location) => {
            const formattedLocation = location.split(' - ').join('-');
            if (!cost[formattedLocation]) {
                return accum;
            } else {
                return accum + parseFloat(cost[formattedLocation].pricePerPrint);
            }
        }, 0) || 0;
        
        cost.shirtCount = shirtCount;
        
        if (order) {
            cost.totalCost = cost.pricePerPrint * order.totalQuantity || 0;
        } else {
            cost.totalCost = 0;
        }
        
        return cost;        
    }

    priceForSwitches(priceItem: Array<any>, totalStitches) { 
        // let headers = this.findHeader();
        // for (let i = 0; i < headers.prices.length; i++) {
        //     if (headers.prices[i + 1] != undefined) {
        //         let low = +headers.prices[i].value;
        //         let high = +headers.prices[i + 1].value;
        //         if (totalStitches > low && totalStitches <= high) {
        //             console.log('priceItem[i+1].value 1 =', priceItem[i + 1].value);
        //             return priceItem[i + 1].value;
        //         }

        //         if (totalStitches <= low && i == 0) {
        //             console.log('priceItem[i+1].value 2 =', priceItem[i].value);
        //             return priceItem[i].value;
        //         }

        //     } else {
        //         console.log('priceItem[i+1].value 3 =', priceItem[i].value);
        //         return priceItem[i].value;
        //     }
        // }
        // return 0;
        for (let i = 0; i < this.embroideryHeaders.length; i++) {
            if (this.embroideryHeaders[i + 1] != undefined) {
                let low = +this.embroideryHeaders[i];
                let high = +this.embroideryHeaders[i + 1];
                if (totalStitches > low && totalStitches <= high) {
                    console.log('priceItem[i+1].value 1 =', priceItem[i + 1].value);
                    return priceItem[i + 1].value;
                }

                if (totalStitches <= low && i == 0) {
                    console.log('priceItem[i+1].value 2 =', priceItem[i].value);
                    return priceItem[i].value;
                }

            } else {
                console.log('priceItem[i+1].value 3 =', priceItem[i].value);
                return priceItem[i].value;
            }
        }
        return 0;
    }

    findHeader() {
        let header = this.priceEmbList.findIndex((row) => row.quantity == "header");
        return this.priceEmbList[header];
    }

    async getEmbTableandItemData() {
        let embroideryMethod = await this.commonAPIService.getPrintMethodByName(this.commonAPIService.EMBROIDERY_PRINTING);
        if (embroideryMethod) {
            let embroideryMethodId = embroideryMethod['print_method_id'];
            if (embroideryMethodId) {
                return new Promise((resolve) => {
                    if (this.embItems && this.embItems.length <= 0) {          
                        let embItems: any = this.commonAPIService.getPricingByPpCompanyAndPrintMethodId(embroideryMethodId, this.ppCompanyId);
                        this.EmbroideryItemLoad = true;
                        if (embItems && embItems.length > 0 && embItems[0]) {
                            this.embItems = embItems;
                            this.embItems = (this.embItems as Array<any>).filter((e: any) => {
                                if (e.key != "Stitches per Square Inch") {
                                    return e;
                                }
                            });
                        }
                        resolve(null);              
                    } else {
                      resolve(null);
                    }
                });
            }
        }
    }

    getArtworkFee(order) {
        if (order.artworkFee && order.totalQuantity) {
            return parseFloat((Number(order.artworkFee) / Number(order.totalQuantity)).toFixed(2));
        }
        return 0.0;
    }

}
