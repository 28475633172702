import { Directive, ElementRef, OnInit } from '@angular/core';
declare const window: any;
@Directive({
  selector: '[fit-image]'
})
export class FitImageDirective implements OnInit{

  constructor(private el:ElementRef) { }

  ngOnInit(){
    // setTimeout(()=>{
    //   console.dir(this.el.nativeElement)
    //   let {parentNode} = this.el.nativeElement;
    //   let height = parentNode.clientHeight;
    //   this.el.nativeElement.attributes["height"].value = height + "px";
    // },50)
  }

}
