import {
  CanActivate,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AngularFireAuth } from "@angular/fire/auth";
import { AuthService } from 'app/utils/services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private af: AngularFireAuth, private router: Router, private authService:AuthService ) { }

  canActivate(): Observable<boolean> {
    return new Observable((observer) => {
      console.log('come here for checking');
      this.af.user.subscribe(user => {
         
        if (user != null)
        return  observer.next(true);
        else

      // var isProfileComplete  =  this.authService.isProfileComplete();
      
      // if(!isProfileComplete) {
      //   this.router.navigate(['profile/edit']);
      // }
      // return observer.next(true);

      this.router.navigate(['/'])
      return false;
      }
      );
    });

  }
}
