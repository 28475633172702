import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { AuthService } from 'app/utils/services/auth.service';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit,AfterViewInit {

  isSidebarOpen:boolean = true;
  brands = [
    'Gildan',
    'Bella+Canvas',
    'Comfort Colors',
    'Next Level',
    'American Apparel',
    'Champion',
    'Jerzees',
    'Yupoong',
    'Adidas',
    'Augusta Sportswear'
  ];
  categories = [
    'Short Sleeve',
    'Long Sleeve',
    'Tank Tops',
    'Crewneck',
    'Hooded',
    'Headwear',
    'Full-Zips',
    'Quarter Zips',
    '3/4 Sleeve',
    'Face Masks'
  ];

  constructor(
    private sidebarService:SidebarService,
    private authService:AuthService,
    private router: Router
    ) { }

  ngAfterViewInit(): void {
    // $('.collapse').collapse()
  }
  
  loadmenu() {
    $('.collapse').collapse()
  }

  closeSidebar(){
    this.sidebarService.toggle();
  }

  ngOnInit() {

    this.sidebarService.subscribe.subscribe( res => {
        this.isSidebarOpen = res;
    });
    this.brands.sort();
    this.categories.sort();
  }

  searchProducts(searchValue = '') {
    if ($.trim(searchValue) != '') {
      this.router.navigateByUrl("products?searchString=" + $.trim(searchValue));
    } else {
      alert('Please enter value');
    }
  }

  routeTOStartAnOrder(){
    this.router.navigate(['/start-an-order']).then(()=>{
      window.location.reload()
    })
  }
  
}
