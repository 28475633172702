import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SidebarService {
  observal:Subject<boolean> = new Subject();
  subscribe  = this.observal.asObservable();
  sidebarStatus:boolean = false;
  constructor() { }

  toggle() {
     this.sidebarStatus = !this.sidebarStatus;
     this.observal.next(this.sidebarStatus);
  }

}
