import { Injectable } from '@angular/core';

@Injectable()
export class UserService {


  getDefaultPhoto(user) {
    if(user) {
      user.photo = '/assets/images/user-default.jpg';
    }
  }

  isDefaultPhoto(user) {
    return user.photo == '/assets/images/user-default.jpg';
  }

}
