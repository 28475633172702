import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeSpaceConvertUnderScopeLowerCase'
})
export class RemoveSpaceConvertUnderScopeLowerCasePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let str = value.replace(/[\s]/g, '_').replace(/[\/]/g, '~').toLowerCase();
    return str;
  }

}
