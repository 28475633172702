export const environment = {
  production: true,
  environmentName: 'qa',
  config: {
    apiKey: "AIzaSyCf2jrlenybRLM9vWEvWexxJxfS7fdPAqI",
    authDomain: "upmerch-qa.firebaseapp.com",
    databaseURL: "https://upmerch-qa.firebaseio.com",
    projectId: "upmerch-qa",
    storageBucket: "upmerch-qa.appspot.com",
    messagingSenderId: "379091642370",
    appId: "1:379091642370:web:d7669467b2db9f49849f1b",
    measurementId: "G-NXZYZ3B451",
    visiableAfter: "570",
    productionNewOrderEmailFunctionURL: "https://us-central1-upmerch-qa.cloudfunctions.net/SendToPPNewOrderEmails",
    carrier_ids: ["se-118242", "se-142819"], // USPS id removed: "se-116618"
    admin_uid: "WSUlOtlHamhIu2fFoTX6bB5ds302",
    // filtersproductscount: "http://localhost:8080/filtersproductscount",
    // garmentsfilters: "http://localhost:8080/garments-filters",
    // get_quote_sheet_fun_url: "https://script.google.com/macros/s/AKfycbzECndaUtW2ns8ahY1aCdCCdKGH5waBUUDwqTd0fbV3J4PrwEyvMqcY7w/exec",
    // proxy_url: "http://localhost:8080/",
    // notification_mark_all_as_read: "http://localhost:8080/notification-mark-all-as-read",
    filtersproductscount: "https://proxy-qa.upmerch.com/filtersproductscount",
    garmentsfilters: "https://proxy-qa.upmerch.com/garments-filters",
    get_quote_sheet_fun_url: "https://script.google.com/macros/s/AKfycbzECndaUtW2ns8ahY1aCdCCdKGH5waBUUDwqTd0fbV3J4PrwEyvMqcY7w/exec",
    proxy_url: "https://proxy-qa.upmerch.com/",
    notification_mark_all_as_read: "https://proxy-qa.upmerch.com/notification-mark-all-as-read",
    api_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
    start_an_order_page_url: "https://script.google.com/macros/s/AKfycbyPsGL9q5xk1tXp4iNULiAr--9Lgh7Tbo-DHtc98qz0YlIaY_NbDRAl3zC4NoOfLYr1-Q/exec",
    order_id_to_apply_processing_fee: 6176,
    default_markup: 30,
    // suppliersForNewDesign: ['S&S Activewear', 'SanMar'],
    suppliersForNewDesign: ['s&s_activewear','sanmar','alphabroder'],
    apparelCategories : ['Bags and Accessories','Decoration Supplies','Fleece Jackets','Headwear','Infants | Toddlers','Knits and Layering','Outerwear','Pants', 'Polos','T-Shirts', 'Sports | Outdoors','Sweatshirts | Fleece', 'Technology', 'Shorts','Woven Shirts'],
    defaultToAddress: {
      fname:'Kevin',
      lname:'Alsterda',
      phone:'312-813-0019',
      company:'UpMerch',
      country_code: "US",
      postal_code: "75202",
      city_locality: "Dallas",
      state_province: "TX",
      address1: "1000 Commerce St",
      email:'kevin@upmerch.com'
    },
    supplierCodes: [
      {
        name: 's&s_activewear',
        code: 'ss'
      },
      {
        name: 'sanmar',
        code: 'sm'
      },
      {
        name: 'alphabroder',
        code: 'ab'
      },
      {
        name: 'hit',
        code: 'ht'
      },
      {
        name: 'hit_promo',
        code: 'ht'
      },
      {
        name: 'leeds',
        code: 'pc'
      },
      {
        name: 'bullet',
        code: 'pc'
      },
      {
        name: 'trimark',
        code: 'pc'
      }
    ],
    stripe_public_key: 'pk_test_51P65W4Ii7wzq49wTPHHYRcSiELbzSFHwqeImRNLYmFlRYaTRlFmoKftiTWrifxCicsFrW2QkuYfvN1KnFhS5w65i00vKn09rYS',
    SELF_CHECKOUT_ACCESS_TOKEN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NjYzLCJlbWFpbCI6InZpcmF0LmdAY3Jlc3RpbmZvc3lzdGVtcy5jb20iLCJpYXQiOjE3MTA4NTE3NjUsImV4cCI6MTc0MjM4Nzc2NX0.U1Ymq0ez2bBf8E8i_J7Q5qxJl1AJ3TDlf6zl2CVj68U',
    SELF_CHECKOUT_USER_ID:663,
    tinyMceAPIKey: 'cmmxsdpeo8mlqemeqp9w3i87sbnwgru8dl5ey1dhc5cw12b1',
    SELF_CHECKOUT_GROSS_MARGIN: 0.40,
    harmonized_tariff_code: '6109.10.00',
    country_of_manufacture: 'US',
    country_of_origin: 'US',
    currency: 'usd',
    non_delivery: 'treat_as_abandoned',
    contents: 'merchandise',
  }
};