import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeZero'
})
export class RemoveZeroPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      if(value.toString().startsWith('$0.', 0) && value.toString() != '$0.00') {
        return value.toString().replace('$0.', '$.');
      }
      return value;
    }
  }

}
