import { Injectable } from '@angular/core';
import * as _ from "lodash";

import { CommonAPIService } from 'app/utils/services/common-api.service';

@Injectable({
    providedIn: 'root'
})

export class SizesService { 

    sizes: Array<any>;
    fetchedSizes: boolean = false;

    specs = {
        'Body Width': 'body_width',
        'Body Length': 'body_length',
        'Sleeve Length': 'sleeve_length',
        'Chest Width': 'chest_width',
        'Sizes (US)': 'sizes_us',
        'Body Length Back': 'body_length_back',
        'Body Length Front': 'body_length_front',
        'Measurements': 'measurements',
        'Body Length Tolerance': 'body_length_tolerance',
        'Body Width Tolerance': 'body_width_tolerance',
        'Across Shoulders': 'across_shoulders',
        'Across Shoulders Tolerance': 'across_shoulders_tolerance',
        'Inseam': 'inseam',
        'Waist': 'waist',
        'Waist Relaxed': 'waist_relaxed',
        'Hip': 'hip',
        'Length Tolerance': 'length_tolerance',
        'Outseam': 'outseam',
        'Waist Extended': 'waist_extended',
        'Waist Tolerance': 'waist_tolerance',
        'Sleeve Length (From Center Back)': 'sleeve_length_from_center_back',
        'Sizes (CA)': 'sizes_CA',
        'Armhole': 'armhole',
        'FRT HPS Length': 'FRT_HPS_length',
        'Length': 'length',
        'Bill Length': 'bill_length',
        'Width at bottom of band': 'width_at_bottom_of_band',
        'Imprint or Embroidery area': 'imprint_or_embroidery_area',
        'Neck Size': 'neck_size',
        'Nose Contour Height': 'nose_contour_height',
        'Nose Contour Width ': 'nose_contour_width ',
        'Width': 'width',
        'Bust': 'bust',
        'Neck Spec Size': 'neck_spec_size',
    };

    constructor (private commonAPIService: CommonAPIService) { }

    async getAllSizes() {
        this.commonAPIService.fnGetAllSizes().then((response: Array<any>) => {
            if (response && response.length) {
                this.sizes = response;
            }
            this.fetchedSizes = true;
        }).catch((error) => {
            this.sizes = [];
            this.fetchedSizes = true;
            console.error('getAllSizes fails');
        });
    }

    getSizeNames () {
        let order = {};
        _.forEach(this.sizes, function (s) {
            order[s.sizeName] = s.order;
        }); 
        return order;
    }

    async getSizeNamesList() {
      while (!this.fetchedSizes) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      let sizes =   _.orderBy(this.sizes, function (s) {
          return s.order;
        }); 
    
        let sizeNames = [];
    
        _.forEach(sizes,function(f){
             let strString = f.sizeName.replace(/[^a-zA-Z0-9 ]/g, "-");
             sizeNames.push(strString);
        });
        return sizeNames;
     }

    getFieldKeyByTitle(title) {
        return this.specs[title];
    }

    getFieldTitleByKey(key) {
        return Object.keys(this.specs).find(f => {
          return this.specs[f] == key;
        });
    }
    
    getSpecDataByColorsSize(colorsSize) {
    
        var rank = this.getSizeNames();
    
        colorsSize = _.sortBy(colorsSize, function (element) {
          return rank[element["sizeName"]];
        });
    
        let SpecArray = [];
        _.forEach(colorsSize, f => {
          _.forEach(this.specs, (value, key) => {
    
            if (f[value] != undefined && f[value] != '') {
              SpecArray.push({
                key: key,
                value: value
              });
            }
          });
        });
    
        SpecArray = _.uniqBy(SpecArray, 'key');
    
    
        _.forEach(SpecArray, spec => {
          let sizeSpecValue = [];
          _.forEach(colorsSize, f => {
    
            let value = f[spec.value] ? f[spec.value] : null;
            sizeSpecValue.push({
              sizeName: f['sizeName'],
              value: value
            });
          });
    
          spec.sizeSpecValue = sizeSpecValue;
        });
    
        return SpecArray;
      }

}