import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CommonAPIService } from './common-api.service';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ChatsService {

  isChatPanelOpen = false;
  isFullImageViewOpen = false;
  orderId: number;
  unreadMessages: number;
  showConfirmDelete = false;

  constructor(private commonAPIService: CommonAPIService,
    private authService: AuthService) {}
    private unreadMessageCountsFetched = false;
    
  async fetchAllUnreadMessagesCounts(){
    const response: any = await this.commonAPIService.fnFetchUnreadMessagesCount({userId:this.authService.getUID()});
    this.unreadMessages = response.count;
    this.unreadMessageCountsFetched = true;
  }

  areUnreadMessageCountsFetched() {
    return this.unreadMessageCountsFetched;
  }

  sendOrderStatusChangeMessage(oldStatus: string, newStatus: string, orderId: number) {
    // const message = `Changed order status from ${oldStatus} to ${newStatus}`;
    const temp_msg_id = uuid();
    const message = `${oldStatus} to ${newStatus}`;
    const user_id =  this.commonAPIService.fnGetUser()['id'];
    const statusMessage = {
      temp_msg_id,
      message,
      user_id,
      orderId,
      isStatusUpdate: true
    };

    return new Promise(async (resolve, reject) => {
      await this.commonAPIService.fnAddChatsMessages(statusMessage);
      resolve(true);
    });
  }

  sendArtworkRejectionReasonMessage(message, orderId) {
    const user_id =  this.commonAPIService.fnGetUser()['id'];
    const msgObj = {
      message,
      user_id,
      orderId,
      isArtworkRejectReason: true
    };
    this.commonAPIService.fnAddChatsMessages(msgObj);
  }

  addMemberToChat(userId: number, orderId: number) {
    const data = {
      userId,
      orderId,
    };
    this.commonAPIService.fnAddMemberToChat(data);
  }
}
