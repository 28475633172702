<div id="chatPageContainer" [ngClass]="{'full-page-chat': showFullPageChat, 'small-screen-chat': isSmallScreen}" class="chats-container">

    <div class="when-closed" [ngClass]="{'mobile-chat-icon': chatsService?.isChatPanelOpen}" [hidden]="(chatsService?.isChatPanelOpen && !isSmallScreen) || showFullPageChat">
        <div class="open-chat" (click)="openChatPanel()" [ngClass]="{'with-msg-counts': (unreadMessages || isMarkedUnread), 'without-msg-counts': (!unreadMessages && !isMarkedUnread)}">
            <div [ngClass]="{'big': !isSmallScreen}" class="open-left">

                <i *ngIf="!unreadMessages && !isMarkedUnread" class="fa-light fa-messages"></i>
                <i *ngIf="unreadMessages || isMarkedUnread" class="fa-solid fa-messages"></i>

                <div class="order-chat">Order Chat</div>
                <div *ngIf="unreadMessages || isMarkedUnread" class="unread-mark"></div>
            </div>

            <i *ngIf="!isSmallScreen" class="fa-light fa-chevron-up"></i>
        </div>
    </div>

    <div [hidden]="!chatsService.isChatPanelOpen && isSmallScreen" [ngClass]="{'when-full-page': showFullPageChat, 'when-chat-panel': !showFullPageChat}" class="when-opened">

        <div [hidden]="isSmallScreen && !showThreads" *ngIf="showFullPageChat" class="threads-section">

            <div class="search-parent with-margin" [ngClass]="{'with-focus':((searchText && searchText!='') || isSearching)}">
            <input class="search" type="text" placeholder="Search Threads..." (keyup.enter)="searchThreads()" [(ngModel)]="searchText"/>
                <div class="search-icon" (click)="searchThreads()" *ngIf="!isSearching">
                    <i class="fa fa-search"></i>
                </div>
                <div class="search-icon" (click)="closeThreads()" *ngIf="isSearching">
                    <i class="fa fa-close"></i>
                </div>
            </div>

            <div class="show-switch th-sw">
                <div (click)="switchThread('all')" [ngClass]="{'active': currentThreadTab === 'all'}" class="switch">Show All</div>
                <div (click)="switchThread('unread')" [ngClass]="{'active': currentThreadTab === 'unread'}" class="switch">Only Unread</div>
            </div>

            <div id="threadListContainer" class="thread-list">
                <div *ngIf="(currentThreadTab === 'all' && !fetchingAllThreads && !chatsList.length) || (currentThreadTab === 'unread' && !fetchingUnreadThreads && !chatsList.length)" style="margin-top: 10px; margin-bottom: 10px; text-align: center;"> 
                    No chats found.
                </div>
                <div *ngFor="let chat of chatsList" (click)="onChatClick(chat)" 
                [ngClass]="{'selected-thread': chat?.chat_id === selectedChat?.chat_id, 'unread-thread': (chat?.unreadMessages || chat.isMarkedUnread), 'with-menu': (chat.showChatMenu && !isSmallScreen)}"
                class="thread-div">
                    <div class="order-pic">
                        <div class="no-pic">{{getNameInitials(chat.chat_name || '') || getJobNameInitials(chat.orderJobName || '') | uppercase}}</div>
                    </div>
                    <div class="order-id-name">
                        <div class="th-id-time">
                            <div class="order-id">Order #{{chat.order_id}}</div>
                            <div class="th-time">
                                <div class="msg-time" *ngIf="chat.lastMessageTime">{{getChatDateTimeLabel(chat.lastMessageTime, true)}}</div>
                                <div *ngIf="chat.unreadMessages || chat.isMarkedUnread" class="unread-mark inTh"></div>
                                <div>
                                    <div [ngClass]="{'transparent': !chat.showChatMenu}" *ngIf="isSmallScreen" class="th-dots mobile">
                                        <span (click)="toggleThreadActions($event, chat)">...</span>
                                    </div>

                                    <div [ngClass]="{'mobile': isSmallScreen}" [ngStyle]="{'left.px': chat.clientX, 'top.px': chat.clientY}" *ngIf="chat.showChatMenu" class="thread-actions">
                                        <div *ngIf="false" class="thread-opt">
                                            <img src="assets/images/thumbtack.svg" />
                                            <div class="opt-name">Pin this Chat</div>
                                        </div>
                                        <div *ngIf="!chat.isMarkedUnread && !chat.unreadMessages" (click)="markChatReadUnread(chat, true, $event)" class="thread-opt">
                                            <img src="assets/images/check-double.svg" />
                                            <div class="opt-name">Mark Unread</div>
                                        </div>
                                        <div *ngIf="chat.isMarkedUnread || chat.unreadMessages" (click)="markChatReadUnread(chat, false, $event)" class="thread-opt">
                                            <img src="assets/images/check-double.svg" />
                                            <div class="opt-name">Mark Read</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="order-name">{{chat.chat_name || chat.orderJobName || ''}}</div>
                    </div>
                    <div (click)="$event.stopPropagation()" *ngIf="!isSmallScreen" class="th-dots desktop">
                        <div class="dt-hldr">
                            <i (click)="toggleThreadActions($event, chat)" class="fa-regular fa-ellipsis"></i>
                        </div>
                    </div>                   
                </div>
                <div class="load-more">
                    <paper-spinner *ngIf="(currentThreadTab === 'all' && fetchingAllThreads) || (currentThreadTab === 'unread' && fetchingUnreadThreads)" active></paper-spinner>
                    <button class="more-btn" *ngIf="currentThreadTab === 'all' && !fetchingAllThreads &&fetchMoreOrderChats" (click)="fetchOrderChats(searchText)">
                        <img class="mr-7" src="/assets/images/arrow-down.svg" />
                        <span>Load More...</span>
                    </button>
                </div>
            </div>

        </div>

        <div [ngClass]="{'side-chat-panel': !showFullPageChat}" id="chatPanelDiv" [hidden]="isSmallScreen && !showChat" class="chat-panel">

            <div (click)="closeChatPanel()" class="chat-header" *ngIf="selectedChat != -1">

                <div *ngIf="showFullPageChat" class="chat-detail">
                    <i (click)="navToThreads()" class="fa nav-left fa-chevron-left fa-icon"></i>
                    <div class="order-pic">
                        <div class="no-pic">{{getNameInitials(selectedChat?.chat_name || '') || getJobNameInitials(selectedChat?.orderJobName || '') | uppercase}}</div>
                    </div>
                    <div class="order-id-name">
                        <div class="order-id">
                            <div class="ordr-nm">Order #{{selectedChat?.order_id || ''}}</div>
                            <div *ngIf="!isSmallScreen" class="custom-tooltip-wrapper">
                                <img src="assets/images/pen.svg" *ngIf="false"/>
                                <img src="assets/images/external-link-alt.svg" (click)="openOrderPage()" (mouseover)="chatLinkHover = true" (mouseout)="chatLinkHover = false" />
                                <div *ngIf="chatLinkHover">
                                    <i class="fa fa-sort-desc chat-tooltip-tip" aria-hidden="true"></i>
                                    <div class="chat-tooltip-rectangle">
                                        Open Order Page in a New Tab
                                    </div>
                                </div>
                            </div>

                            <div [ngClass]="{'cntx-opn': showEditOrderContext}" class="edit-order-info" *ngIf="isSmallScreen">
                                <div (click)="toggleEditOrderContext($event)" class="edit-dots">...</div>
                                <div class="edit-order-menu" *ngIf="showEditOrderContext">
                                    <div *ngIf="false" class="edit-item">
                                        <img src="assets/images/pen.svg" />
                                        <div class="edit-act-name">Edit Order Information</div>
                                    </div>
                                    <div class="edit-item" (click)="openOrderPage()">
                                        <img src="assets/images/external-link-alt.svg" />
                                        <div class="edit-act-name">Open Order Page In a New Tab</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="order-name">{{selectedChat?.chat_name || ''}}</div>
                        <div *ngIf="selectedChat?.formattedStatus" class="order-status">
                            {{selectedChat?.formattedStatus | titlecase}}
                        </div>
                    </div>
                </div>

                <div *ngIf="!showFullPageChat" class="full-page">
                    <i (click)="openFullPageChat($event)" class="fa-regular fa-window-maximize tab-icon"></i>
                    <a (click)="openFullPageChat($event)" [routerLink]="['/chat',inputOrderId]" class="open-full">
                        Open Chat Page
                    </a>
                </div>


                <div class="group-actions">

                    <div *ngIf="false" [ngClass]="{'hide-input': (isSmallScreen || !showFullPageChat)}" (click)="$event.stopPropagation()" class="search-parent s-h ">
                        <input (focus)="showSearchFilters = true;" (blur)="showSearchFilters = false;" class="search" type="text" placeholder="Search Chat..." />
                        <div (click)="onSearchClick()" class="search-icon">
                            <i class="fa fa-search"></i>
                        </div>
                        <div *ngIf="showFullPageChat && !isSmallScreen" [hidden]="!showSearchFilters" class="search-filters">
                            <div class="by-tag">
                                <div class="search-tag">Show Saved Messages only</div>
                                <div class="search-tag">Show Attachments only</div>
                                <div class="search-tag">Show Links only</div>
                            </div>
                            <div class="by-user">
                                <div class="by-user-head">Filter Messages by User</div>
        
                                <div class="fltr-usr">
                                    <img class="mem-pic" src="assets/images/user-default.jpg" />
                                    <div class="mem-dtl">
                                        <div class="mem-name">James Kelly</div>
                                        <div class="mem-role">Sales Partner</div>
                                    </div>
                                </div>
        
                                <div class="fltr-usr">
                                    <img class="mem-pic" src="assets/images/user-default.jpg" />
                                    <div class="mem-dtl">
                                        <div class="mem-name">Adam Voigt</div>
                                        <div class="mem-role">Sales Partner</div>
                                    </div>
                                </div>
        
                                <div class="fltr-usr">
                                    <img class="mem-pic" src="assets/images/user-default.jpg" />
                                    <div class="mem-dtl">
                                        <div class="mem-name">Emma Silverton</div>
                                        <div class="mem-role">Design Partner</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="chat-members">

                        <div class="mem-pnl" *ngIf="showFullPageChat && !isSmallScreen">
                            <img *ngIf="chatMembers.length" class="gp-mem" [src]="chatMembers[0].profile_pic || defaultUserImg" />
                            <img *ngIf="chatMembers.length > 1" class="gp-mem r-8" [src]="chatMembers[1].profile_pic || defaultUserImg" />
                            <div *ngIf="chatMembers.length > 2" class="gp-mem full-page r-16">+{{chatMembers.length - 2}}</div>
                        </div>

                        <div *ngIf="!showFullPageChat" class="gp-mem in-pnl">{{chatMembers?.length || ''}}</div>
                        


                        <!-- <div *ngIf="showFullPageChat">
                            <div class="members">4</div>
                        </div>
                        <div class="members" *ngIf="!showFullPageChat">4</div> -->
                        
                        <div id="memDropdwnBtn" [ngClass]="{'members-open': showAddDeleteMember}" (click)="showHideAddDeleteMembers($event)" class="mem-act">
                            <div [hidden]="!showFullPageChat" class="mem-counts">{{chatMembers.length}}</div>
                            <i class="fa-regular fa-user fa-icon"></i>
                            <i [hidden]="showAddDeleteMember" class="fa-regular fa-angle-down f-14"></i>
                            <i [hidden]="!showAddDeleteMember" class="fa-regular fa-angle-up f-14"></i>
                        </div>
                    </div>

                    <i *ngIf="!showFullPageChat" (click)="closeChatPanel()" class="fa-regular fa-angle-down close-icon fa-icon"></i>

                    <div (click)="navToArtworkGarments()" class="br-rght" *ngIf="showFullPageChat && isSmallScreen && (orderProducts.length>0 || artworkApprovalImages.length>0)">
                        <i class="fa fa-bars fa-icon"></i>
                        <i class="fa fa-chevron-right fa-icon"></i>
                    </div>

                    <div id="chatMemMenu" (click)="$event.stopPropagation()" [hidden]="!showAddDeleteMember" class="ch-add-remove">
                        <div class="ch-title">Chat Members</div>
                        <div class="ch-list">
                            <div class="ch-member" *ngFor="let member of chatMembers" style="position: relative;">
                                <div class="mem-detail" style="width: 215px;">
                                    <img src="{{ member.profile_pic ? member.profile_pic : 'assets/images/user-default.jpg'}}" class="mem-pic" />
                                    <div class="mem-info">
                                        <div class="mem-name">
                                            {{member.name}} <span *ngIf="member.user_id == userId">(You)</span>
                                        </div>
                                        <div class="mem-role">{{member.permission}}</div>
                                    </div>
                                </div>
                                <div class="delete-member" *ngIf="!isOrderCancelled && (member.user_id != userId || (currentUser.permissionLevel == 'Admin' || currentUser.permissionLevel == 'Operations Manager' || currentUser.permissionLevel == 'Collegiate Program Coordinator')) && currentUser && (currentUser.permissionLevel == 'Admin' || currentUser.permissionLevel == 'Operations Manager' || currentUser.permissionLevel == 'Collegiate Program Coordinator') && !member.confirmMenu" (mouseover)="onMouseEnterLeaveFromMember(member)" (mouseout)="onMouseEnterLeaveFromMember(member)" >
                                    <!-- <div class="del-text" *ngIf="member.showDeleteMenu">
                                        Delete
                                    </div> -->
                                    <div class="del-mem" [ngStyle]="{'background': member.showDeleteMenu ? '#FBDFD7' :'#3b3e6612', 'color': member.showDeleteMenu ? '#EB6036' :'black'}" (click)="confirmRemoveChatMember(member)">
                                        <i id="deleteMemberIcon" class="fa-regular fa-trash fa-icon"></i>
                                    </div>
                                    <!-- <div  class="del-mem"  *ngIf="currentUser && currentUser.permissionLevel == 'Admin'" (click)="removeChatMember(member.id)">
                                        <i class="fa fa-trash fa-icon"></i>
                                    </div> -->
                                </div>
                                <div id="deleteMemberPopup" class="delete-member" *ngIf="member.confirmMenu">
                                    <div class="del-text">
                                        Confirm?
                                    </div>
                                    <div  class="del-icon-text" (click)="removeChatMember(member)">
                                        <i class="fa-solid fa-check"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ch-add" *ngIf="!isOrderCancelled">
                            <div class="ch-search" [ngClass]="{'with-focus': searchUser && searchUser!=''}">
                                <input type="text" placeholder="Search to add people..." [(ngModel)]="searchUser" (keyup)="fetchSearchUsers()"/>
                                <div class="ch-add-btn">
                                    <i class="fa-regular fa-plus fa5"></i>
                                </div>
                            </div>
                            <div class="user-list" *ngIf="searchUser && searchUser!=''">
                                <div *ngIf="searchedChatUsers.length == 0">
                                    No user found
                                </div>
                                <div class="ch-member" *ngFor="let member of searchedChatUsers">
                                    <div class="mem-detail">
                                        <img src="{{member.photo ? member.photo : 'assets/images/user-default.jpg'}}" class="mem-pic" />
                                        <div class="mem-info">
                                            <div class="mem-name">{{member.name}}</div>
                                            <div class="mem-role">{{member.permissionLevel}}</div>
                                        </div>
                                    </div>
                                    <div class="del-mem" (click)="addChatMember(member)">
                                        <!-- <i class="fa-regular fa-plus fa-icon"></i> -->
                                        <i class="fa-regular fa-plus fa5"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showSmallScreenSearch" class="search-in-chat">
                <div class="search-parent s-fltr m-15">
                    <div class="in-up-dwn">
                        <input (focus)="showSearchFilters = true;" (blur)="showSearchFilters = false;" class="search" (keyup)="searchInChat()" type="text" />
                        <div class="search-counts">2/5</div>
                        <i class="fa fa-angle-up fa-icon"></i>
                        <i class="fa fa-angle-down fa-icon"></i>
                    </div>
                    
                    <div class="clear">
                        <i class="fa fa-times fa-icon"></i>
                    </div>

                    <div [hidden]="!showSearchFilters" class="search-filters">
                        <div class="by-tag">
                            <div class="search-tag">Show Saved Messages only</div>
                            <div class="search-tag">Show Attachments only</div>
                            <div class="search-tag">Show Links only</div>
                        </div>
                        <div class="by-user">
                            <div>Filter Messages by User</div>
    
                            <div class="fltr-usr">
                                <img class="mem-pic" src="assets/images/user-default.jpg" />
                                <div class="mem-dtl">
                                    <div class="mem-name">James Kelly</div>
                                    <div class="mem-role">Sales Partner</div>
                                </div>
                            </div>
    
                            <div class="fltr-usr">
                                <img class="mem-pic" src="assets/images/user-default.jpg" />
                                <div class="mem-dtl">
                                    <div class="mem-name">Adam Voigt</div>
                                    <div class="mem-role">Sales Partner</div>
                                </div>
                            </div>
    
                            <div class="fltr-usr">
                                <img class="mem-pic" src="assets/images/user-default.jpg" />
                                <div class="mem-dtl">
                                    <div class="mem-name">Emma Silverton</div>
                                    <div class="mem-role">Design Partner</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="msg-pane" [ngClass]="{'no-selected-chat-container': selectedChat == -1}" [ngStyle]="{'margin-inline': (!isSmallScreen && showFullPageChat && orderProducts.length==0 && artworkApprovalImages.length==0) ? '150px' : '0px'}" #scrollContainer id="msgPane" [scrollWindow]="false" infiniteScroll [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" (scrolledUp)="onScrollUp()" >
                <div *ngIf="isLoadingMessages" style="margin-top: 10px; margin-bottom: 10px; text-align: center;"> 
                    <paper-spinner active></paper-spinner>
                </div>
                <div *ngIf="selectedChat==-1" class="no-selected-chat">
                    <img src="/assets/images/message-lines.svg" class="no-selected-chat-img">
                    <div class="no-selected-chat-text">
                        Please select a chat thread to start a conversation.
                    </div>
                </div>
                <div [ngClass]="{'out-going': msg.user_id == userId && !msg.is_status_change, 'order-update': msg.is_status_change == 1}" class="msg-div" *ngFor="let msg of chatMessages; let i=index;">
                    
                    <!-- <div style="margin-top: 10px; margin-bottom: 10px; text-align: center; background-color: gainsboro" *ngIf="i == 0 || (chatMessages[i].date != chatMessages[i-1].date)">
                        {{msg.regDate}}
                    </div> -->

                    <div *ngIf="(i == 0 || (chatMessages[i].date != chatMessages[i-1].date)) && !msg.isBeingSent" class="date-time-bar">
                        <div class="devider"></div>
                        <div class="bar-time">{{msg.regDate}}</div>
                        <div class="devider"></div>
                    </div>

                    <div class="msg-sender" [ngClass]="{'on-chat-page': showFullPageChat, 'on-side-panel': !showFullPageChat}" *ngIf="i == 0 || (chatMessages[i].is_status_change != chatMessages[i-1].is_status_change) || (chatMessages[i].user != chatMessages[i-1].user) || 
                      (chatMessages[i].timestamp - chatMessages[i-1].timestamp > 60000 )" [ngStyle]="{'align-items': msg.is_status_change ? 'center' : ''}">
                        <!-- <img src="assets/images/user-default.jpg" class="sender-pic" /> -->
                        <img *ngIf="msg.is_status_change == 1" class="sender-pic" src="assets/images/order-update.svg" />
                        <img *ngIf="msg.is_status_change == 0" src="{{ msg.profile_pic ? msg.profile_pic : 'assets/images/user-default.jpg'}}" class="sender-pic"/>
                        <div class="sender-info" [ngClass]="{'on-chat-page': showFullPageChat, 'on-side-panel': !showFullPageChat && !msg.is_status_change}" [ngStyle]="{'align-items': msg.is_status_change ? 'center' : ''}">
                            <div *ngIf="msg.is_status_change == 1" class="sender-name">Status Update</div>
                            <div *ngIf="msg.is_status_change == 1" class="splitter"></div>
                            <img *ngIf="msg.is_status_change == 1" src="{{ msg.profile_pic ? msg.profile_pic : 'assets/images/user-default.jpg'}}" class="sender-pic" [ngStyle]="{'width': !showFullPageChat ? '16px' : '20px'}" [ngStyle]="{'height': !showFullPageChat ? '16px' : '20px','width' : !showFullPageChat ? '16px' : '20px'}"/>
                            <div class="sender-name" [ngClass]="{'shorten-name': msg.is_status_change && (isSmallScreen || !showFullPageChat)}">{{msg.user}}</div>
                            <div class="splitter"></div>
                            <div class="sender-role" [ngClass]="{'shorten-role': msg.is_status_change && (isSmallScreen || !showFullPageChat)}">{{msg.permissions_role}}</div>
                            <div *ngIf="!msg.isBeingSent && false" class="send-time">{{msg.time.toUpperCase()}}</div>
                        </div>
                    </div>

                    <div class="msg-bubbles">
                        <div [ngClass]="{'on-edit-mode': msg.isBeingEdited, 'is-saved': false, 'msg-action-mode': false}" class="bubble-parent" [ngStyle]="{'flex-wrap': !!msg.attachments?.length ? 'initial' : 'wrap'}">
                            <div (mouseenter)="onMouseEnterLeaveFromMsg($event, msg)" (mouseleave)="onMouseEnterLeaveFromMsg($event, msg)" (click)="onMsgClick($event, msg)" *ngIf="!msg.is_deleted" [ngClass]="{'action-mode': msg.isInActionMode, 'has-file': !!msg.attachments?.length}" class="msg-bubble" [ngStyle]="{'max-width': !showFullPageChat || isSmallScreen ? '100%' :'calc(100% - 113px)' }">
                                <!-- <div [ngClass]="{'no-select': msg.showMsgActionMenu}" *ngIf="msg.message" class="text-msg-div" [innerHTML]="msg.message | safeHTML"></div> -->
                                <div *ngIf="msg.message" class="text-msg-div" [innerHTML]="msg.message | safeHTML"></div>
                                <div [ngClass]="{'msg-image': (attachment.type == 'image' && !attachment.fullScreenMode)}" class="msg-doc" *ngFor="let attachment of msg.attachments; let j = index;">
                                    <div *ngIf="attachment.type == 'image'" (click)="openFullViewImage  ($event, attachment, msg)" class="msg-img"
                                        [ngStyle]="{'background-image': attachment.backgroundImage}">
                                    
                                    </div>

                                    <div (click)="$event.stopPropagation()" [ngStyle]="{'height': window?.innerHeight}" *ngIf="attachment.fullScreenMode" class="full-screen-mode">
                                        <div class="full-img-container">
                                            <div class="inn-full-img">
                                                <div class="img-opts">
                                                    <i (click)="downloadAttachment($event, attachment)" class="fa-regular fa-cloud-arrow-down"></i>
                                                    <i (click)="closeFullScreenImageMode($event, attachment)" class="fa-regular fa-xmark"></i>
                                                </div>
                                                <img [src]="attachment.attachment" />
                                            </div>
                                            
                                            <div *ngIf="msg.user_id === currentUser.user_id" class="act-menu-for-full-screen">
                                                <div class="msg-actions">

                                                    <div (click)="askDeleteConfirmation($event, msg, confirmDeletePopup)" class="act-item">
                                                        <div class="act-tooltip tltp-remove">Delete</div>
                                                        <i class="fa fa-trash fa-icon"></i>
                                                        <div class="act-name">Remove</div>
                                                    </div>
                        
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                    <img *ngIf="attachment.type == 'image' && false" [ngClass]="{'small-img': !showFullPageChat || isSmallScreen}" (click)="imgService.showImage(attachment.attachment)" class="msg-img" src="{{attachment.attachment}}"/>

                                    <div *ngIf="attachment.type == 'doc'" class="file-icn">
                                        <i class="fa fa-file"></i>
                                        <i *ngIf="['.zip', '.rar'].includes(attachment.ext) || !attachment.ext" class="fa fa-archive fa-icon"></i>
                                        <i *ngIf="!['.zip', '.rar'].includes(attachment.ext) && attachment.ext" class="fa fa-align-left fa-icon"></i>
                                    </div>                                    
                                    <div *ngIf="attachment.type == 'doc'" class="file-dtl">
                                        <div class="file-name">{{attachment.attachment_name}}</div>
                                        <div class="file-size">{{attachment.attachment_size || 'x'}} kb</div>
                                    </div>
                                    <div (click)="downloadAttachment($event, attachment)" class="file-act">
                                        <i class="fa-regular fa-cloud-arrow-down"></i>
                                    </div>
                                    <div *ngIf="false" class="file-act">
                                        <img src="assets/images/share-all.svg" />
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="msg.is_deleted" class="msg-bubble deleted">Message Deleted</div>

                            <div (click)="onMsgClick($event, msg)" (mouseenter)="onMouseEnterLeaveFromMsg($event, msg)" class="msg-meta" [ngClass]="{'full-big': !isSmallScreen && showFullPageChat, 'small-panel': isSmallScreen || !showFullPageChat}" *ngIf="(!msg.is_deleted && !msg.showMsgActionMenu && !msg.showDots)" [hidden]="showFullPageChat && (msg.showDeleteConfirmation)">
                                <div *ngIf="msg.is_edited" class="edited">Edited</div>
                                <div *ngIf="!msg.isBeingSent" class="msg-send-time" [ngClass]="{'on-chat-page': showFullPageChat}">{{msg.time.format('hh:mm A')}}</div>
                                <mat-spinner *ngIf="msg.isBeingSent" class="is-being-sent"></mat-spinner>
                            </div>

                            <div (mouseleave)="onMouseEnterLeaveFromMsg($event, msg)" [ngClass]="{'when-full-big': showFullPageChat && !isSmallScreen}" *ngIf="(msg.showMsgActionMenu && showFullPageChat && !isSmallScreen)" class="msg-actions">
                                <div *ngIf="false" class="act-item">
                                    <div class="act-tooltip tltp-save">Save</div>
                                    <i class="fa fa-bookmark fa-icon"></i>
                                    <div class="act-name">Save</div>
                                </div>
                                <div (click)="onEditMessage(msg)" *ngIf="msg.user_id === currentUser.user_id && msg.message" class="act-item">
                                    <div class="act-tooltip tltp-edit">Edit</div>
                                    <img src="assets/images/pen-blue.svg" />
                                    <div class="act-name">Edit</div>
                                </div>
                                <div *ngIf="false" class="act-item">
                                    <div class="act-tooltip tltp-emoji">React with Emoji</div>
                                    <img src="assets/images/laugh-wink.svg" />
                                    <div class="act-name">React</div>
                                </div>
                                <div *ngIf="false" class="act-item">
                                    <div class="act-tooltip tltp-copy">Copy</div>
                                    <i class="fa fa-copy fa-icon"></i>
                                    <div class="act-name">Copy</div>
                                </div>
                                <div *ngIf="false" class="act-item">
                                    <div class="act-tooltip tltp-quote">Quote</div>
                                    <i class="fa fa-quote-right fa-icon"></i>
                                    <div class="act-name">Quote</div>
                                </div>
                                <div *ngIf="msg.user_id === currentUser.user_id" (click)="askDeleteConfirmation($event, msg)" class="act-item">
                                    <div class="act-tooltip tltp-remove">Delete</div>
                                    <i class="fa-regular fa-trash fa-icon"></i>
                                    <div class="act-name">Remove</div>
                                </div>
                            </div>

                            <div class="dots-parent" (mouseleave)="onMouseEnterLeaveFromMsg($event, msg)" *ngIf="msg.showDots">
                                <div  (click)="onDotsClick(msg)" class="msg-dots br-50">
                                    <div *ngIf="msg.showDotOptions" class="dots-options">
                                        <div (click)="onEditMessage(msg)" *ngIf="msg.user_id === currentUser.user_id && msg.message" class="dot-opt edit">
                                            <img src="assets/images/pen-blue.svg" />
                                        </div>
    
                                        <div (click)="askDeleteConfirmation($event, msg, confirmDeletePopup)" *ngIf="msg.user_id === currentUser.user_id" class="dot-opt">
                                            <i class="fa-regular fa-trash fa-icon"></i>
                                        </div>
                                    </div>
                                    <div class="msg-dot">.</div>
                                    <div class="msg-dot">.</div>
                                    <div class="msg-dot">.</div>
                                </div>
                            </div>
                            
                            
                            <div id="deleteMsgPopup" *ngIf="msg.showDeleteConfirmation && this.showFullPageChat && !isSmallScreen" class="confirm-sw">
                                <div class="cnfrm">Confirm?</div>
                                <div (click)="onDeleteMsg(msg)" class="rmv-msg">
                                    <i class="fa fa-trash fa-icon"></i>
                                    <div>Remove</div>
                                </div>
                            </div>
                            
                            <div *ngIf="msg.isFailed">Failed!</div>

                            <img *ngIf="msg.isBookmarked" class="bkmrk-solid" src="assets/images/bookmark-solid.svg" />

                        </div>

                        <div *ngIf="msg.showMsgActionMenu && (isSmallScreen)" class="msg-actions">
                            <div *ngIf="false" class="act-item">
                                <div class="act-tooltip tltp-save">Save</div>
                                <i class="fa fa-bookmark fa-icon"></i>
                                <div class="act-name">Save</div>
                            </div>
                            <div *ngIf="msg.user_id === currentUser.user_id && msg.message" class="act-item" (click)="onEditMessage(msg)">
                                <div class="act-tooltip tltp-edit">Edit</div>
                                <img src="assets/images/pen-blue.svg" />
                                <div class="act-name">Edit</div>
                            </div>
                            <div *ngIf="false" class="act-item">
                                <div class="act-tooltip tltp-emoji">React with Emoji</div>
                                <img src="assets/images/laugh-wink.svg" />
                                <div class="act-name">React</div>
                            </div>
                            <div *ngIf="false" class="act-item">
                                <div class="act-tooltip tltp-copy">Copy</div>
                                <i class="fa fa-copy fa-icon"></i>
                                <div class="act-name">Copy</div>
                            </div>
                            <div *ngIf="false" class="act-item">
                                <div class="act-tooltip tltp-quote">Quote</div>
                                <i class="fa fa-quote-right fa-icon"></i>
                                <div class="act-name">Quote</div>
                            </div>

                            <div *ngIf="msg.user_id === currentUser.user_id" (click)="askDeleteConfirmation($event, msg, confirmDeletePopup)" class="act-item">
                                <div class="act-tooltip tltp-remove">Delete</div>
                                <i class="fa-regular fa-trash fa-icon"></i>
                                <div class="act-name">Remove</div>
                            </div>

                        </div>

                        <div id="deleteMsgPopup" *ngIf="msg.showDeleteConfirmation && (!this.showFullPageChat || isSmallScreen)" class="confirm-sw for-small">
                            <div class="cnfrm">Confirm?</div>
                            <div (click)="onDeleteMsg(msg)" class="rmv-msg">
                                <i class="fa fa-trash fa-icon"></i>
                                <div>Remove</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isOrderCancelled && selectedChat != -1" class="editor-attachment">

                <!-- Show attach container when user selects file to upload -->
                <div class="attach-container" [ngClass]="{'has-file': !!uploads.length}">
                    <div class="attachment-div" *ngFor="let file of uploads; let i=index;">
                        <div class="times-sqr">
                            <i class="fa fa-times fa-icon" (click)="removeAttachment(i)"></i>
                        </div>
                        <div class="msg-doc">
                            <div *ngIf="file.type=='doc'" class="file-icn">
                                <i class="fa fa-file"></i>

                                <i *ngIf="['.zip', '.rar'].includes(file.ext) || !file.ext" class="fa fa-archive fa-icon"></i>
                                <i *ngIf="!['.zip', '.rar'].includes(file.ext) && file.ext" class="fa fa-align-left fa-icon"></i>

                            </div>                            
                            <img *ngIf="file.type=='image'" [ngClass]="{'image-file': true}" [src]="file.src"/>
                            <div class="file-dtl">
                                <div class="file-name">{{file.name}}</div>
                                <div class="size-progress">
                                    <div *ngIf="true" class="file-size">{{file.size}}kb</div>
                                    <mat-progress-bar class="upload-progress" *ngIf="true" mode="determinate" value="{{file.progress}}"></mat-progress-bar>
                                    <div class="file-size">{{file.progress.toFixed(0)}} % Complete</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div class="attachment-div">
                        <div class="times-sqr">
                            <i class="fa fa-times fa-icon"></i>
                        </div>
                        <div class="msg-doc">
                            <div class="file-icn">
                                <i class="fa fa-file"></i>
                                <i class="fa fa-archive fa-icon"></i>
                            </div>
                            <div class="file-dtl">
                                <div class="file-name">logo-file-9170.zip</div>
                                <div class="size-progress">
                                    <div *ngIf="true" class="file-size">10.75MB</div>
                                    <mat-progress-bar class="upload-progress" *ngIf="false" mode="determinate" value="40"></mat-progress-bar>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="attachment-div">
                        <div class="times-sqr">
                            <i class="fa fa-times fa-icon"></i>
                        </div>
                        <div class="msg-doc">
                            <div class="file-icn">
                                <i class="fa fa-file"></i>
                                <i *ngIf="false" class="fa fa-archive fa-icon"></i>
                                <i *ngIf="true" class="fa fa-align-left fa-icon"></i>
                            </div>
                            <div class="file-dtl">
                                <div class="file-name">document-spec.docx</div>
                                <div class="size-progress">
                                    <div *ngIf="false" class="file-size">10.75MB</div>
                                    <mat-progress-bar class="upload-progress" *ngIf="true" mode="determinate" value="40"></mat-progress-bar>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'image-file': true}" class="attachment-div">
                        <div class="times-sqr">
                            <i class="fa fa-times fa-icon"></i>
                        </div>
                        <img src="http://www.ssactivewear.com/Images/Style/2690_fl.jpg" />
                    </div> -->
                </div>
                
                <div [ngClass]="{'editor-active': isEditorFocused}" class="chat-editor">
                    <editor [apiKey]="tinyMceAPIKey" [(ngModel)]="msgContent" [init]="editorInit"></editor>
                    <div *ngIf="!this.msgBeingEdited" title="Attachment" class="paper-clip">
                       <input #attachmentInput type="file" accept="*" multiple (change)="uploadAttachments($event)" class="msg-file-inp"  size="0" />
                       <i class="fa-regular fa-paperclip fa-icon"></i>
                    </div>
                    
                    <div [hidden]="msgBeingEdited" class="send-msg" (click)="sendMessage(msgContent)">
                        <i class="fa-regular fa-paper-plane fa-icon"></i>
                    </div>
                    <div [hidden]="!msgBeingEdited" class="send-msg cross" [ngClass]="{'in-panel': !showFullPageChat}" (click)="cancelMsgEditing()">
                        <img src="assets/images/times.svg" />
                    </div>
                    <div [hidden]="!msgBeingEdited" [ngClass]="{'in-panel': !showFullPageChat}" class="send-msg edit" (click)="saveEditedMessage(msgContent, msgBeingEdited)">
                        <img src="assets/images/double-check.svg" />                        
                    </div>
                </div>
            </div>
            

        </div>

        <div [hidden]="isSmallScreen && !showArtworkGarment" *ngIf="showFullPageChat && selectedChat && selectedChat!=-1 && (orderProducts.length>0 || artworkApprovalImages.length>0) " class="artwork-garments">
            <div class="art-gar-head">
                <div (click)="navToChat()" class="br-rght" *ngIf="showFullPageChat && isSmallScreen" [ngStyle]="{'margin-right': selectedChat && selectedChat.orderStatus == 'New Order' ? 'auto' : '15px'}">
                    <i class="fa fa-chevron-left fa-icon"></i>
                    <i class="fa fa-bars fa-icon"></i>
                </div>
                <div class="show-switch ar-sw" *ngIf="selectedChat && selectedChat!=-1 && selectedChat.orderStatus!='New Order'">
                    <div [ngClass]="{'active': currentArtGarTab === 'garment'}" (click)="showHideArtworkGarment('garment')" class="switch sw-garment">Garment</div>
                    <div [ngClass]="{'active': currentArtGarTab === 'artwork'}" (click)="showHideArtworkGarment('artwork')" class="switch sw-artwork">Artwork</div>
                </div>
            </div>
            <div class="only-garment" *ngIf="selectedChat && selectedChat.orderStatus == 'New Order'">
                Garment
            </div>

            <div [hidden]="showArtwork" class="garment-section">

                <div *ngFor="let product of orderProducts; let i = index;" class="garment-div">
                    <div class="brand">{{product.brand}}</div>
                    <div class="gar-det">{{product.garment}}</div>
                    <div class="gar-code">#{{product.style}}</div>
                    <div class="gar-clr">
                        <div [ngStyle]="{'background': product.color_hex}" class="clr-dot"></div>
                        <div class="clr-name">{{product.color_name}}</div>
                    </div>
                    
                    <div class="gar-view-sec">

                        <img [src]="product.selectedImage" class="main-view" (click)="imgService.showImage(product.selectedImage)" />

                        <div class="side-view" [ngClass]="{'side-view-before': product.isUpArrowVisible, 'side-view-after': product.isDownArrowVisible}">

                            <div class="gar-pnl-wrapper">
                                <div *ngIf="product.isUpArrowVisible" class="pnl-nav up-nav">
                                    <div class="up-down">
                                        <i (click)="upArrowClick(i)" class="fa-regular fa-chevron-up fa-icon"></i>
                                    </div>
                                </div>

                                <div class="gar-pnl gar-item-{{i}}" (scroll)="scrollHandler($event, i)">
                                    <img (click)="product.selectedImage = product.style_image" *ngIf="product.style_image && product.supplierID == 1" [src]="product.style_image" class="pnl-img" [ngClass]="{'highlight-image': product.selectedImage == product.style_image}" />

                                    <img (click)="product.selectedImage = product.model_front_image" *ngIf="product.model_front_image" [src]="product.model_front_image" class="pnl-img" [ngClass]="{'highlight-image': product.selectedImage == product.model_front_image}" />

                                    <img (click)="product.selectedImage = product.model_back_image" *ngIf="product.model_back_image" [src]="product.model_back_image" class="pnl-img" [ngClass]="{'highlight-image': product.selectedImage == product.model_back_image}" />

                                    <img (click)="product.selectedImage = product.model_side_image" *ngIf="product.model_side_image" [src]="product.model_side_image" class="pnl-img" [ngClass]="{'highlight-image': product.selectedImage == product.model_side_image}" />

                                    <img (click)="product.selectedImage = product.model_custom_image" *ngIf="product.model_custom_image" [src]="product.model_custom_image" class="pnl-img" [ngClass]="{'highlight-image': product.selectedImage == product.model_custom_image}" />

                                    <img (click)="product.selectedImage = product.color_front_image" *ngIf="product.color_front_image" [src]="product.color_front_image" class="pnl-img" [ngClass]="{'highlight-image': product.selectedImage == product.color_front_image}" />

                                    <img (click)="product.selectedImage = product.color_back_image" *ngIf="product.color_back_image" [src]="product.color_back_image" class="pnl-img" [ngClass]="{'highlight-image': product.selectedImage == product.color_back_image}" />

                                    <img (click)="product.selectedImage = product.color_side_image" *ngIf="product.color_side_image" [src]="product.color_side_image" class="pnl-img" [ngClass]="{'highlight-image': product.selectedImage == product.color_side_image}" />
                                </div>

                                <div *ngIf="product.isDownArrowVisible" class="pnl-nav down-nav">
                                    <div class="up-down">
                                        <i (click)="downArrowClick(i)" class="fa-regular fa-chevron-down fa-icon"></i>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <div [hidden]="showGarment" class="artwork-section">

                <div *ngIf="selectedChat && selectedChat.orderStatus == 'Artwork' && (selectedChat.orderSubstatus == 'Need Designer' || selectedChat.orderSubstatus == 'In Progress')" class="art-detail-sec">
                    <div class="artwrk-cntnr" >

                        <div class="art-detail">
                            <div class="art-title">Print Details</div>
                            <div *ngFor="let artwork of orderArtworkDetails">
                                <div class="art-position">
                                    {{artwork.position || ''}}: {{artwork.positionType}}
                                </div>
                            <!-- <div class="art-det-pair">
                                <div class="art-detail-title">{{artwork.position || ''}}:</div>
                                <div class="art-value">{{artwork.positionType}}</div>
                            </div> -->
                            
                            <div class="art-det-pair" *ngIf="artwork.type">
                                <div class="art-detail-title">Print Method</div>
                                <div class="art-value">{{artwork.type}}</div>
                                <!-- <div class="art-value">{{artwork.methodDetail || ''}}</div> -->
                            </div>
                            <div class="art-det-pair" *ngIf="artwork.type === 'Screen Printing'">
                                <div class="art-detail-title">Color</div>
                                <div class="art-value">{{artwork.number_of_colors + 1}} Ink Layers = {{artwork.number_of_colors}} Colors + 1 Underbase</div>
                            </div>
                            <div class="art-det-pair" *ngIf="artwork.type === 'Embroidery'">
                                <div class="art-detail-title">Dimensions</div>
                                <div class="art-value">{{artwork.width}} Width x {{artwork.height}} Height</div>
                            </div>
                        </div>
                        </div>
                        <div class="art-break"></div>
                        <!-- <div class="art-detail">
                            <div class="art-title">Design Notes</div>
                    
                            <div class="des-note">{{artwork.description || ''}}</div>
                        </div> -->
    
                        <div class="art-detail" >
                            <div class="art-title">Audience</div>
                    
                            <!-- <div class="art-detail-title">{{artwork.audience || ''}}</div> -->
                            <div class="art-detail-title">{{orderArtworkDetails[0]?.audience || ''}}</div>
                        </div>
                        
                        <div class="art-break"></div>

                        <div class="art-detail">
                            <div class="art-title">Inspiration</div>
                            <div *ngFor="let artwork of orderArtworkDetails;let i=index;">
                                <div class="art-position">
                                    {{artwork.position || ''}}: {{artwork.positionType}}
                                </div>

                                <div style="display: flex;">
                                    <div class="art-position" style="color: #9D9EB2;">Suggested Color</div>
                                    <div *ngFor="let color of artwork.suggested_colors" class="art-colors" [style.background-color]="color">&nbsp;</div>
                                </div>

                                <!-- <div class="art-detail-title">Suggested Color</div>
                                <div class="des-note">{{artwork.description || ''}}</div> -->

                                <div class="art-detail-title" style="margin-top: 5px;">Design Notes</div>
                                <div class="des-note">{{artwork.description || ''}}</div>
                                <div class="des-doc" *ngFor="let attachment of artwork.sales_pictures">
                                    <div class="art-doc">
                                        <div *ngIf="attachment.type == 'doc'" class="art-file-icn">
                                            <i class="fa fa-file"></i>
                                            <i *ngIf="['.zip', '.rar'].includes(attachment.ext) || !attachment.ext" class="fa fa-archive fa-icon"></i>
                                            <i *ngIf="!['.zip', '.rar'].includes(attachment.ext) && attachment.ext" class="fa fa-align-left fa-icon"></i>
                                        </div>
                                        <div *ngIf="attachment.type == 'image'" class="art-file-icn">
                                            <img class="art-img-icon" [src]="attachment.attachment" (click)="imgService.showImage(attachment.attachment)">
                                        </div>                                    
                                        <div class="art-file-dtl">
                                            <div class="file-name">{{attachment.attachment_name}}</div>
                                            <div class="file-size">{{attachment.attachment_size || 'x'}} kb</div>
                                        </div>
                                        <div (click)="downloadAttachment($event, attachment)" class="art-file-act">
                                            <i class="fa fa-cloud-download fa-icon"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="art-break" *ngIf=" orderArtworkDetails.length && i!=orderArtworkDetails.length - 1"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="(artworkApprovalImages.length && (selectedChat.orderStatus == 'Artwork' && selectedChat.orderSubstatus != 'Need Designer') && (selectedChat.orderStatus == 'Artwork' && selectedChat.orderSubstatus != 'In Progress')) || (artworkApprovalImages.length && selectedChat.orderStatus != 'Artwork')" class="art-approval">
                    <div class="art-title">Artwork Approval</div>
                    <div class="art-cntnr" *ngFor="let image of artworkApprovalImages">
                        <img class="art-img" [src]="image.file_name" (click)="imgService.showImage(image.file_name)"/>
                        <div *ngIf="false" class="art-dwnld">
                            <i class="fa fa-cloud-download fa-icon"></i>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>

    </div>

</div>

<div #confirmDeletePopup [hidden]="true">
    <div class="confirm-delete-msg">
        <div class="actionIcon dustbin">
            <i class="fad fa-trash"></i>
        </div>
        <div class="delete-txt">Are you sure you want to delete this message?</div>
        <div class="primaryBtn actionBtn no dlt-msg-btn-no">No</div>
        <div class="regularBtn actionBtn yes dlt-msg-btn-yes">Yes, Delete</div>
    </div>
</div>