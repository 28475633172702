import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import * as _ from "lodash";
import * as moment from 'moment';
import { CommonAPIService } from 'app/utils/services/common-api.service';
import { CommonService } from 'app/utils/services/common.service';
// import { PricingService } from 'app/utils/shared-modules/vieworder/services/pricing.service';
import { AuthService } from 'app/utils/services/auth.service';
// import { StatusService } from 'app/utils/services/status.service';
import { NotificationService } from 'app/utils/services/notification.service';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    calculateTotalGarmentOnRemoveAddress: BehaviorSubject<any> = new BehaviorSubject(null);
    assignSplitAddressesOnGarment: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedOldProductValue: BehaviorSubject<any> = new BehaviorSubject(null);
    setNewProductValues: BehaviorSubject<any> = new BehaviorSubject(null);
    callAssignGarmentInSplitAddresses: BehaviorSubject<any> = new BehaviorSubject(null);
    artworkLocationKeysList: BehaviorSubject<any> = new BehaviorSubject(null);
    setLineItemsWithMarkup: BehaviorSubject<any> = new BehaviorSubject(null);
    displayProductionAndPricingArea: BehaviorSubject<any> = new BehaviorSubject(null);
    callGetShipmentsCount: BehaviorSubject<any> = new BehaviorSubject(null);
    callMakeTurnAroundList: BehaviorSubject<any> = new BehaviorSubject(null);
    initializeTurnAround: BehaviorSubject<any> = new BehaviorSubject(null);
    autoSaveDisable: BehaviorSubject<any> = new BehaviorSubject(null);
    isOrderIsNewOrder: BehaviorSubject<any> = new BehaviorSubject(null);
    kickBacksChangeEvent: BehaviorSubject<any> = new BehaviorSubject(null);
    resetCustomerPrice: BehaviorSubject<any> = new BehaviorSubject(null);
    updateOrderService: BehaviorSubject<any> = new BehaviorSubject(null);
    // proccesing: Boolean = true;
    isInitialCall: Boolean = false;
    order: any
    key: string;
    clonedOrderForProduction: any
    turnAroundList: Array<any> = [];
    ppCompanyId: Number;
    ppCompanies: Array<any> = [];
    orderReference: any;
    calculation: any = {};
    DEFAULT_MARKUP: number = environment.config.default_markup;

    states = [
        { "value": 'AL', "label": 'Alabama' },
        { "value": 'AK', "label": 'Alaska' },
        { "value": 'AS', "label": 'American Samoa' },
        { "value": 'AZ', "label": 'Arizona' },
        { "value": 'AR', "label": 'Arkansas' },
        { "value": 'CA', "label": 'California' },
        { "value": 'CO', "label": 'Colorado' },
        { "value": 'CT', "label": 'Connecticut' },
        { "value": 'DE', "label": 'Delaware' },
        { "value": 'DC', "label": 'District of Colombia' },
        { "value": 'FM', "label": 'Federated States of Micronesia' },
        { "value": 'FL', "label": 'Florida' },
        { "value": 'GA', "label": 'Georgia' },
        { "value": 'GU', "label": 'Guam' },
        { "value": 'HI', "label": 'Hawaii' },
        { "value": 'ID', "label": 'Idaho' },
        { "value": 'IL', "label": 'Illinois' },
        { "value": 'IN', "label": 'Indiana' },
        { "value": 'IA', "label": 'Iowa' },
        { "value": 'KS', "label": 'Kansas' },
        { "value": 'KY', "label": 'Kentucky' },
        { "value": 'LA', "label": 'Louisiana' },
        { "value": 'ME', "label": 'Maine' },
        { "value": 'MH', "label": 'Marshall Islands' },
        { "value": 'MD', "label": 'Maryland' },
        { "value": 'MA', "label": 'Massachusetts' },
        { "value": 'MI', "label": 'Michigan' },
        { "value": 'MN', "label": 'Minnesota' },
        { "value": 'MS', "label": 'Mississippi' },
        { "value": 'MO', "label": 'Missouri' },
        { "value": 'MT', "label": 'Montana' },
        { "value": 'NE', "label": 'Nebraska' },
        { "value": 'NV', "label": 'Nevada' },
        { "value": 'NH', "label": 'New Hampshire' },
        { "value": 'NJ', "label": 'New Jersey' },
        { "value": 'NM', "label": 'New Mexico' },
        { "value": 'NY', "label": 'New York' },
        { "value": 'NC', "label": 'North Carolina' },
        { "value": 'ND', "label": 'North Dakota' },
        { "value": 'MP', "label": 'Northern Mariana Islands' },
        { "value": 'OH', "label": 'Ohio' },
        { "value": 'OK', "label": 'Oklahoma' },
        { "value": 'OR', "label": 'Oregon' },
        { "value": 'PW', "label": 'Palau' },
        { "value": 'PA', "label": 'Pennsylvania' },
        { "value": 'PR', "label": 'Puerto Rico' },
        { "value": 'RI', "label": 'Rhode Island' },
        { "value": 'SC', "label": 'South Carolina' },
        { "value": 'SD', "label": 'South Dakota' },
        { "value": 'TN', "label": 'Tennessee' },
        { "value": 'TX', "label": 'Texas' },
        { "value": 'UT', "label": 'Utah' },
        { "value": 'VT', "label": 'Vermont' },
        { "value": 'VI', "label": 'Virgin Islands' },
        { "value": 'VA', "label": 'Virginia' },
        { "value": 'WA', "label": 'Washington' },
        { "value": 'WV', "label": 'West Virginia' },
        { "value": 'WI', "label": 'Wisconsin' },
        { "value": 'WY', "label": 'Wyoming' }
    ];

    countries = [
        { "value": 'US', "label": 'United States' }
    ];

    constructor(
        private db: AngularFireDatabase,
        private commonAPIService: CommonAPIService,
        private commonService: CommonService,
        // private pricing: PricingService,
        private authService: AuthService,
        // private statusService: StatusService,
        private notificationService: NotificationService,
    ) { 
        this.commonService.saveArtworkStatus.subscribe(async status => {
            if (status === false) {                
                this.isInitialCall = false;
                this.commonAPIService.proccesing = true;
            } else if(status === true){
                this.isInitialCall = false;
                this.commonAPIService.proccesing = false;
            }
          });
        this.updateOrderService.subscribe((value) => {            
            if (value) {
              this.order = value;
            }
        });
    }

    /**
    * get orders without page limit
    * 
    * @returns Array
    */
    getAllOrder() {
        return this.db.list('/orders').snapshotChanges();
    }

    createSplitAddressesObjOnEachGarment(order) {
        if (order && order.hasOwnProperty('shirts') && order?.shirts && order?.shirts?.length) {
            order?.shirts.forEach((element, i) => {
                if (element && element.hasOwnProperty('product_id') && element.product_id && element.hasOwnProperty('color') && element.color && element.color.color_id) {
                    order?.splitAddresses.forEach((item, j) => {
                        if (order?.shirts[i]?.hasOwnProperty('splitAddresses') && order?.shirts[i]?.splitAddresses?.length < order?.splitAddresses?.length) {
                            order.shirts[i].splitAddresses.push({ addressIndex: order?.shirts[i]?.splitAddresses?.length + 1, isDisplay: false, productId: element?.product_id, colorId: element?.color?.color_id, quantities: {}, firstName: element.firstName, lastName: element.lastName, email: element.email, address1: element.address1, address2: element.address2, method: element.method, city: element.city, state: element.state, zip: element.zip });
                        } else {
                            if (!order.shirts[i].hasOwnProperty('splitAddresses')) {
                                order.shirts[i].splitAddresses = [{ addressIndex: j + 1, isDisplay: false, productId: element?.product_id, colorId: element?.color.color_id, quantities: {} }];
                            } else if (order?.shirts[i]?.splitAddresses?.length == order?.splitAddresses?.length) {
                                let mappingOrder = this.mappingShirtsAndSplitAddresses(order);
                                order = mappingOrder;
                            }
                        }
                        order.shirts[i].splitAddresses = this.getUniqueItems(order?.shirts[i].splitAddresses);
                        this.countTotalQtyOfProduct(order);
                    });
                } 
            });
        }
        this.assignSplitAddressesOnGarment.next(true);
        return order;
    }

    addDeliveryDaysIndividualShipping(date) {
        date = moment(date);
        date = date.add(2, 'days');
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    getUniqueItems(items) {
        const uniqueIds = new Set(items.map((item) => item.addressIndex))
      
        const itemsWithUniqueIds = [...uniqueIds].map(id => items.find(item => item.addressIndex === id)).filter(Boolean)
      
        return itemsWithUniqueIds
    }

    mappingShirtsAndSplitAddresses(order) {
        if (order && order?.shirts && order?.shirts?.length) {
            order?.shirts?.forEach((element, i) => {
                order.splitAddresses.forEach((item, j) => {
                    let garment = item?.shirts && item?.shirts[i];
                    let address = element?.splitAddresses && element?.splitAddresses[j];
                    if (garment && address && (garment.addressIndex == address.addressIndex) && (garment.productId == address.productId) && (garment.colorId == address.colorId)) {
                        garment.quantities = address.quantities;
                        garment.totalQuantity = address.totalQuantity;
                    }
                });
            });

            order?.shirts?.forEach((shirt, i) => {
                shirt?.splitAddresses?.forEach((address, j) => {
                    if (address) {
                        let obj: any = {};
                        obj['addressIndex'] = address.addressIndex;
                        obj['quantities'] = address.quantities;
                        obj['productId'] = address.productId;
                        obj['colorId'] = address.colorId;
                        obj['totalQuantity'] = address.totalQuantity;
                        if (!order?.splitAddresses[j]?.hasOwnProperty('shirts')) {
                            order.splitAddresses[j].shirts = [];
                            order?.splitAddresses[j]?.shirts?.push(obj);
                        } else {
                            if (!order?.splitAddresses[j]?.shirts.length) {
                                order?.splitAddresses[j]?.shirts?.push(obj);
                            } else { }
                        }
                    }
                });
                order?.splitAddresses[i]?.shirts?.sort((a,b) => a.addressIndex - b.addressIndex);
            });
        }

        let shirtTotalQty = 0;
        let shirtTotalQtyOnEachSplitAddress = 0;
        if (order && order.shirts && order.shirts.length) {
            order.shirts.forEach((shirt, i) => {
                if (order.splitAddresses && order.splitAddresses.length) {
                    order.splitAddresses.forEach((address, j) => {
                        if (address.shirts && address.shirts.length) {
                            address.shirts.forEach((product, k) => {
                                if (shirt.product_id == product.productId && (shirt.color && shirt.color.color_id == product.colorId)) {
                                    shirtTotalQty = shirt.totalQty;
                                    shirtTotalQtyOnEachSplitAddress += product.totalQuantity;
                                }
                            });
                        }
                    });
                    if (shirtTotalQty != shirtTotalQtyOnEachSplitAddress) {
                        order.splitAddresses.forEach((addressOne, m) => {
                            if (addressOne.shirts && addressOne.shirts.length) {
                                addressOne.shirts.forEach((productOne, n) => {
                                    if (shirt.product_id == productOne.productId && (shirt.color && shirt.color.color_id == productOne.colorId) && m == 0) {
                                        // productOne.quantities = shirt.quantities;
                                        // productOne.totalQuantity = shirt.totalQty;
                                    }
                                });
                            }
                        });
                    }
                }
            });
        }
        return order;
    }

    getTotalQuantityByItem(productsQty) {
        return Object.values(productsQty).reduce((a, b) => Number(a) + Number(b), 0);
    }

    getTotalQuantityOnEachSplitAddresses(products, mainProducts) {
        let allQuantities = [];
        let productQuantities = [];
        let mainProductQuantities = [];
        if (products && products.length) {
            products.forEach(element => {
                if (element && element.quantities && Object.keys(element.quantities).length) {
                    for(let key in element.quantities) {
                        if(Number(element.totalQuantity) > 0 && element.quantities && element.quantities.hasOwnProperty(key)) {
                            let value = element.quantities[key];
                            if (value) {
                                productQuantities.push(value);
                            }
                        }
                    }
                }
            });

            if (productQuantities && !productQuantities.length) {
                products.forEach(element => {
                    (mainProducts || []).forEach(item => {
                        if (item && item.quantities && Object.keys(item.quantities).length) {
                            let selectedProduct = mainProducts?.find((product) => product.product_id == element.productId && product.color.color_id == element.colorId);
                            if (selectedProduct && !selectedProduct.rangeDisable) {
                                for(let key in item.quantities) {
                                    let value = selectedProduct.quantities[key];
                                    if (value) {
                                        value = value / item?.splitAddresses?.length;
                                        // mainProductQuantities.push(value);
                                        return
                                    }
                                }
                            }
                        }
                    });
                });
            }
            allQuantities = [...productQuantities, ...mainProductQuantities];
            return allQuantities.reduce((a, b) => Number(a) + Number(b), 0);
        }
    }

    padStarting(number, size) {
        number = number.toString();
        while (number.length < size) number = "0" + number;
        return number;
    }

    countTotalQtyOfProduct(order) {
        let sumValues: any = 0;
        if (order && order.shirts && order.shirts.length) {
            order.shirts.forEach((product, i) => {
                if (product && product.quantities) {
                    sumValues = Object.values(product.quantities).reduce((a, b) => Number(a) + Number(b), 0);
                }
                product.totalQty = sumValues;
           });
        this.calculateTotalProductOnEachAddress(order);
        //    this.commonAPIService.assignLineItemFlag = true;
        }
    }

    isSameUser = (a, b) => a.addressIndex === b.addressIndex && a.productId === b.productId && a.colorId == b.colorId;

    onlyInLeft = (left, right, compareFunction) => left.filter(leftValue => !right.some(rightValue => compareFunction(leftValue, rightValue)));

    getSubStatuses(data, status) {
        return data?.find((x) => x?.status?.toLowerCase() == status?.toLowerCase())?.substatuses;
    }

    getSubStatusValue(data, status, substatus) {
        let substatuses = data?.find((x) => x?.status?.toLowerCase() == status?.toLowerCase())?.substatuses;
        return substatuses?.find((x) => x?.substatus?.toLowerCase() == substatus?.toLowerCase())?.value;
    }

    fetchAvailableSizes(order) {
        let availableSizes = [];
        if (order && order.shirts && order.shirts.length) {
            order.shirts.forEach((shirt) => {
                if (shirt && shirt.hasOwnProperty('colorName') && shirt.hasOwnProperty('style')) {
                    const colorName = shirt?.colorName;
    
                    const colorsArray = Object.values(shirt?.style?.colors);
    
                    for (let color of colorsArray as any[]) {
                        if (color && color.length && color[0].colorName === colorName) {
                            color.forEach((item) => {
                                const obj = {
                                    sizeName: item.sizeName,
                                    sizeId: item.size_id
                                };
                                if (!availableSizes.some((item) => item.sizeId === obj.sizeId)) {
                                    availableSizes.push(obj);
                                }
                            });
                        }
                    }
                    availableSizes = _.orderBy(availableSizes, ['sizeId']);
                }
            });
        }
        return availableSizes || [];
    }

    isAddressIndividualShipping(order) {
        let shipMethods = [];
        if (order && order.hasOwnProperty('splitAddresses') && order.splitAddresses && order.splitAddresses.length) {
            order.splitAddresses.forEach(element => {
              if (element && element.method) {
                shipMethods.push(element.method);
              }
            });
        }
        if (shipMethods && shipMethods.length) {
            return shipMethods.includes(this.commonAPIService.INDIVIDUAL_SHIPPING);
        } else {
            return false;
        }
    }

    isAddressBulkShipping(order) {
        let shipMethods = [];
        if (order && order.hasOwnProperty('splitAddresses') && order.splitAddresses && order.splitAddresses.length) {
            order.splitAddresses.forEach(element => {
              if (element && element.method) {
                shipMethods.push(element.method);
              }
            });
        }
        if (shipMethods && shipMethods.length) {
            return shipMethods.includes(this.commonAPIService.BULK_SHIPPING);
        } else {
            return false;
        }
    }

    getTotalQuantity(order) {
        if (order && order.shirts && order.shirts.length > 0) {
            return _.reduce(order.shirts, function (totalQty, shirt: any) {
                if (shirt.totalQty) {
                    return totalQty += shirt.totalQty;
                } else {
                    return totalQty;
                }
            }, 0);
        } else {
            return 0;
        }
    }

    getStateCode(stateName) {
        return this.states.find(x => ((x.label).toLowerCase() == stateName.toLowerCase()) || ((x.value).toLowerCase() == stateName.toLowerCase()))?.value || '';
    }

    getTurnAroundValue(order) {
        if (order && order.turnAround && order.turnAround.value) {
            return order.turnAround.value;
        }
        return 0;
    }

    calculateTotalProductOnEachAddress(order) {
        let addresswiseProductdata = [];
        if (order && order.shirts && order.shirts.length) {
            order.shirts.forEach((element, i) => {
                if (element && element.splitAddresses && element.splitAddresses.length) {
                    element.splitAddresses.forEach((address, j) => {
                        if (address && address.quantities) {
                            let adddressItem = addresswiseProductdata.find((item) => {
                                return (item.addressIndex == address.addressIndex);
                            });
                            let sumValues = Object.values(address.quantities).reduce((a, b) => Number(a) + Number(b), 0);
                            let totalQuantity = Number(sumValues);
                            if (adddressItem) {
                                adddressItem.totalQuantity = adddressItem.totalQuantity + totalQuantity;
                            } else {
                                addresswiseProductdata.push({
                                    addressIndex: address.addressIndex,
                                    totalQuantity: totalQuantity
                                });
                            }
                        }
                    });
                }
            });
    
            addresswiseProductdata = _.orderBy(addresswiseProductdata, ['addressIndex']);
            order.splitAddresses.forEach((address, j) => {
                if (addresswiseProductdata && addresswiseProductdata[j] && addresswiseProductdata[j].totalQuantity) {
                    address.totalQuantity = addresswiseProductdata[j].totalQuantity;
                }
            });
        }
    }

    isOrderSavedSuccessfully() {                
        if (sessionStorage.getItem('is_save_order_completed') == 'false' || this.commonAPIService.proccesing) {
            alert (`Page Not Saved. 
Please close this pop up and allow 2-3 seconds for the page to save before exiting the page.`)            
            return false;
        }
        return true;
    }

    // Payload
    // order, clonedOrderForProduction
    async changeProductionLintedItemsAndShirts(){
        if(this.order){
            this.order.productionShirts = this.order.shirts
          if(this.order.isLocked && this.order.produtionLintedItems == undefined && this.order.productionShirts == undefined){
            this.order.produtionLintedItems = this.clonedOrderForProduction?.lintedItems || [];
          } else if(this.order.isLocked == false || this.order.isLocked == undefined) {
            this.order.produtionLintedItems = this.order.lintedItems || [];
          }
        }
    }

    // Payload
    // turnAroundList, order, turnAroundFreeOptionData

    // After execution is completed, set the below value in component file
    // $('.turn-select').val(this.order.turnAround.id).trigger('change');
    saveFreeTurnAround() {
        this.turnAroundList.forEach(element => {
            if ((element.print_method_ids == '1,2,3,4,5' || element.print_method_ids == '1,2,3,4') && element.amount == 0) {
                this.order.turnAround = element;
                // this.turnAroundFreeOptionData = element; // Not used
            }
        });
    }

    // Payload
    // shirtQuanty, order, shirtQuanty, ProdModalFormGroup
    fnGetFormGroup(payload: any) {
        let tempArray: Array<any> = [];
        let i = 0;
        payload.shirtQuanty = {};
        if (payload.order.shirts) {
            for (let shirt of payload.order.shirts) {
                let formArray: FormArray = new FormArray([]);
                tempArray.push(formArray);
                payload.shirtQuanty[i] = false;
                i++;
            }
        }
        payload.ProdModalFormGroup = tempArray;
    }

    async saveOrder() {
        // this.pricing.calculateRecountCharge.next(true);
        _.forEach(this.order, (elem, key) => {
            if (!elem) {
                delete this.order[key];
            }
        });
        _.forEach(this.order?.artwork?.positions, (image) => {
            delete image.colorSwatches;
            delete image.mainLocation;
            if (image.selectedColors) {
                for (let i = image.selectedColors.length - 1; i >= 0; i -= 1) {
                    if (!image.selectedColors[i]) {
                        image.selectedColors.splice(i, 1);
                    }
                }
            }
        });
        if (!this.order.salesPartner) {
            this.order.salesPartner = this.authService.getUser();
            delete this.order.salesPartner.$key;
            this.order.salesPartner.uid = this.authService.getUID();
        }
        await this.changeProductionLintedItemsAndShirts();
        // this.order.estimateCost = await this.pricing.getArtWorkEstimatedCost(this.order);
        if (this.order && this.order.turnAround && Object.keys(this.order.turnAround).length == 0 && Object.getPrototypeOf(this.order.turnAround) === Object.prototype) {
            this.saveFreeTurnAround();
        }
        if (this.key) {
            delete this.order.$key;
            if (this.order.shirts) {
                let updateShirt: Array<any> = [];
                for (let shirt of this.order.shirts) {
                    if (!_.isEmpty(shirt.quantities)) {
                        let shirtQuants = Object.keys(shirt.quantities).filter(e => shirt.quantities[e] !== '').reduce((o, e) => { o[e] = shirt.quantities[e]; return o; }, {});
                        shirt.quantities = shirtQuants;
                    }
                    shirt.totalCost = (Number.isNaN(shirt.totalCost)) ? 0 : shirt.totalCost;
                    if(shirt.style)
                        shirt.style.colors = shirt.color;
                    updateShirt.push(shirt);
                }
                this.order.shirts = updateShirt;
            }
            if (this.order.productionPartner) {
                let ppMemberData = await this.commonAPIService.getPpMemberByUserUID(this.order.productionPartner.user_id);
                while (!ppMemberData) {
                    await new Promise(resolve => setTimeout(resolve, 100));
                }
        
                this.ppCompanyId = ppMemberData['ppCompanyId'];
                let ppCompany = this.ppCompanies.find((company: any) => company.id === this.ppCompanyId);
                if (ppCompany) {
                    this.order.productionPartner['ppCompanyName'] = ppCompany.name;
                }    
            }
            // let newStatuses = this.statusService.getOrderStatusesObj(this.order.salesStatus);
            // if (newStatuses && newStatuses.hasOwnProperty('status') && newStatuses.hasOwnProperty('substatus')) {
            //     this.order.statuses = newStatuses;
            //     this.order.status = this.order.statuses.status;
            //     this.order.substatus = this.order.statuses.substatus;
            // }
            if (this.order.statuses.status == 'Artwork' && this.order.statuses.substatus == 'Artwork: Need Designer' && (!this.orderReference || this.orderReference.statuses.substatus != 'Artwork: Need Designer')) {
                this.order.isDPAssigned = false;
                this.notificationService.sendNewOrderDesigner(this.order, this.key);
            }
            if (this.order?.salesPartner?.permissionLevel == this.commonAPIService.USER_SP || this.order?.salesPartner?.permissionLevel == this.commonAPIService.USER_SELLER) {
                this.order.totalRevenue = Number(this.calculation.totalRevanue);
                this.order.totalProfit = Number(this.calculation.totalEstimatedProfit);

                let psmObj = this.order?.orderProfitShareMarkupDetails;
                let spObj = this.order?.salesPartner;

                psmObj.comboIFPFCS = psmObj?.comboIFPFCS ? psmObj?.comboIFPFCS : spObj?.comboIFPFCS ? spObj?.comboIFPFCS : -5;

                psmObj.kickbacksDonation = psmObj?.kickbacksDonation ? psmObj?.kickbacksDonation : spObj?.kickbacksDonation ? spObj?.kickbacksDonation : -5;

                psmObj.kickbacksInfluencer = psmObj?.kickbacksInfluencer ? psmObj?.kickbacksInfluencer : spObj?.kickbacksInfluencer ? spObj?.kickbacksInfluencer : -5;

                psmObj.licensingGreek = psmObj?.licensingGreek ? psmObj?.licensingGreek : spObj?.licensingGreek ? spObj?.licensingGreek : -5;

                psmObj.shipTypeIndividualShipping = psmObj?.shipTypeIndividualShipping ? psmObj?.shipTypeIndividualShipping : spObj?.shipTypeIndividualShipping ? spObj?.shipTypeIndividualShipping : -10;

                psmObj.paymentTypeOnlineStore = psmObj?.paymentTypeOnlineStore ? psmObj?.paymentTypeOnlineStore : spObj?.paymentTypeOnlineStore ? spObj?.paymentTypeOnlineStore : 0;

                psmObj.variantsTQ = psmObj?.variantsTQ ? psmObj?.variantsTQ : spObj?.variantsTQ ? spObj?.variantsTQ : -5;

                psmObj.nftProject = psmObj?.nftProject ? psmObj?.nftProject : spObj?.nftProject ? spObj?.nftProject : -5;
                psmObj.splitShipment = psmObj?.splitShipment ? psmObj?.splitShipment : spObj?.splitShipment ? spObj?.splitShipment : -5;

                psmObj.markUp = psmObj?.markUp ? psmObj?.markUp : spObj?.markup ? spObj?.markup : this.DEFAULT_MARKUP;
                psmObj.profitShare = psmObj?.profitShare ? psmObj?.profitShare : spObj?.profitShare ? spObj?.profitShare : 50;
                psmObj.baseProfit = psmObj?.baseProfit ? psmObj?.baseProfit : spObj?.baseProfit ? spObj?.baseProfit : 45; 
                psmObj.SPUID = spObj.uid;
                psmObj.profitType = psmObj?.profitType ? psmObj?.profitType : spObj?.profitType ? spObj?.profitType : 'Dynamic';
            }
            this.order.isArtworkFileRequested = this.order.isArtworkFileRequested ? this.order.isArtworkFileRequested : 0;
            if (this.order.artworkFee) {
                this.order.artworkFee = this.order.artworkFee;
            } else {
                this.order.artworkFee = 0;
            }
            let updateOrderData = await this.commonAPIService.fnSaveOrder(this.order);
        }
    }
confirmOrder: any
    setOrder(order: any, carrier: any) {
        this.confirmOrder = {
            ...order,
            carrier: carrier
        };
        // this.s
        console.log('JJJJ2 set', this.confirmOrder);
      }
    
      getOrder(): any {
        console.log('JJJJ2 get', this.confirmOrder);
        return this.confirmOrder;
      }

}
