<div class="parent" *ngIf="isOpen">

<div class="background" (click)="closemodal()">
</div>
<div class="close-button" (click)="closemodal()">
    <span>X</span>
</div>

<div class="image-div">
    <img [src]="imageUrl" alt="">
</div>
</div>