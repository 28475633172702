import { Directive,OnInit,OnDestroy,ElementRef,Renderer2 } from '@angular/core';

@Directive({
  selector: '[keep-square]'
})
export class KeepSquareDirective implements OnInit {
  interval : any;
  constructor(private el: ElementRef,private renderer: Renderer2) {
    renderer.listen(window, "resize",event=>{
      this.ngOnInit();
    })
    renderer.listen(el.nativeElement,"open",event=>{
        
      this.ngOnInit();
    })
   }

  ngOnInit(){
    // this.interval = setInterval(()=>{
    //   this.el.nativeElement.style["height"] = this.el.nativeElement.clientWidth + "px";
    // },500)
    setTimeout(()=>{
      this.el.nativeElement.style["height"] = this.el.nativeElement.clientWidth + "px";
    },300)
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }

}
