import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}


// webcomponentsReady().then(() => {
//   // requires "module: "esnext" in tsconfig.json "compilerOptions" and
//   // "angularCompilerOptions": {
//   //   "entryModule": "app/app.module#AppModule"
//   // }
//   return import('./app/app.module');
// }).then(({ AppModule }) => {

//   console.log("module intit");
//   platformBrowserDynamic().bootstrapModule(AppModule);
// });





function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule);
}

bootstrap();
