import { Component, OnInit, Input,OnChanges, Output, EventEmitter, ChangeDetectorRef, HostListener,SimpleChanges } from '@angular/core';
import { NotificationService } from 'app/utils/services/notification.service';
import { CommonAPIService } from 'app/utils/services/common-api.service';
import { AuthService } from 'app/utils/services/auth.service';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import * as _ from 'lodash'
import { FirebaseKeyHandler } from 'app/utils/models/firebase_key_handler';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare const $:any;
import { WebSocketService } from 'app/utils/services/web-socket.service';
import * as moment from 'moment';
@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit,OnChanges {

  filterByUnread:boolean = true;
  editPath: string = '/sales/';
 
  filteredNification:Array<any> = [];
  pagination:any  = {};
  paginationAll:any  = {};
  unreadNotifications = [];
  hideNotifications:boolean  = true;
  isLoading:boolean = false;
  isFetchingData = false
  uid:number
  startedAt:string;
  page: number
  lastPage: number
  isButtonDisabled:boolean
  notifications : Array<any> = [];
  @Input('auth') auth : any;
  constructor(
    private notificationService:NotificationService,
    private commonAPIService:CommonAPIService,
    private  route:Router,
    private authService:AuthService,
    // private db: AngularFireDatabase,
    private http: HttpClient,
    private webSocketService: WebSocketService,
    private cdr: ChangeDetectorRef
  ) {


  }
  formattedTimestamp(timestamp: string): string {
    return moment(timestamp).format('h:mm A M/D/YYYY').toLowerCase();
  }

  @HostListener('window:resize')
  onWindowResize() {
    const screenWidth = window.innerWidth;
    this.webSocketService.listen('notification').subscribe((event: any) => {
      this.fnUnreadNotification()
    });
    if (screenWidth <= 800) {
      this.fnUnreadNotification();
    }
    if(!this.filterByUnread) this.fetchNotifications();
  }

  get notificationBadgeLabel(): any {
    const count = this.unreadNotifications.length;
    return count > 20 ? '20+' : count.toString();
  }
 
  
  ngOnInit() {

    this.page = 1;

    this.webSocketService.listen('notification').subscribe((event: any) => {
      this.fnUnreadNotification()
    });

    this.authService.checkAuthAndCallback((auth) => {
      this.uid = auth.uid

      this.webSocketService.openTheConnection(null, this.uid);
      if (this.authService.isUserAuthorized()) {
        // if they are a design partner
        if (this.authService.isUserDesignPartner()) {
          this.editPath = '/design/';
          // if they are a prod partner
        } else if (this.authService.isUserProductionPartner()) {
          this.editPath = '/production/';
        }
      }
      this.commonAPIService.fnfetchTotalNotificationCount(this.uid).then((totalNotifications:any) => {
        this.lastPage = Math.ceil(totalNotifications / 51);
    })
    });

    if(this.filterByUnread) this.fnUnreadNotification();
    else this.fetchNotifications();

    var $this = this;
    $('body').on('click', '.notification-badge', function () {
      //function code
      $this.toggleNotificationDropdown();
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    // Handle changes in specific properties here
    if (changes.unreadNotifications) {
      this.fnUnreadNotification();
    }
  }

  fnUnreadNotification() {
    this.commonAPIService.fnGetUnreadNotification(this.uid).then((data: any[]) => {
      let notifications: any[];
      notifications = data;
      this.unreadNotifications = notifications;
      this.resetPagination();
      this.loadNextPageData()
    }).catch(error => console.log(error));
  }

  fetchNotifications() {
    this.isFetchingData = true
    this.commonAPIService.fnGetAllNotification(this.uid, this.page).then((data: any[]) => {
      if (this.page === this.lastPage) this.isButtonDisabled = true;
      let notifications: any[];
      notifications = data;
      this.notifications.push(...notifications);
      this.isFetchingData = false
      // this.resetPagination();
      this.loadNextPageDataAll()
    }).catch(error => console.log(error))

  }

  async fnMarkAsRead() {
    this.isLoading = true;
    this.commonAPIService.fnmarkAsAllRead(this.uid).then(res => {
      this.isFetchingData = true
    }).catch(error => console.log(error))
    setTimeout(async () => {
      this.isLoading = false;
    }, 1000);
    if(this.filterByUnread == true) {
      this.resetPagination();
      this.loadNextPageData();
    }else{
      this.filterByUnread = true;
      if (this.page === this.lastPage) {
        this.page = 1;
        this.isButtonDisabled = false;
      }
    }
  }

  resetPaginationAll(){
    this.filteredNification = [];
    this.notifications=[]
    this.paginationAll = {
      pageSize : 50,
      page:0,
      isFinished : false
    };
  }
  resetPagination() {

    this.filteredNification = [];
    
    this.pagination = {
      pageSize : 50,
      page:1,
      isFinished : false
    };
  }

  hide() {
    this.toggleNotificationDropdown();
    this.filterByUnread = true;
    this.resetPagination();
    this.loadNextPageData();
  }

  fnFilterByUnread() {
    return this.unreadNotifications
     // .filter(notifi => {
    //     if( !notifi.isRead || this.filterByUnread == false) {
    //       return notifi;
    //     }
    //   })
    .filter(notifi => {
      return (notifi != undefined && notifi != '' && notifi != 'undefined');
      })
      .map(notifi => {
        notifi.message = notifi.message ? ( notifi.message as String).replace(new RegExp("Order #"+notifi.orderId+ ":"),"") : "";
        return notifi;
     });
  }

  fnFilter() {
    this.page = 1
    if(this.page !== this.lastPage) this.isButtonDisabled = false;
    this.filterByUnread = !this.filterByUnread ;
    if(this.filterByUnread){
      this.resetPagination();
      this.loadNextPageData();
    }
    else{
      this.startedAt = '';
      this.resetPaginationAll();
      this.fetchNotifications();
    }
  }

  readNotification(notification) {
    let user = this.commonAPIService.fnGetUser();
    if (! notification.isRead) {
      notification.isRead = true;
      this.notificationService.readNotification(user.id, notification).then(() => {
        this.unreadNotifications = this.unreadNotifications.filter(notific => notific.notificationId !== notification.notificationId)
        this.resetPagination();
        this.loadNextPageData();
      });
    }
    this.hide();
    this.route.navigate([this.editPath,notification.orderId]);
  }

  changeStatus(notification) {
    let user = this.commonAPIService.fnGetUser();
    notification.isRead = !notification.isRead;
    this.notificationService.readNotification(user.id, notification).then(res => {
      if(this.filterByUnread){
        this.unreadNotifications = this.unreadNotifications.filter(notific => notific.notificationId !== notification.notificationId)
        this.resetPagination();
        this.loadNextPageData();
      }
      else{
        this.startedAt=''
        this.notifications = this.notifications.map((notific) => {
          if (notific.notificationId === notification.notificationId) {
            notific.isRead = notification.isRead;
          if (notification.isRead == false) {
            const existingNotification = this.unreadNotifications.find(n => n.notificationId === notification.notificationId);

            if (!existingNotification) {
              this.unreadNotifications.push(notification);
            }
          } else {
            this.unreadNotifications = this.unreadNotifications.filter(n => n.notificationId !== notification.notificationId);
          }
        }
          return notific;
        });
      }
    });
  }

  loadNextPageData() {
    let totalNotification:Array<any> = this.fnFilterByUnread();
    let start = (this.pagination.page * this.pagination.pageSize) - this.pagination.pageSize;
    let end  = this.pagination.page * this.pagination.pageSize;
    let slice:Array<any> = [];

    if(this.pagination.isFinished == false) {
     if (totalNotification.length > 0) {
       if (totalNotification.length > this.pagination.pageSize) {
         slice = totalNotification.slice(start, end);
       } else {
         slice = totalNotification;
         this.pagination.isFinished = true;
       }
       if (slice.length == 0) {
         this.pagination.isFinished = true;
         return;
       }
       this.pagination.page++;
       this.filteredNification.push(...slice);
     }
    }
}

  loadNextPageDataAll() {
    let totalNotification:Array<any> = this.notifications
    .filter(notifi => {
     return (notifi != undefined && notifi != '' && notifi != 'undefined');
      })
      .map(notifi => {
        notifi.message = notifi.message ? ( notifi.message as String).replace(new RegExp("Order #"+notifi.orderId+ ":"),"") : "";
        return notifi;
      });
      let start = (this.paginationAll.page * this.paginationAll.pageSize);
      let end  = this.paginationAll.page * this.paginationAll.pageSize + this.paginationAll.pageSize;
      let slice:Array<any> = [];
       // console.log(totalNotification.length , this.paginationAll.page * this.paginationAll.pageSize);
      if(this.paginationAll.isFinished == false) {

      if (totalNotification.length > 0) {
        if (totalNotification.length > this.paginationAll.page * this.paginationAll.pageSize) {
          slice = totalNotification.slice(start, end);

        } else {
          // slice = totalNotification;
          this.paginationAll.isFinished = true;
        }
        if (slice.length == 0) {
          this.paginationAll.isFinished = true;
          return;
        }
        this.paginationAll.page++;
        this.filteredNification.push(...slice);
        // this.filteredNification = [...this.filteredNification.reduce((map, obj) => map.set(obj.$key, obj), new Map()).values()];

      }
    }
  }

  readAllNotifications() {
    let updateNotifications: Object = {};
    let user = this.commonAPIService.fnGetUser();
    for (let notification of this.unreadNotifications) {
      if (!notification.isRead) {
        let key = notification.$key;
        notification.isRead = true;
        delete notification.$key;
        if (notification.user) {
          delete notification.user;
        }
        updateNotifications[this.auth.uid + "/" + key] = notification;
        // this.db.object("notifications/" + this.auth.uid + "/" + key).update(notification);
        // this.db.object("notifications/" + user.id + "/" + key).update(notification);
        console.log('Done index =', key);
      }
    }
  }

  toggleNotificationDropdown() {
    this.hideNotifications = !this.hideNotifications;
  }

  loadMoreNotifications() {
    if (this.page === this.lastPage) {
      this.isButtonDisabled = true;
      return; // Stop fetching notifications
    }
  
    if (this.page > this.lastPage) {
      this.page = 1;
      this.isButtonDisabled = true;
      return; // Stop fetching notifications
    }
  
    this.page++;
    this.fetchNotifications();

  }
  
  @HostListener('scroll', ['$event']) 
  onScrollNoti($event) {
    let scrollTop = $event.target.scrollTop;
    let scrollHeight = $event.target.scrollHeight;
   
    if(this.filterByUnread && scrollTop > (scrollHeight - 1000)) {
      this.loadNextPageData();
      // this.fetchNotifications()
    }
  }

}
