import {Injectable, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFireDatabase} from "@angular/fire/database";

import * as _ from "lodash";
import * as firebase from "firebase/app";
import { HttpClient } from '@angular/common/http';
import { FirebaseKeyHandler } from '../models/firebase_key_handler';
import { BehaviorSubject } from 'rxjs';
import { CommonAPIService } from './common-api.service';

declare const window: any;

@Injectable()
export class AuthService implements OnInit {

  isModalOpen: BehaviorSubject<any> = new BehaviorSubject(null);
  modalDialog: BehaviorSubject<any> = new BehaviorSubject(null);
  auth: any;
  isAdmin: boolean = false;
  isCSD: boolean = false;
  isOperationsManager: boolean = false;
  isDesignPartner: boolean = false;
  isProductionPartner: boolean = false;
  isSalesPartner: boolean = false;
  isAuthorized =  null;
  callbacks: any[] = [];
  permission: any;
  user: any;
  subscribedFunctions: Function[] = [];
  isLoading = true;
  userAuthorizedFlag: BehaviorSubject<any> = new BehaviorSubject(null);
  userDataAssignedFlag: BehaviorSubject<any> = new BehaviorSubject(null);
  DEFAULT_MARKUP = 10;

  constructor(private af: AngularFireAuth,
              private router: Router,
              private http: HttpClient,
              private db: AngularFireDatabase,
              private commonAPIService : CommonAPIService
              ) {
    af.user.subscribe(async (auth) => {
      this.auth = await af.currentUser;
      af.currentUser.then(res => console.log(res,"cuurent"));
      console.log(auth,"auth"); 
      if (auth) {
        // window.subscriptions.push(this.db.object(`/permissions/${this.auth.uid}`).snapshotChanges().subscribe((permissions:any) => {
        //   permissions = FirebaseKeyHandler.covertSingle(permissions);
        //   if(permissions != null) {
        //   this.permission = permissions;
        //   this.isAdmin = permissions.permissionLevel == "Admin";
        //   this.isCSD = permissions.permissionLevel == "Collegiate Sales Director";
        //   this.isDesignPartner = permissions.permissionLevel == "Design Partner";
        //   this.isProductionPartner = permissions.permissionLevel == "Production Partner";
        //   this.isSalesPartner = permissions.permissionLevel == "Seller";
        //   this.isAuthorized = permissions.permissionLevel === "Admin" || permissions.permissionLevel === "Seller" || permissions.permissionLevel === "Design Partner" || permissions.permissionLevel === "Production Partner" || permissions.permissionLevel === "Collegiate Sales Director";
          
        //   if(this.isSalesPartner == true && this.user ) {
        //     this.user.markup =  30;
        //     this.user.profitShare = this.user.profitShare ? this.user.profitShare : 50;
        //   }
                    
        //   if (this.user) {
        //     _.forEach(this.callbacks, callback => {
        //       callback(auth)
        //     })
        //     this.callbacks = [];
        //   }
        //   if (this.router.url == "/" && this.isAuthorized) {
        //     this.router.navigateByUrl("view");
        //   } else if (!this.isAuthorized) {
        //     this.router.navigateByUrl("");
        //   }
        //   _.forEach(this.subscribedFunctions, (fxn) => {
        //     fxn(this.auth, this.isAdmin, this.isAuthorized);
        //   })
        //   this.isLoading = false;
        // } else {
        //   this.isAuthorized = false;
        //   this.isLoading = false; 
        // }
        // },
        // error => {
        //   this.isAuthorized = false;
        //   this.isLoading = false;
        // }));
        // console.log("going to subscribe");
        // window.subscriptions.push(this.db.object(`/users/${this.auth.uid}`).snapshotChanges().subscribe((user:any) => {
        //    user = FirebaseKeyHandler.covertSingle(user);
        //   window.subscriptions.push(this.db.object(`/permissions/${this.auth.uid}`).valueChanges().subscribe((permissions:any) => {
        //     if (user  == null || !this.isUserProfileComplete(user)) {
        //       this.setUserInfo();
        //       router.navigateByUrl("profile/edit");
        //     }
        //     this.user = user;
        //     this.setUserPhotoURL();
        //     this.user.permissionLevel = permissions == null ? 'Not Authorized': permissions.permissionLevel;
        //     this.permission = this.permission == null ? this.user.permissionLevel : this.permission;
        //     console.log(this.user);
        //     if (this.permission) {
        //       _.forEach(this.callbacks, callback => {
        //         callback(auth)
        //       })
        //       this.callbacks = [];
        //     }
        //     _.forEach(this.subscribedFunctions, (fxn) => {
        //       fxn(this.auth, this.isAdmin, this.isAuthorized);
        //     })
        //      this.isLoading = false;
        //   }));
        // }))
        let authUser = await this.commonAPIService.fnGetUserByEmail(this.auth.email);
        
        if (authUser == null || !this.isUserProfileComplete(authUser)) {
          this.isAuthorized = false;
          this.userAuthorizedFlag.next(this.isAuthorized);
          this.isLoading = false;
        } else {
          this.user = authUser;
          this.auth = {...this.auth, ...this.user};
          this.user.permissionLevel == null ? this.commonAPIService.USER_NOT_AUTHORIZED : this.user.permissionLevel;
          this.permission = this.user.permissionLevel;
          if (this.user.permissionLevel != null) {
            this.isAdmin = this.user.permissionLevel == this.commonAPIService.USER_ADMIN;
            this.isCSD = this.user.permissionLevel == this.commonAPIService.USER_CSD;
            this.isOperationsManager = this.user.permissionLevel == this.commonAPIService.USER_OM;
            this.isDesignPartner = this.user.permissionLevel == this.commonAPIService.USER_DP;
            this.isProductionPartner = this.user.permissionLevel == this.commonAPIService.USER_PP;
            this.isSalesPartner = this.user.permissionLevel == this.commonAPIService.USER_SP || this.user.permissionLevel == this.commonAPIService.USER_SELLER;
            this.isAuthorized = this.user.permissionLevel === this.commonAPIService.USER_ADMIN || this.user.permissionLevel === this.commonAPIService.USER_SP || this.user.permissionLevel === this.commonAPIService.USER_SELLER || this.user.permissionLevel === this.commonAPIService.USER_DP || this.user.permissionLevel === this.commonAPIService.USER_PP || this.user.permissionLevel === this.commonAPIService.USER_CSD || this.user.permissionLevel === this.commonAPIService.USER_OM;
            this.userAuthorizedFlag.next(this.isAuthorized);
            this.userDataAssignedFlag.next(this.user);

            if (this.isSalesPartner == true && this.user) {
              this.user.markup = this.DEFAULT_MARKUP;
              this.user.profitShare = this.user.profitShare ? this.user.profitShare : 50;
            }
            if (!this.isAuthorized) {
              this.isLoading = false;
              this.router.navigateByUrl("");
            } else {
              let authToken = this.commonAPIService.fnGetAuthToken();
              if (!authToken) {
                  this.logout();
              } else {
                this.isLoading = true;
                if (this.user) {
                  _.forEach(this.callbacks, callback => {
                    callback(auth);
                  })
                  this.callbacks = [];
                }
                _.forEach(this.subscribedFunctions, (fxn) => {
                  fxn(this.auth, this.isAdmin, this.isAuthorized);
                });
              }
            }
          } else {
            this.isAuthorized = false;
            this.userAuthorizedFlag.next(this.isAuthorized);
            this.isLoading = false;
          }
        }
      } else {
        this.isLoading = false;
      }
    })

  }
  isUserProfileComplete(user): boolean {
    if(user.name &&
      user.address &&
      user.city &&
      user.state &&
      user.zip) {
      return true;
    }
    return false;
  }

  getUID() {
    return this.auth.uid
  }

  setUserInfo() {
    console.log("come here for the user info",this.auth);
    // if((this.auth.facebook && this.auth.facebook.displayName) || (this.auth && this.auth.displayName)) {
    //   this.db.object(`/users/${this.auth.uid}/name`).set(this.auth.facebook.displayName || this.auth.displayName);
    // }

    if ((this.auth.facebook && this.auth.facebook.displayName) || (this.auth && this.auth.displayName)) {
      if (this.auth.facebook && this.auth.facebook.displayName) {
        this.db.object(`/users/${this.auth.uid}/name`).set(this.auth.facebook.displayName);
      } else {
        this.db.object(`/users/${this.auth.uid}/name`).set(this.auth.displayName);
      }
    }
    //TODO
    this.db.object(`/users/${this.auth.uid}/email`).set(this.auth.email);
  }

  setUserPhotoURL() {
    var url = this.auth.facebook ? this.auth.facebook.photoURL : null;
    if(url && !this.user.photo) {
      this.db.object(`/users/${this.auth.uid}/photo`).set(url);
      this.db.object(`/users/${this.auth.uid}/facebookPhoto`).set(url);
    }
  }

  readFile(file, callback){
    let reader = new FileReader();
    // Set a callback funtion to fire after the file is fully loaded
    reader.onload = () => {
      // callback with the results
      callback(reader.result);
    }

    // Read the file
    reader.readAsDataURL(file);
  }



  ngOnInit() {
      
  }

  isUserAdmin() {
    return this.isAdmin;
  }

  isUserCSD() {
    return this.isCSD;
  }

  isUserOM() {
    return this.isOperationsManager;
  }

  isUserAuthorized() {
    return this.isAuthorized;
  }

  isUserSalesPartner() {
    return this.isSalesPartner;
  }

  isUserDesignPartner() {
    return this.isDesignPartner;
  }

  isUserProductionPartner() {
    return this.isProductionPartner;
  }

  
  linkWithFacebook() {
    var provider = new  firebase.default.auth.FacebookAuthProvider();
    return new Promise((resolve,reject) => {

      this.af.authState.subscribe(res => resolve(res.linkWithPopup(provider)),error => reject(error))
    });
  }

  isFacebookAccount(): boolean {
    return this.isAccountType('facebook.com');
  }

  isEmailAccount(): boolean {
   return this.isAccountType('password');
  }

  linkWithEmail(email: string, password: string) {
    var credential = firebase.default.auth.EmailAuthProvider.credential(email, password);

    return this.auth.getAuth().auth.link(credential);
  }


  getUserName() {
    if (!this.user) {
      return ""
    } else {
      return this.user.name
    }
  }

  getUser() {
    console.log('this.user===>', this.user);
    
    if (this.user) {
      this.user.uid = (this.user.$key) ? this.user.$key : (this.user.user_id) ? this.user.user_id : 0 ;
      return this.user;
    } else {
      return null
    }
  }

  checkAuthAndCallback(callback) {
    console.log(this.permission,this.user,"user and permision");
     if (this.permission != null && this.user != null) {
      callback(this.auth);
    } else {
      this.callbacks.push(callback);
    }
  }

  public subscribe(fxn: Function) {
    this.subscribedFunctions.push(fxn);
  }


  public login() {
    console.log('User login');
    
    return this.af.user.toPromise();
  }

  public logout() {

    _.forEach(window.subscriptions, (subscription) => {
      
      subscription.unsubscribe()
    });

    this.isAuthorized = null;
    this.userAuthorizedFlag.next(this.isAuthorized);
    this.isAdmin = false;

    window.subscriptions = [];

    this.af.signOut().then(res => {
      this.commonAPIService.fnAuthLogOut(this.auth.email); // Remove API Auth Token from Sessions
      this.router.navigateByUrl("/");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }

  public isProfileComplete() {
    if(!this.user) {
      return true;
    }
    let valid = false;

    if (this.permission.permissionLevel == "Admin" || this.permission.permissionLevel == "Collegiate Program Coordinator" || this.permission.permissionLevel == "Operations Manager") {
      valid = true;
    }

    if (this.permission.permissionLevel == "Seller") {
      if (this.user.hasOwnProperty('name') &&
        this.user.hasOwnProperty('address') &&
        this.user.hasOwnProperty('email')
      ) {
        valid = true;
      }
    }

    if (this.permission.permissionLevel == "Design Partner") {
      if (this.user.hasOwnProperty('name') &&
        this.user.hasOwnProperty('address') &&
        this.user.hasOwnProperty('email') &&
        this.user.hasOwnProperty('company')
      ) {
        valid = true;
      }
    }

    if (this.permission.permissionLevel == "Production Partner") {
      valid = true;
    }

    return valid;
  }

  emailSignUp(email:string, password:string){
    return this.af.createUserWithEmailAndPassword(email, password);
  }

  emailLogin(email: string, password: string) {
    return new Promise(async (resolve, reject) => {
      resolve(this.af.signInWithEmailAndPassword(email, password));
    });
  }



  // isFacebookAccount(): boolean {
  //   return this.isAccountType('facebook.com');
  // }

  // isEmailAccount(): boolean {
  //  return this.isAccountType('password');
  // }


  async reauthenticateWithCredential(currentPassword: string) {
    var email: string = this.user.email;
    var credential = firebase.default.auth.EmailAuthProvider.credential(email, currentPassword);
    
    let user = await  this.af.currentUser;
    return user.reauthenticateWithCredential(credential);

  }

  changePassword(newPassword: string) {
    return this.af.currentUser.then( user => user.updatePassword(newPassword));
  }

  resetPassword(email: string) {
    var auth = firebase.default.auth();

    return auth.sendPasswordResetEmail(email);
  }

  private isAccountType(type: string): boolean {
    var providers = firebase.default.auth().currentUser.providerData;
    for(let i=0;i<providers.length;i++) {
      if(providers[i] != undefined && providers[i].providerId == type) {
        return true;
      }
    }
    return false;
  }
}
 
  /* commented the code due to not used any where */
  // private isAccountType(type: string): boolean {
    // var providers = this.af.currentUser.providerData;

    // for(let i=0;i<providers.length;i++) {
    //   if(providers[i].providerId == type) {
    //     return true;
    //   }
    // }
    // return false;
  // }

