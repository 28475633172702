import { NgModule } from '@angular/core';

import { RouterModule, Routes  } from '@angular/router';
import { AuthGuard } from './gards/auth-guard.service';
import { ImportProductService } from './utils/components/import-product/services/import-product.service';

// These are our app routes and where they go
const AppRoutes: Routes = [
  { path: 'ppdashboard',loadChildren:() => import('app/modules/ppdashboard/ppdashboard.module').then(m => m.PPDashboardModule),canActivate:[AuthGuard]},
  { path: 'chat',loadChildren:() => import('app/modules/chats/chats.module').then(m => m.ChatsModule),canActivate:[AuthGuard]},
  { path: 'forgotPassword',loadChildren:() => import('app/modules/forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
  { path: '',loadChildren:() => import('app/modules/login/login.module').then(m => m.LoginModule)},
  { path: 'sales',loadChildren:() => import('app/modules/sales/sales.module').then(m => m.SalesModule),canActivate:[AuthGuard]},
  { path: 'design',loadChildren:() => import('app/modules/design/design.module').then(m => m.DesignModule),canActivate:[AuthGuard]},
  { path: 'production/pricing',loadChildren:() => import('app/modules/pricing-list/pricing-list.module').then(m => m.PricingListModule),canActivate:[AuthGuard]},
  { path: 'production',loadChildren:() => import('app/modules/production/production.module').then(m => m.ProductionModule),canActivate:[AuthGuard]},
  { path: 'admin',loadChildren:() => import('app/modules/admin/admin.module').then(m => m.AdminModule),canActivate:[AuthGuard]},
  { path: 'profile',loadChildren:() => import('app/modules/profile/profile.module').then(m => m.ProfileModule),canActivate:[AuthGuard]},
  { path: 'edit',redirectTo:"sales/edit",canActivate:[AuthGuard]},
  { path: 'products/manage',loadChildren:() => import('app/modules/admin-product-list/admin-product-list.module').then(m => m.AdminProductListModule),canActivate:[AuthGuard]},
  { path: 'start-an-order', loadChildren: () => import('app/modules/start-an-order/start-an-order.module').then(m => m.StartAnOrderModule)},
  // { path: 'checkout', loadChildren: () => import('app/modules/create-an-order/create-an-order.module').then(m => m.CreateAnOrderModule)},
  { path: '', loadChildren: () => import('app/modules/create-an-order/create-an-order.module').then(m => m.CreateAnOrderModule)},
  { path: 'products',loadChildren:() => import('app/modules/products-list/products-list.module').then(m => m.ProductsListModule)},
  { path: 'p/edit',loadChildren:() => import('app/modules/product-edit/product-edit.module').then(m => m.ProductEditModule),canActivate:[AuthGuard]},
  { path: "p",loadChildren:() => import('app/modules/product-view/product-view.module').then(m => m.ProductViewModule)},
  { path: 'products/manage/categories',loadChildren:() => import('app/modules/category-management/category-management.module').then(m => m.CategoryManagementModule),canActivate:[AuthGuard]},
  { path: 'products/manage/colors',loadChildren:() => import('app/modules/color-management/color-management.module').then(m => m.ColorManagementModule),canActivate:[AuthGuard]},
  // { path: "products/:supplier/:brand",loadChildren:() => import('app/modules/product-view/product-view.module').then(m => m.ProductViewModule)},
  { path: "products/:brand/:id",loadChildren:() => import('app/modules/new-product-view/new-product-view.module').then(m => m.NewProductViewModule)},
  { path: 'error', loadChildren:() => import('app/modules/error-page/error-page.module').then(m => m.ErrorPageModule)}

];
 
@NgModule({ 
  imports: [
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy',scrollPositionRestoration: "top" })
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard
  ],
  declarations: [

  ]
})
export class AppRoutingModule { }
