import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PPDashboardRoutingModule } from './ppdashboard-routing.module';
import { PpMembersComponent } from './components/pp-members/pp-members.component';


@NgModule({
  declarations: [PpMembersComponent],
  imports: [
    CommonModule,
    FormsModule,
    PPDashboardRoutingModule
  ]
})
export class PPDashboardModule { }
