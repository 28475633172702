import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
@Injectable()
export class NavigationService {

  public onOrderPage: boolean = false;
  showSystemLoader = false;
  showOrderChatIcon = false;
  isIOSDevice = false;

  constructor(private router: Router) { }

  public isNavEnabled(){
    return "not-active"
  }

}
