<div class="backgroud-layer" [hidden]="hideNotifications" (click)="hide()">

</div>
<div class="notification-list" [class.empty-box]="fnFilterByUnread().length == 0" [hidden]="hideNotifications">
    <i class="fa fa-sort-asc brand-sort-asc" aria-hidden="true"></i>
    <div class=header-content>
        <!-- <div class="close-button">
            <span (click)="hide()">X</span>
        </div> -->
        <!-- header -->
        <!-- <div class="header">
            <span>Notifications</span>
            <hr>
        </div> -->
        <!-- filter option -->
        <div class="filter">
            <div class="unread"> <span (click)="fnFilter()">{{filterByUnread ? "View All": "Filter by Unread"}}</span>
            </div>
            <div class="mark-all-read"> <span (click)="fnMarkAsRead()">Mark All as Read</span></div>
        </div>
    </div>
    <!-- listing of messages -->
    <div class="noti-list" (scroll)="onScrollNoti($event)">
        <div class="noti-list-wrapper">
            <div class="noti-empty" *ngIf="filteredNification.length == 0  && !isLoading">
                <!-- <div class="icon-section">
                </div> -->
                <span class="image-section">
                    <i class="fa fa-comment-o" aria-hidden="true"></i>
                </span>
                <br><br>
                <span>
                    No Unread Notifications
                </span>
                <br>
                <small>
                    Click <u><span class="view-all-in-empty-message link"
                            (click)="fnFilter()">{{filterByUnread ? "View All": "Filter by Unread"}}</span></u> to view
                    all of your notifications
                </small>
            </div>
            <div class="noti-empty" *ngIf="isLoading">
                <paper-spinner active></paper-spinner>
                <br><br>
                <!-- <span class="updating-messages">
                   <strong>Please wait, Updating messages as read and will take few minutes!</strong>
                </span>
                <br><br> -->
            </div>
            <ng-container *ngIf="filteredNification.length > 0 && !isLoading">
                <div class="position-relative" *ngFor="let notification of filteredNification; let i=index">
                    <div>
                    <div class="single-notification" [class.filer]="filterByUnread">
                        <a [routerLink]="editPath + notification.orderId" (click)="readNotification(notification)">
                            <div class="order-number">Order #{{notification.orderId}} |
                                {{notification.jobName ? notification.jobName : ""}}</div>
                        </a>
                        <a [routerLink]="editPath + notification.orderId" (click)="readNotification(notification)">
                            <div class="message">{{ notification.message }}
                            </div>
                        </a>
                        <a [routerLink]="editPath + notification.orderId" (click)="readNotification(notification)">
                            <div class="action-info">
                                <div></div>
                                <div class="date">
                                    {{ formattedTimestamp(notification.timestamp) }}
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="read-noti-position">
                        <div class="read-flag ">
                            <div class="dark" [tooltip]="(notification.isRead ? 'Mark Unread' : 'Mark Read')"
                                [class.green]="!notification.isRead" (click)="changeStatus(notification)"></div>
                        </div>
                    </div>
                    </div>
            </div>
            <!-- <div class="load-more" *ngIf="!filterByUnread && !isFetchingdata && !paginationAll.isFinished"><span (click)="loadMoreNotifications()" [disabled]="isButtonDisabled">Load More...</span></div> -->
            <div class="load-more" *ngIf="!filterByUnread && !isFetchingdata && !paginationAll.isFinished">
                <span (click)="loadMoreNotifications()" [hidden]="isButtonDisabled">Load More...</span>
              </div>
              
            </ng-container>
        </div>
        <div></div>
    </div>
</div>

<!-- <paper-icon-button icon="mail" slot="dropdown-trigger" (click)="toggleNotificationDropdown()"></paper-icon-button> -->
<!-- <img (click)="toggleNotificationDropdown()" class="icon-img" src="/assets/images/iconmonstr-bell-2.svg" alt="" srcset=""> -->
<div class="noti-icon-wrapper">
<img (click)="toggleNotificationDropdown()" class="icon-img" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUgMjFjMCAxLjU5OC0xLjM5MiAzLTIuOTcxIDNzLTMuMDI5LTEuNDAyLTMuMDI5LTNoNnptLjEzNy0xNy4wNTVjLS42NDQtLjM3NC0xLjA0Mi0xLjA3LTEuMDQxLTEuODJ2LS4wMDNjLjAwMS0xLjE3Mi0uOTM4LTIuMTIyLTIuMDk2LTIuMTIycy0yLjA5Ny45NS0yLjA5NyAyLjEyMnYuMDAzYy4wMDEuNzUxLS4zOTYgMS40NDYtMS4wNDEgMS44Mi00LjY2OCAyLjcwOS0xLjk4NSAxMS43MTUtNi44NjIgMTMuMzA2djEuNzQ5aDIwdi0xLjc0OWMtNC44NzctMS41OTEtMi4xOTMtMTAuNTk4LTYuODYzLTEzLjMwNnptLTMuMTM3LTIuOTQ1Yy41NTIgMCAxIC40NDkgMSAxIDAgLjU1Mi0uNDQ4IDEtMSAxcy0xLS40NDgtMS0xYzAtLjU1MS40NDgtMSAxLTF6bS02LjQ1MSAxNmMxLjE4OS0xLjY2NyAxLjYwNS0zLjg5MSAxLjk2NC01LjgxNS40NDctMi4zOS44NjktNC42NDggMi4zNTQtNS41MDkgMS4zOC0uODAxIDIuOTU2LS43NiA0LjI2NyAwIDEuNDg1Ljg2MSAxLjkwNyAzLjExOSAyLjM1NCA1LjUwOS4zNTkgMS45MjQuNzc1IDQuMTQ4IDEuOTY0IDUuODE1aC0xMi45MDN6Ii8+PC9zdmc+">
<paper-badge *ngIf="unreadNotifications.length > 0" [label]="notificationBadgeLabel" class="notification-badge" style="left: 22px; top: 8.8px;"></paper-badge>

    </div>